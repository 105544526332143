/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react"
import Logo from "../Logos/Logo"
import Icon from "../Icons/Icon.jsx"
import {StrDef,StringifyOCode,DrawOCode} from '../../assets/lib/utils'
import { withTranslation } from 'react-i18next'
import LanguagesModal from "components/Modal/LanguagesModal"
import { languages } from "utils/languages"

const WIDHT_CANVAS_OCODE_DISPLAY = 120
class PublicNavbar extends React.Component {
  state = {
    isOpen: false,
    loading : false,
  };
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    },async () =>{
      if (this.state.isOpen) {
        let ocode = window.API.ocodeId;
        if (!StrDef(ocode)) {
          this.setState({loading:true})
          let res = await window.API.get_consumer('byConsumerId');
          if (!StrDef(res.error)) {
            ocode = res.visit_card_ocode_id;
          }
        }
        var canvas = document.getElementById('ocode');
        var ctx = canvas.getContext('2d');
        canvas.width = WIDHT_CANVAS_OCODE_DISPLAY
        canvas.height = WIDHT_CANVAS_OCODE_DISPLAY
        let chaine = StringifyOCode(ocode)
        ctx = DrawOCode(chaine,ctx,30,30,true, "#1B1464")
        this.setState({loading:false})
      }
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  };

  LinkClick(path) {
    this.toggle()
    if (path === '/') {
      window.Logout()
    }
    this.props.history.push(path);
    this.setState({active:path})
  }

  isLightBackground = () => {
    return document.body.classList.contains("light-header")
  }

  render() {
    const { t } = this.props;
    return (

      <div className={"c-menu-navbar " + (this.props.className || " ") + (this.state.isOpen ? "is-open" : "")}>

        <div className="c-menu-navbar__navbar u-flex-desktop-only u-pd-hz-m">
          <button
            onClick={this.LinkClick.bind(this,'/moncompte/mes-velos')}
            className={"c-menu-navbar__item u-pd-vt-m " + this.activeRoute('/moncompte/mes-velos')}
          >
            {t('navbar.consumer.label_bike')}
          </button>

          <button
            onClick={this.LinkClick.bind(this,'/moncompte/mon-profil')}
            className={"c-menu-navbar__item u-pd-vt-m " + this.activeRoute('/moncompte/mon-profil')}
          >
            {t('navbar.consumer.label_account')}
          </button>

          <button
            onClick={this.LinkClick.bind(this,'/')}
            className={"c-menu-navbar__item u-pd-vt-m " + this.activeRoute('/')}
          >
            {t('navbar.consumer.label_disconnect')}
          </button>
          {(window.API.getSubDomain() === 'wallet' || window.API.getSubDomain() === 'decathlon') &&
            <LanguagesModal languages={languages} className='u-pd-vt-m' isLightBackground={this.isLightBackground()} />
          }
        </div>

        <div className="c-menu-navbar__navbar u-flex-mobile-only u-pd-hz-m">
          <div className="c-menu-navbar__logo u-flex-1">
            <Logo className="u-primary" />
          </div>
          <button className="c-menu-navbar__toggle u-flex-0" title={t("navbar.consumer.title_open_close_menu")} onClick={this.toggle}>
              <Icon key={'menu'} name="menu" size={25} />
          </button>
        </div>

        {/* TODO: refacto the nav links in a single component to avoid duplicate */}
        <div className="c-menu-navbar__menu u-flex-mobile-only">

          <div className="c-menu-navbar__header u-pd-hz-l">
            <h2 className="c-menu-navbar__title">{t("common.menu")}</h2>
          </div>

          <div className="c-menu-navbar__content">
            <div className="c-menu-navbar__links u-flex-1 u-pd-l">
              <button
                onClick={this.LinkClick.bind(this,'/moncompte/mes-velos')}
                className={"c-menu-navbar__item u-pd-vt-m " + this.activeRoute('/moncompte/mes-velos')}
              >
                {t('navbar.consumer.label_bike')}
              </button>

              <button
                onClick={this.LinkClick.bind(this,'/moncompte/mon-profil')}
                className={"c-menu-navbar__item u-pd-vt-m " + this.activeRoute('/moncompte/mon-profil')}
              >
                {t('navbar.consumer.label_account')}
              </button>

              {/*
              <button
                onClick={this.LinkClick.bind(this,'/moncompte/faq')}
                className={"c-menu-navbar__item u-pd-vt-m " + this.activeRoute('/moncompte/faq')}
              >
                {t('navbar.consumer.label_faq')}
              </button>
              */}
              <button
                onClick={this.LinkClick.bind(this,'/')}
                className={"c-menu-navbar__item u-pd-vt-m " + this.activeRoute('/')}
              >
                {t('navbar.consumer.label_disconnect')}
              </button>
              {(window.API.getSubDomain() === 'wallet' || window.API.getSubDomain() === 'decathlon') &&
                <LanguagesModal languages={languages} className='u-pd-vt-m' isLightBackground={this.isLightBackground()} />
              }
            </div>

            <div className="u-flex-0 u-pd-left-l">
              <div className="c-section-title u-relative">
                <div className="c-section-title__content">
                  <h2 className="c-h2 u-medium u-uppercase">{t('navbar.consumer.label_ocode')}</h2>
                  <Icon key={''} name="" size={40} />
                </div>
              </div>
              <div className="u-flex u-flex-center-hz">
                {this.state.loading &&
                  <div className="u-center">
                    <div className="c-spinner"></div>
                  </div>
                }
                <canvas id='ocode' style={{display : this.state.loading ? 'none' : 'block'}}/>
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default withTranslation()(PublicNavbar);
