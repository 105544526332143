import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import common_fr_FR from "./translations/fr-FR/common.json";
//import common_fr_BE from "./translations/fr-BE/common.json";
import common_en_UK from "./translations/en-UK/common.json";
import common_nl_NL from "./translations/nl-NL/common.json";
//import common_nl_BE from "./translations/nl-BE/common.json";

const resources = {
  'fr-FR': {
    translation: common_fr_FR
  },
  // 'fr-BE': {
  //   translation: common_fr_BE
  // },
  'en-UK' : {
    translation: common_en_UK
  },
  'nl-NL' : {
    translation: common_nl_NL
  },
  // 'nl-BE' : {
  //   translation: common_nl_BE
  // }
};

export const getLanguage = () => {
  return i18next.language || (typeof window !== "undefined" && window.localStorage.i18nextLng) || 'fr-FR'
}

export const translationContext = i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      // if languages detected isn't handled by the app, we display the site in French
      fallbackLng: ['fr-FR'],
      // translation files
      resources,
      // options of LanguageDetector library 
      detection: {
        // query string parameter (URL)
        lookupQuerystring: 'locale',
        // // language detection preference order
        order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator'], 
        // It allows integrating dynamic values into your translations.
        interpolation: {
          // false = XSS attacks are possible (please use dangerouslySetInnerHTML in your code)
          escapeValue: false 
        }
      },
      react: {
        useSuspense: false
      },
    }
  );
