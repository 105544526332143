import * as ReactIs from "react-is"

export const isDefined = (obj) => {
    return typeof(obj) !== "undefined" && obj !== null
}

export const isNotEmpty = (obj) => {
    return (typeof(obj) !== "undefined" && obj !== null && obj !== "")
}

export const isPasswordValid = (str) => {
    const reg = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z\W]{8,}$/)
    return !(str.match(reg) === null)
}

export const isEmailValid = (str) => {
    const reg = new RegExp(/^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/)
    return !(str.match(reg) === null)
}

export const isHourValid = (str) => {
    // 9:00, 18:00, ...
    const reg = new RegExp(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)
    return !(str.match(reg) === null)
}

export const isAccountNumberValid = (str) => {
    const reg = new RegExp(/^\d{11}$/)
    return !(str.match(reg) === null)
}

export const isInt = (str) => {
    return /^[0-9]*$/.test(str)
}

export const isReactElement = (obj) => {
    return ReactIs.isElement(obj)
}

export const isBikeCodeValid = (str) => {
    return str?.length > 6
}

export const isConfirmationCodeValid = (str) => {
    const reg = new RegExp(/^[0-9]{6}$/)
    return !(str.match(reg) === null)
}

export const isSiretValid = (siret) => {
    const size = 14
    if (isNaN(siret) || siret.length !== size) return false
    let bal = 0
    let total = 0
    for (let i = size - 1; i >= 0; i--) {
        const step = (siret.charCodeAt(i) - 48) * (bal + 1)
        total += (step > 9) ? step - 9 : step
        bal = 1 - bal
    }
    return (total % 10 === 0) ? true : false
}

export const isRnaValid = (rna) => {
    // start with W + 9 numbers
    const reg = new RegExp(/^[w,W]\d{9}$/)
    return !(rna.match(reg) == null)
}
