import React from "react";
import {StrDef, GetBikeLabelStatus, GetBikeIdFormat} from '../assets/lib/utils';
import LabelBike from '../components/Labels/LabelBike';
import FormInput from '../components/Forms/FormInput';
import Icon from "../components/Icons/Icon.jsx";
import { createBrowserHistory } from "history";
import { withTranslation } from "react-i18next";
import ReactMarkdown from 'react-markdown'

import BikesTable from "components/table/BikesTable"
import LogoDecathlon from "components/Logos/LogoDecathlon"

const BIKE_LIMIT = 20;

// @todo: to be deleted
const randomInteger = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min


class Bike extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bikes: [],
      bikeBundle: [],
      loading: false,
      isWallet: true,
      isFleetManager: false,
      isDecathlonBikeInBikeCollection: false,

      formDataCode :{
        code : '',
      },
      formErrorCode :{
        code : false,
      },
      formEventCode : {
        ready : true,
      },
    }

    this.lookAtBike = this.lookAtBike.bind(this);
    this.scanOcode = this.scanOcode.bind(this);

    this.bundleTableRef = React.createRef();
  }

  // Dynamic fake data
  fakeData =[...Array(20)].map(x => [
    {
        bikecode: "BC112AA430",
        brand: "Cube",
        model: "Strero 150",
        type: "VTT",
        color: "Noir",
        internalCode: "01234567890123"
    },
    {
        bikecode: "BC112AA431",
        brand: "Lapierre",
        model: "Shaper 1.0",
        type: "VTC",
        color: "Bleu",
        internalCode: "01234567890124"
    },
    {
        bikecode: "BC112AA432",
        brand: "Moustache",
        model: "Lundi",
        type: "Router",
        color: "Rouge",
        internalCode: "01234567890125"
    }
  ][randomInteger(0,2)])


  async componentDidMount() {
    if (StrDef(this.props.location.state) && StrDef(this.props.location.state.ocode)) {
      // on vient de scanner un o°code depuis cet écran
      let ocodeId = this.props.location.state.ocode
      let res = await window.API.get_one_bike('byOcode',ocodeId);
      // reset history
      const history = createBrowserHistory();
      const state = { ...history.location.state };
      delete state.ocode;
      history.replace({ ...history.location, state });
      if (!StrDef(res.error)) {
        this.props.history.push({
          pathname: '/moncompte/mes-velos/'+res.bike_id,
          state: { detail: res.bike_id }
        });
      } else if (StrDef(res.error)) {
        if (res.error.code === 'RessourceNotFoundException') {
          // on regarde s'il ne vient d'acheter un vélo par le biais de la pré-inscription
          let resBikeOcode = await window.API.get_bike_id(ocodeId);
          if (StrDef(resBikeOcode.error) && res.error.code === 'RessourceNotFoundException') {
            window.notify(this.props.t('bike.error_ocode_unknown'),'danger')
          } else {
            this.setState({
              formDataCode: {
                ...this.state.formDataCode,
                code : resBikeOcode.bike_id
              }
            })
          }
        }
      }
    }
    // Reset aside
    if (this.props.setLayoutAside) this.props.setLayoutAside(!this.props.isFleetManager)
    this.load()
  }

  async load() {
    this.setState({loading:true})
    let bikes = []
    if (window.API.getSubDomain() === 'wallet') {
      let res = await window.API.get_bike()
      if (!StrDef(res.error)) {
        for (var i = 0; i < res.length; i++) {
          bikes.push(res[i])
        }
        while (res.length >= BIKE_LIMIT) {
          res = await window.API.get_bike(res[res.length - 1].modify_date)
          for (var y = 0; y < res.length; y++) {
            bikes.push(res[y])
          }
        }
        // le dernier
        if (res.length > 0) {
          res = await window.API.get_bike(res[res.length - 1].modify_date)
          if (res.length < BIKE_LIMIT) {
            for (var z = 0; z < res.length; z++) {
              bikes.push(res[z])
            }
          }
        }
        bikes = bikes.filter(oneBike => oneBike.bike_status !== 3);

        // If at least 1 DK bike in list => update boolean and not display DK message
        if(bikes.find(bike => bike?.operator === "DK"))  this.setState({isDecathlonBikeInBikeCollection:true})

        this.setState({bikes:bikes,loading:false})
      } else {
        // TODO - fbo - gestion d'erreurs
      }
    } else {
      let res = await window.API.get_bike_nowallet('byBikeId',window.API.bikeId)
      if (!StrDef(res.error)) {
        bikes.push(res)
        this.setState({
          loading :false,
          isWallet:false,
          bikes   :bikes
        })
      } else if (StrDef(res.error)) {
        if (res.error.code === 'NotAuthorizedException') {
          //window.Logout();
        } else if (res.error.code === 'RessourceNotFoundException') {
          this.setState({
            loading :false,
            isWallet:false,
            bikes   :[]
          })
        }
      }
    }
  }

  lookAtBike(bike) {
    if (bike.pending_ownership_transfer === true) {
      let uri;
      if (this.state.isWallet) {
        uri = '/moncompte/mes-velos/'+bike.bike_id+'/transferer'
      } else {
        uri = '/moncompte/mon-velo/'+bike.bike_id+'/transferer'
      }
      this.props.history.push({
        pathname: uri,
        state: {
          from: this.props.location.pathname,
          bike_id : bike.bike_id,
          row_version : bike.row_version,
          bike_description : bike.bike_description,
          pending_ownership_transfer : true,
          operator: bike.operator
        }
      });
    } else {
      if (this.state.isWallet) {
        this.props.history.push({
          pathname: '/moncompte/mes-velos/'+bike.bike_id,
          state: { detail: bike.bike_id }
        });
      } else {
        this.props.history.push({
          pathname: '/moncompte/mon-velo/'+bike.bike_id,
          state: { detail: bike.bike_id }
        });
      }
    }
  }

  scanOcode() {
    this.props.history.push({
      pathname: '/moncompte/scan',
      state: {
        from: this.props.location.pathname,
        title : this.props.t("bike.table_scan")
      }
    });
  }

  handleInputChangeCode = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState({
      formDataCode: {
        ...this.state.formDataCode,
        [name]: name === 'code' ? value.toUpperCase() : value
      },
      formErrorCode: {
        ...this.state.formErrorCode,
        [name]: false
      }
    })
  }

  addToWallet = async (event) => {
    event.preventDefault()
    const formReady = this.formValidation()
    if (formReady) {
      this.setState({
        formEvent: {
            ...this.state.formEvent,
            ready: false
        },
      })

      let code  = this.state.formDataCode.code.replace(/\s/g, '')
      this.props.history.push({
        pathname: '/moncompte/mes-velos/ajouter/'+code,
        state: { detail: code }
      });

      this.setState({
        formEvent: {
            ...this.state.formEvent,
            ready: true
        },
      })
    }
  }

  formValidation = () => {
    let formData = this.state.formDataCode;
    let formError = this.state.formErrorCode;
    let inputValidation = Object.keys(formData).map(itemName => {
      switch (itemName) {
        case 'code':
          const codeError = !StrDef(formData.code)
          if (codeError) {
            formError.code = codeError
          }
          return !codeError
        default:
            return true
      }
    })
    this.setState({formErrorCode:formError})
    return inputValidation.reduce((acc, next) => acc && next)
  }

  // Manage bike bundle
  setBikeBundle = (bundle) => {
    this.setState({
      bikeBundle: bundle
    })
  }

  scrollToBundle = () => {
    if (this.bundleTableRef) {
      this.bundleTableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }
  
  render() {
    const { t } = this.props;

    return (
      <main className={"c-main-content u-flex u-flex-dir-col u-pd-hz-xl u-pd-vt-m u-pd-xl@main"}>

        {this.state.loading &&
          <div className="u-flex u-flex-center-vt u-flex-center-hz u-mg-top-xl">
            <div className="c-spinner"></div>
          </div>
        }

        {!this.state.loading &&
          <>
            <div className="c-section-title u-pd-left-m u-mg-negative-hz-xl u-mg-bottom-l">
              <div className="l-container-md u-relative u-pd-right-m">
                <div className="c-section-title__content">
                  <h1 className="c-h2 u-medium u-uppercase">{this.state.isWallet ? t('bike.label_bikes') : t('bike.label_bike')}</h1>
                  <Icon key={'bike'} name="bike_bottom" size={40} />
                </div>
              </div>
            </div>

            {this.state.isWallet &&
              <div className="u-flex u-flex-end u-mg-bottom-l">
                <button
                  type="button"
                  className="u-underline"
                  onClick={() => {
                    const isFleetManager = !this.state.isFleetManager
                    this.setState({ isFleetManager: isFleetManager })
                    if (this.props.setLayoutAside) this.props.setLayoutAside(!isFleetManager)
                  }}
                >
                  {this.state.isFleetManager ? t('bike.change_view_classic') : t('bike.change_view_bundle')}
                </button>
              </div>
            }

            <div className="u-flex u-flex-1 u-flex-dir-col">

              {!this.state.isFleetManager &&
                <div className="">
                  {this.state.isWallet && this.state.bikes.length === 0 &&
                    <div className="l-form-width-container">
                      <p className="c-h3 u-bold u-center u-mg-bottom-l">{t('bike.welcome_title')}&nbsp;!</p>
                      <p className="c-h3 u-medium u-mg-bottom-s">{t('bike.welcome_title_p1')}</p>
                      <p className="u-fs-xs u-blue20 u-mg-bottom-l">{t('bike.welcome_explain_p1')}</p>
                      <p className="c-h3 u-medium u-mg-bottom-s">{t('bike.welcome_title_p2')}</p>
                      <h4 className="u-fs-xs u-blue20 u-mg-bottom-l">{t('bike.welcome_explain_p2')}</h4>
                    </div>
                  }

                  <div className="l-form-width-container">
                    {this.state.bikes.length > 0 && this.state.bikes.map((data, i) => {
                      let status = GetBikeLabelStatus(data.bike_status)
                      if (data.pending_ownership_transfer === true) {
                        status = t('bikestatus.label_transit')
                      }
                      let bikeId = (data.is_bicycode_2_0 === 1 || data.is_bicycode_2_0 === 0) ? data.bike_id : GetBikeIdFormat(data.bike_id)
                      return(
                        <LabelBike
                          key={'lb'+i}
                          className="u-mg-bottom-xl"
                          status={status}
                          bike_status={data.bike_status}
                          brand={data.bike_description.brand}
                          code={bikeId}
                          ocode={data.ocode_id}
                          lookAtBike={this.lookAtBike.bind(this,data)}
                          operator={data.operator}
                        />
                      );
                    })}
                  </div>
                </div>
              }

              {this.state.isFleetManager &&
                <>
                  <BikesTable
                    data={this.state.bikes}
                    refreshData={this.load.bind(this)}
                    hasSelectableRows={true}
                    selection={this.state.bikeBundle}
                    callback= {this.setBikeBundle}
                    scrollToBundle={this.scrollToBundle}
                    history={this.props.history}
                    location={this.props.location}
                  />

                  {this.state.bikeBundle && this.state.bikes.length > 0 &&
                    <div ref={this.bundleTableRef}>
                      <div className="c-section-title u-pd-left-m u-mg-negative-hz-xl u-mg-top-xxl u-mg-bottom-l">
                        <div className="l-container-md u-relative u-pd-right-m">
                          <div className="c-section-title__content">
                            <h1 className="c-h2 u-medium u-uppercase">{t('bike.bundleTitle')}</h1>
                            <Icon key={'bike'} name="bike_bottom" size={40} />
                          </div>
                        </div>
                      </div>
                      <BikesTable
                        data={this.state.bikeBundle}
                        refreshData={this.load.bind(this)}
                        isBikeBundle={true}
                        hasBikeActions={true}
                        history={this.props.history}
                        location={this.props.location}
                      />
                    </div>
                  }
                </>
              }

              {this.state.isWallet && !this.state.isDecathlonBikeInBikeCollection &&
                <div className="u-fs-xs u-primary u-pd-vt-l l-form-width-container">
                    {/* <div className="u-mg-bottom-s">
                      <LogoDecathlon width="100" height="25" />
                    </div> */}
                    <ReactMarkdown
                    			components={{
                            a: ({ href, children }) => (
                              <a className="u-underline u-mg-vt-s" target="_blank" rel="noopener noreferrer" href={href}>
                                {children}
                              </a>
                            ),
                          }}
                    >
                      {t('bike.d4_message')}
                    </ReactMarkdown>
                  </div>    
                }

              {this.state.isWallet && !this.state.isFleetManager &&
                <div className="u-flex u-flex-0 u-flex-dir-col u-flex-center-vt u-flex-center-hz">
                  {/* <div className="c-owallet-block">
                    <p className="c-h3 u-medium u-mg-bottom-xs">{ t('owalletBlock.title')}</p>
                    <p className="u-fs-xs">
                      <span className="u-bold">{ t('owalletBlock.attention')} :</span> { t('owalletBlock.description')}
                    </p>
                  </div> */}

                  <form className="l-form-width">
                    <div className="u-flex u-flex-end-vt">
                      <FormInput
                        style={{ minWidth: "calc(100% - 20px)" }}
                        formData={this.state.formDataCode}
                        formError={this.state.formErrorCode}
                        what={"code"}
                        error={t('bike.label_code_ocode_error')}
                        placeholder={t('bike.label_code_ocode')}
                        handleInputChange={this.handleInputChangeCode}
                      />
                      <button className="c-btn-form-group" onClick={this.scanOcode}>
                        <Icon key={'scan'} name="scan" size={40} />
                      </button>
                    </div>
                    <div className="u-flex u-flex-center-hz u-mg-top-l">
                      <button
                        type="submit"
                        className="c-btn c-btn--primary"
                        onClick={this.state.formEventCode.ready ? this.addToWallet.bind(this) : undefined}
                      >
                        {this.state.formEventCode.ready ? t('bike.label_valid') : t('bike.label_waiting')}
                      </button>
                    </div>
                  </form>
                </div>
              }
            </div>
          </>
        }

      </main>
    )
  }
}

export default withTranslation()(Bike);
