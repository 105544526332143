import React from "react"
import { withTranslation } from 'react-i18next'
import { isValidPhoneNumber } from "react-phone-number-input"

import {StrDef, Clone, ValidateEmail, isInt} from '../../assets/lib/utils'
import { isNotEmpty } from "utils/validation"
import FormInput from '../Forms/FormInput'
import FormInputPhone from '../Forms/FormInputPhone'
import FormDate from '../Forms/FormDate'

const MAXLENGTH_CP = 5

class FormPerson extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: this.props.mode, // recovery //account // accountNoWallet // transfer // register = {true} entreprise particulier
      role: this.props.role || 1, // soit on définit la propriété soit c'est 1 par défaut
      formData: this.props.formData,
      formError: {}, //this.props.formError,
      formEvent: {ready:true}, //this.props.formEvent,
      disabled: this.props.disabled || false, // false si non précisé
      citySuggestion: [],
    }

    this.listRole = [
      {id:1, label: this.props.t('formperson.role_particular')},
      {id:2, label: this.props.t('formperson.role_company')}
    ]
  }

  componentDidMount () {
    let confirmMail = document.getElementsByName('confirmMail')[0]
    if (StrDef(confirmMail)) {
      confirmMail.onpaste = function(e) {
        e.preventDefault();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.disabled !== prevProps.disabled) {
      this.setState({disabled:this.props.disabled})
    }
    if (this.props.formData !== prevProps.formData) {
      this.setState({formData:this.props.formData})
    }
    if (this.props.role !== prevProps.role) {
      this.setState({role:this.props.role})
    }
  }

  changeValueRole = (e) => {
    let role = e.currentTarget.id;
    this.setState({
      role:parseInt(role)
    },() => {
      this.props.updateState(this.state)
    })
  }

  handleInputChange = (event, type) => {  
    const target = event.target
    var value = ""
    var name = ""

    if (!target && (type = "date")) {
      name = type
      value = event        
    } else {
      value = target.type === 'checkbox' ? target.checked : target.value
      name = target.name
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value,
      },
      formError: {
        ...this.state.formError,
        [name]: false
      }
    },() => {
      this.props.updateState(this.state)
      if( name === 'cp' && value.length === 5 ) {
        const url = 'https://geo.api.gouv.fr/communes?codePostal='+value+'&fields=nom,code,codesPostaux,codeDepartement,population&format=json';

        switch (value.toString()) {
          case "75000":
            return this.setState({
              citySuggestion:[{ nom: "Paris", cp: "75000", code: "75000", dpt: "75" }],
              formError: {
                ...this.state.formError,
                [name]: false
              }
            })
          case "13000":
            return this.setState({
              citySuggestion:[{ nom: "Marseille", cp: "13000", code: "13000", dpt: "13" }],
              formError: {
                ...this.state.formError,
                [name]: false
              }
            })
          case "69000":
            return this.setState({
              citySuggestion:[{ nom: "Lyon", cp: "69000", code: "69000", dpt: "69" }],
              formError: {
                ...this.state.formError,
                [name]: false
              }
            })
          default:
            return fetch(url)
            .then( res => res.json() )
            .then( data => {
              if( data.length > 0 ) {
                var city=null, res=[];
                for(var i=0;i<data.length;i++) {
                  city = data[i];
                  for(var c=0;c<city.codesPostaux.length;c++) {
                    res.push({
                      code:city.code,
                      dpt:city.codeDepartement,
                      cp:city.codesPostaux[c],
                      nom:city.nom
                    });
                  }
                }
                this.setState({
                  citySuggestion:res,
                  formError: {
                    ...this.state.formError,
                    [name]: false
                  }
                })
              } else {
                window.notify('Code postal inconnu','danger');
                this.setState({
                  formError: {
                    ...this.state.formError,
                    [name]: true
                  }
                })
              }
            })
        }

      }
    })
  }

  formValidation = () => {
    let formData = this.state.formData;
    let formError = this.state.formError;
    let inputValidation = Object.keys(formData).map(itemName => {
      switch (itemName) {
        case 'lastName':
          if (this.state.role === 2) {//entreprise
            return true;
          } else {
            const lastNameError = !StrDef(formData.lastName)
            if (lastNameError) {
              formError.lastName = lastNameError
            }
            return !lastNameError
          }
        case 'firstName':
          if (this.state.role === 2) {//entreprise
            return true;
          } else {
            const firstNameError = !StrDef(formData.firstName)
            if (firstNameError) {
              formError.firstName = firstNameError
            }
            return !firstNameError
          }
        case 'socialReason':
          if (this.state.role === 1) {//particulier
            return true;
          } else {
            const socialReasonError = !StrDef(formData.socialReason)
            if (socialReasonError) {
              formError.socialReason = socialReasonError
            }
            return !socialReasonError
          }
        case 'phone':
          const phoneError = !isNotEmpty(formData.phone) || !isValidPhoneNumber(formData.phone)

          if (phoneError) {
            formError.phone = phoneError
            formError.errorPhone = this.props.t('formperson.label_phone_error')
          }
          return !phoneError
        case 'email':
          const emailError = !StrDef(this.state.formData.email) || !ValidateEmail(this.state.formData.email)//(this.state.formData.mail.match(/^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/) == null)
          if (emailError) {
            formError.email = emailError
            formError.errorMail = this.props.t('formperson.label_mail_error')
          }
          return !emailError
        case 'confirmMail':
          if (this.state.mode === 'preinscription' || this.state.mode === 'register') {
            const confirmMailError = !StrDef(this.state.formData.confirmMail) || !ValidateEmail(this.state.formData.confirmMail) || (this.state.formData.confirmMail !== this.state.formData.email)//(this.state.formData.mail.match(/^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/) == null)
            if (confirmMailError) {
              formError.confirmMail = confirmMailError
              if (!StrDef(this.state.formData.confirmMail)) {
                formError.errorConfirmMail = this.props.t('formperson.label_confirmmail_error')
              } else if (!ValidateEmail(this.state.formData.confirmMail)) {
                formError.errorConfirmMail = this.props.t('formperson.label_mail_error')
              } else if (this.state.formData.confirmMail !== this.state.formData.email) {
                formError.errorConfirmMail = this.props.t('formperson.label_mail_notsame_error')
              }
            }
            return !confirmMailError
          } else {
            return true;
          }
        case 'cp':
          if (this.state.mode === 'account') {
            const cpError = StrDef(this.state.formData.cp) && (this.state.formData.cp.length < 5 || !isInt(this.state.formData.cp))
            if (cpError) {
              formError.cp = cpError
            }
            return !cpError
          } else {
            return true;
          }
        default:
            return true
      }
    })
    this.setState({formError:formError})
    return inputValidation.reduce((acc, next) => acc && next)
  }

  checkMail = async (event) => {
    event.preventDefault()
    // Validate inputs
    const formReady = this.formValidation()
  
    if (formReady) {
        // Inform the state that the component is unavailable
        // to prevent any new user submit
        this.setState({
          formData: { ...this.state.formData, },
          formError: { ...this.state.formError },
          formEvent: {
              ...this.state.formEvent,
              ready: false
          },
        })

        let res = await window.API.check_mail(this.state.formData.email);
        if (!StrDef(res.error)) {
          this.props.onSubmit()
        } else if (StrDef(res.error)) {
          if (res.error.code === 'MailNotAvailableException') {
            window.notify(this.props.t("formperson.label_mail_unavailable_error"),'danger')
          } else if (res.error.code === 'TooManyRequestsException') {
            window.notify(this.props.t("formperson.label_mail_code_sent"),'danger')
          }
        }

        //cal API
        this.setState({
          formEvent: {
            ...this.state.formEvent,
            ready: true
          }
        })
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const formReady = this.formValidation()

    if (formReady) {
      this.setState({
        formData: { ...this.state.formData, },
        formError: { ...this.state.formError },
        formEvent: {
            ...this.state.formEvent,
            ready: false
        },
      })

      //cal API
      this.props.onSubmit()
      this.setState({
        formEvent: {
          ...this.state.formEvent,
          ready: true
        },
      })
    }
  }

  cpAdd(data,index) {
    var suggest = Clone(this.state.citySuggestion);
    suggest.splice(index,1);
    this.setState({
      citySuggestion : [],
      formData: {
        ...this.state.formData,
        city : data.nom,
        insee_code : data.code,
        cp : data.cp
      },
      formError: {
        ...this.state.formError,
        city : false,
      }
    },() => {
      this.props.updateState(this.state)
    });
  }

  cpClear() {
    this.setState({
      citySuggestion : [],
      formData: {
        ...this.state.formData,
        cp : '',
        city : '',
      },
      formError: {
        ...this.state.formError,
      }
    });
  }

  toggleDisabled = () => {
    this.setState({ disabled: !this.state.disabled },() => {
      if (this.state.disabled && StrDef(this.props.onCancel)) {
        this.props.onCancel()
      }
    })
  }

  disabled() {
    this.setState({disabled:true},() =>{
      this.props.onDisabled()
    })
  }

  render() {
    const { t } = this.props;
    return (
      <form className="l-form-width ">
        {(this.state.mode === 'register' || this.state.mode === 'preinscription' || this.state.mode === 'recovery') &&
          <div className="u-flex u-flex-dir-col u-flex-dir-row@main u-flex-align-center-vt u-flex-between u-mg-bottom-l">
          {this.listRole.map((data, i) => {
            return (
              <label
                key ={'listRole'+i}
                className={"c-radio u-mg-bottom-s " + ((data.id === this.state.role) ? "is-checked" : "")}
              >
                <input
                  type="radio"
                  id={data.id}
                  className="u-sr-only"
                  name="roles"
                  value={data.id}
                  checked={data.id === this.state.role}
                  onChange={this.changeValueRole}
                />
                <div>{data.label}</div>
              </label>
            )
          })}
          </div>
        }

        {/* Particular */}
        {this.state.role === 1 &&
          <>
            <FormInput
              className={"u-mg-bottom-m"}
              formData={this.state.formData}
              formError={this.state.formError}
              what={"lastName"}
              error={t('formperson.label_lastname_error')}
              placeholder={t('formperson.label_lastname')}
              handleInputChange={this.handleInputChange}
              disabled={this.state.disabled}
            />

            <FormInput
              className={"u-mg-bottom-m"}
              formData={this.state.formData}
              formError={this.state.formError}
              what={"firstName"}
              error={t('formperson.label_firstname_error')}
              placeholder={t('formperson.label_firstname')}
              handleInputChange={this.handleInputChange}
              disabled={this.state.disabled}
            />
          </>
        }

        {/* Company */}
        {this.state.role === 2 &&
          <FormInput
            className={"u-mg-bottom-m"}
            formData={this.state.formData}
            formError={this.state.formError}
            what={"socialReason"}
            error={t('formperson.label_socialreason_error')}
            placeholder={t('formperson.label_socialreason')}
            handleInputChange={this.handleInputChange}
            disabled={this.state.disabled}
            />
        }

        <FormInputPhone
          className={"u-mg-bottom-m"}
          formData={this.state.formData}
          formError={this.state.formError}
          what="phone"
          error={this.state.formError.errorPhone}
          label={t('formperson.label_phone')}
          handleInputChange={this.handleInputChange}
          disabled={this.state.disabled}
        />

        {this.state.mode !== 'account' &&
          <FormInput
            className={"u-mg-bottom-m"}
            formData={this.state.formData}
            formError={this.state.formError}
            what={"email"}
            error={this.state.formError.errorMail}
            placeholder={t('formperson.label_mail')}
            handleInputChange={this.handleInputChange}
            disabled={this.state.disabled}
          />
        }
        {(this.state.mode === 'register' || this.state.mode === 'preinscription') &&
          <FormInput
            className={"u-mg-bottom-m"}
            formData={this.state.formData}
            formError={this.state.formError}
            what={"confirmMail"}
            error={this.state.formError.errorConfirmMail}
            placeholder={t('formperson.label_confirm_mail')}
            handleInputChange={this.handleInputChange}
            disabled={this.state.disabled}
          />
        }

        {(this.state.mode === 'register' || this.state.mode === 'firstLogin') &&
          <>
            <p className="u-fs-xs u-center u-primary u-pd-vt-s">
              {t('formperson.label_codetemp_explain')}
            </p>
            <div className="u-flex u-flex-center-hz u-mg-top-l">
              <button
                type="button"
                className="c-btn c-btn--primary"
                onClick={this.state.formEvent.ready ? this.checkMail.bind(this) : undefined}
              >
                {this.state.formEvent.ready ? t('formperson.label_continue') : t('formperson.label_waiting')}
              </button>
            </div>
          </>
        }

        {this.state.mode === 'account' &&
          <>
            <div className="l-grid u-mg-bottom-m">
              <div className="l-col-6">
                <FormInput
                 formData={this.state.formData}
                 formError={this.state.formError}
                 what={"street_number"}
                 error={t('formperson.label_streetnumber_error')}
                 label={t('formperson.label_streetnumber')}
                 placeholder={t('formperson.label_streetnumber')}
                 handleInputChange={this.handleInputChange}
                 disabled={this.state.disabled}
                />
              </div>
            </div>
            <FormInput
              className="u-mg-bottom-m"
              formData={this.state.formData}
              formError={this.state.formError}
              what={"adresse"}
              error={t('formperson.label_address_error')}
              placeholder={t('formperson.label_address')}
              handleInputChange={this.handleInputChange}
              disabled={this.state.disabled}
            />

            <FormInput
              className={"u-mg-bottom-m"}
              formData={this.state.formData}
              formError={this.state.formError}
              what={"cp"}
              error={t('formperson.label_cp_error')}
              placeholder={t('formperson.label_cp')}
              handleInputChange={this.handleInputChange}
              maxLength ={MAXLENGTH_CP}
              disabled={this.state.disabled}
            />

            <FormInput
              className={ this.state.citySuggestion.length > 0 ? "" : "u-mg-bottom-m"}
              formData={this.state.formData}
              formError={this.state.formError}
              what={"city"}
              error={t('formperson.label_city_error')}
              placeholder={t('formperson.label_city')}
              handleInputChange={this.handleInputChange}
              disabled={this.state.disabled}
            />

            {this.state.citySuggestion.length > 0 &&
              <div className="u-relative">
                <div className="c-select-list u-pd-m">
                  <label className="u-bold">{t('formperson.label_choose_city')}</label>
                  { this.state.citySuggestion.map((data,i)=>{
                      return (
                        <button key={'btnCity'+i} color="link" style={{margin:0,textAlign:'left',width:'100%'}} onClick={this.cpAdd.bind(this,data,i)}>
                          {data.nom+' ('+data.cp+')'}
                        </button>
                      )
                    })
                  }
                  <div className="u-flex u-flex-end">
                    <button
                      className="u-block u-blue20 u-fs-xs u-pd-vt-s u-underline"
                      onClick={this.cpClear.bind(this)}
                    >
                      {t('formperson.label_choose_other_cp')}
                    </button>
                  </div>
                </div>
              </div>
            }

            {this.state.role === 1 &&
              <FormDate
                className={"u-mg-bottom-m"}
                value={this.state.formData.date}
                formError={this.state.formError}
                label={t('formperson.label_birthdate')}
                what={"date"}
                error={t('formperson.label_birthdate_error')}
                placeholder={t('formperson.label_birthdate')}
                handleInputChange={this.handleInputChange}
                disabled={this.state.disabled}
              />
            }

            {!this.state.disabled &&
              <div className="u-flex u-flex-center-vt u-mg-top-l">

                <div className="u-flex-1 u-flex u-flex-center-hz">
                  <button
                    type="button"
                    className="c-btn c-btn--primary"
                    onClick={this.disabled.bind(this)}
                  >
                    {t('formperson.label_cancel')}
                  </button>
                </div>

                <div className="u-flex-1 u-flex u-flex-center-hz">
                  <button
                    type="button"
                    className="c-btn c-btn--primary"
                    onClick={this.state.formEvent.ready ? this.handleSubmit.bind(this) : undefined}
                  >
                    {this.state.formEvent.ready ? t('formperson.label_save') : t('formperson.label_waiting')}
                  </button>
                </div>
              </div>
            }
          </>
         }

        {this.state.mode === 'transfer' &&
          <div className="u-flex u-flex-center-hz u-mg-top-l">
              <button
                type="submit"
                className="c-btn c-btn--primary"
                onClick={this.state.formEvent.ready ? this.handleSubmit.bind(this) : undefined}
              >
                {this.state.formEvent.ready ? t('formperson.label_transfer') : t('formperson.label_waiting')}
            </button>
          </div>
        }

        {this.state.mode === 'accountNoWallet' &&
          <div className="u-flex u-flex-center-vt u-mg-top-l">
            {!this.state.disabled &&
              <div className="u-flex-1 u-flex u-flex-center-hz">
                <button
                  type="button"
                  className="c-btn c-btn--primary"
                  onClick={this.toggleDisabled.bind(this)}
                >
                  {t('formperson.label_cancel')}
                </button>
              </div>
            }
            <div className="u-flex-1 u-flex u-flex-center-hz">
              <button
                type="button"
                className="c-btn c-btn--primary"
                onClick={
                  this.state.formEvent.ready ?
                  this.state.disabled ? this.toggleDisabled.bind(this) : this.handleSubmit.bind(this)
                  : undefined
                }
              >
                { this.state.formEvent.ready ?
                    (this.state.disabled ? t('formperson.label_modify') : t('formperson.label_save'))
                    : t('formperson.label_waiting')
                }
              </button>
            </div>
          </div>
        }

        {this.state.mode === 'preinscription' &&
          <>
            <p className="u-fs-xs u-blue20 u-mg-top-l u-mg-bottom-s">{t('formperson.preinscription_p1')}</p>
            <div className="u-flex u-flex-center-hz u-mg-top-l">
              <button
                type="submit"
                className="c-btn c-btn--primary"
                onClick={this.state.formEvent.ready ? this.handleSubmit.bind(this) : undefined}
              >
                {this.state.formEvent.ready ? t('formperson.label_valid') : t('formperson.label_waiting')}
              </button>
            </div>
          </>
        }

        {this.state.mode === 'recovery' &&
          <div className="u-flex u-flex-center-hz u-mg-top-l">
            <button
              type="submit"
              className="c-btn c-btn--primary"
              onClick={this.state.formEvent.ready ? this.handleSubmit.bind(this) : undefined}
            >
              {this.state.formEvent.ready ? t('formperson.label_continue') : t('formperson.label_waiting')}
            </button>
          </div>
        }

        {false && this.state.mode === 'firstLogin' &&
          <div className="u-flex u-flex-center-hz u-mg-top-l">
            <button
              type="submit"
              className="c-btn c-btn--primary"
              onClick={this.state.formEvent.ready ? this.handleSubmit.bind(this) : undefined}
            >
              {this.state.formEvent.ready ? 'Valider' : 'Patientez'}
            </button>
          </div>
        }
     </form>
    )
  }
}

export default withTranslation()(FormPerson)