import React from "react";
import {StrDef,GetBikeIdFormat} from '../assets/lib/utils';
import FormInput from '../components/Forms/FormInput';
import { withTranslation } from 'react-i18next';
import jwtDecode from "jwt-decode"
import NotificationAlert from "../components/Notification/Notification.js";
var moment = require('moment');

const BACKTOSTORE_REQUEST = "RETOUR"
const BUY_REQUEST = "RACHAT"

class PublicBackStoreBike extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step : 1,//2
      bikeId : '',
      socialReason : '',
      city: '',
      token: null,
      isExpiredToken: null,
      formDataBackStore : {
        brand : '',
        colorBike : '',
        model : '',
        gearType : '',
        fnuciCode : '',
        code : '', // ocode_id ou short_code_id
      },
      formErrorBackStore : {},
      formEventBackStore : {
        ready : false,
      },
    }
  }

  notificationAlert = React.createRef();

  async componentDidMount() {
    window.notify = (msg,type) => this.refs.notificationAlert.notificationAlert({ message: msg, type: type });

    const token = new URLSearchParams(this.props.location?.search).get("token")

    try {
        if (token) {
            const tokenDecoded = jwtDecode(token)
            // If a request exceeds 24 hours, we need to hide buttons
            // In develop/preprod, exp is created date + 1 hour. It's for tests
            const isExpiredToken = parseInt(moment.utc().valueOf()/1000) > tokenDecoded.exp

            if (tokenDecoded && (tokenDecoded.operation === BACKTOSTORE_REQUEST || tokenDecoded.operation === BUY_REQUEST)) {
                const bike_id           = tokenDecoded?.bike_id
                const bike_description  = tokenDecoded?.bike_description
                const is_bicycode_2_0   = tokenDecoded?.is_bicycode_2_0
                const store_data   = tokenDecoded?.store_data

                this.setState({
                    token,
                    isExpiredToken,
                    bikeId : bike_id,
                    socialReason : store_data?.nom_commercial,
                    city : store_data?.ville,
                    formDataBackStore: {
                        ...this.state.formDataBackStore,
                        brand : bike_description?.brand,
                        model : bike_description?.model,
                        gearType : bike_description?.gear_type,
                        colorBike : bike_description?.color,
                        fnuciCode : ( is_bicycode_2_0 === 0 || is_bicycode_2_0=== 1) ? tokenDecoded?.bike_id : GetBikeIdFormat(tokenDecoded?.bike_id),
                        operation: tokenDecoded?.operation
                    }
                })

                this.setState({
                    formEventBackStore: {
                        ...this.state.formEventBackStore,
                        ready: true
                    },
                })
            } else {
                throw new Error("operationNotAllowed")         
            }
        } else {
            throw new Error("notoken")         
        }
    } catch(error) {
        console.log(error)
        window.notify(this.props.t('onebike.error_bike_unknwown'),'danger')
    }
  }

  handleSubmit = async (consumerResponse) => {
    this.setState({
        formEventBackStore: {
            ...this.state.formEventBackStore,
            ready: false
        },
    })

    const backStoreBikePayload = {
        bike_id: this.state.bikeId,
        response: consumerResponse === "confirmReturn" ? "accepted" : "rejected",
    }    

    try {
        const res = await window.API.put_back_store_bike(backStoreBikePayload, this.state.token)
        if (StrDef(res.error)) throw new Error("operationNotAllowed") 

        consumerResponse === "confirmReturn" 
            ? window.notify(this.props.t('backstore.success_backstore'),'success') 
            : window.notify(this.props.t('onebike.label_cancel_recovery_bike'),'success')   

    } catch(error) {
        console.log(error)
        window.notify(this.props.t('onebike.error_update_coowner'),'danger') 

    } finally {
        this.props.history.push({
            pathname: '/',
        });
    }
  }

  render() {
    const { t } = this.props;

    const transfertRequestOperationType =
    this.state.formDataBackStore?.operation === BACKTOSTORE_REQUEST
        ? BACKTOSTORE_REQUEST.toLowerCase()
        : BUY_REQUEST.toLowerCase()

    return(
        <>
            <NotificationAlert ref="notificationAlert"/>          

            <main className={"c-main-content u-flex u-flex-dir-col"}>
                <div className="c-header u-pd-top-xl@main">
                    <div className="l-container-md u-pd-hz-m">
                        <h1 className="c-h1 c-header__title u-medium u-uppercase">
                            {t("backstore.title", { propertyTransferType: transfertRequestOperationType })}
                        </h1>
                    </div>
                </div>

                <div className="l-container-md u-pd-hz-m u-pd-vt-l">    

                    {/* Créer le lien */}
                    {this.state.step === 1 &&
                        <div className="l-form-width u-pd-bottom-xl">
                            <div className="u-center text-justify">
                                <h2 className="c-h2 u-center u-bold u-mg-bottom-xl">{t('backstore.subtitle_infos_bike')}</h2>
                                <p className="u-fs-xs u-center u-blue20 u-mg-bottom-xl">
                                    {t("backstore.label_backstore_p1", { propertyTransferType: transfertRequestOperationType })}
                                    <br /> {this.state.socialReason}, {this.state.city}</p>
                            </div>

                            <FormInput
                                className={"u-mg-bottom-m"}
                                formData={this.state.formDataBackStore}
                                formError={this.state.formErrorBackStore}
                                what={"brand"}
                                error={""}
                                placeholder={t('backstore.label_brand')}
                                handleInputChange={null}
                                disabled={true}
                            />

                            <FormInput
                                className={"u-mg-bottom-m"}
                                formData={this.state.formDataBackStore}
                                formError={this.state.formErrorBackStore}
                                what={"colorBike"}
                                error={""}
                                placeholder={t('backstore.label_color')}
                                handleInputChange={null}
                                disabled={true}
                            />

                            <FormInput
                                className={"u-mg-bottom-l"}
                                formData={this.state.formDataBackStore}
                                formError={this.state.formErrorBackStore}
                                what={"model"}
                                error={""}
                                placeholder={t('backstore.label_model')}
                                handleInputChange={null}
                                disabled={true}
                            />

                            <FormInput
                                className={"u-mg-bottom-l"}
                                formData={this.state.formDataBackStore}
                                formError={this.state.formErrorBackStore}
                                what={"gearType"}
                                error={""}
                                placeholder={t('backstore.label_gear_type')}
                                handleInputChange={null}
                                disabled={true}
                            />


                            <FormInput
                                className={"u-mg-bottom-l"}
                                formData={this.state.formDataBackStore}
                                formError={this.state.formErrorBackStore}
                                what={"fnuciCode"}
                                error={""}
                                placeholder={t('backstore.label_bikecode')}
                                handleInputChange={null}
                                disabled={true}
                            />

                            {this.state.isExpiredToken &&
                                <p className="u-fs-xs u-center u-blue20 u-mg-bottom-m u-danger">
                                    {t("backstore.expired_backstore_request", { propertyTransferType: transfertRequestOperationType })}
                                </p>
                            }

                            {this.state.formEventBackStore.ready && !this.state.isExpiredToken &&
                                <>
                                    <p className="u-fs-xs u-center u-blue20 u-mg-bottom-m">{t('backstore.label_backstore_p2')}</p>

                                    <div className="u-flex u-flex-between u-mg-top-l">
                                        <button
                                            type="button"
                                            className="c-btn c-btn--primary u-fs-xs"
                                            onClick={() => this.handleSubmit("cancelReturn")}
                                        >
                                            {t('backstore.label_goBack')}
                                        </button>

                                        <button
                                            type="button"
                                            className="c-btn c-btn--primary u-fs-xs"
                                            onClick={()=>{this.setState({step:2})}}
                                        >
                                            {t('backstore.label_continue')}
                                        </button>
                                    </div>
                                </>
                            }
                        </div>
                    }

                    {/* Verification */}
                    {this.state.step === 2 &&
                        <div className="l-form-width u-pd-bottom-xl">
                            <div className="u-center text-justify">
                                <h2 className="c-h2 u-center u-bold u-mg-bottom-xl">{t('backstore.subtitle_confirm')}</h2>
                                <p className="u-fs-xs u-blue20 u-mg-bottom-s">{t('backstore.label_confirm_p1')}</p>
                                <p className="u-fs-xs u-blue20 u-mg-bottom-xl">{t('backstore.label_confirm_p2')}</p>
                                <p className="u-fs-xs u-blue20 u-mg-bottom-l u-bold">{t('backstore.label_confirm_p3')}</p>
                            </div>

                            <div className="u-flex u-flex-center-hz u-mg-top-l">
                                <button type="button" className="c-btn c-btn--primary" onClick={this.state.formEventBackStore.ready ? () => this.handleSubmit("confirmReturn") : null}>
                                    {this.state.formEventBackStore.ready ? t('backstore.label_confirm') : t('backstore.label_waiting')}
                                </button>
                            </div>
                        </div>
                    }

                    {/* Verification */}
                    {this.state.step === 3 &&
                        <div className="l-form-width u-pd-bottom-xl">
                            <div className="u-center text-justify">
                                <h2 className="c-h2 u-center u-bold u-mg-bottom-xl">{t('backstore.subtitle_save')}</h2>
                                <p className="u-fs-xs u-blue20 u-mg-bottom-s">{t('backstore.label_save_p1')}</p>
                            </div>
                        </div>
                    }
                </div>
            </main>
        </>
    )
  }
}

export default withTranslation()(PublicBackStoreBike);