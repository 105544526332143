import React from "react"
import { withTranslation } from 'react-i18next'

import {StrDef} from '../assets/lib/utils'
import FormInput from '../components/Forms/FormInput'
import NotificationAlert from "../components/Notification/Notification.js"
import { isNotEmpty } from "utils/validation"

class PublicBikePortability extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        bikeId : '',
        transferCode: '',
        operator: '',

        storeName : '',
        city: '',

        brand : '',
        colorBike : '',
        model : '',
        gearType : '',
        bikeStatus: "",

        formError : {},
        formEvent : {
            ready : false,
        },
    }
  }

  notificationAlert = React.createRef();

  async componentDidMount() {
    window.notify = (msg,type) => this.refs.notificationAlert.notificationAlert({ message: msg, type: type });

    this.state.bikeId = new URLSearchParams(this.props.location?.search).get("bike_id")
    this.state.transferCode = new URLSearchParams(this.props.location?.search).get("transfer_code")
    this.state.operator = window.API.getSubDomain() === 'decathlon' ? "DK" : "MF"

    if (this.state.bikeId && this.state.transferCode) {
        try {
            let bikeInformation = await window.API.get_bike_portability_info(this.state.bikeId, this.state.operator, this.state.transferCode)
            
            if (StrDef(bikeInformation.error)) {
                if (bikeInformation.error.code === 'FnuciApiException') {
                    window.notify(this.props.t('portability.expired_code'),'danger')
                } else if (bikeInformation.error.code === 'RessourceNotFoundException') {
                    window.notify(this.props.t('portability.expired_request'),'danger')
                }
                else {
                    window.notify(this.props.t('onebike.error_bike_unknwown'),'danger')
                }
                
              } else {
                this.setState({
                    storeName: bikeInformation.request_data?.store_name,
                    city: bikeInformation.request_data?.store_city,
                    brand: bikeInformation.bike_description?.brand,
                    model: bikeInformation.bike_description?.model,
                    gearType: bikeInformation.bike_description?.gear_type,
                    colorBike: bikeInformation.bike_description?.color,
                    bikeStatus: bikeInformation.bike_status
                })
              }
        } catch(error) {
            console.log(error)
            window.notify(this.props.t('onebike.error_bike_unknwown'),'danger')
        } finally {
            this.setState({
                formEvent: {
                    ...this.state.formEvent,
                    ready: true
                },
            })
        }
    }
  }

  handleSubmit = async (consumerResponse) => {
    this.setState({
        formEvent: {
            ...this.state.formEvent,
            ready: false
        },
    })

    const portabilityBikePayload = {
        bike_id: this.state.bikeId,
        operator: window.API.getSubDomain() === 'decathlon' ? "DK" : "MF",
        transfer_code: this.state.transferCode,
        response: consumerResponse === "confirmReturn" ? "accepted" : "rejected",
    }    

    try {
        console.log(portabilityBikePayload)
        let res = await window.API.put_bike_portability(portabilityBikePayload)
        if (StrDef(res.error)) console.log(res.error)

        consumerResponse === "confirmReturn" 
            ? window.notify(this.props.t('portability.success_transfert'),'success') 
            : window.notify(this.props.t('portability.cancel_transfert'),'success')   

    } catch(error) {
        console.log(error)
        window.notify(this.props.t('portability.already_update'),'danger') 

    } finally {
        this.props.history.push({
            pathname: '/',
        });
    }
  }

  render() {
    const { t } = this.props;

    return(
        <>
            <NotificationAlert ref="notificationAlert"/>          

            <main className={"c-main-content u-flex u-flex-dir-col"}>
                <div className="c-header u-pd-top-xl@main">
                    <div className="l-container-md u-pd-hz-m">
                        <h1 className="c-h1 c-header__title u-medium u-uppercase">
                            {t("portability.title")}
                        </h1>
                    </div>
                </div>

                <div className="l-container-md u-pd-hz-m u-pd-vt-l">    
                    <div className="l-form-width u-pd-bottom-xl">
                        <div className="u-center text-justify">
                            
                            {this.state.formEvent.ready && this.state.bikeStatus && 
                                <>
                                    <h2 className="c-h2 u-center u-bold u-mg-bottom-xl">{t('backstore.subtitle_infos_bike')}</h2>
                                    <p className="u-fs-xs u-center u-blue20 u-mg-bottom-xl">
                                    {t("portability.label_p1")} {this.state.storeName}, {this.state.city}</p>
                                </>
                            }

                            {this.state.formEvent.ready && !isNotEmpty(this.state.bikeStatus) &&
                                <p className="u-fs-xs u-center u-blue20 u-mg-bottom-m u-danger">
                                    {t("portability.expired_request")}
                                </p>
                            }
                        </div>

                        <FormInput
                            className={"u-mg-bottom-l"}
                            formData={this.state}
                            formError={this.state.formError}
                            what={"bikeId"}
                            error={""}
                            placeholder={t('backstore.label_bikecode')}
                            handleInputChange={null}
                            disabled={true}
                        />

                        <FormInput
                            className={"u-mg-bottom-m"}
                            formData={this.state}
                            formError={this.state.formError}
                            what={"brand"}
                            error={""}
                            placeholder={t('backstore.label_brand')}
                            handleInputChange={null}
                            disabled={true}
                        />

                        <FormInput
                            className={"u-mg-bottom-m"}
                            formData={this.state}
                            formError={this.state.formError}
                            what={"colorBike"}
                            error={""}
                            placeholder={t('backstore.label_color')}
                            handleInputChange={null}
                            disabled={true}
                        />

                        <FormInput
                            className={"u-mg-bottom-m"}
                            formData={this.state}
                            formError={this.state.formError}
                            what={"model"}
                            error={""}
                            placeholder={t('backstore.label_model')}
                            handleInputChange={null}
                            disabled={true}
                        />

                        <FormInput
                            className={"u-mg-bottom-l"}
                            formData={this.state}
                            formError={this.state.formError}
                            what={"gearType"}
                            error={""}
                            placeholder={t('backstore.label_gear_type')}
                            handleInputChange={null}
                            disabled={true}
                        />

                        {this.state.formEvent.ready ? 
                            <>
                                {this.state.bikeStatus &&
                                    <>
                                        <p className="u-fs-xs u-center u-blue20 u-mg-bottom-m">{t('portability.label_p2')}</p>

                                        <div className="u-flex u-flex-between u-mg-top-l">
                                            <button
                                                type="button"
                                                className="c-btn c-btn--primary u-fs-xs"
                                                onClick={() => this.handleSubmit("cancelReturn")}
                                            >
                                                {t('backstore.label_goBack')}
                                            </button>

                                            <button
                                                type="button"
                                                className="c-btn c-btn--primary u-fs-xs"
                                                onClick={() => this.handleSubmit("confirmReturn")}
                                            >
                                                {t('portability.label_continue')}
                                            </button>
                                        </div>
                                    </>
                                }
                            </>
                            :
                                <div className="u-flex u-flex-center-vt u-flex-center-hz u-mg-top-xl">
                                    <div className="c-spinner"></div>
                                </div>
                            }
                    </div>
                </div>
            </main>
        </>
    )
  }
}

export default withTranslation()(PublicBikePortability);