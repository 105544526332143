import React from "react";
import { withTranslation } from 'react-i18next'
import * as translationContext from 'translation';

import {StrDef,Clone,ValidateEmail,isMdpValid,isInt} from '../../assets/lib/utils';
import FormInput from '../Forms/FormInput';
import { WALLET_TOU_LINK } from "../../utils/languages";

const PASSWORD_LENGTH = 8;
const CODE_LENGTH = 6;

class FormPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step : 1, // pour forgot
      mode : this.props.mode, // forgot - account/accountNoWallet - register
      formData : this.props.formData,
      formError : {},//this.props.formError,
      formEvent : {ready:true},//this.props.formEvent,
      currentLanguage : translationContext.getLanguage()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.formError !== prevProps.formError) {
      this.setState({formError:this.props.formError})
    }
  }

  handleInputChange = (event) => {
    const target = event.target
    const val = target.type === 'checkbox' ? target.checked : target.value
    const what = target.name
    let state = Clone(this.state);
    this.setState({
      formData: {
        ...this.state.formData,
        [what]: val
      },
      formError: {
        ...this.state.formError,
        [what]: false
      }
    },() =>{
      this.props.updateState(this.state)
    })
  }

  formValidation = () => {
    let formData = this.state.formData;
    let formError = this.state.formError;
    let password = formData.password
    let password2 = formData.password2
    let inputValidation = Object.keys(formData).map(itemName => {
      switch (itemName) {
        case 'actualPassword':
          let actualPassword = formData.actualPassword
          if (this.state.mode === 'account') {
            const actualPasswordError = !StrDef(actualPassword) || actualPassword.length < PASSWORD_LENGTH || !isMdpValid(actualPassword)
            if (actualPasswordError) {
              formError.actualPassword = actualPasswordError
              formError.actualErrorPassword = this.props.t('formpassword.label_password_invalid')
            }
            return !actualPasswordError
          } else if (this.state.mode === 'accountNoWallet') {
            const actualPasswordError = !StrDef(actualPassword) || actualPassword.length < PASSWORD_LENGTH
            if (actualPasswordError) {
              formError.actualPassword = actualPasswordError
              formError.actualErrorPassword = this.props.t('formpassword.label_password_invalid')
            }
            return !actualPasswordError
          } else {
            return true
          }
        case 'email':
          if (this.state.mode === 'account' || this.state.mode === 'accountNoWallet') {
            return true;
          } else {
            const emailError = !ValidateEmail(formData.email)
            if (emailError) {
              formError.email = emailError
            }
            return !emailError
          }

        case 'tempCode':
          if (this.state.mode === 'account'  || this.state.mode === 'accountNoWallet') {
            return true;
          } else {
            const tempCodeError = formData.tempCode.length != CODE_LENGTH || !isInt(formData.tempCode)
            if (tempCodeError) {
              formError.tempCode = tempCodeError
            }
            return !tempCodeError
          }

        case 'password':
          //let password = formData.password
          //let password2 = formData.password2
          const passwordError = !StrDef(password) || password.length < PASSWORD_LENGTH || !isMdpValid(password)
          if (passwordError) {
            formError.password = passwordError
            //formError.password2 = passwordError
            formError.errorPassword = this.props.t('formpassword.label_password_invalid')
          }
          return !passwordError
        case 'password2':

          const passwordError2 = !StrDef(password2) || password !== password2 || password2.length < PASSWORD_LENGTH || !isMdpValid(password2)
          if (passwordError2) {
            //formError.password = passwordError
            formError.password2 = passwordError2
            if (!StrDef(password2) || !isMdpValid(password2) || password2.length < PASSWORD_LENGTH) {
              formError.errorPassword2 = this.props.t('formpassword.label_password_invalid')
            } else if (password !== password2) {
              formError.errorPassword2 = this.props.t('formpassword.label_password_notsame')
            }
          }
          return !passwordError2
          //errorPassword
        case 'cguAccept':
          if (this.state.mode === 'register') {
            const cguError = formData.cguAccept === false
            if (cguError) {
              formError.cguAccept = cguError
            }
            return !cguError
          } else {
            return true;
          }
        default:
            return true
      }
    })
    this.setState({formError:formError})
    return inputValidation.reduce((acc, next) => acc && next)
  }

  formValidationMail = () => {
    let formData = this.state.formData;
    let formError = this.state.formError;
    let inputValidation = Object.keys(formData).map(itemName => {
      switch (itemName) {
        case 'email':
          const emailError = !ValidateEmail(formData.email)
          if (emailError) {
            formError.email = emailError
          }
          return !emailError
        default:
            return true
      }
    })
    this.setState({formError:formError})
    return inputValidation.reduce((acc, next) => acc && next)
  }

  initpasswordbymail = async (event) => {
    event.preventDefault()
    // Validate inputs
    const formReady = this.formValidationMail()
    //const formReady = true;
    if (formReady) {
      // Inform the state that the component is unavailable
      // to prevent any new user submit
      this.setState({
        formData: { ...this.state.formData, },
        formError: { ...this.state.formError },
        formEvent: {
            ...this.state.formEvent,
            ready: false
        },
      })

      let res = await window.API.initpasswordbymail(this.state.formData.email);
      if (StrDef(res.error)) {
        if (res.error.code === 'MailNotAvailableException') {
          window.notify(this.props.t('formpassword.label_mail_unavailable'),'danger')
        } else if (res.error.code === 'TooManyRequestsException') {
          window.notify(this.props.t('formpassword.label_mail_code_sent'),'danger')
          this.setState({step:2})
        } else if (res.error.code === 'MailNotFoundException') {
          window.notify(this.props.t('formpassword.label_mail_new_code'),'danger')
        }
        this.setState({
          formEvent: {
            ...this.state.formEvent,
            ready: true
          },
        })
      } else {
        this.setState({
          formEvent: {
            ...this.state.formEvent,
            ready: true
          },
          step:2
        })
      }
    }
  }

  checkMail = async (event) => {
    event.preventDefault()
    // Validate inputs
    const formReady = this.formValidationMail()
    //const formReady = true;
    if (formReady) {
        // Inform the state that the component is unavailable
        // to prevent any new user submit
        this.setState({
          formData: { ...this.state.formData, },
          formError: { ...this.state.formError },
          formEvent: {
              ...this.state.formEvent,
              ready: false
          },
        })

        let res = await window.API.check_mail(this.state.formData.email);
        if (StrDef(res.error)) {
          if (res.error.code === 'MailNotAvailableException') {
            window.notify(this.props.t('formpassword.label_mail_unavailable'),'danger')
          } else if (res.error.code === 'TooManyRequestsException') {
            window.notify(this.props.t('formpassword.label_mail_code_sent'),'danger')
          }
        } else {
          window.notify(this.props.t('formpassword.label_mail_new_code'),'danger')
        }
        //cal API
        this.setState({
          formEvent: {
            ...this.state.formEvent,
            ready: true
          },
          step:2
        })
    }
  }

  changePassword = (event) => {
    event.preventDefault()
    // Validate inputs
    const formReady = this.formValidation()
    //const formReady = true;
    if (formReady) {
        // Inform the state that the component is unavailable
        // to prevent any new user submit
        this.setState({
          formData: { ...this.state.formData, },
          formError: { ...this.state.formError },
          formEvent: {
              ...this.state.formEvent,
              ready: false
          },
        })

        this.props.onSubmit()

        //cal API
        this.setState({
          formEvent: {
            ...this.state.formEvent,
            ready: true
          },
          //step:2
        })
    }
  }

  render() {
    const { t } = this.props;
    return (
      <form className="l-form-width">
        {this.state.mode !== 'account' && this.state.mode !== 'accountNoWallet' &&
          <FormInput
            className={this.state.mode === 'forgot' && this.state.step === 1 ? "" : "u-mg-bottom-m"}
            formData={this.state.formData}
            formError={this.state.formError}
            what={"email"}
            error={t('formpassword.label_mail_error')}
            placeholder={t('formpassword.label_mail')}
            handleInputChange={this.handleInputChange}
          />
        }
        {this.state.mode === 'forgot' && this.state.step === 1 &&
          <>
            <p className="u-fs-xs u-primary u-pd-vt-s">
              {this.props.t("formpassword.label_mail_code_reinit")}
            </p>
            <div className="u-flex u-flex-center-hz u-mg-top-xl">
              <button
                type="button"
                className="c-btn c-btn--primary"
                onClick={this.state.formEvent.ready ? this.initpasswordbymail.bind(this) : undefined}
              >
                {this.state.formEvent.ready ? this.props.t("common.send") : this.props.t("common.wait")}
              </button>
            </div>
          </>
        }

        {(this.state.mode === 'account' || this.state.mode === 'accountNoWallet' || this.state.mode === 'register' || (this.state.mode === 'forgot' && this.state.step === 2)) &&
          <>
            {this.state.mode !== 'account' && this.state.mode !== 'accountNoWallet' &&
              <div className="u-mg-bottom-l">
                <FormInput
                  className={"u-mg-bottom-s"}
                  formData={this.state.formData}
                  formError={this.state.formError}
                  what={"tempCode"}
                  error={t('formpassword.label_tempcode_error')}
                  placeholder={t('formpassword.label_tempcode')}
                  handleInputChange={this.handleInputChange}
                  maxLength={CODE_LENGTH}
                />
                <div className="u-flex u-flex-end">
                  <button
                    onClick={this.state.mode === 'register' ? this.checkMail : this.initpasswordbymail.bind(this)}
                    className="u-block u-blue20 u-fs-xxs u-underline"
                  >
                    {t('formpassword.label_send_code')}
                  </button>
                </div>
              </div>
            }

            {((this.state.mode === 'account') || (this.state.mode === 'accountNoWallet')) &&
              <FormInput
                className={"u-mg-bottom-m"}
                formData={this.state.formData}
                formError={this.state.formError}
                what={"actualPassword"}
                error={this.state.formError.actualErrorPassword}
                placeholder={this.props.t("formpassword.label_current_pwd")}
                handleInputChange={this.handleInputChange}
                type={'password'}
              />
            }

            <FormInput
              className={""}
              formData={this.state.formData}
              formError={this.state.formError}
              what={"password"}
              error={this.state.formError.errorPassword}
              placeholder={t('formpassword.label_password')}
              handleInputChange={this.handleInputChange}
              type={'password'}
            />

            <div className="u-fs-xs u-primary u-pd-vt-s u-mg-bottom-m">
              {t('formpassword.label_password_rules')}
            </div>

            <FormInput
              className={"u-mg-bottom-m"}
              formData={this.state.formData}
              formError={this.state.formError}
              what={"password2"}
              error={this.state.formError.errorPassword2}
              placeholder={t('formpassword.label_confirm_password')}
              handleInputChange={this.handleInputChange}
              type={'password'}
            />

            {this.state.mode === 'register' &&
            <>
              <div className="c-form-group u-flex u-flex-start-vt u-pd-vt-s u-mg-top-l u-mg-bottom-s">
                <input
                    className="c-checkbox u-mg-right-s"
                    id="CGU"
                    name="cguAccept"
                    type="checkbox"
                    checked={this.state.formData.cguAccept}
                    onChange={this.handleInputChange}
                />
                <label className="u-mg-0 u-blue20 u-fs-xs" htmlFor="CGU">
                  {t('formpassword.label_cgu_p1')}<a href={WALLET_TOU_LINK[this.state.currentLanguage]} target="_blank"><u>{t('formpassword.label_cgu_p2')}</u></a>{t('formpassword.label_cgu_p3')}
                </label>

              </div>
              {this.state.formError.cguAccept === true &&
                <p className="u-fs-xs u-pd-vt-s u-danger">{t('formpassword.label_cgu_error')}</p>
              }
              </>
            }

            <div className="u-flex u-flex-center-hz u-mg-vt-l">
              <button
                type="submit"
                className="c-btn c-btn--primary"
                onClick={this.state.formEvent.ready ? this.changePassword : undefined}
              >
                {this.state.formEvent.ready ? ((this.state.mode === 'account' || this.state.mode === 'accountNoWallet') ? t('formpassword.label_modify') : t('formpassword.label_valid')) : t('formpassword.label_waiting')}
              </button>
            </div>
          </>
        }
      </form>
    );
  }
}

export default withTranslation()(FormPassword)