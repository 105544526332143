import axios from 'axios'
import {StrDef} from './utils'
var jwtDecode = require('jwt-decode')
var moment = require('moment')

let _api = null;

const OBIKE_API         = 'obikeapi'
const APP_NAME          = 'ObikeBackEnd'
const APP_NAME_BC       = 'BCObikeBackEnd'
const APP_NAME_MF       = 'MFObikeBackEnd'
const APP_NAME_DK       = 'DKObikeBackEnd'
const ISS_OBIKE_API     = 'ObikeAPI'
const ISS_SAFETHING_API = 'SafeAuthenticationAPI'

export default class Api {
  constructor() {
    var url = 'https://dev.ocode.team/';

    //url = 'https://api.savething.team/';

    if( process.env.REACT_APP_MODE === 'production' ) {
      url = '/';
    }

    this.baseURL = url;

    this.api = axios.create({
      baseURL: url,
      timeout: 10000
    });

    this.API_BASE_URL = url;
    this.API_TIMEOUT_GET = 30000;

    if(_api != null) {
        throw 'There is already an instance of api alive';
    }

    _api = this;
  }

  isTokenExpired(token) {
    var decoded = null;
    try {
      decoded = jwtDecode(token);
    }
    catch(e) {
      return true;
    }
    return decoded.exp < parseInt(moment.utc().valueOf()/1000);
  }

  async get_new_accessToken(force) {
    var res = null;
    // si la version n'est déjà pas bonne ne pas faire cette opération
    var user = JSON.parse(localStorage.getItem('user'));
    if( user == null ) {
      // console.log("USER NULLL");
      window.Logout();
      return '';
    }
    var refreshToken = user.refreshToken;

    if( this.isTokenExpired(refreshToken) && !Defined(force) ) {
      // console.log("refreshToken2 EXPIRED",this.isTokenExpired(refreshToken));
      window.Logout();
      return '';
    }
    else {
      try {
        // console.log( 'refresh USE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!' );
        res = await this.api.get('authenticationapi/signin',{ headers: {'accept-version': '1.0.0',
                                                                        'Content-Type': 'application/json',
                                                                        'Authorization': 'Bearer '+refreshToken} })

      }
      catch(e) {
        // var event = new CustomEvent('logout', {detail:''});
        // window.dispatchEvent(event);
       // console.log("refreshToken2 EXPIRED 55555",this.isTokenExpired(refreshToken));
        window.Logout();
        return '';
      }

      if( Defined(res) && Defined(res.data.access_token) ) {
        // console.log("res",res);
        user = JSON.parse(localStorage.getItem('user'));
        user.accessToken = res.data.access_token;
        localStorage.setItem('user',JSON.stringify(user));
        this.accessToken = res.data.access_token;
        return res.data.access_token;
      }
      else {
        return '';
      }
    }
  }

  async GetAccessToken() {
    var user = JSON.parse(localStorage.getItem('user'));
    if( user != null ) {
      var accessToken = user.accessToken;
      if( this.isTokenExpired(accessToken) ) {
       //  console.log("acces token EXPIRED");
        return await this.get_new_accessToken();
      }
      else {
        return accessToken;
      }
    } else {
      return await this.get_new_accessToken();
    }
  }

  async GET(query,config,force) {
    //var self = this;
    var conf = config;
    if( typeof(config) == 'undefined' ) {
      var accessToken = await this.GetAccessToken();
      //console.log("query ",query);
      //if( accessToken == '' ) return null;
      //console.log("accessToken",accessToken);
      if( !StrDef(accessToken) ) return ({error:true});
      conf = { headers: {'Authorization': 'Bearer '+accessToken,
                          'accept-version': '1.0.0',
                          'Content-Encoding': 'gzip',
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_GET };
    }
    else {
      conf = { headers: { 'accept-version': '1.0.0',
                          'Content-Encoding': 'gzip',
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_GET };
    }
    if( Defined(config) && Defined(config.headers) && Defined(config.headers.Authorization) ) {
      conf.headers['Authorization'] = config.headers.Authorization;
    }

    if( Defined(config) && Defined(config.headers) && Defined(config.headers.acceptVersion) ) {
      conf.headers['accept-version'] = config.headers.acceptVersion;
    }
    if( StrDef(force) ) {
      conf = config;
    }
    return this.api.get(query,conf)
        .then(function (response) {
          // console.log("response",response);
          if( response.status === 200 ) {
            return response.data;
          }
          else {
            return ({error:''});
          }
        })
        .catch(async function (error) {
          if( error.response ) {
            if( error.response.status === 400 ) {
              //console.log("error.response",error.response);
              return ({error:error.response.data})
            }
          }
          return ({error:error.response.data});
        });
  }

  async POST(query,data,config) {
    var conf = config;
    if( typeof(config) == 'undefined' ) {
      var accessToken = await this.GetAccessToken();
      //console.log("POST 1 accessToken",accessToken);
      //if( accessToken == '' ) return null;
      if( accessToken === '' ) return ({error:true});
      conf = { headers: {'Authorization': 'Bearer '+accessToken,
                          'accept-version': '1.0.0',
                          /*'Content-Encoding': 'gzip',*/
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_POST };
    }
    else {
      conf = { 
        headers: { 
          'accept-version': '1.0.0',
          'Content-Type': 'application/json',
          },
          timeout: this.API_TIMEOUT_POST };
      if( Defined(config) && Defined(config.headers) && Defined(config.headers.Authorization) ) {
        conf.headers['Authorization'] = config.headers.Authorization;
      }
    }
    return this.api.post(query, data, conf)
      .then(function (response) {
       //  console.log("response",response);
        if( response.status === 200 ) {
          return(response.data);
        }
        else {
          return {error:'unknown'};
        }
      })
      .catch(async function (error) {
       //  console.log("error",error);
        if (error.response) {
          if( error.response.status === 400 ) {
           //  console.log("POST",error.response);
            if( error.response.data.code === 'NotAuthorizedException'
                && error.response.data.message === 'Code expired or invalid.') {
                  return ({error:error.response.data})
            }
            else {
              //console.log( '((((((((((((((((((((((()))))))))))))))))))))))' );
              return {error:error.response.data};
            }
          }
          return( {error:error.response.data} );
        } else {
          return {error:'unknown'};
        }
      });
  }

  async PUT(query,data,config) {
   //  console.log("config",config);
    var self = this;
    var conf = config;
    if( typeof(config) == 'undefined' ) {
      var accessToken = await this.GetAccessToken();
      //if( accessToken == '' ) return null;
      if( accessToken === '' ) return ({error:true});
      conf = { headers: {'Authorization': 'Bearer '+accessToken,
                          'accept-version': '1.0.0',
                          /*'Content-Encoding': 'gzip',*/
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_POST };
    }
    else {
      conf = { headers: { 'accept-version': '1.0.0',
                          /*'Content-Encoding': 'gzip',*/
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_GET };
    }
    if( Defined(config) && Defined(config.headers) && Defined(config.headers.Authorization) ) {
      conf.headers['Authorization'] = config.headers.Authorization;
    }

    if( Defined(config) && Defined(config.headers) && Defined(config.headers.acceptVersion) ) {
      conf.headers['accept-version'] = config.headers.acceptVersion;
    }

    return this.api.put(query, data, conf)
      .then(function (response) {
        if( response.status === 200 ) {
          return(response.data);
        }
        else {
          return {error:'unknown'};
        }
      })
      .catch(async function (error) {
       //  console.log("PUTPUT ",error.response);
       //  console.log("PUTPUT ",query);
        if (error.response) {
          if( error.response.status === 400 ) {
            if( error.response.data.code === 'NotAuthorizedException' && query !== 'authenticationapi/password' && query !== 'authenticationapi/kpis' ) {
              var newToken = await self.get_new_accessToken(true);
              if( newToken !== '' ) {
                //return await self.PUT(query,data,config);
              } else {
                return ({error:true});
              }
            }
          }
          return( {error:error.response.data} );
        } else {
        }
        return {error:'unknown'};
      });
  }

  async DELETE(query,config) {
    var self = this;
    var conf = config;
    if( typeof(config) == 'undefined' ) {
      var accessToken = await this.GetAccessToken();
      if( accessToken === '' ) return null;
      conf = { headers: {'Authorization': 'Bearer '+accessToken,
                          'accept-version': '1.0.0',
                          'Content-Encoding': 'gzip',
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_POST };
    }
    else {
      conf = { headers: { 'accept-version': '1.0.0',
                          'Content-Encoding': 'gzip',
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_GET };
    }

    return this.api.delete(query,conf)
        .then(function (response) {
          if( response.status === 200 ) {
            return response.data;
          }
          return {error:response.status};
        })
        .catch(async function (error) {
          if( error.response.status === 400 ) {
            if( error.response.data.code === 'NotAuthorizedException' ) {
              var newToken = await self.get_new_accessToken(true);
              if( newToken !== '' ) {
                return await self.DELETE(query,config);
              }else {
                //console.log("logoutlogoutlogoutlogoutlogoutlogoutlogout");
                /*var event = new Event('logout');
                document.dispatchEvent(event);*/
                return ({error:true});
              }
            }
          }
          return {error:error.response.status};
        });
  }

  //------------------------------------------------ API CALLS
  // checkmail
  async check_mail(mail) {
    let data = {
      mail : mail,
      app : APP_NAME,
    }
    let res = await this.POST('authenticationapi/consumer/check_mail',data,{});
    return res;
  }

  async register_by_mail(data) {
    let res = await this.POST('authenticationapi/consumer/register_by_mail',data,{});
    return res;
  }

  async signin(info,callback) {
    /*var rememberMe = localStorage.getItem('rememberMe');
    localStorage.clear();
    localStorage.setItem('rememberMe',rememberMe);*/

    var apiObj = {
      app             : APP_NAME,
    	device_platform : 'browser',
      code_gender     : 'LOGIN',
      code_id         : info.code_id,
      password        : info.password
    }

    //console.log("apiObj",apiObj);
    let data = await this.POST( 'authenticationapi/signin', apiObj, {} );
    //console.log("data",data);
    if( Defined(data.access_token) ) {
      this.accessToken = data.access_token;
      this.refreshToken = data.refresh_token;
      this.userId = data.user_id;
      localStorage.setItem('user',JSON.stringify({accessToken:this.accessToken,
                                                  refreshToken:this.refreshToken,
                                                  userId:this.userId,entityId:this.entityId}));

      return data;
    } else {
      return data;
      //callback(data);
    }
  }

  async signin_nowallet(data) {
    let res = await this.POST(OBIKE_API+'/nowallet/signin',data,{});
    if (!StrDef(res.error)) {
      this.accessToken = res.access_token;
      this.bikeId      = jwtDecode(res.access_token).bike_id
      localStorage.setItem('user',JSON.stringify({accessToken:this.accessToken,bikeId:this.bikeId}));
    }
   //  console.log("res",res);
    return res;
  }

  async signin_bicycode_2_0(data) {
    let res = await this.POST(OBIKE_API+'/nowallet/signin_bicycode_2_0',data,{});
    if (!StrDef(res.error)) {
      this.accessToken = res.access_token;
      this.bikeId      = jwtDecode(res.access_token).bike_id
      localStorage.setItem('user',JSON.stringify({accessToken:this.accessToken,bikeId:this.bikeId}));
    }
    // console.log("res",res);
    return res;
  }

  async get_consumer(mode,attribute) {
    // byConsumerId (consumer_id) , byOcode (ocode_id), byEmail (mail), byShortCode (short_code_id)
    let param = ''
    switch (mode) {
      case 'byConsumerId':
        let userId = this.userId
       //  console.log("userId",userId);
        if (userId == null) {
          if( localStorage.getItem('user') !== null ) {
            var user = JSON.parse(localStorage.getItem('user'));
            userId = user.userId;
          }
        }
        param = 'consumer_id='+userId
        break;
      case 'byOcode':
        param = 'ocode_id='+attribute
        break;
      /*case 'byEmail': (ROUTE SUPPRIMEE)
        param = 'mail='+attribute
        break;*/
      case 'byShortCode':
        param = 'short_code_id='+attribute
        break;
      default:
        break;
    }

    //let res = await this.GET('authenticationapi/consumer?'+param);
    let res = await this.GET(OBIKE_API+'/consumer/consumer?'+param);

   //  console.log("res",res);
    if (mode === 'byConsumerId') {
      this.ocodeId = res.visit_card_ocode_id
    }
    return res;
  }

  async get_consumer_nowallet(mode,attribute) {
    // byOcode (ocode_id), byShortCode (short_code_id)
    let param = ''
    switch (mode) {
      case 'byOcode':
        param = 'ocode_id='+attribute
        break;
      case 'byShortCode':
        param = 'short_code_id='+attribute
        break;
      default:
        break;
    }

    let res = await this.GET(OBIKE_API+'/nowallet/consumer?'+param);
   //  console.log("res",res);
    return res;
  }

  async get_short_code() {
    let res = await this.GET('authenticationapi/consumer/get_short_code');
    return res;
  }

  async get_bike(updated_after,status) {
    let param = ''
    if( StrDef(updated_after) )                   param = '?updated_after='+updated_after;
    if( StrDef(status) && StrDef(updated_after))  param += '&status='+status;
    if( StrDef(status) && !StrDef(updated_after)) param = '?status='+status;

    let res = await this.GET(OBIKE_API+'/consumer/bikes'+param);
    //console.log("res",res);
    return res;
  }

  async get_bike_nowallet(mode,attribute) {
   //  console.log("mode,attribute",mode,attribute);
    let param = ''
    switch (mode) {
      case 'byBikeId':
        if (attribute == null) {
          if( localStorage.getItem('user') !== null ) {
            var user = JSON.parse(localStorage.getItem('user'));
            attribute = user.bikeId;
          }
        }
        param = 'bike_id='+attribute
        break;
      case 'byOcode':
        param = 'ocode_id='+attribute
        break;
    default:
      break;
    }
    let res = await this.GET(OBIKE_API+'/nowallet/bike?'+param);
    return res;
  }

  async get_bike_id(ocode_id) {
    let res = await this.GET(OBIKE_API+'/get_bike_id/'+ocode_id);
    return res;
  }

  async get_one_bike(mode,attribute) {
    // byBikeId (bike_id) , byOcode (ocode_id)
    let param = ''
    switch (mode) {
      case 'byBikeId':
        param = 'bike_id='+attribute
        break;
      case 'byOcode':
        param = 'ocode_id='+attribute
        break;
    default:
      break;
    }
    let res = await this.GET(OBIKE_API+'/consumer/bike?'+param);
   //  console.log("res",res);
    return res;
  }

  async get_bike_portability_info(bikeId, operator, transferCode) {
    let res = await this.GET(OBIKE_API+'/fastaction/portability/bike?bike_id=' + bikeId + '&operator='  + operator + '&transfer_code=' + transferCode, {});
    return res;
  }

  async put_bike_portability(data) {
    let res = await this.PUT(OBIKE_API+'/fastaction/portability/bike', data, {});
    return res;
  }

  async put_back_store_bike(recoveryBike, token) {
    const config = {
      headers: {
        'accept-version': '1.0.0',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ token
      },
      timeout: this.API_TIMEOUT_POST
    }

    let res = await this.PUT(OBIKE_API+'/fastaction/bike', recoveryBike, config);
   //  console.log("res",res);
    return res;
  }



  async put_bike(data) {
    let res = await this.PUT(OBIKE_API+'/consumer/bike',data);
   //  console.log("res",res);
    return res;
  }

  async put_bike_no_wallet(data) {
    let res = await this.PUT(OBIKE_API+'/nowallet/bike',data);
   //  console.log("res",res);
    return res;
  }

  async put_bike_bicycode_2_0(data) {
    let res = await this.PUT(OBIKE_API+'/nowallet/bike_bicycode_2_0',data);
   //  console.log("res",res);
    return res;
  }

  async add_fleet_towallet(data) {
    let res = await this.PUT(OBIKE_API+'/consumer/fleet/addtowallet',data);
   //  console.log("res",res);
    return res;
  }

  async put_consumer(data) {
    let res = await this.PUT('authenticationapi/consumer',data);
   //  console.log("res",res);
    return res;
  }

  async transfer(fnuci_code,data) {
    let res = await this.PUT(OBIKE_API+'/consumer/bike/'+fnuci_code+'/propertytransfer',data);
   //  console.log("res",res);
    return res;
  }

  async transfer_nowallet(fnuci_code,data) {
    let res = await this.PUT(OBIKE_API+'/nowallet/bike/'+fnuci_code+'/propertytransfer',data);
   //  console.log("res",res);
    return res;
  }

  async put_password(data) {
    let res = await this.PUT('authenticationapi/password',data);
   //  console.log("res",res);
    return res;
  }

  async put_password_nowallet(data) {
    let res = await this.PUT('obikeapi/nowallet/updatepassword',data);
   //  console.log("res",res);
    return res;
  }

  async initpasswordbymail(mail) {
    let res = await this.GET('authenticationapi/initpasswordbymail?mail='+mail+'&app='+APP_NAME,{});
   //  console.log("res",res);
    return res;
  }

  async forgotpassword(data) {
    let res = await this.POST('authenticationapi/forgotpassword',data,{});
   //  console.log("res",res);
    return res;
  }

  async forgotpasswordnowallet(data) {
    let res = await this.POST(OBIKE_API+'/nowallet/forgotpassword',data,{});
   //  console.log("res",res);
    return res;
  }

  async pre_registration(data) {
    let app_name;
    let subdomain = this.getSubDomain()
    switch (subdomain) {
      case 'bicycode':
        app_name = APP_NAME_BC
        break;
      case 'mfc':
        app_name = APP_NAME_MF
        break;
      case 'decathlon':
        app_name = APP_NAME_DK
        break;
      default:
        app_name = APP_NAME_BC
        break;
    }
    let obj = {}
    obj.app = app_name
    obj.consumer_data = data
   //  console.log("obj",obj);
    let res = await this.POST(OBIKE_API+'/nowallet/pre_registration',obj,{});
   //  console.log("res",res);
    return res;
  }

  async get_short_code_pre_registration(pre_registration_id) {
    let res = await this.GET(OBIKE_API+'/nowallet/pre_registration?pre_registration_id='+pre_registration_id,{});
   //  console.log("res",res);
    return res;
  }

  async addtowallet(fnucicode,obj) {
   //  console.log("obj",obj);
    let res = await this.PUT(OBIKE_API+'/consumer/bike/'+fnucicode+'/addtowallet',obj);
   //  console.log("res",res);
    return res;
  }

  async accept_cgu(obj) {
    let res = await this.PUT(OBIKE_API+'/nowallet/account',obj);
   //  console.log("res",res);
    return res;
  }

  getSubDomain() {
    let subdomain = 'wallet' // par défaut
    if (StrDef(this.accessToken)) { // authenticated
      let decoded = jwtDecode(this.accessToken)
      switch (decoded.iss) {
        case ISS_SAFETHING_API:
          subdomain = 'wallet'
          break;
        case ISS_OBIKE_API:
          subdomain = this.getSubDomainNoWallet()
          break;
        default:
          subdomain = 'wallet'
          break;
      }
    } else { // not authenticated
      subdomain = this.getSubDomainNoWallet()
    }
    //console.log("return subdomain",subdomain);
    return subdomain
  }

  getSubDomainNoWallet() {
    let subdomain = 'wallet'
    switch (document.domain.split('.')[0]) {
      case 'wallet':
        subdomain = 'wallet'
        document.title = "O°Code Wallet"
        break;
      case 'bicycode':
        subdomain = 'bicycode'
        document.title = "Bicycode"
        break;
      case 'mfc':
        subdomain = 'mfc'
        document.title = "MFC"
        break;
      case 'decathlon':
        subdomain = 'decathlon'
        document.title = "Decathlon"
        break;
      default:
        subdomain = 'wallet'
        document.title = "O°Code Wallet"
        break;
    }
    // specific case
    if (document.domain.split('.')[1] === 'bicycode') {
      subdomain = 'bicycode'
      document.title = "Bicycode"
    }
    return subdomain
  }
}


function Defined(obj) {
 return typeof(obj) != 'undefined' && obj != null;
}
/*function StrDef(obj) {
  return typeof(obj) != 'undefined' && obj != null && obj != '';
}*/
export function sharedAPI() {
  return _api;
}
