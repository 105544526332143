import React from "react";
import { withTranslation } from 'react-i18next';

class PDF extends React.Component {
  componentDidMount() {
    var link

    if(window.API.getSubDomain() === 'mfc') {
      link = document.createElement('a');
      link.href = process.env.PUBLIC_URL+'/politiquedeconfidentialite_mfc.pdf'
      link.taget = '_blank'
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  render() {
    return (
      <div></div>
    )
  }
}

export default withTranslation()(PDF);
