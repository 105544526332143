import React from "react";

import {StrDef,Clone,StringifyOCode,DrawOCode,isMdpValid,isPhoneNumber,ValidateEmail, isInt,json_hash} from '../assets/lib/utils';
import FormInput from '../components/Forms/FormInput';
import Icon from "../components/Icons/Icon.jsx";
import FormPassword from '../components/Forms/FormPassword';
import FormPerson from '../components/Forms/FormPerson';
import { withTranslation } from 'react-i18next';

const WIDHT_CANVAS_OCODE_DISPLAY = 110
const CODE_LENGTH = 6;
const BIKE_LIMIT = 20;
const MAX_UPDATE_BIKE = 20;
var moment = require('moment');
class Profil extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      consumer : {},
      loading : false,
      role : 1,
      step :1, // pour changement de mail
      ocode : '',
      disableChange : true,
      changePassword : false,
      //changeEmail : false,

      formDataCode :{
        code : '',
      },
      formErrorCode :{
        code : false,
      },
      formEventCode : {
        ready : true,
      },

      formData : {
        socialReason : '',
        firstName : '',
        lastName : '',
        phone : '',
        //email : '',
        adresse : '',
        cp : '',
        city : '',
        date : '', // birth_date
        street_number : '',
        insee_code : ''
      },
      initialHashFormData : '',
      hasFormDataChanged : false,
      formDataPassword : {
        actualPassword : '',
        password : '',
        password2 : '',
      },
      formErrorPassword : {
        actualPassword : false,
      },

      formDataMail : {
        actualMail : '',
        newMail : '',
        tempCode : '',
      },

      formErrorMail : {
        actualMail : false,
        newMail : false,
        tempCode : false,
      },
      formEventMail : {
        ready : true,
      },
    }

    this.load = this.load.bind(this);
  }

  componentDidMount() {
    document.body.classList.add("home-page");
    this.load()
  }

  async load() {
    this.setState({loading:true})
    let consumer = await window.API.get_consumer('byConsumerId');
    if (!StrDef(consumer.error)) {
      let ocode         = consumer.visit_card_ocode_id
      let consumer_data = consumer.consumer_data
      let address       = consumer.consumer_data.address
      let role          = consumer_data.gender

      let formDataPassword = {
        ... this.state.formDataPassword,
        actualPassword : '',
        password : '',
        password2 : '',
      }
      let formDataMail = {
        ... this.state.formDataMail,
        actualMail : consumer_data.mail,
        newMail : '',
        tempCode : '',
      }
      let formData = {
        //email         : consumer_data.mail,
        socialReason  : consumer_data.social_reason || '',
        firstName     : consumer_data.first_name || '',
        lastName      : consumer_data.last_name || '',
        phone         : consumer_data.phone,
        street_number : StrDef(address) ? address.street_number : '',
        adresse       : StrDef(address) ? address.street_name   : '',
        cp            : StrDef(address) ? address.postal_code   : '',
        city          : StrDef(address) ? address.city          : '',
        insee_code    : StrDef(address) ? address.insee_code    : '',
        date          : consumer_data.birth_date || '',
      }
      let initialHashFormData  = json_hash(formData)

      this.setState({
        consumer            : consumer,
        formData            : formData,
        formDataMail        : formDataMail,
        formDataPassword    : formDataPassword,
        role                : role,
        ocode               : ocode,
        loading             : false,
        disableChange       : true,
        initialHashFormData : initialHashFormData,
        hasFormDataChanged  : false
      },() => {
        var canvas = document.getElementById('ocodeAccount');
        var ctx = canvas.getContext('2d');
        canvas.width = WIDHT_CANVAS_OCODE_DISPLAY
        canvas.height = WIDHT_CANVAS_OCODE_DISPLAY
        let chaine = StringifyOCode(ocode)
        ctx = DrawOCode(chaine,ctx,30,25,true,"#fff")
      })
    }
  }

  updateStatePassword = (state) => {
    this.setState({
      formDataPassword: state.formData
    })
  }

  updateStatePerson = (state) => {
    this.setState({
      formData: state.formData,
    } , () => {
      let newHash  = json_hash(this.state.formData)
      if (newHash !== this.state.initialHashFormData) {
        this.setState({hasFormDataChanged:true})
      } else {
        this.setState({hasFormDataChanged:false})
      }

    })
  }

  generateCode = async (event) => {
    event.preventDefault()
    this.setState({
      formDataCode: { ...this.state.formDataCode, },
      formEventCode: {
          ...this.state.formEventCode,
          ready: false
      },
    })
    // api
    let res = await window.API.get_short_code()

    this.setState({
      formDataCode: {
        ...this.state.formDataCode,
        code : res.short_code_id
      },
      formEventCode: {
          ...this.state.formEventCode,
          ready: true
      },
    })
  }

  getObjPerson() {
    let objPerson = {}
    objPerson.mail = this.state.formDataMail.actualMail
    objPerson.phone = this.state.formData.phone
    objPerson.gender = this.state.consumer.consumer_data.gender
    if (this.state.role === 1) {
      objPerson.last_name      = this.state.formData.lastName
      objPerson.first_name     = this.state.formData.firstName
    } else if(this.state.role === 2) {
      objPerson.social_reason  = this.state.formData.socialReason
    }

    // birth_date
    if (StrDef(this.state.formData.date)) {
      objPerson.birth_date = moment(this.state.formData.date).format('YYYY-MM-DD')
    }
    return objPerson
  }

  getObjAddress() {
    let objAddress = {}
    if (StrDef(this.state.formData.cp) || StrDef(this.state.formData.street_number) || StrDef(this.state.formData.adresse)) {
      if (!StrDef(this.state.formData.cp)  || !StrDef(this.state.formData.street_number) || !StrDef(this.state.formData.adresse)) {
        window.notify(this.props.t('profil.label_address_incomplete'),'danger')
        this.setState({loading:false})
        return {error:true};
      }

      objAddress.postal_code     = this.state.formData.cp
      objAddress.city            = this.state.formData.city
      objAddress.insee_code      = this.state.formData.insee_code
      objAddress.country         = 'FR'
      objAddress.street_number   = this.state.formData.street_number
      objAddress.street_name     = this.state.formData.adresse
    }
    return objAddress
  }

  // données personnelles
  handleSubmit = async () => {
    if (this.state.hasFormDataChanged === false) {
      window.notify(this.props.t('profil.no_changes'),'danger')
      return;
    }
    this.setState({loading:true})

    // le consommateur
    let consumerToPut = {}
    consumerToPut.consumer_id = this.state.consumer.consumer_id

    let objPerson = this.getObjPerson()
    let consumer_data = objPerson

    // infos facultatives
    let objAddress = this.getObjAddress()
    if (StrDef(objAddress.error))
      return

    if (Object.keys(objAddress).length > 0)
      consumer_data.address = objAddress

    consumerToPut.consumer_data = consumer_data;

    // les vélos
    let bikes = []
    let res = await window.API.get_bike()
    if (!StrDef(res.error)) {
      for (var i = 0; i < res.length; i++) {
        bikes.push(res[i])
      }
      while (res.length >= BIKE_LIMIT) {
        res = await window.API.get_bike(res[res.length - 1].modify_date)
        for (var i = 0; i < res.length; i++) {
          bikes.push(res[i])
        }
      }
    }

    // on ne met pas à jour les vélos car il y 'en a trop -
    // on mettera à jour le vélo lorsqu'il sera déclaré volé
    if (bikes.length <= MAX_UPDATE_BIKE) {
      for (var i = 0; i < bikes.length; i++) {
        let oneBike = bikes[i];
        let bikeToPut = {}
        bikeToPut.bike_id     = oneBike.bike_id
        bikeToPut.row_version = oneBike.row_version
        let bikeOwnerToPut = objPerson
        if (Object.keys(objAddress).length > 0)
          bikeOwnerToPut.address  = objAddress

        // s'il y a un co-pro de défini sur un vélo, on le conserve
        if (StrDef(oneBike.bike_owner.co_owner))
          bikeOwnerToPut.co_owner = oneBike.bike_owner.co_owner

        bikeToPut.bike_owner = bikeOwnerToPut

        let resPut = await window.API.put_bike(bikeToPut);
        if (StrDef(resPut.error)){
          // le consumer est à jour mais pas le bike ! aie - désynchro
          if (resPut.error.code === 'TooManyRequestsException') {
            window.notify(this.props.t('profil.error_already_update'),'danger')
          } else if (resPut.error.code === 'BikeVersionMismatchException') {
            window.notify(this.props.t('profil.error_version'),'danger')
            this.load()
            return
          } else if (resPut.error.code === 'FnuciApiException') {
            window.notify(this.props.t('profil.error_other'),'danger')
          } else {
            window.notify(this.props.t('profil.error_other'),'danger')
          }
          this.setState({loading:false})
          //return;
        }
      }
    }

    delete consumerToPut.consumer_data.co_owner
    let resPutConsumer = await window.API.put_consumer(consumerToPut);
    if (!StrDef(resPutConsumer.error)) {
      // à la fin on notifie le user
      window.notify(this.props.t('profil.success'),'success')
      this.load()
    } else if (StrDef(resPutConsumer.error)) {
      if (resPutConsumer.error.code === 'TooManyRequestsException') {
        window.notify(this.props.t('profil.error_already_update'),'danger')
      } else if (resPutConsumer.error.code === 'MailNotFoundException') {
        window.notify(this.props.t('profil.error_mail_notexist'),'danger')
      } else if (resPutConsumer.error.code === 'MailNotAvailableException') {
        window.notify(this.props.t('profil.error_mail_notavailable'),'danger')
      } else if (resPutConsumer.error.code === 'FnuciApiException') {
          window.notify(this.props.t('profil.error_other'),'danger')
      } else {
        window.notify(this.props.t('profil.error_other'),'danger')
      }
      this.setState({loading:false})
    }

  }

  changePassword = async (event) => {
    //this.setState({loading:true})
    //cal API
    let obj = {}
    obj.newpassword = this.state.formDataPassword.password
    obj.password    = this.state.formDataPassword.actualPassword

    let res = await window.API.put_password(obj)
    if (!StrDef(res.error)) {
      window.notify(this.props.t('profil.success_password'),'success')
      this.setState({loading:false, changePassword:false},() => {
        this.load()
      })
    } else if (StrDef(res.error)) {
      if (res.error.code === 'NotAuthorizedException') {
        this.setState({
          formErrorPassword: {
            ...this.state.formErrorPassword,
            actualPassword : true,
            actualErrorPassword : this.props.t('profil.error_actual_mail')
          },
        })
      }
    }
  }

  changeEmail = async (event) => {
    event.preventDefault()
    const formReady = this.formValidation()
    if (formReady) {
      this.setState({
        formDataMail: { ...this.state.formDataMail, },
        formErrorMail: { ...this.state.formErrorMail },
        formEventMail: {
            ...this.state.formEvent,
            ready: false
        },
        loading : true,
      })

      let consumerToPut = {}
      let consumer_data = this.getObjPerson()
      consumer_data.mail = this.state.formDataMail.newMail
      consumerToPut.consumer_id = this.state.consumer.consumer_id
      let objAddress = this.getObjAddress()
      if (StrDef(objAddress.error)) {
        return
      }
      if (Object.keys(objAddress).length > 0) {
        consumer_data.address = objAddress
      }
      consumerToPut.consumer_data = consumer_data
      consumerToPut.code = this.state.formDataMail.tempCode
      let resPutConsumer = await window.API.put_consumer(consumerToPut)
      if (StrDef(resPutConsumer.error)) {
        if (resPutConsumer.error.code === 'TooManyRequestsException') {
          window.notify(this.props.t('profil.error_already_update'),'danger')
        } else if (resPutConsumer.error.code === 'MailNotFoundException') {
          window.notify(this.props.t('profil.error_mail_notexist'),'danger')
        } else if (resPutConsumer.error.code === 'MailNotAvailableException') {
          window.notify(this.props.t('profil.error_mail_notavailable'),'danger')
        } else if (resPutConsumer.error.code === 'NotAuthorizedException') {
          window.notify(this.props.t('profil.error_tempcode'),'danger')
        } else {
          window.notify(this.props.t('profil.error_other'),'danger')
        }
        this.setState({
          formEventMail: {
            ...this.state.formEventMail,
            ready: true
          },
          loading: false
        }, () => {
          this.load()
        })
      } else {
        // update bike with new mail
        // les vélos
        let bikes = []
        let res = await window.API.get_bike()
        if (!StrDef(res.error)) {
          for (var i = 0; i < res.length; i++) {
            bikes.push(res[i])
          }
          while (res.length >= BIKE_LIMIT) {
            res = await window.API.get_bike(res[res.length - 1].modify_date)
            for (var i = 0; i < res.length; i++) {
              bikes.push(res[i])
            }
          }
        }
        // on ne met pas à jour les vélos car il y 'en a trop -
        // on mettera à jour le vélo lorsqu'il sera déclaré volé
        if (bikes.length <= MAX_UPDATE_BIKE) {
          for (var i = 0; i < bikes.length; i++) {
            let oneBike = bikes[i];
            let bikeToPut = {}
            bikeToPut.bike_id         = oneBike.bike_id
            bikeToPut.row_version     = oneBike.row_version
            bikeToPut.bike_owner      = oneBike.bike_owner
            bikeToPut.bike_owner.mail = this.state.formDataMail.newMail

            let resPut = await window.API.put_bike(bikeToPut);
            if (StrDef(resPut.error)){
              // le consumer est à jour mais pas le bike ! aie - désynchro
              if (resPut.error.code === 'TooManyRequestsException') {
                window.notify(this.props.t('profil.error_already_update'),'danger')
              } else if (resPut.error.code === 'BikeVersionMismatchException') {
                window.notify(this.props.t('profil.error_version'),'danger')
                this.load()
                return
              } else if (resPut.error.code === 'FnuciApiException') {
                window.notify(this.props.t('profil.error_other'),'danger')
              } else {
                window.notify(this.props.t('profil.error_other'),'danger')
              }
              //this.setState({loading:false})
              //return;
            }
          }
        }

        window.notify(this.props.t('profil.success_mail'),'success')
        this.setState({
          step : 1,
          formEventMail: {
            ...this.state.formEventMail,
            ready: true
          },
        },() => {
          this.load()
        })
      }
    }
  }

  formValidation = () => {
    let formData = this.state.formDataMail;
    let formError = this.state.formErrorMail;
    let inputValidation = Object.keys(formData).map(itemName => {
      switch (itemName) {
        case 'newMail':
          const emailError = !ValidateEmail(formData.newMail)
          if (emailError) {
            formError.newMail = emailError
          }
          return !emailError
        case 'tempCode':
          const tempCodeError = formData.tempCode.length != CODE_LENGTH || !isInt(formData.tempCode)
          if (tempCodeError) {
            formError.tempCode = tempCodeError
          }
          return !tempCodeError
        default:
            return true
      }
    })
    this.setState({formErrorMail:formError})
    return inputValidation.reduce((acc, next) => acc && next)
  }

  formValidationMail = () => {
    let formData = this.state.formDataMail;
    let formError = this.state.formErrorMail;
    let inputValidation = Object.keys(formData).map(itemName => {
      switch (itemName) {
        case 'newMail':
          const emailError = !ValidateEmail(formData.newMail)
          if (emailError) {
            formError.newMail = emailError
          }
          return !emailError
        case 'tempCode':
          return true
        default:
            return true
      }
    })
    this.setState({formErrorMail:formError})
    return inputValidation.reduce((acc, next) => acc && next)
  }

  handleInputChangeMail = (event) => {
    const target = event.target
    const val = target.type === 'checkbox' ? target.checked : target.value
    const what = target.name
    let state = Clone(this.state);
    this.setState({
      formDataMail: {
        ...this.state.formDataMail,
        [what]: val
      },
      formErrorMail: {
        ...this.state.formErrorMail,
        [what]: false
      }
    })
  }

  checkMail = async (event) => {
    event.preventDefault()
    const formReady = this.formValidationMail()
    if (this.state.formDataMail.actualMail === this.state.formDataMail.newMail) {
      window.notify(this.props.t('profil.error_mail_same'),'danger')
      return
    }
    if (formReady) {
        this.setState({
          formDataMail: { ...this.state.formDataMail, },
          formErrorMail: { ...this.state.formErrorMail },
          formEventMail: {
              ...this.state.formEventMail,
              ready: false
          },

        })
        let res = await window.API.check_mail(this.state.formDataMail.newMail);
        if (!StrDef(res.error)) {
          window.notify(this.props.t('profil.label_check_mail'),'success')
          this.setState({step:3})
        } else if (StrDef(res.error)) {
          if (res.error.code === 'MailNotAvailableException') {
            window.notify(this.props.t('profil.error_mail_notavailable'),'danger')
          } else if (res.error.code === 'TooManyRequestsException') {
            window.notify(this.props.t('profil.error_code_notuse'),'danger')
          }
        }
        this.setState({
          formEventMail: {
            ...this.state.formEvent,
            ready: true
          },
        })
    }
  }

  render() {
    const { t } = this.props;
    return(
      <main className={"c-main-content u-flex u-flex-dir-col"}>

        {this.state.loading &&
          <div className="u-center">
            <div className="c-spinner"></div>
          </div>
        }

        {!this.state.loading &&
          <>

            <div className="c-skew-header">
              <div className="c-skew-header__top">
                <div className="l-container-md u-pd-hz-m">
                  <h1 className="c-h1 u-medium u-uppercase">{t('profil.label_account')}</h1>
                </div>
              </div>
              <div className="c-skew-header__bottom">
                <div className="l-container-md u-pd-hz-m">
                  <div className="c-skew-header__color u-relative">
                    {t('profil.label_ocode')}
                    <span className="u-absolute" style={{ top: "50%", right: "0" }}>
                      <Icon key={'arrow'} name="arrow" size={35} />
                    </span>
                  </div>

                  <div className="u-flex u-flex-center-vt u-flex-end">
                    <div className="c-skew-header__canvas">
                      <canvas className="" id='ocodeAccount' width={WIDHT_CANVAS_OCODE_DISPLAY} height={WIDHT_CANVAS_OCODE_DISPLAY} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="l-form-width u-flex-center-hz u-pd-bottom-l">
              <div className="c-form-group">
                <label className="c-label" >{t('profil.label_userid')}</label>
                <input
                  className={"c-input "}
                  name={'userid'}
                  type={'text'}
                  value={this.state.consumer.consumer_id}
                  disabled={true}
                />
              </div>
            </div>
            <div className="u-pd-hz-m">
              <form className="l-form-width">
                <div className="u-flex u-flex-end-vt">
                  <FormInput
                    style={{ minWidth: "calc(100% - 20px)" }}
                    formData={this.state.formDataCode}
                    formError={this.state.formErrorCode}
                    what={"code"}
                    error={""}
                    placeholder={t('profil.label_shortcode')}
                    handleInputChange={this.handleInputChangeCode}
                    disabled={true}
                  />
                  <button className="c-btn-form-group" onClick={this.state.formEventCode.ready ? this.generateCode.bind(this) : undefined}>
                    <Icon key={'shortcode'} name="shortcode" size={40} />
                  </button>
                </div>
              </form>
            </div>

            <div className="u-pd-hz-m u-pd-bottom-l">

              <div className="c-section-title u-pd-left-m u-mg-negative-hz-m u-mg-vt-l">
                <div className="l-container-md u-relative u-pd-right-m">
                  <div className="c-section-title__content">
                    <h2 className="c-h2 u-medium u-uppercase">{t('profil.label_informations')}</h2>
                  </div>
                </div>
              </div>

              <FormPerson
                mode = {'account'}
                role = {this.state.role}
                formData={this.state.formData}
                updateState={this.updateStatePerson}
                disabled={this.state.disableChange}
                onSubmit = {this.handleSubmit}
                onDisabled = {this.load}
              />

              {this.state.disableChange &&
                <div className="u-flex u-flex-center-hz u-mg-top-l">
                    <button
                      type="button"
                      className="c-btn c-btn--primary"
                      onClick={() => {this.setState({disableChange:!this.state.disableChange})}}
                    >
                      {t('profil.label_modify')}
                    </button>
                </div>
              }

              <div className="c-section-title u-pd-left-m u-mg-negative-hz-m u-mg-vt-l">
                <div className="l-container-md u-relative u-pd-right-m">
                  <div className="c-section-title__content">
                    <h2 className="c-h2 u-medium u-uppercase">{t('profil.label_id')}</h2>
                    <Icon key={''} name="" size={40} />
                  </div>
                </div>
              </div>

              <form className="l-form-width">
                <FormInput
                  className="u-mg-bottom-m"
                  formData={this.state.formDataMail}
                  formError={this.state.formErrorMail}
                  what={"actualMail"}
                  error={""}
                  placeholder={t('profil.label_actual_mail')}
                  handleInputChange={this.handleInputChangeMail}
                  disabled={true}
                />

                {(this.state.step === 2 || this.state.step === 3) &&
                  <FormInput
                    className="u-mg-bottom-m"
                    formData={this.state.formDataMail}
                    formError={this.state.formErrorMail}
                    what={"newMail"}
                    error={t('profil.label_new_mail_error')}
                    placeholder={t('profil.label_new_mail')}
                    handleInputChange={this.handleInputChangeMail}
                  />
                }

                {this.state.step === 3 &&
                <>
                  <FormInput
                    className="u-mg-bottom-s"
                    formData={this.state.formDataMail}
                    formError={this.state.formErrorMail}
                    what={"tempCode"}
                    error={t('profil.label_tempcode_error')}
                    placeholder={t('profil.label_tempcode')}
                    handleInputChange={this.handleInputChangeMail}
                    maxLength={CODE_LENGTH}
                  />
                  <div className="u-flex u-flex-end">
                    <button
                      onClick={this.checkMail}
                      className="u-block u-blue20 u-fs-xs u-underline"
                    >
                      {t('profil.label_send_code')}
                    </button>
                  </div>
                </>
                }

                {this.state.step === 1 &&
                  <div className="u-flex u-flex-center-hz u-mg-top-l">
                    <button
                      type="button"
                      className="c-btn c-btn--primary"
                      onClick={() => {this.setState({step:2})}}
                    >
                      {t('profil.label_modify')}
                    </button>

                  </div>
                }

                {this.state.step === 2 &&
                  <>
                    <p className="u-fs-xs u-primary u-pd-vt-s">
                      {t('profil.label_send_code_explain')}
                    </p>
                    <div className="u-flex u-flex-center-hz u-mg-top-l">
                      <button
                        type="submit"
                        className="c-btn c-btn--primary"
                        onClick={this.checkMail}
                      >
                        {t('profil.label_continue')}
                      </button>

                    </div>
                  </>
                }

                {this.state.step === 3 &&
                  <div className="u-flex u-flex-center-hz u-mg-top-l">
                  <button
                    type="submit"
                    className="c-btn c-btn--primary"
                    onClick={this.changeEmail.bind(this)}
                  >
                    {t('profil.label_confirm')}
                  </button>
                  </div>
                }
              </form>

              <div className="c-section-title u-pd-left-m u-mg-negative-hz-m u-mg-vt-l">
                <div className="l-container-md u-relative u-pd-right-m">
                  <div className="c-section-title__content">
                    <h2 className="c-h2 u-medium u-uppercase">{t('profil.label_password_title')}</h2>
                    <Icon key={''} name="" size={40} />
                  </div>
                </div>
              </div>

              {this.state.changePassword === false &&
                <div className="u-flex u-flex-center-hz u-mg-top-l">
                  <button
                    type="submit"
                    className="c-btn c-btn--primary"
                    onClick={() => {this.setState({changePassword:true})}}
                  >
                    {t('profil.label_modify')}
                  </button>
                </div>
              }

              {this.state.changePassword === true &&
                <FormPassword
                  mode = {'account'}
                  formData={this.state.formDataPassword}
                  formError={this.state.formErrorPassword}
                  updateState={this.updateStatePassword}
                  onSubmit = {this.changePassword}
                />
              }
            </div>
          </>
        }
      </main>
    )
  }
}


export default withTranslation()(Profil);
