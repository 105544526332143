import React from "react"
import {
    Page,
    Text,
    View,
    Document,
} from "@react-pdf/renderer"
import { useTranslation } from "react-i18next"
import styles from "./PDFStyles"
import HeaderPDF from "./PDFHeader"
import DataViewPDF from "./PDFDataView"
import { isNotEmpty } from "utils/validation"

const PDFRequestTemplate = ({
    children,
    title,
    bike = null,
    operatorGender,
}) => {
    const { t } = useTranslation()
    const contents = t("pdf.main_top_content", { returnObjects: true })

    return (
        <Document>
            <Page size="A4" style={styles.page}>

                <HeaderPDF bike={bike} operatorGender={operatorGender} />

                {isNotEmpty(title) &&
                    <View style={styles.sectionTitle}>
                        <Text style={styles.h1}>{title}</Text>
                    </View>
                }

                {isNotEmpty(bike) &&
                    <View style={styles.section}>
                        <View>
                            <Text style={[styles.h2]}>{t("pdf.pdf_identification_title")}</Text>
                        </View>

                        <View>   
                            {
                                contents.map((content, index) => {
                                    return <Text style={styles.p} key={index}> {content} </Text>
                                })
                            }
                        </View>

                        <View style={styles.grid}>
                            <View style={styles.sectionRight}>
                                <Text style={styles.h3}>{t("pdf.bike_info")}</Text>
                                <DataViewPDF
                                    label={t("bike.table_col_brand")}
                                    value={bike?.bike_description.brand}
                                />
                                <DataViewPDF
                                    label={t("bike.table_col_model")}
                                    value={bike?.bike_description.model}
                                />
                                <DataViewPDF
                                    label={t("bike.table_col_gear_type")}
                                    value={bike?.bike_description.gear_type}
                                />
                                <DataViewPDF
                                    label={t("bike.table_col_color")}
                                    value={bike?.bike_description.color}
                                />

                                {bike?.bike_description.label_electric === "OUI" &&
                                    <DataViewPDF
                                    label={t("onebike.label_electric")}
                                    value={t("common.yes")}
                                    />
                                }

                                {bike?.bike_description.label_electric !== "OUI" &&
                                    <DataViewPDF
                                    label={t("onebike.label_electric")}
                                    value={t("common.no")}
                                    />
                                }

                                <DataViewPDF
                                    label={t("bike.label_code")}
                                    value={bike?.bike_id}
                                />
                                {/* {isNotEmpty(bike?.bike_description?.frame_serial_number) &&
                                    <DataViewPDF
                                        label={t("onebike.label_serial_number")}
                                        value={bike?.bike_description.frame_serial_number}
                                    />
                                }
                                {isNotEmpty(bike?.bike_description?.engine_serial_number) &&
                                    <DataViewPDF
                                        label={t("onebike.label_engine_serial")}
                                        value={bike?.bike_description.engine_serial_number}
                                    />
                                }
                                {isNotEmpty(bike?.bike_description.battery_serial_number) &&
                                    <DataViewPDF
                                        label={t("onebike.label_battery_serial")}
                                        value={bike?.bike_description?.battery_serial_number}
                                    />
                                } */}
                            </View>
                            <View style={styles.sectionLeft}>
                                <Text style={styles.h3}>{t("pdf.personal_info")}</Text>
                                {bike?.bike_owner?.gender === 2 &&
                                    <DataViewPDF
                                        label={t("formperson.role_company")}
                                        value={bike?.bike_owner?.social_reason}
                                    />
                                }
                                {bike?.bike_owner?.gender === 1 &&
                                    <DataViewPDF
                                        label={t("formperson.label_lastname")}
                                        value={bike?.bike_owner.last_name}
                                    />
                                }
                                {bike?.bike_owner?.gender === 1 &&
                                    <DataViewPDF
                                        label={t("formperson.label_firstname")}
                                        value={bike?.bike_owner.first_name}
                                    />
                                }
                                <DataViewPDF
                                    label={t("formperson.label_phone")}
                                    value={bike?.bike_owner.phone.replace("+33", "0")}
                                />
                                <DataViewPDF
                                    label={t("formperson.label_mail")}
                                    value={bike?.bike_owner.mail}
                                />
                            </View>
                        </View>
                    </View>
                }

                {children}
            </Page>
        </Document>
    )
}

export default PDFRequestTemplate
