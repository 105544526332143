import React from "react";

import {StrDef} from '../../assets/lib/utils';

const tabIcon = {
  'help':
    <>
      <path d="M54.883 73.926A4.885 4.885 0 0150 78.809a4.885 4.885 0 01-4.883-4.883A4.885 4.885 0 0150 69.043a4.885 4.885 0 014.883 4.883zm0 0"/>
      <path d="M50 0C22.367 0 0 22.363 0 50c0 27.633 22.363 50 50 50 27.633 0 50-22.363 50-50 0-27.633-22.363-50-50-50zm0 92.188C26.684 92.188 7.812 73.32 7.812 50 7.813 26.684 26.68 7.812 50 7.812 73.316 7.813 92.188 26.68 92.188 50c0 23.316-18.868 42.188-42.188 42.188zm0 0"/>
      <path d="M50 25.098c-8.617 0-15.625 7.007-15.625 15.625a3.908 3.908 0 003.906 3.906 3.908 3.908 0 003.907-3.906c0-4.309 3.503-7.813 7.812-7.813s7.813 3.504 7.813 7.813c0 4.308-3.504 7.812-7.813 7.812a3.908 3.908 0 00-3.906 3.906v9.766A3.908 3.908 0 0050 66.113a3.908 3.908 0 003.906-3.906v-6.355c6.73-1.739 11.719-7.864 11.719-15.13 0-8.617-7.008-15.624-15.625-15.624zm0 0"/>
    </>,
  'line':
  	<>
  	 <polyline points='0.5 8.52 134.74 8.52 145.29 0.62 155.89 8.52 289.5 8.52'  style={{fill:'#385680'}}/>
  	</>,
  'see':
    <>
      <path id="bordereye" d="M48.57,78.21c-1.18,0-2.36,0-3.54-.13C18.65,76.19,2.16,53.89,1.47,52.94L.4,51.48,1.47,50C2.16,49.06,18.65,26.76,45,24.87,62.83,23.6,80.62,32,97.9,49.73l1.7,1.75-1.7,1.74C81.77,69.81,65.19,78.21,48.57,78.21ZM6.69,51.48c4.07,4.86,18.43,20.18,38.74,21.62C61.11,74.2,77,66.93,92.59,51.48,77,36,61.11,28.75,45.43,29.85,25.13,31.29,10.76,46.61,6.69,51.48Z"  style={{fill:'#385680'}}/>
      <path id="center" d="M50.1,70.48a19,19,0,1,1,19-19A19,19,0,0,1,50.1,70.48Zm0-33a14,14,0,1,0,14,14A14,14,0,0,0,50.1,37.45Z" style={{fill:'#385680'}}/>
    </>,
	'hide':
    <>
      <path id="bordereye" d="M48.57,78.21c-1.18,0-2.36,0-3.54-.13C18.65,76.19,2.16,53.89,1.47,52.94L.4,51.48,1.47,50C2.16,49.06,18.65,26.76,45,24.87,62.83,23.6,80.62,32,97.9,49.73l1.7,1.75-1.7,1.74C81.77,69.81,65.19,78.21,48.57,78.21ZM6.69,51.48c4.07,4.86,18.43,20.18,38.74,21.62C61.11,74.2,77,66.93,92.59,51.48,77,36,61.11,28.75,45.43,29.85,25.13,31.29,10.76,46.61,6.69,51.48Z"  style={{fill:'#385680'}}/>
      <path id="center" d="M50.1,70.48a19,19,0,1,1,19-19A19,19,0,0,1,50.1,70.48Zm0-33a14,14,0,1,0,14,14A14,14,0,0,0,50.1,37.45Z" style={{fill:'#385680'}}/>
      <polygon points="18.23 85.35 88.84 14.74 81.77 14.74 11.16 85.35 18.23 85.35" style={{fill:'#385680'}}/>
    </>,
  'back':
    <>
      <polygon points="74.5 90.51 33.99 50 74.5 9.49 74.5 1.01 25.5 50 74.5 98.99 74.5 90.51" style={{fill:'currentColor'}}/>
    </>,
  'scan':
    <>
      <path id="border" d="M85.36,14.64A50,50,0,0,0,14.64,85.36,50,50,0,0,0,85.36,14.64ZM50,95A45,45,0,1,1,95,50,45,45,0,0,1,50,95Z" style={{fill:'#385680'}}/>
      <circle id="background" cx="50" cy="50" r="45" style={{fill:'#FFFFFF'}}/>
      <path id="grando" d="M35.43,66.66a17,17,0,0,1,0-34h0a17.08,17.08,0,0,1,17,17,16.68,16.68,0,0,1-4.89,11.94,16.91,16.91,0,0,1-12,5.1h-.06Zm0-30.48h0a13.48,13.48,0,1,0,0,27h0A13.44,13.44,0,0,0,45,59.08a13.2,13.2,0,0,0,3.88-9.43A13.57,13.57,0,0,0,35.45,36.18Z" style={{fill:'#385680'}}/>
      <path id="degre" d="M61.05,44.75a6,6,0,1,1,6-6A6,6,0,0,1,61.05,44.75Zm0-8.83a2.84,2.84,0,1,0,2.84,2.83A2.83,2.83,0,0,0,61.05,35.92Z" style={{fill:'#385680'}}/>
      <path id="petito" d="M71.38,67.28a10.25,10.25,0,0,1,0-20.5h0A10.3,10.3,0,0,1,81.62,57a10,10,0,0,1-3,7.24,10.2,10.2,0,0,1-7.23,3h0Zm0-17.16h0a6.92,6.92,0,0,0,0,13.83h0a6.84,6.84,0,0,0,4.84-2.05,6.75,6.75,0,0,0,2-4.87A7,7,0,0,0,71.39,50.12Z" style={{fill:'#385680'}}/>
    </>,
  'modify':
    <>
      <rect id='whitesquare' x='4.66' y='21.84' width='73.49' height='73.49' style={{fill:'#FFFFFF'}}/>
      <polygon id='whitepen' points='99.55 18.63 41.22 76.97 19.33 80.61 22.79 58.95 81.34 0.4 99.55 18.63' style={{fill:'#FFFFFF'}}/>
      <polygon id='pen' points='63.01 24.93 59.91 21.83 22.79 58.95 34.71 70.87 37.81 67.77 29 58.95 63.01 24.93' style={{fill:'#1B1464'}}/>
      <polygon id='pengomme' points='87.65 6.63 84.55 9.75 93.36 18.63 84.55 27.51 87.65 30.63 99.55 18.63 87.65 6.63' style={{fill:'#1B1464'}}/>
      <polygon id='penpointe' points='25.12 75.68 23.72 75.93 25.41 65.17 25.45 64.95 22.32 61.61 19.33 80.61 28.33 79.1 25.12 75.68' style={{fill:'#1B1464'}}/>
      <polygon id='penS' points='84.44 3.5 81.34 0.4 63.11 18.62 78.23 33.74 38.3 73.68 28.43 75.32 31.67 78.56 41.09 76.99 41.22 76.97 84.44 33.74 69.32 18.62 84.44 3.5' style={{fill:'#1B1464'}}/>
      <rect id='topborder' y='17.03' width='50' height='5' style={{fill:'#1B1464'}}/>
      <rect id='rightborder' x='77.97' y='47.13' width='5' height='41' style={{fill:'#1B1464'}}/>
      <polygon id='bottomborder' points='4.66 95.35 4.66 29 0 29 0 100 83 100 83 95.35 4.66 95.35' style={{fill:'#1B1464'}}/>
    </>,
  'menu':
    <>
      <rect id="top" y="21" width="100" height="8" />
      <rect id="middle" x="6" y="46" width="88" height="8" />
      <rect id="bottom" data-name="top" y="71" width="100" height="8" />
    </>,
  'cancel':
  	<>
    	<path id='border' d='M85.36,14.64A50,50,0,0,0,14.64,85.36,50,50,0,0,0,85.36,14.64ZM50,95A45,45,0,1,1,95,50,45,45,0,0,1,50,95Z' style={{fill:'#1B1464'}}/>
    	<circle id='background' cx='50' cy='50' r='45' style={{fill:'#FFFFFF'}}/>
    	<polygon id='top' points='43.49 47.1 18.95 22.5 26.18 22.69 47.07 43.53 43.49 47.1' style={{fill:'#1B1464'}}/>
    	<polygon id='large' points='33.59 62.85 60.31 36.13 60.3 36.12 73.77 22.66 81.05 22.52 53.68 49.9 49.93 53.64 26.16 77.34 18.95 77.48 33.58 62.84 33.59 62.85' style={{fill:'#1B1464'}}/>
    	<polygon id='btm' points='56.5 52.93 81.08 77.5 73.76 77.33 52.93 56.5 56.5 52.93' style={{fill:'#1B1464'}}/>
  	</>,
  'bike':
  <>
    <path d='M81.71,43.21a18,18,0,0,0-5.9,1L63.19,20.67H56.38v5h3.81l3.35,6.24H32.41l-3-5.54H20.51v5h5.91l2.72,5L24.7,44.41A18.05,18.05,0,1,0,36.15,63.77H46.83L67.18,38.72l4.18,7.78a18,18,0,1,0,10.35-3.29ZM32,41.54l9.43,17.23H36.15a18,18,0,0,0-7.07-11.94Zm-5.34,9.71a13,13,0,0,1,4.45,7.52H22.52ZM18.29,74.33a13.06,13.06,0,1,1,4-25.49L14.06,63.77h17A13.08,13.08,0,0,1,18.29,74.33ZM46.05,56.81l-10.9-19.9H62.21ZM81.71,74.33a13,13,0,0,1-8-23.38L80.21,63l4.4-2.36L78.21,48.7a13.05,13.05,0,1,1,3.5,25.63Z' style={{fill:'#385680'}} />
  </>,
  'bike_bottom':
  <>
    <path d='M81.71,63.89a17.74,17.74,0,0,0-5.9,1L63.19,41.34H56.38v5h3.81l3.35,6.24H32.41l-3-5.54H20.51v5h5.91l2.72,5L24.7,65.08A18.05,18.05,0,1,0,36.15,84.44H46.83l20.35-25,4.18,7.78a18,18,0,1,0,10.35-3.28ZM32,62.22l9.43,17.22H36.15A18,18,0,0,0,29.08,67.5Zm-5.34,9.71a13,13,0,0,1,4.45,7.51H22.52ZM18.29,95a13.06,13.06,0,1,1,4-25.49L14.06,84.44h17A13.08,13.08,0,0,1,18.29,95ZM46.05,77.48l-10.9-19.9H62.21ZM81.71,95a13,13,0,0,1-8-23.38l6.47,12.07,4.4-2.37-6.4-11.94A13.05,13.05,0,1,1,81.71,95Z' style={{fill:'#385680'}} />
  </>,
  'shortcode':
    <>
      <path id="border" d="M85.36,14.64A50,50,0,0,0,14.64,85.36,50,50,0,0,0,85.36,14.64ZM50,95A45,45,0,1,1,95,50,45,45,0,0,1,50,95Z" style={{fill:'#385680'}}/>
      <circle id="background" cx="50" cy="50" r="45" style={{fill:'#FFFFFF'}}/>
      <path d="M54.1,18.3v5A28.18,28.18,0,1,1,25.94,52.22h12.9L23.43,36.66,8,52.22H20.94A33.17,33.17,0,1,0,54.1,18.3Zm-30.67,24,5.82,5.88H17.6Z" style={{fill:'#385680'}}/>
    </>,
  'arrow':
    <>
      <path d='M78.34,84.41c13-20.53,15.6-38.95,7.42-53.34C74.84,11.84,45.11.8,8.18,2.24l.13,3.11C44.07,4,72.71,14.4,83.06,32.61c7.49,13.19,5.07,30.29-7,49.55l-13-13,0,28.72,28.72,0ZM66.18,76.68,84.3,94.74l-18.09,0Z' style={{fill:'#F2F3F4'}} />
    </>,
  'earth':
    <>
      <path d="M267,474l-.8-.13A.85.85,0,0,0,267,474Z"/>
      <path d="M448.9,187.78a5.51,5.51,0,0,0-10.67-.63h0A5.52,5.52,0,0,1,433,191H417.53a5.48,5.48,0,0,1-2.84-.79l-22.38-13.42a5.48,5.48,0,0,0-2.84-.79h-35.8a5.48,5.48,0,0,0-3.06.93l-44.15,29.43A5.52,5.52,0,0,0,304,211v41.74a5.51,5.51,0,0,0,2.92,4.87l57.89,30.9a5.55,5.55,0,0,1,2.92,4.8L368,316.8a5.53,5.53,0,0,0,2.85,4.75l23.26,12.87a5.54,5.54,0,0,1,2.85,4.83v48.6a5.52,5.52,0,0,0,9.17,4.14c9.38-8.26,22.83-20.32,24.62-23.08q4.44-6.87,8.33-14.07a207.39,207.39,0,0,0,13.6-31C465.36,287.13,455.34,221.14,448.9,187.78Z"/>
      <path d="M286.4,302.8l-61.33-46a4,4,0,0,0-2.4-.8h-29.1a3.78,3.78,0,0,1-2.68-1.11l-13.72-13.72a4,4,0,0,0-2.83-1.17H121.15a3.79,3.79,0,0,1-2.68-6.47l8.42-8.42a3.78,3.78,0,0,1,2.68-1.11h32.37a8,8,0,0,0,7.7-5.83l6.89-24.5a4,4,0,0,1,2-2.47L206,177.06a3.79,3.79,0,0,0,2.05-3.37v-12.5a3.82,3.82,0,0,1,.68-2.17L223.33,138a3.75,3.75,0,0,1,1.78-1.38l20.43-7.67a3.79,3.79,0,0,0,2.46-3.55V114a3.8,3.8,0,0,0-1.69-3.16L225.83,97.22A3.83,3.83,0,0,0,222,97l-27.88,13.94a3.78,3.78,0,0,1-4-.41L176.9,100.08a3.8,3.8,0,0,1,.1-6l10.74-7.91a3.78,3.78,0,0,0-.09-6.16L170.92,68.34a3.78,3.78,0,0,0-4-.22c-6.05,3.31-23.8,13.11-30.1,17.52a209.48,209.48,0,0,0-68.16,80c-1.82,3.76-4.07,7.59-4.29,11.72s-3.46,13.35-4.81,17.08a3.78,3.78,0,0,0,.24,3.1l35.69,65.58a3.74,3.74,0,0,0,1.38,1.44l37.55,22.54a3.78,3.78,0,0,1,1.81,2.73l7.52,54.54a3.82,3.82,0,0,0,1.61,2.61l29.3,20.14a4,4,0,0,1,1.65,2.48l15.54,73.8a3.6,3.6,0,0,0,.49,1.22c1.46,2.36,7.28,11,14.3,12.28-.65.18-1.23.59-1.88.78a47.63,47.63,0,0,1,5,1.16c2,.54,4,1,6,1.43,3.13.62,3.44,1.1,4.94-1.68,2-3.72,4.29-5,6-5.46a3.85,3.85,0,0,0,2.89-2.9l10.07-46.68a4,4,0,0,1,1.6-2.42l45-31.9a4,4,0,0,0,1.69-3.27V306A4,4,0,0,0,286.4,302.8Z"/>
      <path d="M262,48s-3.65.21-4.39.23q-8.13.24-16.22,1.12A207.45,207.45,0,0,0,184.21,64c2.43,1.68-1.75,3.22-1.75,3.22L189,80h35l24,12,21-12Z"/>
      <path d="M354.23,120.06l16.11-14a4,4,0,0,0-.94-6.65l-18.81-8.73a4,4,0,0,0-5.3,1.9l-7.75,16.21a4,4,0,0,0,1.49,5.11l10.46,6.54A4,4,0,0,0,354.23,120.06Z"/>
      <path d="M429.64,140.67l-5.83-9c-.09-.14-.17-.28-.25-.43-1.05-2.15-9.74-19.7-17-26.51-5.45-5.15-7-3.67-7.43-2.53a3.77,3.77,0,0,1-1.19,1.6L369.1,127.11a4,4,0,0,1-2.51.89H351.66a4,4,0,0,0-2.83,1.17l-12,12a4,4,0,0,0,0,5.66l12,12a4,4,0,0,0,2.83,1.17h75.17a4,4,0,0,0,4-4.17l-.55-13.15A4,4,0,0,0,429.64,140.67Z"/>
      <path d="M256,72a184,184,0,1,1-130.1,53.9A182.77,182.77,0,0,1,256,72m0-40C132.3,32,32,132.3,32,256S132.3,480,256,480,480,379.7,480,256,379.7,32,256,32Z"/>
    </>,
};

export default class Icon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      color: this.props.color || 'currentColor',
      name: this.props.name || 'stock',
      viewBox: this.props.viewBox || '0 0 100 100',
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  render() {
    var content = (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={this.state.viewBox} width={this.props.size} height={this.props.size}>
        <g fill={this.state.color}>
          {tabIcon[this.state.name]}
        </g>
      </svg>
    );

    if( StrDef(this.props.onClick) ) {
      if( StrDef(this.props.style) ) {
        return (
          <div id={this.props.id} style={this.props.style}>
            <button style={{cursor:'pointer',border:'none',background:'none'}} onClick={this.props.onClick}>
              {content}
            </button>
          </div>
        );
      }
      else {
        return (
          <button style={{cursor:'pointer',border:'none',background:'none'}} onClick={this.props.onClick}>
            {content}
          </button>
        );
      }
    }
    else {
      if( StrDef(this.props.style) ) {
        return (
          <div style={this.props.style}>
            {content}
          </div>
        );
      }
      else {
        return content;
      }
    }

  }
}
