import Home from "views/home.jsx";
import Profil from "views/profil.jsx";
import ProfilNoWallet from "views/profilnowallet.jsx";
import Forgot from "views/forgot.jsx";
import ForgotNoWallet from "views/forgotnowallet.jsx";
import ListBike from "views/bike.jsx";
import Bike from "views/oneBike.jsx";
import AddBike from "views/addBike.jsx";
import Scan from "views/scan.jsx";
import Transfer from "views/transfer.jsx";
import BackStore from "views/backstore.jsx";
//import Destroy from "views/destroy.jsx";
import PreinscriptionRegister from "views/preinscription.jsx";
import PreinscriptionData from "views/preinscriptiondata.jsx";
import Faq from "views/faq.jsx";
import CGUNoWallet from "views/cgunowallet.jsx";
import PDF from "views/pdf.jsx";
import PublicBackStoreBike from "views/publicbackstore.jsx"
import PublicBikePortability from "views/bikePortability.jsx"
import Recovery from "views/recovery.jsx"

let routes = [
  // routes authentifiées
  // ----------------
  // {
  //   path: "/conditions-générales-utilisation",
  //   name: "Profil",
  //   icon: "exit",
  //   component: CGUNoWallet,
  //   layout: "/moncompte",
  // },
  // {
  //   path: "/mon-profil",
  //   name: "Profil",
  //   icon: "exit",
  //   component: Profil,
  //   layout: "/moncompte",
  // },
  // {
  //   path: "/mes-informations",
  //   name: "Profil",
  //   icon: "exit",
  //   component: ProfilNoWallet,
  //   layout: "/moncompte",
  // },
  // {
  //   path: "/mon-velo/:id/transferer",
  //   name: "Mon vélo",
  //   icon: "exit",
  //   component: Transfer,
  //   layout: "/moncompte",
  // },
  // {
  //   path: "/mes-velos/transferer",
  //   name: "Mes vélos",
  //   icon: "exit",
  //   component: Transfer,
  //   layout: "/moncompte",
  // },
  // {
  //   path: "/mes-velos/:id/transferer",
  //   name: "Mes vélos",
  //   icon: "exit",
  //   component: Transfer,
  //   layout: "/moncompte",
  // },
  // {
  //   path: "/mon-velo/:id/retour",
  //   name: "Mon vélo",
  //   icon: "exit",
  //   component: BackStore,
  //   layout: "/moncompte",
  // },
  // {
  //   path: "/mes-velos/:id/retour",
  //   name: "Mes vélos",
  //   icon: "exit",
  //   component: BackStore,
  //   layout: "/moncompte",
  // },
  /*{
    path: "/mes-velos/:id/detruire",
    name: "Mes vélos",
    icon: "exit",
    component: Destroy,
    layout: "/moncompte",
  },*/
  // {
  //   path: "/mes-velos/ajouter/:id",
  //   name: "Mes vélos",
  //   icon: "exit",
  //   component: AddBike,
  //   layout: "/moncompte",
  // },
  // {
  //   path: "/mes-velos/:id",
  //   name: "Mes vélos",
  //   icon: "exit",
  //   component: Bike,
  //   layout: "/moncompte",
  // },
  // {
  //   path: "/scan",
  //   name: "Mes vélos",
  //   icon: "exit",
  //   component: Scan,
  //   layout: "/moncompte",
  // },
  // {
  //   path: "/mes-velos",
  //   name: "Mes vélos",
  //   icon: "exit",
  //   component: ListBike,
  //   layout: "/moncompte",
  // },
  // {
  //   path: "/mon-velo/:id",
  //   name: "Mon vélo",
  //   icon: "exit",
  //   component: Bike,
  //   layout: "/moncompte",
  // },
  // {
  //   path: "/mon-velo",
  //   name: "Mon vélo",
  //   icon: "exit",
  //   component: ListBike,
  //   layout: "/moncompte",
  // },
  // {
  //   path: "/reprise-bicycode",
  //   name: "Accueil",
  //   component: Home,
  //   layout: "/moncompte",
  // },

  // routes publiques
  // ----------------
  {
    path: "bike/politiquedeconfidentialite.pdf",
    name: "Accueil",
    component: Home,
    layout: "/",
  },
  {
    path: "faq",
    name: "Accueil",
    component: Home,
    layout: "/",
  },
  {
    path: "mot-de-passe-oublie",
    name: "Accueil",
    component: Home,
    layout: "/",
  },
  {
    path: "mot-de-passe-oublie-nowallet",
    name: "Accueil",
    component: Home,
    layout: "/",
  },
  /*plus d'actualité
  {
    path: "connexion/:fnuci_id",
    name: "Accueil",
    component: Home,
    layout: "/",
  },*/
  {
    path: "pre-inscription/:id",
    name: "Accueil",
    component: Home,
    layout: "/",
  },
  {
    path: "pre-inscription",
    name: "Accueil",
    component: Home,
    layout: "/",
  },
  {
    path: "modifier-mon-velo",
    name: "Accueil",
    component: Home,
    layout: "/",
  },
  {
    path: "changer-d-operateur",
    name: "Accueil",
    component: Home,
    layout: "/",
  },
  /*{
    path: "bienvenue/:email",
    name: "Accueil",
    component: Home,
    layout: "/",
  },*/
  {
    path: "bienvenue",
    name: "Accueil",
    component: Home,
    layout: "/",
  },
  {
    path: "",
    name: "Accueil",
    component: Home,
    layout: "/",
  },


];

export default routes;
