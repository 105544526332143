
import React from "react"
import ReactDOM from "react-dom"
import { createBrowserHistory } from "history"
import { Router, Route, Switch, Redirect } from "react-router-dom"
import './translation'
import "styles/styles.scss"

import AccountLayout from "layouts/Account.jsx"
import ScrollToTop from "layouts/scrolltop.jsx"
import PublicLayout from "layouts/Public.jsx"

import Api from './assets/lib/api'

var MobileDetect = require('mobile-detect')
var md = new MobileDetect(window.navigator.userAgent)
var isMobile = md.mobile() != null
window.isMobile = isMobile
window.md = md

// on instancie la classe API qui gère les appels aux serveurs
var api = new Api()
window.API = api

var basename = ''
if( document.domain.indexOf('wallet.o-code.co') === -1 || document.domain.indexOf('wallet.ochain.fr') === -1) {
  basename = ''
}
const hist = createBrowserHistory({basename:basename});

window.browserHist = hist;

window.Logout = function() {
  var rememberMe = localStorage.getItem('rememberMe');
  localStorage.clear();
  localStorage.setItem('rememberMe',rememberMe);
  window.API.ocodeId = ''
  hist.push('/');
}

ReactDOM.render(
  <Router history={hist}>
    <ScrollToTop>
    <Switch>
      <Route
        path="/moncompte"
        render={props => {
          return <AccountLayout {...props} />;
        }}
      />
      <Route
        path="/"
        render={props => {
          return <PublicLayout {...props} />;
        }}
      />
      <Redirect to="/" />
    </Switch>
    </ScrollToTop>
  </Router>,
  document.getElementById("root")
);
