import React from "react";

import {
  StrDef,
  Clone,
  ValidateEmail,
  isMdpValid,
} from '../assets/lib/utils';
import Icon from "../components/Icons/Icon.jsx";
import FormInput from '../components/Forms/FormInput';
import FormPassword from '../components/Forms/FormPassword';
import FormPerson from '../components/Forms/FormPerson';
import Logo from "../components/Logos/Logo"
import { withTranslation } from 'react-i18next';
import { sanitize } from "dompurify";
import ReactMarkdown from 'react-markdown'

const PASSWORD_LENGTH = 8;
const FNUCI_CODE_LENGTH = 16;

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modeRegister : false,
      role : 1, // particulier par défaut,
      step : 1, // 1 - checkMail // 2 - register
      isWallet : false,
      isWelcome : false, // path bienvenue
      cguAccept : false,
      formDataRegisterPerson : {
        socialReason  : '',
        lastName      : '', // nom
        firstName     : '', // prénom
        phone         : '',
        email         : '',
        confirmMail   : '',
      },

      formDataRegisterPassword : {
        email     : '',
        tempCode  : '',
        password  : '',
        password2 : '',
        cguAccept : false,
      },

      //login
      formDataLogin : {
        bikeCode : '',
        email    : '',
        password : '',
        cguAccept: false,
      },
      formErrorLogin : {
        bikeCode  : false,
        email     : false,
        password  : false,
        cguAccept : false,
      },
      formEventLogin : {
        ready : true,
      }
    }

    this.switchMode       = this.switchMode.bind(this);
    this.changeValueRole  = this.changeValueRole.bind(this);
    this.changeValueCGU   = this.changeValueCGU.bind(this);
    this.register         = this.register.bind(this);
    this.login            = this.login.bind(this);
    this.forgotPassword   = this.forgotPassword.bind(this);
    this.forgotNoWallet   = this.forgotNoWallet.bind(this);
    this.backStep         = this.backStep.bind(this);
  }
  componentDidMount() {
    let isWallet          = this.state.isWallet
    let isWelcome         = this.state.isWelcome
    let formDataLogin     = this.state.formDataLogin
    let modeRegister      = this.state.modeRegister
    let step              = this.state.step
    let role              = this.state.role
    let formDataRegisterPerson   = this.state.formDataRegisterPerson
    let formDataRegisterPassword = this.state.formDataRegisterPassword

    if (this.props.location.pathname.indexOf('/bienvenue') !== -1) {
      isWelcome  = true;
      modeRegister = true;
      step = 2;
      // on regarde le bikeOwner pour récupérer les infos (first_name, last_name, etc) et passer à la step 2
      /*if (StrDef(this.props.match.params.email)) {
        formDataRegisterPassword.email = this.props.match.params.email
      }*/
      if( localStorage.getItem('bikeOwner') !== null ) {
        let bikeOwner = JSON.parse(localStorage.getItem('bikeOwner')).bikeOwner;

        // form step 1
        role = bikeOwner.gender
        if (role === 1) {
          formDataRegisterPerson.lastName   = bikeOwner.last_name
          formDataRegisterPerson.firstName  = bikeOwner.first_name
        } else {
          formDataRegisterPerson.socialReason = bikeOwner.social_reason
        }
        formDataRegisterPerson.email = bikeOwner.mail
        formDataRegisterPerson.phone = bikeOwner.phone

        // form step 2
        formDataRegisterPassword.email = bikeOwner.mail
        localStorage.removeItem('bikeOwner')
      }
    } else if (window.API.getSubDomain() !== 'wallet') {
      isWallet = false;
      formDataLogin.bikeCode = ''
    } else {
      isWallet = true;
    }

    this.setState({
      role : role,
      isWallet:isWallet,
      isWelcome : isWelcome,
      formDataLogin:formDataLogin,
      formDataRegisterPassword : formDataRegisterPassword,
      formDataRegisterPerson : formDataRegisterPerson,
      modeRegister : modeRegister,
      step : step
    })
  }

  changeValueRole(e) {
    let role = e.currentTarget.id;
    this.setState({
      role:parseInt(role)
    })
  }

  changeValueCGU(e) {
    let role = e.currentTarget.id;
    this.setState({
      role:parseInt(role)
    })
  }

  updateState = (state) => {
    this.setState({
      formDataRegisterPassword: state.formData,
      formDataRegisterPerson : {
        ...this.state.formDataRegisterPerson,
        email : state.formData.email
      }
    })
  }

  updateStatePerson = (state) => {
    this.setState({
      role : state.role,
      formDataRegisterPerson: state.formData,
      formDataRegisterPassword : {
        ...this.state.formDataRegisterPassword,
        email : state.formData.email
      }
    })
  }

  handleInputChangeLogin = (event) => {
    const target = event.target
    const val = target.type === 'checkbox' ? target.checked : target.value
    const what = target.name
    let state = Clone(this.state);

    this.setState({
      formDataLogin: {
        ...this.state.formDataLogin,
        [what]: val
      },
      formErrorLogin: {
        ...this.state.formErroLogin,
        [what]: false
      },

    })
  }

  nextStep = () => {
    this.setState({
      step:this.state.step+1
    })
  }

  register = async () => {
    // call api
    let data = {}
    data.mail     = this.state.formDataRegisterPerson.email
    data.code     = this.state.formDataRegisterPassword.tempCode
    data.password = this.state.formDataRegisterPassword.password

    let consumer_data = {}
    consumer_data.gender  = this.state.role
    consumer_data.phone   = this.state.formDataRegisterPerson.phone
    if (this.state.role === 1) { // particulier
      consumer_data.first_name  = this.state.formDataRegisterPerson.firstName
      consumer_data.last_name   = this.state.formDataRegisterPerson.lastName
    } else if (this.state.role === 2) { //entreprise
      consumer_data.social_reason = this.state.formDataRegisterPerson.socialReason
    }
    data.consumer_data = consumer_data;

    let res = await window.API.register_by_mail(data);
    if (!StrDef(res.error)) {
      window.notify(this.props.t("home.label_account_created"),'success')
      let urlWallet = 'https://wallet.' + document.domain.split('.')[1]

      if(document.domain.split('.')[1] + '.' + document.domain.split('.')[2] === "bicycode.eu") {
        urlWallet = "https://wallet.o-code"
      }

      if (document.domain.split('.')[1] === 'localhost') {
        urlWallet += ':3000'
      } else {
        urlWallet += process.env.REACT_APP_MODE === 'production' ? '.co' : '.fr'
      }

      setTimeout(()=>{
        window.location = urlWallet
      },1000)

      /*let formDataRegisterPerson = {}
      this.setState({
        // TODO - fbo - reinit tous les champs register
        modeRegister:false,
        //step:1,
        formDataRegisterPerson : {},
        formDataRegisterPassword : {}
      })*/
    } else if (StrDef(res.error)) {
      if (res.error.code === 'MailNotFoundException') {
        window.notify(this.props.t("home.error_check_mail_address"),'danger')
      } else if (res.error.code === 'NotAuthorizedException') {
        window.notify(this.props.t("home.error_expire_code"),'danger')
      } else if (res.error.code === 'MailNotAvailableException') {
        window.notify(this.props.t("home.error_invalid_mail"),'danger')
      }
    }
  }

  login = async (event) => {
    event.preventDefault()
    // Validate inputs
    const formReady = this.formValidationLogin()

    //const formReady = true;
    if (formReady) {
        // Inform the state that the component is unavailable
        // to prevent any new user submit
        this.setState({
          formDataLogin: { ...this.state.formDataLogin, },
          formErrorLogin: { ...this.state.formErrorLogin },
          formEventLogin: {
            ...this.state.formEventLogin,
            ready: false
          },
        })

        if (!this.state.isWallet) {
          let info = {}
          info.bike_id  = this.state.formDataLogin.bikeCode.replace(/\s/g, '').toUpperCase();
          info.password = this.state.formDataLogin.password

          let res = await window.API.signin_nowallet(info);
          if (!StrDef(res.error)) {
            localStorage.setItem('isAuth','1');
            if (Object.keys(res.gtu).length === 0) {
              this.props.history.push('/moncompte/conditions-générales-utilisation');
            } else {
              this.props.history.push('/moncompte/mon-velo');
            }
          } else if (StrDef(res.error)) {
            if (res.error.code === 'NotAuthorizedException') {
              window.notify(this.props.t("home.error_check_pwd_mail"),'danger')
            } else if (res.error.code === 'TooManyRequestsException') {
              window.notify(this.props.t("home.error_account_locked"),'danger')
            } else {
              window.notify(this.props.t("home.error_check_pwd_mail"),'danger')
            }
          }
        } else {
          // login
          let info = {}
          info.code_id  = this.state.formDataLogin.email
          info.password = this.state.formDataLogin.password

          let res = await window.API.signin(info);
          if (!StrDef(res.error)) {
            localStorage.setItem('isAuth','1');
            this.props.history.push('/moncompte/mes-velos');
          } else if (StrDef(res.error)) {
            if (res.error.code === 'NotAuthorizedException') {
              window.notify(this.props.t("home.error_check_pwd_mail"),'danger')
            } else if (res.error.code === 'TooManyRequestsException') {
              window.notify(this.props.t("home.error_account_locked"),'danger')
            }
          }

        }

        this.setState({
          formDataLogin: { ...this.state.formDataLogin, },
          formErrorLogin: { ...this.state.formErrorLogin },
          formEventLogin: {
            ...this.state.formEventLogin,
            ready: true
          },
        })
    }
  }

  formValidationLogin = () => {
    let formDataLogin = this.state.formDataLogin;
    let formErrorLogin = this.state.formErrorLogin;
    let inputValidation = Object.keys(formDataLogin).map(itemName => {
      switch (itemName) {
        case 'email':
          if (this.state.isWallet) { // on vérifie l'email
            const mailCodeError = !StrDef(formDataLogin.email) || !ValidateEmail(formDataLogin.email)
            if (mailCodeError) {
              formErrorLogin.email = mailCodeError
            }
            return !mailCodeError
          } else {
            return true
          }
        case 'bikeCode':
          if (!this.state.isWallet) { // on vérifie le fnuci
            const bikeCodeError = !StrDef(formDataLogin.bikeCode)
            if (bikeCodeError) {
              formErrorLogin.bikeCode = bikeCodeError
            }
            return !bikeCodeError
          } else {
            return true;
          }
        case 'cguAccept':
          return true;
        case 'password':
          if (!this.state.isWallet) {
            const passwordCodeError = !StrDef(formDataLogin.password) || formDataLogin.password.length < PASSWORD_LENGTH
            if (passwordCodeError) {
              formErrorLogin.password = passwordCodeError
            }
            return !passwordCodeError
          } else {
            const passwordCodeError = !StrDef(formDataLogin.password) || formDataLogin.password.length < PASSWORD_LENGTH || !isMdpValid(formDataLogin.password)
            if (passwordCodeError) {
              formErrorLogin.password = passwordCodeError
            }
            return !passwordCodeError
          }

        default:
            return true
      }
    })
    this.setState({formErrorLogin:formErrorLogin})
    return inputValidation.reduce((acc, next) => acc && next)
  }


  forgotPassword() {
    this.props.history.push('/mot-de-passe-oublie');
  }

  forgotNoWallet() {
    this.props.history.push('/mot-de-passe-oublie-nowallet');
  }

  switchMode() {
    this.setState({modeRegister:!this.state.modeRegister})
  }

  backStep() {
    this.setState({step:this.state.step - 1})
  }

  render() {
    const { t } = this.props;

    return (
      <main className={"c-main-content u-pd-hz-xl u-pd-xl@main"}>
        <div className="l-form-width-container u-mg-bottom-xl">

          <h2 className="c-h2 u-fs-xl u-center u-bold u-mg-bottom-m">{t('newPlatform.title1')}</h2>
          <h2 className="c-h2 u-fs-xl u-center u-bold u-mg-bottom-m">{t('newPlatform.title2')}</h2>
          <h2 className="c-h2 u-fs-xl u-center u-bold u-mg-bottom-xl">{t('newPlatform.title3')}</h2>

        <p className="u-blue20 u-fs-xs u-mg-bottom-m">{t('newPlatform.text1')}</p>
        <p className="u-blue20 u-fs-xs u-mg-bottom-l">{t('newPlatform.text2')}</p>

        <p className="u-blue20 u-fs-xs u-mg-bottom-s">{t('newPlatform.text3')}</p>
        <div className="u-flex u-flex-center-hz u-mg-bottom-xl">
          <a href={"https://moncompte.bicycode.eu"} className="c-btn c-btn--primary u-fs-xs" target="_blank" rel="noopener noreferrer">
          {t('newPlatform.button1')}
          </a>
        </div>

        <p className="u-blue20 u-fs-xs u-mg-bottom-s">{t('newPlatform.text4')}</p>
        <div className="u-flex u-flex-center-hz u-mg-bottom-xl">
          <a href={"https://mfc.o-code.co/"} className="c-btn c-btn--primary u-fs-xs" target="_blank" rel="noopener noreferrer">
          {t('newPlatform.button2')}
          </a>
        </div>

        <p className="u-blue20 u-fs-xs u-mg-bottom-s">{t('newPlatform.text5')}</p>
        <div className="u-flex u-flex-center-hz">
          <a href={"https://decathlon.o-code.co/"} className="c-btn c-btn--primary u-fs-xs" target="_blank" rel="noopener noreferrer">
          {t('newPlatform.button3')}
          </a>
        </div>


        </div>


      </main>
    )
  }
}

export default withTranslation()(Home);
