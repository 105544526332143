import React from "react"
import {
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Modal, ModalHeader, ModalBody, ModalFooter,
  } from "reactstrap"
import { useTable, useSortBy, usePagination, useRowSelect, useGlobalFilter } from "react-table"
import { default as ReactSelect } from "react-select"
import i18next from "i18next"
import { exportXLSX, StrDef } from "assets/lib/utils"
import moment from "moment"
import TableImportModal from "components/table/TableImportModal"
import ReactTooltip from "react-tooltip"
import Logo from "../../components/Logos/LogoById"
import Icon from "../../components/Icons/Icon.jsx"
import { sanitize } from 'dompurify'

const BIKES_LENGTH = 10
const pageSizesList = [
    { value: "25", label: "25" },
    { value: "50", label: "50" },
    { value: "75", label: "75" },
    { value: "100", label: "100" },
    { value: "150", label: "150" },
    { value: "200", label: "200" }
]

const bikeStatus = [
    { id: 1, label: i18next.t("bikestatus.label_inservice") },
    { id: 2, label: i18next.t("bikestatus.label_stolen") },
    { id: 5, label: i18next.t("bikestatus.label_insale") },
    { id: 3, label: i18next.t("bikestatus.label_destroy") },
    { id: 6, label: i18next.t('bikestatus.label_backstore') },
]

const BikesTable = ({
    data,
    refreshData,
    isBikeBundle = false,
    hasSelectableRows = false,
    selection,
    selectionLength = BIKES_LENGTH,
    hasBikeActions = false,
    scrollToBundle,
    callback,
    history,
    location
}) => {
    // Columns definition
    const columns = React.useMemo(
        () => [
            {
                Header: i18next.t('bike.table_col_bikecode'),
                accessor: 'bike_id',
            },
            {
                Header: i18next.t('bike.table_col_operator'),
                accessor: row => row['operator'],
                id: 'operator'
            },
            {
                Header: i18next.t('bike.table_col_brand'),
                accessor: 'bike_description.brand',
            },
            {
                Header: i18next.t('bike.table_col_model'),
                accessor: 'bike_description.model',
            },
            {
                Header: i18next.t('bike.table_col_gear_type'),
                accessor: 'bike_description.gear_type',
            },
            {
                Header: i18next.t('bike.table_col_color'),
                accessor: 'bike_description.color',
            },
            {
                Header: i18next.t('bike.table_col_status'),
                accessor: row => bikeStatus.find(s => s.id === row['bike_status'])?.label,
            },
            {
                Header: i18next.t('bike.table_col_internal_code'),
                accessor: 'additional_sn',
            },
            {
                Header: i18next.t('bike.table_col_order'),
                accessor: 'order_id',
            },
        ],
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        getToggleAllRowsSelectedProps, // select all rows in all pages
        headerGroups,
        prepareRow,
        rows,
        page, // the rows for the active page

        // pagination utilities
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        setPageSize,
        setGlobalFilter,
        state: { pageIndex, pageSize, selectedRowIds },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageSize: 50
            }
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect
    )

    const getColumnSize = React.useCallback((columnName) => {
        // sum should be 24
        switch (columnName) {
            case "bike_id":
                return "c-table__cell--3"
            case "operator":
                return "c-table__cell--3"
            case "bike_description.brand":
                return "c-table__cell--3"
            case "bike_description.model":
                return "c-table__cell--2"
            case "bike_description.gear_type":
                return "c-table__cell--3"
            case "bike_description.color":
                return "c-table__cell--3"
            case "Statut":
                return "c-table__cell--3"
            case "additional_sn":
                return "c-table__cell--3"
            case "order_id":
                return "c-table__cell--3"
            default:
                return "u-flex-1"
        }
    }, [])

    // Manage pageSize select
    // -------------------
    const [pageSizeSelection, setPageSizeSelection] = React.useState({ value: pageSize, label: pageSize })
    React.useEffect(() => { setPageSize(pageSizeSelection.value) }, [pageSizeSelection, setPageSize])

    // Manage global search
    // -------------------
    const [dataTableFilter, setDataTableFilter] = React.useState("")
    React.useEffect(() => setGlobalFilter(dataTableFilter), [dataTableFilter, setGlobalFilter])

    // Manage bundle selection
    // -------------------
    const [currentBundleStatus, setCurrentBundleStatus] = React.useState(null)
    React.useEffect(() => {
        // Get the bike_status of the first bike selected to prevent a bundle with multiple status
        const selectedBikes = Object.keys(selectedRowIds).map(index => data[index])
        if (!isBikeBundle) {
            if (!currentBundleStatus && selectedBikes.length > 0) setCurrentBundleStatus(selectedBikes[0]?.bike_status)
            if (currentBundleStatus && selectedBikes.length === 0) setCurrentBundleStatus(null)
        }

        // On selection, set the bike bundle on the parent state
        if (callback) {
            callback(selectedBikes)
        }
        // react-table issue here: we must rely on selectedRowIds since selectedFlatRows is not consistent
        // see https://github.com/tannerlinsley/react-table/issues/2210
    }, [selectedRowIds]) // eslint-disable-line

    // Select all input
    const [alllowSelectAll, setAllowSelectAll] = React.useState(null)
    React.useEffect(() => {
        if (hasSelectableRows) {
            // we only want to allows the "select all input" when the selectable data have the same status
            const currentDataStatus = rows.map(row => row.original).reduce((acc, currBike) => {
                if (!(acc.includes(currBike.bike_status))) acc.push(currBike.bike_status)
                return acc
            }, [])
            const hasMultipleStatus = currentDataStatus.length > 1
            // eg: if an "in sale bike" is selected and the user filter the data on "in service", we don't want to let
            // the ability to "select all" "in service" bikes
            const isCurrentStatus = currentBundleStatus ? (currentDataStatus.length === 1 && currentDataStatus[0] === currentBundleStatus) : true
            setAllowSelectAll(!hasMultipleStatus && isCurrentStatus)
        }
    }, [rows, currentBundleStatus, hasSelectableRows])

    // Manage fleet import
    // -------------------
    const defaultImportModaleConfig = React.useMemo(() => ({
        isOpen: false,
        component: null,
        callback: null
    }), [])
    const [importModaleConfig, setImportModaleConfig] = React.useState(defaultImportModaleConfig)
    const importData = () => {
        setImportModaleConfig({
            isOpen: true,
            callback: () => {
                // refresh table data (parent state ?)
                refreshData()
            }
        })
    }

    // Manage individual bike import
    // -------------------
    const scanOcode = () => {
        history.push({
            pathname: "/moncompte/scan",
            state: {
                from: location.pathname,
                title: i18next.t('bike.table_scan')
            }
        })
    }
    const [codeToImport, setCodeToImport] = React.useState("")
    const handleInputChangeCode = (event) => {
        const value = event.target.value.toUpperCase()
        setCodeToImport(value)
    }
    const addToWallet = async (event) => {
        event.preventDefault()
        if (codeToImport) {
          let code  = codeToImport.replace(/\s/g, '')
          history.push({
            pathname: '/moncompte/mes-velos/ajouter/'+code,
            state: { detail: code }
          });
        }
      }

    // Manage export
    // -------------------
    const exportToXLSX = (dataToExport = data) => {
        let tabToExport = []
        tabToExport.push( [
            i18next.t('bike.table_col_bikecode'),
            i18next.t('bike.table_col_brand'),
            i18next.t('bike.table_col_model'),
            i18next.t('bike.table_col_gear_type'),
            i18next.t('bike.table_col_color'),
            i18next.t('bike.table_col_internal_code'),
            i18next.t('bike.table_col_order'),
        ])
        dataToExport.forEach(item => {
            tabToExport.push([
                item?.bike_id
                ,item?.bike_description?.brand
                ,item?.bike_description?.model
                ,item?.bike_description?.gear_type
                ,item?.bike_description?.color
                ,item?.additional_sn
                ,item?.order_id
            ])
        })

        exportXLSX(tabToExport, `export-${moment().format('DD-MMMM-YYYY')}`,`export-${moment().format('DD-MMMM-YYYY')}.xlsx`);
    }

    // Manage bike actions
    // -------------------
    const [newBundleStatus, setNewBundleStatus] = React.useState(null)
    const [bundleIsReady, setBundleIsReady] = React.useState(true)

    const updateBundleStatus = async (status) => {
        setBundleIsReady(false)
        let apiErrorCount = 0
        let consumer
        if (status === 2) {
          consumer = await window.API.get_consumer('byConsumerId');
        }
        // loop over bundle
        for (const bike of data) {
            let bikeToPut = {}
            bikeToPut.bike_id = bike.bike_id
            bikeToPut.bike_status = status
            bikeToPut.row_version = bike.row_version
            bikeToPut.bike_status_detail = null
            if (status === 2) {
              bikeToPut.bike_owner = consumer.consumer_data
            }
            // NB: for now, bundle is only available in Wallet
            // If implemented in noWaller, add a condition and window.API.put_bike_no_wallet call
            let bikeUpdated = await window.API.put_bike(bikeToPut)
            // API error
            if (StrDef(bikeUpdated.error)) {
                apiErrorCount++
                if (bikeUpdated.error.code === 'TooManyRequestsException') {
                    window.notify(i18next.t('onebike.error_already_update'),'danger')
                }
                else if (bikeUpdated.error.code === 'BikeVersionMismatchException') {
                    window.notify(i18next.t('onebike.error_version'),'danger')
                }
                else if (bikeUpdated.error.code === 'FnuciApiException') {
                    window.notify(i18next.t('onebike.error_other'),'danger')
                }
            }
        }
        // Error / Success
        if (apiErrorCount > 0) {
            window.notify(i18next.t('transfer.bundle_error', { quantity: apiErrorCount}),'danger')
        } else {
            window.notify(i18next.t('transfer.bundle_success'),'success')
        }

        refreshData()
        setBundleIsReady(true)
    }

    // Manage confirmation modale
    // -------------------
    const defaultConfirmationModaleConfig = React.useMemo(() => ({
        isOpen: false,
        component: null,
        callback: null
    }), [])
    const [confirmationModaleConfig, setConfirmationModaleConfig] = React.useState(defaultConfirmationModaleConfig)
    const askForConfirmation = ({title, body, callback}) => {
        setConfirmationModaleConfig({
            isOpen: true,
            title,
            body,
            callback

        })
    }

    React.useEffect(() => {
        if (isBikeBundle && newBundleStatus) {
            // Bike actions according to the status chose by the user
            switch (newBundleStatus) {
                case 1: // in service
                // case 3: // destroy
                    (async () => await updateBundleStatus(newBundleStatus))()
                    break
                case 2: // stolen
                    askForConfirmation({
                        title: i18next.t('bike.confirm_bundle_stolen_title'),
                        body: () => (
                            <>
                                <p className="u-fs-xs u-mg-bottom-m">{i18next.t('bike.confirm_bundle_stolen_p1')}</p>
                                <p className="u-fs-xs u-mg-bottom-m">{i18next.t('bike.confirm_bundle_stolen_p2')}</p>
                            </>
                        ),
                        callback: () => (async () => await updateBundleStatus(newBundleStatus))()
                    })
                    break
                case 5: // in sale
                    history.push({
                        pathname: '/moncompte/mes-velos/transferer',
                        state: {
                            from: location.pathname,
                            bikes_bundle : data,
                        }
                    })
                    break
                case 6: // backstore
                    askForConfirmation({
                        title: i18next.t('bike.confirm_bundle_backstore_title'),
                        body: () => (
                            <>
                                <p className="u-fs-xs u-mg-bottom-m">{i18next.t('bike.confirm_bundle_backstore_p1')}</p>
                                <p className="u-fs-xs u-mg-bottom-m">{i18next.t('bike.confirm_bundle_backstore_p2')}</p>
                                <p className="u-fs-xs u-mg-bottom-m">{i18next.t('bike.confirm_bundle_backstore_p3')}</p>
                            </>
                        ),
                        callback: () => (async () => await updateBundleStatus(newBundleStatus))()
                    })
                    break
                default:
                    break
            }
        }
    }, [newBundleStatus]) // eslint-disable-line

    return (
        <>
            <div className={"c-table-bike " + (bundleIsReady ? "" : "is-disabled")}>

                <div className="c-table-bike__header u-mg-bottom-l u-mg-bottom-0@main u-pd-bottom-l">

                    {!isBikeBundle &&
                        <div className="u-flex u-flex-dir-col u-flex-dir-row@main u-flex-center-vt u-flex-between u-mg-bottom-l">
                            {/* Table bundle warning */}
                            <div className="">
                                <p dangerouslySetInnerHTML={{__html: sanitize(i18next.t('bike.table_bundle_warning'))}} />
                            </div>
                            <div className="u-flex u-flex-dir-col u-flex-dir-row@main u-flex-center-hz">
                                <form className="u-flex u-flex-0@main u-flex-dir-col u-flex-dir-row@main u-flex-center-vt u-flex-center-hz">
                                    <div className="u-flex u-flex-end-vt u-mg-hz-m">
                                        <div className="c-form-group" style={{ minWidth: "calc(100% - 20px)" }}>
                                            <label className="c-label" htmlFor="code">{i18next.t('bike.label_code_ocode')}</label>
                                            <input
                                                className={"c-input"}
                                                name={"code"}
                                                type={"text"}
                                                placeholder={i18next.t('bike.label_code_ocode')}
                                                value={codeToImport}
                                                onChange={handleInputChangeCode}
                                            />
                                        </div>
                                        <button className="c-btn-form-group" onClick={scanOcode}>
                                            <Icon key={'scan'} name="scan" size={40} />
                                        </button>
                                    </div>
                                    <button
                                            type="button"
                                            className="c-btn c-btn--primary u-pd-hz-s u-mg-top-m u-mg-top-0@main"
                                            onClick={addToWallet}
                                        >
                                            {i18next.t('bike.table_import_single')}
                                        </button>
                                    {/* <div className="u-flex u-flex-center-hz u-mg-top-l">
                                        <button
                                            type="submit"
                                            className="c-btn c-btn--primary"
                                            onClick={this.state.formEventCode.ready ? this.addToWallet.bind(this) : undefined}
                                        >
                                            {this.state.formEventCode.ready ? i18next.t('bike.label_valid') : i18next.t('bike.label_waiting')}
                                        </button>
                                    </div> */}
                                </form>
                                <div className="c-or u-pd-m u-mg-hz-m">
                                    <span className="u-uppercase u-pd-xs">
                                        {i18next.t('bike.or')}
                                    </span>
                                </div>
                                <button
                                    type="button"
                                    className="c-btn c-btn--primary u-pd-hz-s u-mg-bottom-l u-mg-bottom-0@main"
                                    onClick={() => importData()}
                                >
                                    {i18next.t('bike.table_import')}
                                </button>
                            </div>
                        </div>
                    }

                    <div className="u-flex u-flex-dir-col u-flex-dir-row@main u-flex-center-vt u-flex-end u-mg-bottom-l">
                        {/* Table actions */}
                        <div className="u-flex u-flex-dir-col u-flex-dir-row@main u-flex-center-hz">
                            <button
                                type="button"
                                className="c-btn c-btn--primary u-pd-hz-s u-mg-hz-m u-mg-bottom-l u-mg-bottom-0@main"
                                onClick={() => exportToXLSX()}
                                disabled={data?.length === 0}
                            >
                                {i18next.t('bike.table_export')}
                            </button>

                            {scrollToBundle &&
                                <button
                                    type="button"
                                    className="c-btn c-btn--primary u-pd-hz-s u-mg-left-m@main"
                                    onClick={scrollToBundle}
                                >
                                    {i18next.t('bike.goToBundle')}
                                </button>
                            }

                            {hasBikeActions &&
                                <UncontrolledDropdown className={"c-dropdown"}>
                                    <DropdownToggle
                                        className={"c-dropdown__toggle"}
                                        caret
                                        disabled={data?.length === 0}
                                    >
                                        {i18next.t('bike.change_status')}
                                    </DropdownToggle>
                                    <DropdownMenu className={"c-dropdown__menu"}>
                                        {bikeStatus.map(status => {
                                            const currentBikeStatus = page[0]?.original?.bike_status || 0
                                            let isDisabled = false
                                            let label = status.label
                                            // Status meaning
                                            const isInService = status.id === 1
                                            const isInSale = status.id === 5
                                            const isStolen = status.id === 2
                                            const isDestroyed = status.id === 3
                                            const isBackstore = status.id === 6
                                            // in service
                                            if (currentBikeStatus === 1 && isInService) isDisabled = true
                                            // in sale
                                            if (currentBikeStatus === 5 && (isInSale || isBackstore)) isDisabled = true
                                            // stolen
                                            if (currentBikeStatus === 2 && (isStolen || isInSale || isDestroyed || isBackstore)) isDisabled = true
                                            if (currentBikeStatus === 2 && isInService) label = i18next.t('bikestatus.label_find')
                                            // destroy (to hide)
                                            if (isDestroyed) return <></>
                                            return (
                                                <DropdownItem key={status.id} onClick={() => setNewBundleStatus(status.id)} disabled={isDisabled}>
                                                    {label}
                                                </DropdownItem>
                                            )
                                        })}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            }
                        </div>
                    </div>

                    <div className="u-flex u-flex-dir-col u-flex-dir-row@main u-flex-between u-mg-bottom-l u-mg-bottom-0@main">
                        <div className="u-flex">
                            {/* Global filter */}
                            <div className={"c-form-group c-form-group--picto u-pd-right-xxl@main u-mg-bottom-l u-mg-bottom-0@main"}>
                                <svg className="c-input-picto" xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 512 512">
                                    <path d="M505.749 475.587l-145.6-145.6c28.203-34.837 45.184-79.104 45.184-127.317C405.333 90.926 314.41.003 202.666.003S0 90.925 0 202.669s90.923 202.667 202.667 202.667c48.213 0 92.48-16.981 127.317-45.184l145.6 145.6c4.16 4.16 9.621 6.251 15.083 6.251s10.923-2.091 15.083-6.251c8.341-8.341 8.341-21.824-.001-30.165zM202.667 362.669c-88.235 0-160-71.765-160-160s71.765-160 160-160 160 71.765 160 160-71.766 160-160 160z"/>
                                </svg>
                                <input
                                    className={"c-input c-input--transparent u-pd-left-xl"}
                                    name={"tableglobalFilter"}
                                    type={"text"}
                                    placeholder={i18next.t('bike.table_filter_search')}
                                    value={dataTableFilter}
                                    style={{ maxWidth: "500px" }}
                                    onChange={(event) => setDataTableFilter(event.target.value)}
                                />
                            </div>
                            {/* Page size select */}
                            <div className="u-flex u-flex-center-hz u-flex-center-vt u-mg-bottom-l u-mg-bottom-0@main u-mg-right-m">
                                <ReactSelect
                                    options={pageSizesList}
                                    value={pageSizeSelection}
                                    className={"c-select u-bold"}
                                    classNamePrefix={"c-select"}
                                    style={{
                                        option: (provided) => ({
                                            ...provided,
                                            minWidth: 100
                                        })
                                    }}
                                    onChange={(pageSizeOption) => setPageSizeSelection(pageSizeOption)}
                                />
                                <span className="u-bold u-mg-left-s">{i18next.t('bike.table_filter_unit')}</span>
                            </div>
                            {/* Pagination */}
                            { pageOptions && pageOptions.length > 1 &&
                                <div className="c-pagination">
                                    <button className="c-btn c-btn--primary u-pd-hz-s u-mg-hz-m" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                        {'<<'}
                                    </button>
                                    {pageOptions.map(index => {
                                        return (
                                            <button key={index} className={"u-mg-hz-m " + (index === pageIndex ? "u-bold" : "")} onClick={() => gotoPage(index)}>
                                                {index + 1}
                                            </button>
                                        )
                                    })}
                                    <button className="c-btn c-btn--primary u-pd-hz-s u-mg-hz-m" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                        {'>>'}
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <table className="c-table c-table--responsive" {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr className="c-table__row u-pd-vt-m" {...headerGroup.getHeaderGroupProps()}>
                                {hasSelectableRows &&
                                    <th className={"c-table__cell u-pd-m " + getColumnSize()}>
                                        <IndeterminateCheckbox
                                            {...getToggleAllRowsSelectedProps()}
                                            disabled={!alllowSelectAll}
                                            className={!alllowSelectAll ? "is-disabled" : ""}
                                        />
                                    </th>
                                }
                                {headerGroup.headers.map(column => (
                                    <th className={"c-table__cell u-pd-m " + getColumnSize(column?.id)} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        <span className={"u-ellipsis"}>{column.render('Header')}</span>
                                        <span>
                                            {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row)
                            return (
                                <tr className={
                                        "c-table__row u-pd-m u-pd-0@main "
                                        + ((currentBundleStatus && row?.original?.bike_status !== currentBundleStatus) ? "is-disabled " : " ")
                                        + ((row?.original?.bike_status === 3) ? "u-black " : " ")
                                    }
                                    {...row.getRowProps()}
                                >
                                    {hasSelectableRows &&
                                        <td
                                          className={"c-table__cell u-pd-m@main test " + getColumnSize()}
                                          data-tip={i18next.t('bike.bike_limit')}
                                          data-tip-disable={selection?.length >= selectionLength ? row.getToggleRowSelectedProps()?.checked : true}
                                        >
                                            <IndeterminateCheckbox
                                                disabled={!row.getToggleRowSelectedProps()?.checked && selection?.length >= selectionLength}
                                                className={!row.getToggleRowSelectedProps()?.checked && selection?.length >= selectionLength ? "is-disabled" : ""}
                                                {...row.getToggleRowSelectedProps()}
                                            />
                                        </td>
                                    }
                                    {row.cells.map(cell => {
                                        if (cell?.column?.id === "operator") {
                                            return (
                                                <td className={"c-table__cell u-pd-m@main " + getColumnSize(cell?.column?.id)} {...cell.getCellProps()}>
                                                    <Logo id={cell.value} style={{ width: 160 }}/>
                                                </td>
                                            )
                                        }
                                        return (
                                            <td className={"c-table__cell u-pd-m@main " + getColumnSize(cell?.column?.id)} {...cell.getCellProps()}>
                                                <span className={"u-ellipsis"}>{cell.render('Cell')}</span>
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                { !isBikeBundle && data.length === 0 &&
                    <p className="u-center u-mg-top-m">
                        {i18next.t('bike.table_empty')}
                    </p>
                }
                { isBikeBundle && data.length === 0 &&
                    <p className="u-center u-mg-top-m">
                        {i18next.t('bike.table_no_selection')}
                    </p>
                }
            </div>
            <ReactTooltip backgroundColor={"#C53762"} />

            {/* Import modale */}
            <TableImportModal
                importModaleConfig={importModaleConfig}
                setImportModaleConfig={setImportModaleConfig}
                defaultImportModaleConfig={defaultImportModaleConfig}
            />


            {/* Confirmation modale */}
            <Modal
                isOpen={confirmationModaleConfig?.isOpen}
                fade={false}
                style={ window.isMobile ? { maxWidth: 'calc(100vw)', margin: 10 } : null }
            >
                <ModalHeader className="">
                    {confirmationModaleConfig?.title}
                </ModalHeader>
                <ModalBody className="">
                    {confirmationModaleConfig?.body && React.createElement(confirmationModaleConfig?.body)}
                </ModalBody>
                <ModalFooter className="justify-content-center">
                    <div className="u-flex u-flex-center-hz u-mg-top-l">
                        <button
                            className="c-btn u-mg-hz-m"
                            onClick={() => setConfirmationModaleConfig(defaultConfirmationModaleConfig)}
                        >
                            {i18next.t('bike.confirm_bundle_cancel')}
                        </button>

                        <button
                            className="c-btn c-btn--primary u-mg-hz-m"
                            onClick={() => {
                                if (confirmationModaleConfig?.callback) confirmationModaleConfig.callback()
                                setConfirmationModaleConfig(defaultConfirmationModaleConfig)
                            }}
                        >
                            {i18next.t('bike.confirm_bundle_validate')}
                        </button>
                    </div>
                </ModalFooter>
          </Modal>
    </>
    )
}

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, className, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef
        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input
                    className={"c-checkbox " + (className || "")}
                    type="checkbox"
                    ref={resolvedRef}
                    {...rest}
                />
            </>
        )
    }
)

export default BikesTable
