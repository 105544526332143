import React from 'react'
import {
  Modal, ModalBody
} from 'reactstrap'
import i18next from "i18next";
import FormInput from 'components/Forms/FormInput';
import * as ColorsUtil from 'utils/colors'
import * as translationContext from 'translation';
import { languages } from 'utils/languages';
import './style.scss'
import Icon from 'components/Icons/Icon';

const LanguagesModal = ({ className, isLightBackground = true }) => {
  const [ loading, setLoading ] = React.useState(false)
  const [ isOpenModal, setIsOpenModal ] = React.useState(false)
  const [ language, setLanguage ] = React.useState('')
  const [ formErrors, setFormErrors ] = React.useState([])
  
  React.useEffect(() => {
    const currentLanguage = translationContext.getLanguage()
    // if key detected by the navigator is just 'en', we set 'en-UK' by default.
    if(i18next.resolvedLanguage === 'en') {
      setLanguage('en-UK')
      i18next.changeLanguage('en-UK')
    }
    // if keys doesn't exist in langues.js, we custom the current langue.
    if(!Object.keys(languages).includes(currentLanguage) || currentLanguage === 'fr') {
      setLanguage('fr-FR')
      i18next.changeLanguage('fr-FR')
    }
    if(Object.keys(languages).includes(currentLanguage)) {
      setLanguage(currentLanguage)
      i18next.changeLanguage(currentLanguage)
    }
  }, [])

  const handleChangeLanguage = (chosenLanguageKey) => {
    setLoading(true)
    setLanguage(chosenLanguageKey)
    i18next.changeLanguage(chosenLanguageKey, (err) => {
      setIsOpenModal((prevState) => !prevState)
      if (err) {
        setLoading(false)
        setFormErrors((prevFormErrors) => ({
          ...prevFormErrors,
          [chosenLanguageKey]: err.toString()
        }))
        console.log('Une erreur s\'est produite lors du changement de langue avec i18next', err)
        return null
      }
      setLoading(false)
    })
  }

  return (
    <div className={`internationalization ${className}`}>
      <div className={'internationalization__switch-btn'}>
        <button onClick={({ currentTarget }) => setIsOpenModal(currentTarget)}>
          <Icon key={'earth'} name="earth" size={35} color={isLightBackground ? ColorsUtil.primaryColor : ColorsUtil.white} viewBox={'0 0 512 512'}/>
          <span>{language.substring(0,2).toUpperCase()}</span>
        </button>
      </div>
      <Modal
        isOpen={!!isOpenModal}
        fade={true}
        style={ window.isMobile ? { maxWidth: 'calc(100vw)', margin: 10 } : null }
        className={'internationalization__modal'}
        keyboard={true}
      >
        <ModalBody>
          {loading &&
            <div className="u-center">
              <div className="c-spinner"></div>
            </div>
          }
          {!loading &&
            <form>
              <ul>
                {Object.keys(languages)?.map((languageKey) => {
                  return (
                    <li key={languageKey} id={languageKey} className={`u-reset-list ${language === languageKey ? "active" : ""}`}>
                      <button onClick={() => handleChangeLanguage(languageKey)} type="button">
                        <FormInput 
                          type={'radio'}
                          id={languageKey} 
                          what={languageKey}
                          formError={formErrors}
                          formData={languages}
                          label={`${languages[languageKey].language}`}
                          className={'u-flex u-flex-dir-row u-mg-vt-m'}
                          labelClassName={'internationalization__country-label u-mg-vt-auto'}
                          inputClassName={'internationalization__country-input'}
                          checked={language === languageKey }
                        />
                        <span>{languages[languageKey].country}</span>
                      </button>
                    </li>
                  )
                })}
              </ul>
            </form>
          }
        </ModalBody>
      </Modal>
    </div>
  )
}

export default LanguagesModal
