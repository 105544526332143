import React from "react"
import "react-phone-number-input/style.css"
import PhoneInput from "react-phone-number-input"

const InputPhone = ({
    // Attributes
    id = null,
    what,
    formData,
    label = "",
    placeholder = "06 00 00 00 00",
    disabled,
    maxlength = 14,
    type,
    // Styles
    style,
    defaultCountry = "FR",
    className = "",
    classNameLabel = "",
    // Error management
    required = "false",
    error,
    formError,
    // Handler
    handleInputChange
}) => {
    const inputRef = React.useRef(null)

    const handleChange = (value) => {
        const inputValue = {
            target: {
                type: "phone",
                name: what,
                value,
            }
        }
        handleInputChange(inputValue) 
    }

    // Custom input to manage maxLength
    // ------------------------
    const CustomInput = React.useMemo(() => {
        return React.forwardRef((props, ref) => (
            <input ref={ref} maxLength={maxlength} {...props} />
        ))
    }, [maxlength])

    return (
        <div className={`c-form-group ${className}`} style={style}>
            <label className={"c-label " + classNameLabel} htmlFor={id}>{label}</label>

                <PhoneInput
                    ref={inputRef}
                    className={`c-input ${formError[what] ? "has-error" : ""} ${disabled ? "is-disabled " : ""}`}
                    id={id}
                    name={what}
                    placeholder={placeholder}
                    value={formData[what]} 
                    defaultCountry={defaultCountry}
                    country={defaultCountry}
                    international={false}
                    disabled={disabled}
                    required={required}
                    onChange={(value) => handleChange(value)}
                    inputComponent={CustomInput}
                />

            {/* Error messages */}
            {formError[what] &&
                <p className="u-fs-xs u-danger">{error}</p>
            }
        </div>
    )
}

export default InputPhone
