import React from "react"
import LogoOcode from "./LogoOcode"
import LogoOWallet from "./LogoOWallet"
import LogoBicycode from "./LogoBicycode"
import LogoMFC from "./LogoMFC"
import LogoDecathlon from "./LogoDecathlon"

const Logo = ({ name, className, style, vertical = false, forceOCode = false }) => {
	const subdomain = window.API.getSubDomain()

    if (forceOCode) name = 'wallet'

	const renderIcon = (name) => {
	  switch(name) {
			// case 'wallet':
			// 	return <LogoOWallet className={className} style={style} />

			// case 'bicycode':
			// 	return <LogoBicycode className={className} style={style} />

			// case 'decathlon':
			// 	return <LogoDecathlon className={className} style={style} />

			// case 'mfc':
			// 	return <LogoMFC className={className + (vertical ? " is-vertical" : "")} style={style} vertical={vertical} />
      default:
        return <LogoOcode className={className} style={style} />
    }
  }
	return renderIcon(name ? name : subdomain)
}

export default Logo
