import React from "react";
import { withTranslation } from 'react-i18next';

import {StrDef, GetBikeIdFormat,GetSubDomainByOperator} from '../assets/lib/utils';
import FormInput from '../components/Forms/FormInput';
import Icon from "../components/Icons/Icon.jsx";

const CODE_LENGTH = 6;

class Transfer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isWallet : false,
      isPendingTransfer : false, // est-il en transit
      step : 2,//2
      consumerId : '', // le buyer qui a un wallet
      preRegistrationId : '', //le buyer qui n'a pas de wallet
      rowVersion : '',
      formDataTransfer : {
        brand : '',
        colorBike : '',
        fnuciCode : '',
        code : '', // ocode_id ou short_code_id,
        operator:'',
      },
      formErrorTransfer : {

      },
      formEventTransfer : {
        ready : true,
      },

      bikes_bundle : [], // if bike bundle
      bikes_bundle_ready : false,

      /*formDataBuyer : {
        email : '',
      },
      formErrorBuyer : {
        email : false
      }*/
    }
    this.scanOcode = this.scanOcode.bind(this);
  }

  async componentDidMount() {
    let isWallet = this.state.isWallet

    if (window.API.getSubDomain() === 'wallet') {
      isWallet = true
    } else {
      isWallet = false
    }
    this.setState({isWallet:isWallet})

    // Single bike
    if (StrDef(this.props.location.state) && StrDef(this.props.location.state?.bike_description)) {
      let bike_description  = this.props.location.state?.bike_description
      let rowVersion        = this.props.location.state?.row_version
      let isPendingTransfer = this.props.location.state?.pending_ownership_transfer
      let is_bicycode_2_0   = this.props.location.state?.is_bicycode_2_0
      let operator          = this.props.location.state?.operator
      this.setState({
        rowVersion : rowVersion,
        isPendingTransfer : isPendingTransfer,
        formDataTransfer: {
          ...this.state.formDataTransfer,
          operator : operator,
          brand : bike_description.brand,
          colorBike : bike_description.color,
          fnuciCode : (is_bicycode_2_0 === 1 || is_bicycode_2_0 === 0)  ? this.props.location.state?.bike_id : GetBikeIdFormat(this.props.location.state?.bike_id)
        }
      })
    }

    // Bike bundle
    if (StrDef(this.props.location.state) && StrDef(this.props.location.state?.bikes_bundle)) {
      this.setState({
        // isPendingTransfer : isPendingTransfer,
        bikes_bundle: this.props.location.state.bikes_bundle
      })
    }

    // O°Code
    if (StrDef(this.props.location.state?.ocode)) {
      let ocodeId = this.props.location.state?.ocode
      let state   = this.props.location.state?.previousState
      let res
      // la valeur 44921 à la même valeur binaire que 044921
      // le scan nous renvoie 44921 au lieu du 044921
      // on complète par des 0
      ocodeId = ocodeId.padStart(6, '0')
      if (window.API.getSubDomain() === 'wallet') {
        res = await window.API.get_consumer('byOcode',ocodeId);
      } else {
        res = await window.API.get_consumer_nowallet('byOcode',ocodeId);
      }

      if (!StrDef(res.error)) {
        this.setState(state,() => {
          // MAJ INFOS BUYER
          // on n'affiche plus les données du buyer (RGPD)
          //let formDataBuyer = this.state.formDataBuyer
          //formDataBuyer.email = res.consumer_data.mail
          this.setState({
            consumerId : res.consumer_id,
            preRegistrationId : res.pre_registration_id,
            //formDataBuyer : formDataBuyer,
            step:3
          })
        })
      } else if (StrDef(res.error)) {
        if (res.error.code === 'MoonCodeNotFoundException') {
          window.notify(this.props.t('transfer.error_ocode_notexist'),'danger')
        } else if (res.error.code === 'RessourceNotFoundException') {
          window.notify(this.props.t('transfer.error_ocode_notexist'),'danger')
        }
      }
    }

  }

  handleInputChangeTransfer = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState({
      formDataTransfer: {
        ...this.state.formDataTransfer,
        [name]: value
      },
      formErrorTransfer: {
        ...this.state.formErrorTransfer,
        [name]: false
      },
    }, async () => {
      // si on a tapé le short_code_id et que cela respecte la longueur, call API
      let short_code_id = this.state.formDataTransfer.code
      if (name === 'code' && short_code_id.length === CODE_LENGTH) {
        let res
        if (this.state.isWallet) {
          res = await window.API.get_consumer('byShortCode',short_code_id);
        } else {
          res = await window.API.get_consumer_nowallet('byShortCode',short_code_id);
        }
        if (!StrDef(res.error)) {
          // MAJ INFOS BUYER
          // step 3

          this.setState({
            /*formDataBuyer: {
              ...this.state.formDataBuyer,
              email:res.consumer_data.mail,
            },*/
            step : 3,
            consumerId : res.consumer_id,
            preRegistrationId : res.pre_registration_id,
          })
        } else if (StrDef(res.error)) {
          if (res.error.code === 'ExpiredShortCodeIdException') {
            window.notify(this.props.t('transfer.error_expired_shortcode'),'danger')
          } else if (res.error.code === 'RessourceNotFoundException') {
            window.notify(this.props.t('transfer.error_bad_shortcode'),'danger')
          }
        }
      }
    })
  }

  transferSingleBike = async (
    fnuciCode,
    rowVersion,
    isFromBundle = false
  ) => {
    //call API
    // si le vélo est à l'état transit, on doit le mettre en vente au préalable
    if (this.state.isPendingTransfer) {
      let bikeToPut = {}
      bikeToPut.bike_id = fnuciCode
      bikeToPut.bike_status = 5
      bikeToPut.row_version = rowVersion
      bikeToPut.bike_status_detail = null;
      let resPut
      if (this.state.isWallet) {
        resPut = await window.API.put_bike(bikeToPut);
      } else {
        resPut = await window.API.put_bike_no_wallet(bikeToPut);
      }

      // API error
      if (StrDef(resPut.error)) {
        if (resPut.error.code === 'TooManyRequestsException') {
          if (!isFromBundle) {
            window.notify(this.props.t('onebike.error_already_update'),'danger')
            this.setState({
              formEventTransfer: {
                ...this.state.formEventTransfer,
                ready: true
              },
              loading:false
            })
          } else {
            console.log(fnuciCode, this.props.t('onebike.error_already_update'))
          }
        } else if (resPut.error.code === 'BikeVersionMismatchException') {
          if (isFromBundle) {
            window.notify(this.props.t('onebike.error_version'),'danger')
            this.props.history.goBack()
          } else {
            console.log(fnuciCode, this.props.t('onebike.error_version'))
          }
          return
        }
      }
      // API success
      else {
        if (!isFromBundle) this.state.rowVersion = resPut.row_version
      }
    }

    let data = {
      //consumer_id : this.state.consumerId,
      row_version: rowVersion
    }
    if (!StrDef(this.state.consumerId)) { // on transfère à un nowallet
      data.pre_registration_id = this.state.preRegistrationId
    } else { // on transfère à un wallet
      data.consumer_id = this.state.consumerId
    }
    let res
    if (this.state.isWallet) { // on est sur un compte wallet
      res = await window.API.transfer(fnuciCode,data)
    } else {
      res = await window.API.transfer_nowallet(fnuciCode,data)
    }

    // Success
    if (!StrDef(res.error)) {
      if (!isFromBundle) {
        this.setState({
          formEventTransfer: {
            ...this.state.formEventTransfer,
            ready: true
          },
          step: 4,
          loading:false
        })
      } else {
        this.setState({
          formEventTransfer: {
            ...this.state.formEventTransfer,
            ready: true
          },
          loading:false
        })
      }
    }
    // Error
    else if (StrDef(res.error)) {
      if (res.error.code === 'TooManyRequestsException') {
        if (!isFromBundle) {
          window.notify(this.props.t('transfer.error_already_update'),'danger')
        } else {
          console.log(fnuciCode,this.props.t('transfer.error_already_update'))
        }
      } else if (res.error.code === 'BikeVersionMismatchException') {
        if (!isFromBundle) {
          window.notify(this.props.t('transfer.error_already_update'),'danger')
        } else {
          console.log(fnuciCode,this.props.t('transfer.error_version'))
        }
      } else if (res.error.code === 'BikeStatusMismatchException') {
        if (!isFromBundle) {
          window.notify(this.props.t('transfer.error_already_update'),'danger')
        } else {
          console.log(fnuciCode,this.props.t('transfer.error_bike_not_forsale'))
        }
      } else if (res.error.code === 'FnuciApiException') {
        if (!isFromBundle) {
          window.notify(this.props.t('transfer.error_already_update'),'danger')
        } else {
          console.log(fnuciCode,this.props.t('transfer.error_other'))
        }
      }

      if (!isFromBundle) this.props.history.goBack()

      this.setState({
        formEventTransfer: {
          ...this.state.formEventTransfer,
          ready: true
        },
        loading:false
      })
    }
  }

  transfer = async (event) => {
    event.preventDefault()

    this.setState({
      formEventTransfer: {
        ...this.state.formEventTransfer,
        ready: false
      },
      loading:true
    })

    // Bikes bundle
    if (this.state.bikes_bundle_ready) {
      let apiErrorCount = 0
      // loop over bundle
      for (const bike of this.state.bikes_bundle) {
        // let fnuciCode = (bike.is_bicycode_2_0 === 1 || bike.is_bicycode_2_0 === 0) ? bike.bike_id : GetBikeIdFormat(bike.bike_id).replace(/\s/g, '')
        let fnuciCode = bike.bike_id
        let row_version = bike.row_version
        let bikeUpdated = await this.transferSingleBike(fnuciCode, row_version)
        if (StrDef(bikeUpdated) && StrDef(bikeUpdated.error)) {
          apiErrorCount++
        }
      }

      // Error / Success
      if (apiErrorCount > 0) {
        window.notify(this.props.t('transfer.bundle_error', { quantity: apiErrorCount}),'danger')
      } else {
        window.notify(this.props.t('transfer.bundle_success'),'success')
        this.setState({
          formEventTransfer: {
            ...this.state.formEventTransfer,
            ready: true
          },
          step: 4,
          loading:false
        })
      }
    }
    // Single bike
    else {
      let fnuciCode = this.state.formDataTransfer.fnuciCode.replace(/\s/g, '')
      let row_version = this.state.rowVersion
      await this.transferSingleBike(fnuciCode, row_version)
    }

  }

  scanOcode() {
    //document.body.classList.remove("home-page");
    this.props.history.push({
      pathname: '/moncompte/scan',
      state: {
        previousState : this.state, // pour conserver le state
        from: this.props.location.pathname,
        title : this.props.t('transfer.scan_title')
      }
    });
  }

  goToHome() {
    if (this.state.isWallet) {
      this.props.history.push({
        pathname: '/moncompte/mes-velos',
      });
    } else {
      // plus de vélo
      //window.Logout()
      this.props.history.push({
        pathname: '/moncompte/mon-velo',
      });
    }
  }

  // Manage selling bike bundle
  updateBikeBundleStatus = async (event) => {
    event.preventDefault()
    let apiErrorCount = 0
    let bikesBundle = []

    // Call API in sequence: https://stackoverflow.com/a/37576787/7716596
    for (const bike of this.state.bikes_bundle) {
      let bikeToPut = {}
      bikeToPut.bike_id = bike.bike_id
      bikeToPut.bike_status = 5 // insale
      bikeToPut.row_version = bike.row_version
      bikeToPut.bike_status_detail = null;// TODO détail pour un vélo détruit ?
      if (this.state.isWallet) {
        let consumer = await window.API.get_consumer('byConsumerId');
        if (!StrDef(consumer.error)) {
          bikeToPut.bike_owner = consumer.consumer_data
        }
      }

      let bikeUpdated = await window.API.put_bike(bikeToPut)

      if (!StrDef(bikeUpdated.error)) {
        // window.notify(this.props.t('onebike.success_update'),'success')
        bikesBundle.push(bikeUpdated)
      } else if (StrDef(bikeUpdated.error)) {
        apiErrorCount++
        if (bikeUpdated.error.code === 'TooManyRequestsException') {
          window.notify(this.props.t('onebike.error_already_update', { quantity: apiErrorCount}),'danger')
        } else if (bikeUpdated.error.code === 'BikeVersionMismatchException') {
          window.notify(this.props.t('onebike.error_version', { quantity: apiErrorCount}),'danger')
        } else if (bikeUpdated.error.code === 'FnuciApiException') {
          window.notify(this.props.t('onebike.error_already_update', { quantity: apiErrorCount}),'danger')
        }
      }
    }

    if (apiErrorCount > 0) {
      window.notify(this.props.t('transfer.bundle_error_update', { quantity: apiErrorCount}),'danger')
    } else {
      window.notify(this.props.t('transfer.bundle_success_update'),'success')
      // Continue process
      this.setState({
        bikes_bundle: bikesBundle,
        bikes_bundle_ready: true
      })
    }

  }

  render() {
    const { t } = this.props;

    let subdomainTransfer
    if (StrDef(this.state.formDataTransfer.operator)) subdomainTransfer = GetSubDomainByOperator(this.state.formDataTransfer.operator)
    let isPendingTransfer = this.state.isPendingTransfer === true ? true : false

    let isBikesBundle = this.state.bikes_bundle?.length > 0 || false

    return(
      <main className={"c-main-content u-flex u-flex-dir-col"}>

        <button className="c-btn-back" title={t('common.back')} onClick={() => this.props.history.goBack()} >
          <Icon key={'back'} name="back" size={30} />
        </button>

        <div className="c-header u-pd-top-l@main">
          <div className="l-container-md u-pd-hz-m">
            {!isBikesBundle &&
              <>
                {isPendingTransfer === false &&
                  <h1 className="c-h1 c-header__title u-medium u-uppercase">{t('transfer.title_1')}<br/>{t('transfer.title_2')}</h1>
                }
                {isPendingTransfer === true &&
                  <h1 className="c-h1 c-header__title u-medium u-uppercase">{t('transfer.title_transit')}</h1>
                }
              </>
            }

            {isBikesBundle &&
              <h1 className="c-h1 c-header__title u-medium u-uppercase">{t('transfer.title_bundle')}</h1>
            }

          </div>
        </div>

        {!isBikesBundle &&
        <div className="l-container-md u-pd-hz-m u-pd-vt-l">

          {/* Mot de passe */}
          {false && this.state.step === 1 && // plus de mot de passe pour le moment
            <div className="l-form-width u-pd-bottom-xl">

              <div className="u-center">
                <h2 className="c-h2 u-center u-bold u-mg-bottom-xl">{t('transfer.title_transfert_secure')}</h2>
                <p className="u-mg-bottom-xl">{t('transfer.label_pwd_to_continue')}</p>
              </div>

              <form>
                <FormInput
                  className={""}
                  formData={this.state.formData}
                  formError={this.state.formError}
                  what={"password"}
                  error={t('common.error.password_invalid')}
                  placeholder={t('common.placeholder.password')}
                  handleInputChange={this.handleInputChange}
                />

                <div className="u-fs-xs u-primary u-pd-vt-s u-mg-bottom-m">
                  {t('common.rules.password')}
                </div>

                <div className="u-flex u-flex-center-hz u-mg-vt-l">
                  <button
                    type="submit"
                    className="c-btn c-btn--primary"
                    onClick={this.state.formEvent.ready ? this.confirmPassword.bind(this) : undefined}
                  >
                    {this.state.formEvent.ready ? t('common.continue') : t('common.wait')}
                  </button>
                </div>
              </form>
            </div>
          }

          {/* Créer le lien */}
          {this.state.step === 2 &&
            <div className="l-form-width u-pd-bottom-xl">
              <div className="u-center">
                {isPendingTransfer === false &&
                  <h2 className="c-h2 u-center u-bold u-mg-bottom-xl">{t('transfer.subtitle')}</h2>
                }
                {isPendingTransfer === true &&
                  <h2 className="c-h2 u-center u-bold u-mg-bottom-xl">{t('transfer.subtitle_transit')}</h2>
                }
              </div>
              {isPendingTransfer === true &&
                <p className="u-fs-xs u-blue20 u-mg-bottom-l">{t('transfer.label_transit_explain_1')}</p>
              }

              <FormInput
                className={"u-mg-bottom-m"}
                formData={this.state.formDataTransfer}
                formError={this.state.formErrorTransfer}
                what={"brand"}
                error={""}
                placeholder={t('transfer.label_brand')}
                handleInputChange={this.handleInputChangeTransfer}
                disabled={true}
              />

              <FormInput
                className={"u-mg-bottom-m"}
                formData={this.state.formDataTransfer}
                formError={this.state.formErrorTransfer}
                what={"colorBike"}
                error={""}
                placeholder={t('transfer.label_color')}
                handleInputChange={this.handleInputChangeTransfer}
                disabled={true}
              />

              <FormInput
                className={"u-mg-bottom-l"}
                formData={this.state.formDataTransfer}
                formError={this.state.formErrorTransfer}
                what={"fnuciCode"}
                error={""}
                placeholder={t('transfer.label_bikecode')}
                handleInputChange={this.handleInputChangeTransfer}
                disabled={true}
              />

              {isPendingTransfer === false &&
                <div className="u-mg-bottom-l">
                  <p className="u-fs-xs u-blue20 u-mg-bottom-s">{t('transfer.label_explain_transfer_1')}</p>

                  {/* Explanation for a Bicycode bike */}
                  {this.state.formDataTransfer.operator === "BC"
                    ?
                      <p className="u-fs-xs u-blue20 u-mg-bottom-s">{t('onebike.label_explain_bicycode_transfer')} <a href="https://wallet.o-code.co/" className="u-bold">wallet.o-code.co</a></p>
                    :
                      <>
                        <p className="u-fs-xs u-blue20 u-mg-bottom-s">{t('onebike.label_explain_transfer_2')}</p>
                        <ul>
                          <li className="u-fs-xs u-blue20">{t('onebike.label_li_1')}</li>
                          <li className="u-fs-xs u-blue20 u-mg-bottom-s">{t('onebike.label_li_2')} {'https://'+subdomainTransfer+'.o-code.co/pre-inscription'}</li>
                        </ul>
                      </>
                  }
                   <p className="u-fs-xs u-blue20 u-mg-bottom-s">{t('transfer.label_explain_transfer_3')}</p>
                </div>
              }
              {isPendingTransfer === true &&
                <div className="u-mg-bottom-l">
                  <p className="u-fs-xs u-blue20 u-mg-bottom-s">{t('transfer.label_transit_explain_2')}</p>
                </div>
              }

              <form className="u-flex u-flex-end-vt u-mg-bottom-xl">
                <FormInput
                  style={{ minWidth: "calc(100% - 20px)" }}
                  className="u-flex-1"
                  formData={this.state.formDataTransfer}
                  formError={this.state.formErrorTransfer}
                  what={"code"}
                  error={t('transfer.label_shortcode_error')}
                  placeholder={t('transfer.label_shortcode')}
                  handleInputChange={this.handleInputChangeTransfer}
                  maxLength={CODE_LENGTH}
                />
                <button className="c-btn-form-group c-btn-form-group--lg" title={t('transfert.label_scan_ocode')} onClick={this.scanOcode}>
                  <Icon key={'scan'} name="scan" size={60} />
                </button>
              </form>
            </div>
          }

          {/* Verification */}
          {this.state.step === 3 &&
            <div className="l-form-width u-pd-bottom-xl">
              <div>
                <h2 className="c-h2 u-center u-bold u-mg-bottom-xl">{t('transfer.label_verify_buyer')}</h2>
                <p className="u-fs-xs u-blue20 u-mg-bottom-s">{t('transfer.label_confirm_transfer')}</p>
              </div>
              {/*<FormInput
                className={"u-mg-bottom-m"}
                formData={this.state.formDataBuyer}
                formError={this.state.formErrorBuyer}
                what={"email"}
                error={t('transfer.label_mail_error')}
                placeholder={t('transfer.label_mail')}
                handleInputChange={null}
                disabled={true}
              />*/}
              <div className="u-flex u-flex-center-hz u-mg-top-l">
                <button
                  type="submit"
                  className="c-btn c-btn--primary"
                  onClick={this.state.formEventTransfer.ready ? this.transfer.bind(this) : undefined}
                >
                  {this.state.formEventTransfer.ready ? t('transfer.label_transfer') : t('transfer.label_waiting')}
              </button>
            </div>
          </div>
        }

        {/* Done ! */}
        {this.state.step === 4 &&
          <div className="l-form-width-container u-pd-bottom-xl">
              <h2 className="c-h2 u-center u-bold u-mg-bottom-xl">{t('transfer.label_transfer_done')}</h2>
              <div className="u-mg-bottom-xl">
                {/*on a vendu à un wallet*/}
                <p className="u-fs-xs u-blue20 u-mg-bottom-s">{t('transfer.label_transfer_done_2')}</p><br/>
                {false && this.state.isWallet &&
                  <p className="u-mg-bottom-m">{t('transfer.label_done_wallet')}</p>
                }
                {false && !this.state.isWallet &&
                  <p className="u-mg-bottom-m">{t('transfer.label_done_nowallet')}</p>
                }

              </div>

            <div className="u-flex u-flex-center-hz u-mg-vt-l">
              <button
                type="button"
                className="c-btn c-btn--primary"
                onClick={this.goToHome.bind(this)}
              >
                {t('transfer.label_home')}
              </button>
            </div>
          </div>
          }
        </div>
        }

        {isBikesBundle &&
          <div className="l-container-md u-pd-hz-m u-pd-vt-l">

            {!this.state.bikes_bundle_ready &&
              <div className="l-form-width u-pd-bottom-xl">
                <h2 className="c-h2 u-center u-bold u-mg-bottom-xl">
                  {t('transfer.bundle_transit_title')}
                </h2>
                <p className="u-fs-xs u-blue20 u-center u-mg-bottom-s">
                  {t('transfer.bundle_transit_explain1')}
                </p>
                <p className="u-fs-xs u-blue20 u-center u-mg-bottom-s">
                  {t('transfer.bundle_transit_explain2')}
                </p>

                <div className="u-flex u-flex-center-hz u-mg-vt-l">
                  <button
                    type="submit"
                    className="c-btn c-btn--primary"
                    onClick={this.updateBikeBundleStatus}
                  >
                    {t('transfer.bundle_transit_confirm')}
                  </button>
                </div>

                <div className="">
                  <p className="u-fs-xs u-blue20 u-center u-mg-bottom-s">
                    {t('transfer.bundle_transit_explain3')}
                  </p>
                  <p className="u-fs-xs u-blue20 u-center u-mg-bottom-s">
                    {t('transfer.bundle_transit_explain4')}
                  </p>
                </div>

              </div>
            }

            {this.state.bikes_bundle_ready && this.state.step === 2 &&
              <div className="l-form-width u-pd-bottom-xl">
                <h2 className="c-h2 u-center u-bold u-mg-bottom-xl">
                  {t('transfer.bundle_transfer_title')}
                </h2>
                <p className="u-fs-xs u-blue20 u-center u-mg-bottom-s">
                  {t('transfer.bundle_transfer_explain1')}
                </p>
                <p className="u-fs-xs u-blue20 u-center u-mg-bottom-s">
                  {t('transfer.bundle_transfer_explain2')}
                </p>
                <p className="u-fs-xs u-blue20 u-center u-mg-bottom-s">
                  {t('transfer.bundle_transfer_explain3')}
                </p>

                <p className="u-fs-xs u-blue20 u-center u-mg-top-l u-mg-bottom-s">
                  {t('transfer.bundle_transfer_explain4')}
                </p>

                <form className="u-flex u-flex-end-vt u-mg-bottom-xl">
                  <FormInput
                    style={{ minWidth: "calc(100% - 20px)" }}
                    className="u-flex-1"
                    formData={this.state.formDataTransfer}
                    formError={this.state.formErrorTransfer}
                    what={"code"}
                    error={t('transfer.label_shortcode_error')}
                    placeholder={t('transfer.label_shortcode')}
                    handleInputChange={this.handleInputChangeTransfer}
                    maxLength={CODE_LENGTH}
                  />
                  <button className="c-btn-form-group c-btn-form-group--lg" title={t('transfer.label_scan_ocode')} onClick={this.scanOcode}>
                    <Icon key={'scan'} name="scan" size={60} />
                  </button>
                </form>

              </div>
            }

          {this.state.bikes_bundle_ready && this.state.step === 3 &&
            <div className="l-form-width-container u-pd-bottom-xl">
              <h2 className="c-h2 u-center u-bold u-mg-bottom-xl">
                {t('transfer.bundle_confirm_title')}
              </h2>
              <div className="u-mg-bottom-xl">
                {/*on a vendu à un wallet*/}
                <p className="u-fs-xs u-blue20 u-mg-bottom-xxs">
                  {t('transfer.bundle_confirm_explain1')}
                </p><br/>
                <p className="u-fs-xs u-blue20 u-mg-bottom-s">
                  {t('transfer.bundle_confirm_explain2')}
                </p><br/>
              </div>

              <div className="u-flex u-flex-center-hz u-mg-vt-l">
                <button
                  type="button"
                  className="c-btn c-btn--primary"
                  onClick={this.transfer.bind(this)}
                >
                  {t('transfer.bundle_confirm')}
                </button>
              </div>
            </div>
          }

          {this.state.bikes_bundle_ready && this.state.step === 4 &&
            <div className="l-form-width-container u-pd-bottom-xl">
                <h2 className="c-h2 u-center u-bold u-mg-bottom-xl">
                  {t('transfer.bundle_done_title')}
                </h2>
                <div className="u-mg-bottom-xl">
                  <p className="u-fs-xs u-blue20 u-mg-bottom-xxs">
                    {t('transfer.bundle_done_explain1')}
                  </p>
                </div>

              <div className="u-flex u-flex-center-hz u-mg-vt-l">
                <button
                  type="button"
                  className="c-btn c-btn--primary"
                  onClick={this.goToHome.bind(this)}
                >
                  {t('transfer.bundle_done_redirect')}
                </button>
              </div>
            </div>
          }

          </div>
        }

      </main>
    )
  }
}


export default withTranslation()(Transfer);
