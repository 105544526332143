import React from "react";

import {
  StrDef,
  StringifyOCode,
  DrawOCode
} from '../assets/lib/utils';
import Icon from "../components/Icons/Icon.jsx";
import FormInput from '../components/Forms/FormInput';
import Logo from "../components/Logos/Logo"
import { withTranslation } from 'react-i18next';

const WIDTH_CANVAS_OCODE_DISPLAY = 130
const TIMEOUT_RELOAD = 480000 // 60 sec

class PreinscriptionData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading : false,
      ocode : '',
      formDataCode :{
        code : '',
      },
      formErrorCode :{
        code : false,
      },
      formEventCode : {
        ready : true,
      },
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }
  componentDidMount() {
    this.load()
    this.timer = setInterval(
      () => this.load(),
      TIMEOUT_RELOAD,
    );
  }

   async load() {
    this.setState({loading:true})
    let res = await window.API.get_short_code_pre_registration(this.props.match.params.id);
    let formDataCode = {}
    formDataCode.code = res.short_code_id
    this.setState({loading:false,ocode:res.short_code_id,formDataCode:formDataCode},() => {
      var canvas = document.getElementById('ocodePreinscription');
      var ctx = canvas.getContext('2d');
      canvas.width = WIDTH_CANVAS_OCODE_DISPLAY
      canvas.height = WIDTH_CANVAS_OCODE_DISPLAY
      let chaine = StringifyOCode(this.state.ocode)
      ctx = DrawOCode(chaine,ctx,20,10,true, "#385680", WIDTH_CANVAS_OCODE_DISPLAY)
    })
  }

  generateCode = async (event) => {
    event.preventDefault()
    this.setState({
      //formDataCode: { ...this.state.formDataCode, },
      formEventCode: {
          ...this.state.formEventCode,
          ready: false
      },
    })

    this.load();

    this.setState({
      /*formDataCode: {
        ...this.state.formDataCode,
        code : res.short_code_id
      },*/
      formEventCode: {
          ...this.state.formEventCode,
          ready: true
      },
    })
  }

  render() {
    const { t } = this.props;
    return (
      <main className={"c-main-content u-pd-vt-xl u-pd-hz-l u-pd-xl@main"}>
          <>
            <div className="u-flex-mobile-only u-flex-center-hz u-mg-bottom-xl">
              <Logo className="u-primary" />
            </div>
            <div className="l-form-width">
              <h1 className="c-h2 u-center u-bold u-mg-bottom-xl">{t('preinscriptiondata.label_explain_p1')}</h1>
                <p className="u-fs-xs u-center u-blue20 u-mg-bottom-s text-justify">{t('preinscriptiondata.label_explain_p2')}</p>

                {this.state.loading &&
                  <div className="u-flex u-flex-center-vt u-flex-center-hz u-mg-top-xl">
                    <div className="c-spinner"></div>
                  </div>
                }
                {!this.state.loading &&
                <div className="u-flex u-flex-center-hz u-mg-vt-l">
                  <canvas className="" id="ocodePreinscription" width={WIDTH_CANVAS_OCODE_DISPLAY} height={WIDTH_CANVAS_OCODE_DISPLAY} />
                </div>
                }
                <p className="u-fs-xs u-center u-blue20 u-mg-bottom-l text-justify">{t('preinscriptiondata.label_explain_p3')}</p>

                <form className="l-form-width u-pd-hz-m u-mg-bottom-l">
                  <div className="u-flex u-flex-end-vt">
                    <FormInput
                      style={{ minWidth: "calc(100% - 20px)" }}
                      formData={this.state.formDataCode}
                      formError={this.state.formErrorCode}
                      what={"code"}
                      error={""}
                      placeholder={t('preinscriptiondata.label_shortcode')}
                      handleInputChange={this.handleInputChangeCode}
                      disabled={true}
                    />
                    <button type="button" className="c-btn-form-group" onClick={this.state.formEventCode.ready ? this.generateCode.bind(this) : undefined}>
                      <Icon key={'shortcode'} name="shortcode" size={40} />
                    </button>
                  </div>
                </form>

                <p className="u-fs-xs u-center u-blue20 u-mg-bottom-m text-justify">{t('preinscriptiondata.label_explain_p4')}<br/>{t('preinscriptiondata.label_explain_p5')}</p>

                <div className="u-flex u-flex-center-hz u-mg-vt-xl">
                  <button
                    type="button"
                    className="c-btn c-btn--primary"
                    onClick={() => this.props.history.push("/")}
                  >
                    {t('preinscriptiondata.label_home')}
                  </button>
                </div>

            </div>
          </>


      </main>
    )
  }
}

export default withTranslation()(PreinscriptionData);
