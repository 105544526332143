import React from "react";
import Icon from "../../components/Icons/Icon.jsx";
import Logo from "../../components/Logos/LogoById";

const LabelBike = ({ className, status, brand, code, lookAtBike, bike_status, operator }) => {
  return (
    <div onClick={lookAtBike} className={"c-item u-mg-vt-m " + (className || "") + (bike_status === 3 ? " c-item--black" : " ")}>
      <div className="c-item__status">{status}</div>
      <div className="c-item__content">
        <span className="">{brand}</span>
        <span className="u-light">{code}</span>
      </div>
      <div className="c-item__picto">
        {!code && <Icon key={'bike'} name="bike" size={40} className={"u-lh-1"} /> }
        {code && <Logo id={operator} style={{ width: 130 }}/> }
      </div>
    </div>
  )
}

export default LabelBike;
