import { format } from "date-fns"
import { zonedTimeToUtc } from "date-fns-tz"
import { fr } from "date-fns/locale"
import isValid from "date-fns/isValid"
import isPast from "date-fns/isPast"
import isToday from "date-fns/isToday"
import getDay from "date-fns/getDay"

const dateService = {
    getCurrentDate(stringFormat = null) {
        return stringFormat
            ? format(new Date(), stringFormat, fr)
            : new Date()
    },

    getUTCDate(date) {
        // https://stackoverflow.com/questions/65183368/proper-way-to-parse-a-date-as-utc-using-date-fns
        return zonedTimeToUtc(date, "UTC")
    },

	formatDate(date, stringFormat, neededGetUTCDate = true) {
		const dateUTC = this.getUTCDate(date)
		return format(neededGetUTCDate ? dateUTC : date, stringFormat, { locale: this.i18nLocale })
	},

    isValidDate(date) {
        return isValid(date)
    },

    isNotInthePast(date) {
        return !isPast(date) || isToday(date)
    },

    isSunday(date) {
        const day = getDay(date)
        return day === 0
    },

}

export default dateService