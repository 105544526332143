const saveBlob = (blob, fileName) => {
    // create link
    const tempLink = document.createElement("a")
    document.body.appendChild(tempLink)
    tempLink.style = "display: none"
    // populate blob
    const url = window.URL.createObjectURL(blob)
    tempLink.href = url
    tempLink.download = fileName
    // download
    tempLink.click()
    // remove
    window.URL.revokeObjectURL(url)
    tempLink.parentNode.removeChild(tempLink)
}

export default saveBlob