import React from "react"
import {
    View,
    StyleSheet,
    Image
} from "@react-pdf/renderer"

import styles from "./PDFStyles"
import DataViewPDF from "./PDFDataView"
// import LogoSunn from "assets/img/logo_sunn.jpg"
// import LogoIntersport from "assets/img/logo_intersport.jpg"
import dateService from "services/date"

// Header component
const PDFHeader = ({ bike, operatorGender }) => {
    const headerStyles = StyleSheet.create({
        header: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between"
        },
        leftColumn: {
            width: 150,
            marginLeft: "auto",
        },
    })

    const activatedBikeDate =
        bike?.bike_last_registered_by?.create_date
            ? dateService.formatDate(bike.bike_last_registered_by.create_date, "dd/MM/y")
            : dateService.formatDate(new Date(), "dd/MM/y")

    return (
        <View style={headerStyles.header}>

            <View style={headerStyles.leftColumn}>
                {(bike.operator === "BC" || bike.operator === "BY") &&
                    <Image src={"/BicyCode-logoBleu.jpg"} style={[styles.logoImage]} />
                }

                {bike.operator === "DK" &&
                    <Image src={"/Decathlon-logo.jpg"} style={[styles.logoImage]} />
                }

                {bike.operator === "MF" &&
                    <Image src={"/MFC_logo.jpg"} style={[styles.logoImage]} />
                }

                {/* {(bike.operator === "MF" && operatorGender === "SUNN") &&
                    <Image src={LogoSunn} width="270" height="45" />
                }

                {(bike.operator === "MF" && operatorGender === "INTER") &&
                    <Image src={LogoIntersport} width="270" height="45" />
                } */}

                <DataViewPDF
                    value={activatedBikeDate}
                />
            </View>

        </View>
    )
}

export default PDFHeader