import React, { Component } from 'react';
import {StrDef} from '../assets/lib/utils';
import Icon from "components/Icons/Icon.jsx";
import { withTranslation } from 'react-i18next';

var video;
var canvas;
var ctx;
var constraints = { video: { facingMode: "environment" }, audio: false };
var track = null;

class Scan extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isMediaSupport : false,
      displayCamera : false,
    };

    this.process = this.process.bind(this);
    this.cameraDraw = this.cameraDraw.bind(this);
    this.onPlay = this.onPlay.bind(this);
  }

  componentDidMount() {
    console.log("let previous = this.props.location.state.from;",this.props.location.state.from);
    // Side effect
    document && document.querySelector('body').classList.add("hide-footer")

    console.log("this.props",this.props);
    navigator.getUserMedia = navigator.getUserMedia       ||
                             navigator.webkitGetUserMedia ||
                             navigator.mozGetUserMedia    ||
                             StrDef(navigator.mediaDevices) ? navigator.mediaDevices.getUserMedia : null ||
                             null;

    if (navigator.getUserMedia === null || window.isWebAssemblySupport() === false) {
      // MEDIA OU WEBASSEMBLY NON SUPPORTÉ SUR CE NAVIGATEUR
      this.setState({isMediaSupport:false})
    } else {
      // listener lorsque le ocode est détecté
      window.addEventListener('ocode', this.process,false);

      // on lance la caméra
      this.setState({isMediaSupport:true},()=>{
        this.cameraStart()
      })
    }
  }

  componentWillUnmount() {
    // Side effect
    document && document.querySelector('body').classList.remove("hide-footer")
    if( StrDef(window.localstream) ) {
      window.localstream.getTracks()[0].stop();
    }
  }

  onPlay() {
    var ratio = video.videoWidth/video.videoHeight;
    var w = 1200;
    var h = parseInt(w/ratio);
    canvas.style.width = w+'px';
    canvas.style.height = h+'px';
    canvas.width = w;
    canvas.height = h;

    if (this.state.isMediaSupport === true) {
      this.cameraDraw();
    }
  }

  cameraStart = () => {

    var self = this;
    this.setState({displayCamera: true},()=>{
      video = document.getElementById("live");
      canvas = document.getElementById("canvas");
      ctx = canvas.getContext('2d');
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function(stream) {
            track = stream.getTracks()[0];
            video.srcObject = stream;
            window.localstream = stream;
        })
        .catch(function(error) {
          window.notify(this.props.t('scan.error_permission'),'danger')
          console.error("Something is broken like permission not authorized", error);
          self.setState({isMediaSupport:false,displayCamera:false},() => {
            self.props.history.goBack()
          })
        });
    })
  };

  cameraDraw() {
    if (video.paused || video.ended) {
      return;
    }
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    let image = canvas.getContext("2d").getImageData(0, 0, canvas.width, canvas.height)
    var buffer = new ArrayBuffer(image.data.length);
    var uint8 = new Uint8Array(buffer);
    uint8.set(image.data);
    try {
      window.detectOcode(uint8,image.width,image.height);
    } catch (e) {
      // PROBLEME
      console.log("pb",e);
    }
    this.timer = setTimeout(this.cameraDraw,0);
  }

  process(e) {
    // le ocode a été détecté
    clearTimeout(this.timer);
    this.timer = null;
    window.removeEventListener('ocode', this.process,false);
    this.setState({displayCamera:false},()=>{
      if( StrDef(window.localstream) ) {
        window.localstream.getTracks()[0].stop();
      }

      let previous = this.props.location.state.from;
      this.props.history.push({
        pathname:previous,
        state: { ocode: e.detail,previousState:this.props.location.state.previousState}
      });

      return;
      // CALL API HERE
      //alert("ocode id "+ e.detail);
    })
  }

  render(){
    const { t } = this.props;
    
    return (
      <main className={"c-main-content u-flex u-flex-dir-col"}>

        <button className="c-btn-back" title={this.props.t('common.back')} onClick={() => this.props.history.goBack()} >
          <Icon key={'back'} name="back" size={30} />
        </button>

        <div className="c-header u-zi-1 u-pd-top-l@main">
          <div className="l-container-md u-pd-hz-m">
            <h1 className="c-h1 c-header__title u-medium u-uppercase">{this.props.location.state.title}</h1>
          </div>
        </div>

      {this.state.displayCamera &&
        <div className="c-scan">

          <video
            id="live"
            className="c-scan__video"
            playsInline
            autoPlay
            onPlay={this.onPlay}>
          </video>

          <canvas
            id="canvas"
            className="c-scan__canvas">
          </canvas>

          <div
            className="c-scan__mire"
            id="mire">
              <div id="mireoverlay" className="c-scan__mire-overlay"></div>
          </div>
        </div>
      }

      </main>
    );
  }

}

export default withTranslation()(Scan);
