import React from "react";
import { StrDef } from '../assets/lib/utils';
import FormPerson from '../components/Forms/FormPerson';
import { withTranslation } from 'react-i18next';
import Icon from "../components/Icons/Icon.jsx";
import FormPassword from '../components/Forms/FormPassword';
class ProfilNoWallet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading : false,
      role : 1,
      bike: {},
      changePassword : false,
      formData : {
        socialReason  : '',
        lastName      : '',
        firstName     : '',
        phone         : '',
        email         : '',
      },
      formDataPassword : {
        actualPassword : '',
        password : '',
        password2 : '',
      },
      formErrorPassword : {
        actualPassword : false,
      },
    }

    this.load = this.load.bind(this)
  }

  componentDidMount() {
    this.load()
  }

  async load() {
    this.setState({ loading: true })
    let bike = await window.API.get_bike_nowallet('byBikeId',window.API.bikeId)

    if (!StrDef(bike.error)) {
      let bikeOwner = bike.bike_owner
      let formData = {
        email         : bikeOwner.mail,
        socialReason  : bikeOwner.social_reason || '',
        firstName     : bikeOwner.first_name || '',
        lastName      : bikeOwner.last_name || '',
        phone         : bikeOwner.phone,
      }

      let formDataPassword = {
        ... this.state.formDataPassword,
        actualPassword : '',
        password : '',
        password2 : '',
      }

      this.setState({
        bike: bike,
        formData: formData,
        formDataPassword : formDataPassword,
        loading: false,
        role : bike.bike_owner.gender
      })
    } else {
      this.setState({loading:false})
    }

  }

  updateStatePassword = (state) => {
    this.setState({
      formDataPassword: state.formData
    })
  }

  updateStatePerson = (state) => {
    this.setState({
      formData: state.formData,
    })
  }

  getObjPerson() {
    let objPerson = {}
    objPerson.mail = this.state.formData.email
    objPerson.phone = this.state.formData.phone
    objPerson.gender = this.state.role
    if (this.state.role === 1) {
      objPerson.last_name      = this.state.formData.lastName
      objPerson.first_name     = this.state.formData.firstName
    } else if(this.state.role === 2) {
      objPerson.social_reason  = this.state.formData.socialReason
    }
    return objPerson
  }

  updateAccount = async (event) => {
    this.setState({loading:true})
    let knownBike = this.state.bike;
    let bikeToPut = {}
    bikeToPut.bike_id     = knownBike.bike_id
    bikeToPut.bike_status = knownBike.bike_status
    bikeToPut.row_version = knownBike.row_version

    // attention au co_owner
    let bike_owner        = this.getObjPerson()
    bike_owner.co_owner   = this.state.bike.bike_owner.co_owner
    bikeToPut.bike_owner  = bike_owner

    let resPut = await window.API.put_bike_no_wallet(bikeToPut)
    if (!StrDef(resPut.error)) {
      window.notify(this.props.t('profilnowallet.success_update'),'success')
      this.load()
    } else if (StrDef(resPut.error)) {
      this.setState({loading:false})
      if (resPut.error.code === 'TooManyRequestsException') {
        window.notify(this.props.t('profilnowallet.error_already_update'),'danger')
      } else if (resPut.error.code === 'BikeVersionMismatchException') {
        window.notify(this.props.t('profilnowallet.error_version'),'danger')
        this.load()
      }
    }
  }

  changePassword = async (event) => {
    //this.setState({loading:true})
    //cal API
    let obj = {}
    obj.new_password = this.state.formDataPassword.password
    obj.old_password = this.state.formDataPassword.actualPassword

    let res = await window.API.put_password_nowallet(obj)
    if (!StrDef(res.error)) {
      window.notify(this.props.t('profil.success_password'),'success')
      this.setState({loading:false, changePassword:false},() => {
        this.load()
      })
    } else if (StrDef(res.error)) {
      if (res.error.code === 'NotAuthorizedException') {
        this.setState({
          formErrorPassword: {
            ...this.state.formErrorPassword,
            actualPassword : true,
            actualErrorPassword : this.props.t('profil.error_actual_mail')
          },
        })
      }
    }
  }

  render() {
    const { t } = this.props;
    return(
      <main className={"c-main-content u-flex u-flex-dir-col"}>
        <div className="c-skew-header">
          <div className="c-skew-header__top">
            <div className="l-container-md u-pd-hz-m">
              <h1 className="c-h1 u-medium u-uppercase" style={{ transform: "translateY(20px)"}}>{t('profilnowallet.title')}</h1>
            </div>
          </div>
          <div className="c-skew-header__bottom">
            <div className="c-skew-header__color">{}</div>
            <div className="u-flex u-flex-center-vt u-flex-between">
            </div>
          </div>
        </div>
        {this.state.loading &&
          <div className="u-center">
            <div className="c-spinner"></div>
          </div>
        }
        {!this.state.loading &&
          <div className="u-pd-hz-m u-pd-bottom-l">
            <div className="c-section-title u-pd-left-m u-mg-negative-hz-m u-mg-vt-l">
              <div className="l-container-md u-relative u-pd-right-m">
                <div className="c-section-title__content">
                  <h2 className="c-h2 u-medium u-uppercase">{t('profil.label_informations')}</h2>
                </div>
              </div>
            </div>

            <div className="l-container-md u-pd-hz-m u-pd-bottom-xl">
              <FormPerson
                mode={'accountNoWallet'}
                role = {this.state.role}
                formData={this.state.formData}
                disabled={true}
                updateState={this.updateStatePerson}
                onSubmit = {this.updateAccount.bind(this)}
                onCancel = {this.load.bind(this)}
              />
            </div>

            <div className="c-section-title u-pd-left-m u-mg-negative-hz-m u-mg-vt-l">
              <div className="l-container-md u-relative u-pd-right-m">
                <div className="c-section-title__content">
                  <h2 className="c-h2 u-medium u-uppercase">{t('profil.label_password_title')}</h2>
                  <Icon key={''} name="" size={40} />
                </div>
              </div>
            </div>

            {this.state.changePassword === false &&
              <div className="u-flex u-flex-center-hz u-mg-top-l">
                <button
                  type="submit"
                  className="c-btn c-btn--primary"
                  onClick={() => {this.setState({changePassword:true})}}
                >
                  {t('profil.label_modify')}
                </button>
              </div>
            }

            {this.state.changePassword === true &&
            <FormPassword
              mode = {'accountNoWallet'}
              formData={this.state.formDataPassword}
              formError={this.state.formErrorPassword}
              updateState={this.updateStatePassword}
              onSubmit = {this.changePassword}
            />
          }
          </div>
        }
      </main>
    )
  }
}

export default withTranslation()(ProfilNoWallet);
