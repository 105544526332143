/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { withTranslation } from 'react-i18next';
import { Route, Switch, Redirect } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// core components
import HelpFAQ from "components/Shared/HelpFAQ";
import Footer from "components/Footer/Footer.jsx";
import NotificationAlert from "components/Notification/Notification.js";
import LanguagesModal from "components/Modal/LanguagesModal";
import DesktopSideView from "layouts/DesktopSideView"

import { languages } from "utils/languages";
import routes from "routes.js";

var moment = require('moment');

var ps;


class PublicLayout extends React.Component {
  state = {
    sidebarMini: false,
    backgroundColor: "blue"
  };
  notificationAlert = React.createRef();
  mainPanel = React.createRef();
  componentDidMount() {
    this.modalAlert = false;
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      if (this.mainPanel.current) ps = new PerfectScrollbar(this.mainPanel.current);
    }
    window.notify = (msg,type) => this.refs.notificationAlert.notificationAlert({ message: msg, type: type });
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      if (ps) ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      //this.mainPanel.current.scrollTop = 0;
    }
    var msg = localStorage.getItem('alertMsg');
    if( moment().format('YYYYMMDD') < '20200529' ) {
      if( (msg == null || msg < '202005271500') && !this.modalAlert ) {
        this.modalAlert = true;
        this.setState({modalAlert:true});
      }
    }
  }

  alertCheck() {
    this.setState({modalAlert:false});
    localStorage.setItem('alertMsg',moment().format('YYYYMMDDHHMM'));
  }

  minimizeSidebar = () => {
    var message = "Sidebar mini ";
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
      message += "deactivated...";
    } else {
      this.setState({ sidebarMini: true });
      message += "activated...";
    }
    document.body.classList.toggle("sidebar-mini");
    var options = {};
    options = {
      place: "tr",
      message: message,
      type: "info",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7
    };
    this.notificationAlert.current.notificationAlert(options);
  };
  handleColorClick = color => {
    this.setState({ backgroundColor: color });
  };
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getActiveRoute = routes => {
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  render() {
    const { t } = this.props;

    /*if (this.props.location.pathname === "/moncompte/mes-velos") {
      document.body.classList.add("light-header");
    } else {*/
      document.body.classList.remove("light-header");
    //}

    const showNavbar = !(this.props.location.pathname === '/public/login' || this.props.location.pathname.startsWith('/pre-inscription') || this.props.location.pathname.startsWith('/faq') || this.props.location.pathname.startsWith('/modifier-mon-velo'))

    return (
      <>
        <NotificationAlert ref="notificationAlert"/>

        <div className="l-grid c-main-grid">

          <aside className="u-flex-desktop-only c-main-grid__aside l-col-4">
            <DesktopSideView />
          </aside>

          <div className={"l-col-12 l-col-8@main c-main-grid__main u-sticky-view"} ref="fullPages">
              <LanguagesModal languages={languages} className='u-absolute u-right-0 u-mg-right-l u-mg-top-m' isLightBackground={showNavbar ? true : false} />
              <Switch>
                {this.getRoutes(routes)}
                <Redirect from="/public" to="/auth/home" />
              </Switch>
            <Footer />
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(PublicLayout);
