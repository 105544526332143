import React from "react"
import Icon from "components/Icons/Icon.jsx"


const FormInput = ({
  formData,
  formError,
  what,
  error,
  label,
  placeholder,
  handleInputChange,
  maxLength,
  disabled,
  type,
  style,
  className,
  labelClassName = "",
  inputClassName = "",
  helpText,
  checked
}) => {

  const [passwordVisible, setPasswordVisible] = React.useState(false)
  const passwordType = passwordVisible ? "text" : "password"

  const [helpVisible, setHelpVisible] = React.useState(false)
  return (
    <div className={"c-form-group " + (className || "")} style={style}>
      <label className={`c-label ${labelClassName}`} htmlFor="code">{label||placeholder}</label>
      <input
        className={`c-input ${formError[what] ? "has-error" : ""} ${inputClassName}`}
        name={what}
        type={(type || what) === "password" ? passwordType : (type || "text")}
        placeholder={placeholder}
        value={formData[what]}
        onChange={handleInputChange}
        maxLength={maxLength}
        disabled={disabled}
        checked={checked}
      />
      {(type || what) === "password" && formData[what].length > 0 &&
        <button type="button" onClick={() => setPasswordVisible(!passwordVisible)}>
          <Icon key={passwordVisible ? 'hide' : 'see'} name={passwordVisible ? 'hide' : 'see'} size={25} />
        </button>
      }
      {helpText &&
        <button type="button" onClick={() => setHelpVisible(!helpVisible)}>
          <Icon key={'help'} name={'help'} size={25} />
        </button>
      }
      {formError[what] &&
          <p className="u-fs-xs u-danger">{error}</p>
      }
      {helpVisible &&
        <p className="u-blue20 u-fs-xs">{helpText}</p>
      }
    </div>
  )
}


export default FormInput;
