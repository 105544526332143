import React from "react"
import Icon from "../components/Icons/Icon.jsx";
import { withTranslation } from 'react-i18next';
import DOMPurify, { sanitize } from 'dompurify'

class Faq extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isWallet : false,
    }

    // sanitize links in trad from i18n
    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
      // set all elements owning target to target=_blank
      if ('target' in node) {
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener noreferrer');
      }
    })
  }

  componentDidMount() {
    let isWallet = this.state.isWallet
    if (window.API.getSubDomain() !== 'wallet') {
      isWallet = false;
    } else {
      isWallet = true;
    }
    this.setState({isWallet:isWallet})
  }

  render() {
    const { t } = this.props;
    return(
      <main className={"c-main-content u-flex u-flex-dir-col"}>
        <button className="c-btn-back" title={t('common.back')} onClick={() => this.props.history.goBack()} >
          <Icon key={'back'} name="back" size={30} />
        </button>
        <div className="c-skew-header">
          <div className="c-skew-header__top">
            <div className="l-container-md u-pd-hz-m">
              <h1 className="c-h1 u-medium u-uppercase" >{t('faq.title')}</h1>
            </div>
          </div>
          <div className="c-skew-header__bottom">
            <div className="c-skew-header__color">{}</div>
            <div className="u-flex u-flex-center-vt u-flex-between">
            </div>
          </div>
        </div>

        <div className="u-pd-hz-m u-pd-bottom-xl">

          <section className="u-mg-bottom-m">
            <div className="c-section-title u-pd-left-m u-mg-negative-hz-m u-mg-vt-l">
              <div className="l-container-md u-relative u-pd-right-m">
                <div className="c-section-title__content">
                  <h2 className="c-h2 u-medium u-uppercase">{t('faq.title_p1')}</h2>
                  <Icon key={''} name="" size={40} />
                </div>
              </div>
            </div>

            <div className="l-container-md">
              <article className="u-pd-hz-l u-mg-bottom-xl">
                <h3 className="c-h2 u-fs-s u-fs-l@main u-font-base u-bold u-mg-bottom-m">{t('faq.subtitle_p1_nowallet')}</h3>
                <p className="u-fs-xs u-fs-s@main u-mg-bottom-s">{t('faq.explain_p1_1_nowallet')}</p>
              </article>
              {this.state.isWallet &&
                <article className="u-pd-hz-l u-mg-bottom-xl">
                  <h3 className="c-h2 u-fs-s u-fs-l@main u-font-base u-bold u-mg-bottom-m">{t('faq.subtitle_p6')}</h3>
                  <p className="u-fs-xs u-fs-s@main u-mg-bottom-s">{t('faq.explain_p6')}</p>
                </article>
              }
              {!this.state.isWallet &&
                <article className="u-pd-hz-l u-mg-bottom-xl">
                  <h3 className="c-h2 u-fs-s u-fs-l@main u-font-base u-bold u-mg-bottom-m">{t('faq.subtitle_p2_nowallet')}</h3>
                  <p className="u-fs-xs u-fs-s@main u-mg-bottom-s">{t('faq.explain_p2_1_nowallet')}</p>
                </article>
              }
            </div>

            {this.state.isWallet &&
              <div className="l-container-md">
                <article className="u-pd-hz-l u-mg-bottom-xl">
                  <h3 className="c-h2 u-fs-s u-fs-l@main u-font-base u-bold u-mg-bottom-m">{t('faq.subtitle_p1')}</h3>
                  <p className="u-fs-xs u-fs-s@main u-mg-bottom-s">{t('faq.explain_p1_1')}</p>
                  <p className="u-fs-xs u-fs-s@main u-mg-bottom-s">{t('faq.explain_p1_2')}</p>
                  <p className="u-fs-xs u-fs-s@main u-mg-bottom-s">{t('faq.explain_p1_3')}</p>
                  <p className="u-fs-xs u-fs-s@main u-mg-bottom-s">{t('faq.explain_p1_4')}</p>
                </article>
              </div>
            }
          </section>

          {window.API.getSubDomain() === "bicycode" &&
              <section className="u-mg-bottom-m">
                <div className="c-section-title u-pd-left-m u-mg-negative-hz-m u-mg-vt-l">
                  <div className="l-container-md u-relative u-pd-right-m">
                    <div className="c-section-title__content">
                    <h2 className="c-h2 u-medium u-uppercase">{t('faq.owner_title')}</h2>
                      <Icon key={''} name="" size={40} />
                    </div>
                  </div>
                </div>
                <div className="l-container-md">
                  <article className="u-pd-hz-l u-mg-bottom-xl">
                    <h3 className="c-h2 u-fs-s u-fs-l@main u-font-base u-bold u-mg-bottom-m u-mg-top-l">{t('faq.owner_title_p1')}</h3>
                    <div dangerouslySetInnerHTML={{__html: sanitize(t('faq.owner_explain_p1'))}} />
                  </article>
                  <article className="u-pd-hz-l u-mg-bottom-xl">
                    <h3 className="c-h2 u-fs-s u-fs-l@main u-font-base u-bold u-mg-bottom-m u-mg-top-l">{t('faq.owner_title_p2')}</h3>
                    <div dangerouslySetInnerHTML={{__html: sanitize(t('faq.owner_explain_p2'))}} />
                  </article>
                  <article className="u-pd-hz-l u-mg-bottom-xl">
                    <h3 className="c-h2 u-fs-s u-fs-l@main u-font-base u-bold u-mg-bottom-m u-mg-top-l">{t('faq.owner_title_p3')}</h3>
                    <div dangerouslySetInnerHTML={{__html: sanitize(t('faq.owner_explain_p3'))}} />
                  </article>
                  <article className="u-pd-hz-l u-mg-bottom-xl">
                    <h3 className="c-h2 u-fs-s u-fs-l@main u-font-base u-bold u-mg-bottom-m u-mg-top-l">{t('faq.owner_title_p4')}</h3>
                    <div dangerouslySetInnerHTML={{__html: sanitize(t('faq.owner_explain_p4'))}} />
                  </article>
                  <article className="u-pd-hz-l u-mg-bottom-xl">
                    <h3 className="c-h2 u-fs-s u-fs-l@main u-font-base u-bold u-mg-bottom-m u-mg-top-l">{t('faq.owner_title_p5')}</h3>
                    <div dangerouslySetInnerHTML={{__html: sanitize(t('faq.owner_explain_p5'))}} />
                  </article>
                  <article className="u-pd-hz-l u-mg-bottom-xl">
                    <h3 className="c-h2 u-fs-s u-fs-l@main u-font-base u-bold u-mg-bottom-m u-mg-top-l">{t('faq.owner_title_p6')}</h3>
                    <div dangerouslySetInnerHTML={{__html: sanitize(t('faq.owner_explain_p6'))}} />
                  </article>
                  <article className="u-pd-hz-l u-mg-bottom-xl">
                    <h3 className="c-h2 u-fs-s u-fs-l@main u-font-base u-bold u-mg-bottom-m u-mg-top-l">{t('faq.owner_title_p7')}</h3>
                    <div dangerouslySetInnerHTML={{__html: sanitize(t('faq.owner_explain_p7'))}} />
                  </article>
                  <article className="u-pd-hz-l u-mg-bottom-xl">
                    <h3 className="c-h2 u-fs-s u-fs-l@main u-font-base u-bold u-mg-bottom-m u-mg-top-l">{t('faq.owner_title_p8')}</h3>
                    <div dangerouslySetInnerHTML={{__html: sanitize(t('faq.owner_explain_p8'))}} />
                  </article>
                </div>
              </section>
            }

          <section className="u-mg-bottom-m">
            <div className="c-section-title u-pd-left-m u-mg-negative-hz-m u-mg-vt-l">
              <div className="l-container-md u-relative u-pd-right-m">
                <div className="c-section-title__content">
                <h2 className="c-h2 u-medium u-uppercase">{t('faq.title_p2')}</h2>
                  <Icon key={''} name="" size={40} />
                </div>
              </div>
            </div>

            <div className="l-container-md">
              <article className="u-pd-hz-l u-mg-bottom-xl">
                <h3 className="c-h2 u-fs-s u-fs-l@main u-font-base u-bold u-mg-bottom-m u-mg-top-l">{t('faq.subtitle_p2')}</h3>
                <p className="u-fs-xs u-fs-s@main u-mg-bottom-s">{t('faq.explain_p2_1')}</p>
              </article>

              {this.state.isWallet &&
                <>
                  <article className="u-pd-hz-l u-mg-bottom-xl">
                    <h3 className="c-h2 u-fs-s u-fs-l@main u-font-base u-bold u-mg-bottom-m u-mg-top-l">{t('faq.subtitle_p3')}</h3>
                    <p className="u-fs-xs u-fs-s@main u-mg-bottom-s">{t('faq.explain_p3_1')}</p>
                    <p className="u-fs-xs u-fs-s@main u-mg-bottom-s">{t('faq.explain_p3_2')}</p>
                    <p className="u-fs-xs u-fs-s@main u-mg-bottom-s">{t('faq.explain_p3_3')}</p>
                  </article>

                  <article className="u-pd-hz-l u-mg-bottom-xl">
                    <h3 className="c-h2 u-fs-s u-fs-l@main u-font-base u-bold u-mg-bottom-m">{t('faq.subtitle_p4')}</h3>
                    <p className="u-fs-xs u-fs-s@main u-mg-bottom-s">{t('faq.explain_p4_1')}</p>
                  </article>
                </>
              }
            </div>
          </section>

          {this.state.isWallet &&
            <>
              <section className="u-mg-bottom-m">
                <div className="c-section-title u-pd-left-m u-mg-negative-hz-m u-mg-vt-l">
                  <div className="l-container-md u-relative u-pd-right-m">
                    <div className="c-section-title__content">
                    <h2 className="c-h2 u-medium u-uppercase">{t('faq.title_p5')}</h2>
                      <Icon key={''} name="" size={40} />
                    </div>
                  </div>
                </div>

                <div className="l-container-md">
                <article className="u-pd-hz-l u-mg-bottom-xl">
                  <h3 className="c-h2 u-fs-s u-fs-l@main u-font-base u-bold u-mg-bottom-m u-mg-top-l">{t('faq.subtitle_p5')}</h3>
                  <p className="u-fs-xs u-fs-s@main u-mg-bottom-s">{t('faq.explain_p5_1')}</p>
                  <p className="u-fs-xs u-fs-s@main u-mg-bottom-s">{t('faq.explain_p5_2')}</p>
                </article>
                </div>
              </section>

              <section>
              <div className="c-section-title u-pd-left-m u-mg-negative-hz-m u-mg-vt-l">
                  <div className="l-container-md u-relative u-pd-right-m">
                    <div className="c-section-title__content">
                    <h2 className="c-h2 u-medium u-uppercase">{t('faq.subtitle_p7')}</h2>
                      <Icon key={''} name="" size={40} />
                    </div>
                  </div>
                </div>

                <div className="l-container-md">
                  <article className="u-pd-hz-l u-mg-bottom-xl">
                    <h3 className="c-h2 u-fs-s u-fs-l@main u-font-base u-bold u-mg-bottom-m u-mg-top-l">{t('faq.explain_p7_1')}</h3>
                    <p className="u-fs-xs u-fs-s@main u-mg-bottom-s">{t('faq.explain_p7_2')}</p>
                    <p className="u-fs-xs u-fs-s@main u-mg-bottom-s">
                      {t('faq.explain_p7_3')}
                      <b><a href={`mailto:${t('help.email_decathlon')}`}>{t('help.email_decathlon')}</a></b>
                    </p>
                    <p className="u-fs-xs u-fs-s@main u-mg-bottom-s">
                      {t('faq.explain_p7_4')}
                      <b><a href={`mailto:${t('help.email_wallet')}`}>{t('help.email_wallet')}</a></b>
                    </p>
                  </article>
                </div>
              </section>
            </>
          }
        </div>
      </main>
    )
  }
}


export default withTranslation()(Faq);
