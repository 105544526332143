import {
    StyleSheet,
} from "@react-pdf/renderer"

const styles = StyleSheet.create({
    page: {
        backgroundColor: "#fff",
        fontFamily: "Helvetica",
        fontSize: 12,
        paddingVertical: 40,
        paddingHorizontal: 60
    },
    sectionTitle: {
        flexDirection: "row",
        paddingBottom: 10,
        marginBottom: 20,
        borderBottomColor: "#005a7c",
        borderBottomStyle: "solid",
        borderBottomWidth: 2
    },
    section: {
        paddingTop: 20,
    },
    grid: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: 20,
        marginBottom: 30,
        padding: 20,
        paddingLeft: 0,
        paddingRight: 0,
        backgroundColor: "#f9f9f9",
    },
    sectionLeft: {
        flexGrow: 1,
        paddingRight: 10
    },
    sectionRight: {
        flexGrow: 1,
        paddingLeft: 25
    },
    h1: {
        fontSize: 20,
        color: "#1B1464",
        marginBottom: 35,
        textAlign: "center",
        textTransform: "uppercase",
    },
    h2: {
        fontSize: 16,
        marginBottom: 10,
        color: "#1B1464",
    },
    h3: {
        fontSize: 13,
        marginBottom: 15,
        color: "#1B1464",
    },
    p: {
        fontSize: 10,
        marginBottom: 10, 
    },
    li: {
        fontSize: 10,
        marginBottom: 5, 
        paddingLeft: 15,
    },
    image: {
        width: 500,
        marginBottom: 50
    },
    bottomPage: {
        marginTop: 30
    },
    logoImage: {
		width: 150,
		marginBottom: 10,
	},
    logoImageLarge: {
		width: 270,
		marginBottom: 10,
	},
    // Utilities
    bold: {
        fontFamily: "Helvetica-Bold"
    },
    uppercase: {
        textTransform: "uppercase"
    }
})

export default styles