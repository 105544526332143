import React from "react";
import { Row, Col } from "reactstrap";
import {sharedAPI} from './api';
import moment from 'moment';
import XLSX from 'xlsx';
import { StandardFonts, rgb } from 'pdf-lib'
//import i18n from '../../i18n';
import { withTranslation,useTranslation } from 'react-i18next';
import i18next from "i18next";

var _ = require('underscore');
var fs = require('fs')
var jwtDecode   = require('jwt-decode');
var bigInt  	= require("big-integer");
var sha3hash     = require('js-sha3')
const strictjson = require('json-stable-stringify')
var multihash   = require('multihashes')
const Promise   = require('bluebird'),
      sha3_256  = require('js-sha3').sha3_256
var mark = [5,7,15,38,44,60,82,105,118,119,122];
var markAbsent = [56,70,121];
var blackWhiteTableau = [50,51,64,76,75,62];
var positionsMatriceLigne   = [3,2,1,0,3,2,1,0,3,2,1,0,3,2,1,0,3,2,1,0,3,2,1,0,3,2,1,0,3,2,1,0,3,2,1,0,3,2,1,0,3,2,1,0,3,2,1,0,3,2,1,0,3,2,1,0,3,2,1,0,3,2,1,0];
var positionsMatriceColonne = [0,0,0,0,1,1,1,1,2,2,2,2,3,3,3,3,0,0,0,0,1,1,1,1,2,2,2,2,3,3,3,3,0,0,0,0,1,1,1,1,2,2,2,2,3,3,3,3,0,0,0,0,1,1,1,1,2,2,2,2,3,3,3,3];
var bit = [39,40,52,65,77,88,87,86,74,61,49,27,28,29,30,41,53,66,78,89,99,98,97,96,85,73,48,37,17,18,19,20,21,31,42,54,67,79,90,100,109,108,107,106,95,84,72,59,47,36,26,8,9,10,11,12,13,22,32,43,55,68,80];
var cheeses = [1,2,2,3,3,4,4,5,5,6,6,1,1,1,2,2,2,3,3,3,4,4,4,5,5,5,6,6,1,1,1,1,2,2,2,2,3,3,3,3,4,4,4,4,5,5,5,6,6,6,6,1,1,1,1,1,2,2,2,2,2,3,3];
var bitParite = [91,101,110,117,116,115,114,113,104,94,83,71,58,46,35,25,16,2,3,4,23,33,92,102,111,124,123,112,103,93,34,24];
var cheeseBitParite = [3,3,3,4,4,4,4,5,5,5,5,5,6,6,6,6,6,1,1,1,2,2,3,3,3,4,4,5,5,5,6,6];
var cheese1Tableau = [39,27,28,29,17,18,19,20,8,9,10,11,12];
var cheese2Tableau = [40,52,30,41,53,21,31,42,54,13,22,32,43,55];
var cheese3Tableau = [65,77,66,78,89,67,79,90,100,68,80];
var cheese4Tableau = [87,88,97,98,99,106,107,108,109];
var cheese5Tableau = [74,86,73,85,96,72,84,95];
var cheese6Tableau = [49,61,37,48,26,36,47,59];
var cheese1PariteTableau = [2,3,4];
var cheese2PariteTableau = [23,33];
var cheese3PariteTableau = [91,101,110,92,102,111];
var cheese4PariteTableau = [114,115,116,117,123,124];
var cheese5PariteTableau = [71,83,94,104,113,93,103,112];
var cheese6PariteTableau = [58,46,35,25,16,34,24];
var nbPresentCheese1 = 2;
var nbPresentCheese2 = 1;
var nbPresentCheese3 = 1;
var nbPresentCheese4 = 2;
var nbPresentCheese5 = 2;
var nbPresentCheese6 = 3;
var checkSumValueTableau = new Array(5);
var checkSumTableau = [1,14,81,125,45];

const POSITION_X_OCODE_VECTOR = 70
const POSITION_Y_OCODE_VECTOR = 110
const POSITION_X_MNT_VECTOR = 67
const POSITION_Y_MNT_VECTOR = 140
const DIAM_OCODE = 20
const DIAM_OCODE_SCAN = 100
const DIAM_OCODE_IMAGE_PNG = 40
const RATIO_X_IMG = 0.82
const RATIO_Y_IMG = 0.14

var fontBase = 2500,                   // selected default width for canvas
    fontSize = 70;
moment.locale('fr', {
    months : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
    monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
    monthsParseExact : true,
    weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    weekdaysParseExact : true,
    longDateFormat : {
        LT : 'HH:mm',
        LTS : 'HH:mm:ss',
        L : 'DD/MM/YYYY',
        LL : 'D MMMM YYYY',
        LLL : 'D MMMM YYYY HH:mm',
        LLLL : 'dddd D MMMM YYYY HH:mm'
    },
    calendar : {
        sameDay : '[Aujourd’hui à] LT',
        nextDay : '[Demain à] LT',
        nextWeek : 'dddd [à] LT',
        lastDay : '[Hier à] LT',
        lastWeek : 'dddd [dernier à] LT',
        sameElse : 'L'
    },
    relativeTime : {
        future : 'dans %s',
        past : 'il y a %s',
        s : 'quelques secondes',
        m : 'une minute',
        mm : '%d minutes',
        h : 'une heure',
        hh : '%d heures',
        d : 'un jour',
        dd : '%d jours',
        M : 'un mois',
        MM : '%d mois',
        y : 'un an',
        yy : '%d ans'
    },
    dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
    ordinal : function (number) {
        return number + (number === 1 ? 'er' : 'e');
    },
    meridiemParse : /PD|MD/,
    isPM : function (input) {
        return input.charAt(0) === 'M';
    },
    meridiem : function (hours, minutes, isLower) {
        return hours < 12 ? 'PD' : 'MD';
    },
    week : {
        dow : 1, // Monday is the first day of the week.
        doy : 4  // Used to determine first week of the year.
    }
});

export function json_hash(json_data) {
  json_data = strictjson(json_data, (a, b) => (a.key > b.key ? 1 : -1))
  return sha3hash.sha3_256.create().update(json_data).hex()
}

export function isBirthPlaceValid (chaine) {
  let accents             = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž'
  let without_accents     = 'AAAAAAAAAAAABOOOOOOOOOOOOOEEEEEEEEOCCDIIIIIIIIUUUUUUUUNNSSYYYZZ'
  let without_accents_arr = without_accents.split('')
  let str = chaine.split('')
  let new_chain = ""
  for(var i=0;i<str.length;i++) {
    if( accents.indexOf(str[i]) != -1 ) {
      new_chain = new_chain + without_accents_arr[i]
    } else {
      new_chain = new_chain + str[i]
    }
  }
  new_chain = new_chain.toUpperCase(new_chain)
  new_chain = new_chain.replace(new RegExp(/[^A-Z]/g),"")
  if ( !StrDef(new_chain) ) {
    return null
  }
  return new_chain
  }

export function isFloat(str) {
  var regFloat=new RegExp(/^\d*(\.\d{0,2})?$/)
  return regFloat.test(str)
}
export function ObjectHash(o) {
  var key = _.keys(o);
  key.sort();
  var res = '';
  for(var i=0;i<key.length;i++) {
    res += o[key[i]];
  }
  return res;
}

export function isMdpValid(str) {
  var reg=new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z\W]{8,}$/)
  return reg.test(str)
}

export function isBikeCodeValid(str) {
  // TODO - regex pas encore défini - on attend les différents format des fnuci codes
  // il y a la reprise de données des anciens codes mais quels formats ont-ils hum hum ?
  return str.length > 5
}

export function get_types_bikes() {
  return [
    {id:999,label: i18next.t('bike.type.other')},
    {id:1, label: i18next.t('bike.type.race')},
    {id:2, label: i18next.t('bike.type.cross')},
    {id:3, label: i18next.t('bike.type.vtt')},
    {id:4, label: i18next.t('bike.type.vtc')},
    {id:5, label: i18next.t('bike.type.city')},
    {id:6, label: i18next.t('bike.type.child_bike')},
    {id:7, label: i18next.t('bike.type.folding_bike')},
    {id:8, label: i18next.t('bike.type.cargo_bike')},
    {id:9, label: i18next.t('bike.type.tricyle')},
    {id:10, label: i18next.t('bike.type.tandem')},
    {id:11, label: i18next.t('bike.type.lying_bike')},
    {id:12, label: i18next.t('bike.type.bmx')},
    {id:14, label: i18next.t('bike.type.scooter')},
    {id:15, label: i18next.t('bike.type.trailer')}
  ]
}

export function get_brand_bikes() {
  return [
    {id:999	, label: 'Autres'},
    {id:1, label: 'Addbike'},
    {id:2	, label: 'Alex Singer'},
    {id:3	, label: 'Amsterdamair'},
    {id:4	, label: 'Angell'},
    {id:5	, label: 'Arcade'},
    {id:6	, label: 'Argon 18'},
    {id:7	, label: 'Arrow'},
    {id:8	, label: 'Atlas Bike'},
    {id:9	, label: 'Attala'},
    {id:10	, label: 'Aventon'},
    {id:11	, label: 'Avigo'},
    {id:12	, label: 'Babboe'},
    {id:13	, label: 'Bakfiets'},
    {id:14	, label: 'Banshee'},
    {id:15	, label: 'Basso'},
    {id:16	, label: 'Batavus'},
    {id:17	, label: 'Beaufort'},
    {id:292	, label: 'BENNO'},
    {id:287	, label: 'BERGAMONT'},
    {id:18	, label: 'Bernard Hinault'},
    {id:19	, label: 'Bernard Thevenet'},
    {id:20	, label: 'Bertaud'},
    {id:21	, label: 'Bertin'},
    {id:22	, label: 'Bianchi'},
    {id:23	, label: 'Bicicapcae'},
    {id:295	, label: 'BIKE43'},
    {id:24	, label: 'BH'},
    {id:25	, label: 'BMC'},
    {id:26	, label: 'Bottecchia'},
    {id:27	, label: 'Breezer'},
    {id:28	, label: 'Brompton'},
    {id:29	, label: 'B\'twin'},
    {id:30	, label: 'Bulls'},
    {id:31	, label: 'Cadex'},
    {id:32	, label: 'Caminade'},
    {id:33	, label: 'Cannondale'},
    {id:34	, label: 'Canyon'},
    {id:35	, label: 'Carqon'},
    {id:36	, label: 'Carraro'},
    {id:37	, label: 'CBT Italia'},
    {id:38	, label: 'Centurion'},
    {id:39	, label: 'Cervelo'},
    {id:40	, label: 'Champion'},
    {id:41	, label: 'Chase'},
    {id:42	, label: 'Chike'},
    {id:43	, label: 'Chromag'},
    {id:44	, label: 'Cinelli'},
    {id:45	, label: 'Cipollini'},
    {id:46	, label: 'CKT'},
    {id:47	, label: 'Coboc'},
    {id:48	, label: 'Colnago'},
    {id:49	, label: 'Commmençal'},
    {id:50	, label: 'Conway'},
    {id:51	, label: 'Corratec'},
    {id:52	, label: 'Cotic bikes'},
    {id:53	, label: 'Cowboy'},
    {id:54	, label: 'Creme'},
    {id:55	, label: 'Cube'},
    {id:56	, label: 'Cult'},
    {id:57	, label: 'CyclO2'},
    {id:58	, label: 'Cyfac'},
    {id:59	, label: 'Dahon'},
    {id:60	, label: 'Dartmoor'},
    {id:61	, label: 'De Rosa'},
    {id:62	, label: 'Decathlon'},
    {id:63	, label: 'Déda'},
    {id:64	, label: 'Devinci'},
    {id:65	, label: 'Diamant'},
    {id:66	, label: 'Dolly'},
    {id:67	, label: 'Douze cycles'},
    {id:68	, label: 'Easybike'},
    {id:69	, label: 'Eddy Merckx'},
    {id:70	, label: 'Electra'},
    {id:300	, label: 'ELOPS'},
    {id:71	, label: 'Engine Lab'},
    {id:72	, label: 'Eovolt'},
    {id:73	, label: 'EXS'},
    {id:74	, label: 'Excelsior'},
    {id:75	, label: 'Faggin'},
    {id:76	, label: 'Fahrradmanufaktur'},
    {id:77	, label: 'Fantic'},
    {id:78	, label: 'Felt'},
    {id:79	, label: 'Feu Vert'},
    {id:80	, label: 'Fixie Inc'},
    {id:81	, label: 'FKC'},
    {id:82	, label: 'Flyer'},
    {id:288	, label: 'FLYING-CAT'},
    {id:83	, label: 'Focus'},
    {id:84	, label: 'Foes'},
    {id:85	, label: 'Frog bikes'},
    {id:86	, label: 'Fuji'},
    {id:87	, label: 'Gazelle'},
    {id:89	, label: 'Genesis'},
    {id:91	, label: 'Ghost'},
    {id:93	, label: 'Giant'},
    {id:95	, label: 'Girs'},
    {id:97	, label: 'Gitane'},
    {id:99	, label: 'Go Sport'},
    {id:101	, label: 'Gocycle'},
    {id:103	, label: 'Granville'},
    {id:105	, label: 'Greyp'},
    {id:107	, label: 'GT Bicycle'},
    {id:108	, label: 'Haibike'},
    {id:109	, label: 'Haro'},
    {id:110	, label: 'Hercules'},
    {id:111	, label: 'Hope'},
    {id:112	, label: 'Husqvarna Bicycles'},
    {id:113	, label: 'Ibis'},
    {id:114	, label: 'Inspyre Bicycles'},
    {id:115	, label: 'Intense'},
    {id:116	, label: 'Iron Horse'},
    {id:117	, label: 'Iweech'},
    {id:118	, label: 'Jacques Anquetil'},
    {id:119	, label: 'Juliana'},
    {id:120	, label: 'Jumpertrek'},
    {id:121	, label: 'Kalkhoff'},
    {id:122	, label: 'Kellys'},
    {id:123	, label: 'Kessels'},
    {id:124	, label: 'Kestrel'},
    {id:125	, label: 'Kettler'},
    {id:126	, label: 'KHE'},
    {id:127	, label: 'KHS'},
    {id:128	, label: 'Kiffy'},
    {id:129	, label: 'Kink BMX'},
    {id:130	, label: 'Knolly'},
    {id:131	, label: 'Koga'},
    {id:132	, label: 'Kona'},
    {id:133	, label: 'Kross'},
    {id:134	, label: 'KS Cycling'},
    {id:135 , label: 'KTM'},
    {id:136	, label: 'Kuota'},
    {id:137	, label: 'Kuwahara'},
    {id:138	, label: 'Lapierre'},
    {id:139	, label: 'Lejeune'},
    {id:140	, label: 'Litespeed'},
    {id:141	, label: 'Liteville'},
    {id:297	, label: 'LIV'},
    {id:142	, label: 'Logo'},
    {id:289	, label: 'LOMBARDO'},
    {id:143	, label: 'Look'},
    {id:144	, label: 'Lutetia'},
    {id:145	, label: 'Maabikes'},
    {id:146	, label: 'Mad'},
    {id:147	, label: 'Marin'},
    {id:148	, label: 'Massi'},
    {id:149	, label: 'Matra'},
    {id:150	, label: 'MBK'},
    {id:290	, label: 'MBM'},
    {id:151	, label: 'Mecer'},
    {id:294	, label: 'MEGAMO'},
    {id:152	, label: 'Mercier'},
    {id:153	, label: 'Merlin'},
    {id:154	, label: 'Merida'},
    {id:155	, label: 'Meybo'},
    {id:156	, label: 'Micmo'},
    {id:157	, label: 'Moma'},
    {id:158	, label: 'Mondraker'},
    {id:159	, label: 'Mongoose'},
    {id:160	, label: 'Montana'},
    {id:161	, label: 'Monty'},
    {id:162	, label: 'Motobécane'},
    {id:163	, label: 'Moustache'},
    {id:164	, label: 'Movea'},
    {id:165	, label: 'MSC'},
    {id:166	, label: 'Muddy Fox'},
    {id:167	, label: 'Nakamura'},
    {id:301	, label: 'NEATT'},
    {id:168	, label: 'Neomouv'},
    {id:169	, label: 'Nicolaï'},
    {id:170	, label: 'Niner'},
    {id:171	, label: 'Norco'},
    {id:172	, label: 'Nox'},
    {id:173	, label: 'NS bikes'},
    {id:174	, label: 'Nukeproof'},
    {id:175	, label: 'Olmo'},
    {id:176	, label: 'On One'},
    {id:177	, label: 'Optim\'alp'},
    {id:178	, label: 'Orbea'},
    {id:179	, label: 'Orbita'},
    {id:180	, label: 'Orange'},
    {id:181	, label: 'Origine'},
    {id:182	, label: 'Ortler'},
    {id:183	, label: 'O2 Feel'},
    {id:184	, label: 'Panther'},
    {id:291	, label: 'PEGASUS'},
    {id:185	, label: 'Peugeot'},
    {id:186	, label: 'Pinarello'},
    {id:187	, label: 'Pivot Cycles'},
    {id:188	, label: 'Polygon'},
    {id:189	, label: 'Position One'},
    {id:190	, label: 'Production privée'},
    {id:191	, label: 'Puch'},
    {id:192	, label: 'Quantum'},
    {id:193	, label: 'Q bikes'},
    {id:194	, label: 'Rabeneick'},
    {id:195	, label: 'Rad Bike'},
    {id:196	, label: 'Radio Bikes'},
    {id:197	, label: 'Radon'},
    {id:198	, label: 'Raleigh'},
    {id:199	, label: 'Raymon'},
    {id:200	, label: 'Rayvolt bikes'},
    {id:201	, label: 'Redline'},
    {id:202	, label: 'Riese & Müller'},
    {id:203	, label: 'Ridley'},
    {id:204	, label: 'Ritchey'},
    {id:299	, label: 'RIVERSIDE'},
    {id:205	, label: 'Rockrider'},
    {id:206	, label: 'Rocky Mountain'},
    {id:296	, label: 'ROMET'},
    {id:207	, label: 'Rondo'},
    {id:208	, label: 'Rose'},
    {id:209	, label: 'Rossignol'},
    {id:210	, label: 'Rotwild'},
    {id:211	, label: 'Ruff Cycles'},
    {id:212	, label: 'Samebike'},
    {id:213	, label: 'Santa Cruz'},
    {id:302	, label: 'SCAMP'},
    {id:214	, label: 'Schwinn'},
    {id:215	, label: 'Scott'},
    {id:216	, label: 'Scrapper'},
    {id:217	, label: 'Serious'},
    {id:218	, label: 'SE Racing'},
    {id:219	, label: 'Seven'},
    {id:293	, label: 'SHENGMILO'},
    {id:220	, label: 'Simplon'},
    {id:221	, label: 'Sinus'},
    {id:222	, label: 'Sirius'},
    {id:303	, label: 'SMOBY'},
    {id:223	, label: 'Sobre'},
    {id:224	, label: 'Solex'},
    {id:225	, label: 'Sparta'},
    {id:226	, label: 'Specialized'},
    {id:227	, label: 'Spego'},
    {id:228	, label: 'Starway'},
    {id:304	, label: 'STILUS'},
    {id:229	, label: 'Storck'},
    {id:230	, label: 'Strida'},
    {id:231	, label: 'Stromer '},
    {id:232	, label: 'Subrosa'},
    {id:233	, label: 'Sunday Bikes'},
    {id:234	, label: 'Sunn'},
    {id:235	, label: 'Swift'},
    {id:236	, label: 'Swype'},
    {id:237	, label: 'Talbot'},
    {id:238	, label: 'T-bird'},
    {id:239	, label: 'Tern'},
    {id:240	, label: 'Thirty One'},
    {id:241	, label: 'Tilgreen'},
    {id:242	, label: 'Time'},
    {id:243	, label: 'Titici'},
    {id:244	, label: 'Topbike'},
    {id:245	, label: 'Top Gun'},
    {id:246	, label: 'Torker'},
    {id:247	, label: 'Torpado'},
    {id:248	, label: 'Transition'},
    {id:249	, label: 'Trek'},
    {id:298	, label: 'TRIBAN'},
    {id:250	, label: 'Turner'},
    {id:251	, label: '3T'},
    {id:252	, label: 'Union'},
    {id:253	, label: 'Univega'},
    {id:254	, label: 'Urban Arrow'},
    {id:255	, label: 'Vaast'},
    {id:256	, label: 'Van Dijck'},
    {id:257	, label: 'Van Moof'},
    {id:258	, label: 'Van Rysel'},
    {id:259	, label: 'Vario'},
    {id:260	, label: 'Velair'},
    {id:261	, label: 'Velobecane'},
    {id:262	, label: 'Vendetta'},
    {id:263	, label: 'Ventana'},
    {id:264	, label: 'Venilu'},
    {id:265	, label: 'Vermont'},
    {id:266	, label: 'Via Veneto'},
    {id:267	, label: 'Victoire'},
    {id:268	, label: 'Vitus'},
    {id:269	, label: 'Voodoo'},
    {id:270	, label: 'Votec'},
    {id:271	, label: 'VUFd'},
    {id:272	, label: 'Wayscral'},
    {id:273	, label: 'Wanderer'},
    {id:274	, label: 'Whe The People'},
    {id:275	, label: 'Wheeler'},
    {id:276	, label: 'Whistle'},
    {id:277	, label: 'White bikes'},
    {id:278	, label: 'Whyte'},
    {id:279	, label: 'Wilier Triestina'},
    {id:280	, label: 'Winora'},
    {id:281	, label: 'Xiaomi'},
    {id:282	, label: 'Yeti'},
    {id:283	, label: 'YT Industries'},
    {id:284	, label: 'Yokler'},
    {id:285	, label: 'Yuba'},
    {id:286	, label: 'Zumbi'}
  ]
}

export function GetMediaUrlPrefix(accessToken) {
  let decoded = jwtDecode(accessToken);
  //console.log("decoded",decoded);
  return decoded.region+'/'+'alveole-documents-'+decoded.region + '/'
}

export function GetEntityId(accessToken) {
  let decoded = jwtDecode(accessToken);
 //  console.log("decoded",decoded);
  return decoded.entity_id
}

export function GetPermission(accessToken) {
  let decoded = jwtDecode(accessToken);
 //  console.log("decoded",decoded);
  return decoded.user_permission
}

export function GetUserId(accessToken) {
  let decoded = jwtDecode(accessToken);
  //console.log("decoded",decoded);
  return decoded.user_id;
}

export function GetUserName(accessToken) {
  let decoded = jwtDecode(accessToken);
  //console.log("decoded",decoded);
  return decoded.user_name;
}

export function GetKeywords(entity_data, addUser,userId) {
  //"keywords": "O-S-00000000000000 O-CP-85430 mounir@o-code.co centre myriam 2 allée jean mermoz 85430 Auigny"
  let keywords = ''
  keywords += 'S-AS-' + entity_data.asso_type + ' '
  keywords += 'S-CP-' + entity_data.zip + ' '
  keywords += 'S-SI-' + entity_data.siret + ' '
  keywords += 'S-IB-' + entity_data.iban + ' '
  keywords += entity_data.name + ' '
  keywords += entity_data.firstname + ' '
  keywords += entity_data.raison_sociale + ' '
  keywords += entity_data.mail + ' '
  keywords += entity_data.city + ' '
  if (addUser) {
    keywords += 'S-US-'+ userId + ' '
  }
  //console.log("keywords",keywords);
  return keywords;
}

export function GetKeywordConsumer(data) {
  data = Clone(data);
  var keywords = ''
  data.smartphone_number = data.smartphone_number.replace('+33','0');
  keywords += 'S-NA-' + data.first_name + ' '
  keywords += 'S-LN-' + data.last_name + ' '
  keywords += 'S-PH-' + data.smartphone_number + ' '
  keywords += 'S-MA-' + data.mail
  return keywords;
}

export function GetKeywordRepair(data) {
  data = Clone(data);
  var keywords = ''
  if (StrDef(data.repair_search_data.name)) {
    keywords += 'S-NA-' + data.repair_search_data.name + ' '
  }
  if (StrDef(data.repair_search_data.lastname)) {
    keywords += 'S-LN-' + data.repair_search_data.lastname + ' '
  }
  if (StrDef(data.repair_search_data.phone)) {
    keywords += 'S-PH-' + data.repair_search_data.phone + ' '
  }
  if (StrDef(data.repair_search_data.mail)) {
    keywords += 'S-MA-' + data.repair_search_data.mail + ' '
  }
  if (StrDef(data.repair_search_data.comment)) {
    keywords += 'S-CM-' + data.repair_search_data.comment + ' '
  }
  if(StrDef(data.repair_data.operation_data.repair_bike_comment)) {
    keywords += 'S-CO-' + data.repair_data.operation_data.repair_bike_comment + ' '
  }
  if(StrDef(data.repair_data.operation_data.repair_bike_brand)) {
    keywords += 'S-BR-' + data.repair_data.operation_data.repair_bike_brand + ' '
  }
  if (StrDef(data.repair_data.operation_data.repair_bike_serial)) {
    keywords += 'S-SN-' + data.repair_data.operation_data.repair_bike_serial + ' '
  }
  // messagerie
  if (StrDef(data.repair_search_data.message_non_lu_rozo)) {
    keywords += 'S-MS-' + data.repair_search_data.message_non_lu_rozo + ' '
    keywords = keywords.replace('S-MS-messagenonlureparateur',''); // on supprime message_non_lu_reparateur
  }
  if (StrDef(data.repair_search_data.message_non_lu_reparateur)) {
    keywords += 'S-MS-' + data.repair_search_data.message_non_lu_reparateur + ' ';
    keywords = keywords.replace('S-MS-messagenonlurozo',''); // on supprime message_non_lu_rozo
  }
  return keywords;
}

export function GetBikeLabelStatus(status) {

  //const { t, i18n } = useTranslation();
  //console.log("t",t);
  let labelStatus = ''
  switch (status) {
    case 1:
      labelStatus = i18next.t('bikestatus.label_inservice')
      break;
    case 2:
      labelStatus = i18next.t('bikestatus.label_stolen')
      break;
    case 3:
      labelStatus = i18next.t('bikestatus.label_destroy')
      break;
    case 4:
      labelStatus = i18next.t('bikestatus.label_insearch')
      break;
    case 5:
      labelStatus = i18next.t('bikestatus.label_insale')
      break;
    case 6:
      labelStatus = i18next.t('bikestatus.label_leftworkshop')
      break;
    default :
      labelStatus = i18next.t('bikestatus.label_unknown')
      break;
  }
  return labelStatus;
}

export function GetBikeIdFormat(bikeId) {//BC 545 WS 963
  console.log("bike id format ",bikeId.substring(0,2) + ' ' + bikeId.substring(2,5) + ' ' + bikeId.substring(5,8) + ' ' + bikeId.substring(8,10));
  return bikeId.substring(0,2) + ' ' + bikeId.substring(2,5) + ' ' + bikeId.substring(5,7) + ' ' + bikeId.substring(7,10)
}

export function GetBikeIdPrefix(subdomain) {
  let prefix;
  switch (subdomain) {
    case 'bicycode':
      prefix = 'BC';
      break;
    case 'mfc':
      prefix = 'MF';
      break;
    case 'decathlon':
      prefix = 'DK';
      break;
    default:
      prefix = '';
      break;
  }
  return prefix
}

export function GetSubDomainByFnuci(bikeId) {
  let subdomain;
  switch (bikeId.substring(0,2)) {
    case 'BC':
      subdomain = 'bicycode';
      break;
    case 'MF':
      subdomain = 'mfc';
      break;
    case 'DK':
      subdomain = 'decathlon';
      break;
    default:
      subdomain = 'wallet';
      break;
  }
  return subdomain
}

export function GetSubDomainByOperator(operator) {
  let subdomain;
  switch (operator) {
    case 'BC':
      subdomain = 'bicycode';
      break;
    case 'MF':
      subdomain = 'mfc';
      break;
    case 'DK':
      subdomain = 'decathlon';
      break;
    default:
      subdomain = 'wallet';
      break;
  }
  return subdomain
}

export function DrawOCode(chaine,ctx,x,y,isForScan, color = "#000000", customDiameter = null) {
  //console.log("isForScan",isForScan);
  ctx.beginPath();
  //ctx.moveTo(2800,150);
  var colorSafecode = color;// noir
  const diameterChoosen = customDiameter ? customDiameter : isForScan ? 80 : 40;
  const diameter = diameterChoosen;
  const diamSpot = diameter/19;
  // hexagone
  const rows = 13;
  let column = 7;
  // ratio stepsafecode
  const ratio = 0.5;
  const step = diamSpot + (ratio * diamSpot);
  const radius = diamSpot /2;

  var safecodeX = x;
  var safecodeY = y;
  var centerX = 0;
  var centerY = 0;

  //context.beginPath();
  column = 7; // reinit column
  centerX = safecodeX;
  centerY = safecodeY;
  var index = 0;
  for (var j = 0; j < rows; j++) {
    for (var i = 0; i < column; i++) {
      centerX += step;
      if(index != 0 && index != 6 && index != 57 && index != 69 && index != 120 && index != 126){
        var isSpot = chaine.substring(index, index + 1) == 'X' ? true : false;
        if(isSpot) {
          ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
          ctx.moveTo(centerX,centerY);
        }
      }
      index++;
    }

    if(j < 6){
      column++;
      centerX = safecodeX - (step / 2);
    } else {
      column--;
      centerX = safecodeX + (step / 2);
    }

    // on stocke la valeur du centerX à l'extrême gauche de l'hexagone afin de savoir d'où on repart pour la ligne suivante
    safecodeX = centerX;
    centerY += (step * (Math.sqrt(3) / 2));
  }
  ctx.fillStyle = colorSafecode;
  ctx.fill();
  ctx.closePath();
  return ctx;
}

export function getFont(canvasWidth) {
    var ratio = fontSize / fontBase;   // calc ratio
    var size = canvasWidth * ratio;   // get font size based on current width
    console.log("size",size);
    return (size|0) + 'px sans-serif'; // set font
}
export function DrawOCode2(chaine,ctx,width,height,isForScan) {
  console.log("DrawOCode2DrawOCode2DrawOCode2 isForScan",isForScan);
  ctx.beginPath();
  //ctx.moveTo(2800,150);
  var colorSafecode = "#000000";// noir
  let diamResult;
  if (height>width) {
    console.log("height plus grand que width");
    diamResult = width*1/11;
  } else {
    console.log("width plus grand que height");
    diamResult = height*1/11;
  }
  const diameterChoosen = isForScan ? DIAM_OCODE_SCAN : diamResult;
  const diameter = diameterChoosen;
  const diamSpot = diameter/19;
  // hexagone
  const rows = 13;
  let column = 7;
  // ratio stepsafecode
  const ratio = 0.5;
  const step = diamSpot + (ratio * diamSpot);
  const radius = diamSpot /2;

  var safecodeX = width*RATIO_X_IMG;
  var safecodeY = height*RATIO_Y_IMG;
  console.log("safecodeX",safecodeX);
  console.log("safecodeY",safecodeY);
  var centerX = 0;
  var centerY = 0;

  //context.beginPath();
  column = 7; // reinit column
  centerX = safecodeX;
  centerY = safecodeY;
  var index = 0;
  for (var j = 0; j < rows; j++) {
    for (var i = 0; i < column; i++) {
      centerX += step;
      if(index != 0 && index != 6 && index != 57 && index != 69 && index != 120 && index != 126){
        var isSpot = chaine.substring(index, index + 1) == 'X' ? true : false;
        if(isSpot) {
          ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
          ctx.moveTo(centerX,centerY);
        }
      }
      index++;
    }

    if(j < 6){
      column++;
      centerX = safecodeX - (step / 2);
    } else {
      column--;
      centerX = safecodeX + (step / 2);
    }

    // on stocke la valeur du centerX à l'extrême gauche de l'hexagone afin de savoir d'où on repart pour la ligne suivante
    safecodeX = centerX;
    centerY += (step * (Math.sqrt(3) / 2));
  }
  ctx.fillStyle = colorSafecode;
  ctx.fill();
  ctx.closePath();
  return ctx;
}

export async function DrawText(pdfDoc,firstPage,text) {
  const { width, height } = firstPage.getSize()
  const fontSize = 10
  const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRomanItalic)
  console.log("timesRomanFont",timesRomanFont);
  console.log("firstPage",firstPage);
  /*firstPage.drawText('Facture 100 euros', {
    x: 15 + 20,
    y: height-(10+20/2),
    size: fontSize,
    font: timesRomanFont,
    color: rgb(0, 0.53, 0.71),
  })*/
  firstPage.drawText(text, {
    x: width - POSITION_X_MNT_VECTOR,
    y: height-(POSITION_Y_MNT_VECTOR),// TODO %
    size: fontSize,
    font: timesRomanFont,
    color: rgb(0, 0, 0),
  })
  return firstPage;
}
export function DrawVectorOcode(firstPage,chaine) {
  const ratioPoint = 2.83464566929134;
	const diameter = DIAM_OCODE /* * ratioPoint*/;

	var diamSpot = diameter/19;
	const rows = 13;
	const ratio = 0.5;
	var step = diamSpot + (ratio * diamSpot);
	var radius = diamSpot /2;
	const { width, height } = firstPage.getSize()
	console.log('DOC WIDTH :::: ',width);
	console.log('DOC HEIGHT :::: ',height);
	//var safecodeX = (10/* * ratioPoint*/) + (diameter / 2) - (4 * step);
	//var safecodeY = (height-10-diameter/* * ratioPoint*/) + (diameter / 2) - (6 * step * (Math.sqrt(3) / 2));

  var safecodeX = (width - POSITION_X_OCODE_VECTOR/* * ratioPoint*/) + (diameter / 2) - (4 * step);
	var safecodeY = (height-POSITION_Y_OCODE_VECTOR-diameter/* * ratioPoint*/) + (diameter / 2) - (6 * step * (Math.sqrt(3) / 2));
	// archive pour retour à la ligne
	const safeCodeXLeft = safecodeX;
	const safeCodeYTop = safecodeY;

	var centerX = 0;
	var centerY = 0;
  var centerCuttingX= 0;
  var centerCuttingY= 0;
	var column = 7; // reinit column
	centerX = safecodeX;
	centerY = safecodeY;

	var index = 0;
	  for (var j = 0; j < rows; j++) {
		for (var i = 0; i < column; i++) {
		  centerX += step;
		  if(index != 0 && index != 6 && index != 57 && index != 69 && index != 120 && index != 126){
			var isSpot = chaine.substring(index, index + 1) == 'X' ? true : false;
			if(isSpot) {
			  firstPage.drawCircle({
				  x: centerX,
				  y: centerY,
				  size: radius,
				  color: rgb(0 / 255, 0 / 255, 0 / 255)
			  })
			}
		  }
		  if (index == 63) {
  			centerCuttingX = centerX;
  			centerCuttingY = centerY;
		  }

		  index++;
		}

		if(j < 6){
		  column++;
		  centerX = safecodeX - (step / 2);
		} else {
		  column--;
		  centerX = safecodeX + (step / 2);
		}

		// on stocke la valeur du centerX à l'extrême gauche de l'hexagone afin de savoir d'où on repart pour la ligne suivante
		safecodeX = centerX;
		centerY += (step * (Math.sqrt(3) / 2));
	  }

    console.log("FINISH");
  	return firstPage;
}

var estPaire = function (valBit1,valBit2,valBit3,valBit4) {
	var nbPaire = 0;
	if(valBit1)
		nbPaire++;
	if(valBit2)
		nbPaire++;
	if(valBit3)
		nbPaire++;
	if(valBit4)
		nbPaire++;

	return(nbPaire == 0 || nbPaire == 2 || nbPaire == 4);
}

export function StringifyOCode(ocode) {
  var val = bigInt(ocode).toString(2);
  //console.log("val",val);
	//console.log("val ",val);
	var tableau = new Array(127);

	for (var i = 0; i < mark.length; i++)
				tableau[mark[i]] = true;

	for (var i = 0; i < markAbsent.length; i++)
		tableau[markAbsent[i]] = false;

	// point central à true par défaut
	tableau[63] = true;

	// par défaut les black&white sont à blanc
	for (var i = 0; i < blackWhiteTableau.length; i++)
		tableau[blackWhiteTableau[i]] = false;

	// répartition des bits dans la matrice
	var nbBitTrueCheese1 = 0;
	var nbBitTrueCheese2 = 0;
	var nbBitTrueCheese3 = 0;
	var nbBitTrueCheese4 = 0;
	var nbBitTrueCheese5 = 0;
	var nbBitTrueCheese6 = 0;

	// on complète par des 0 les bits manquants
	var binaryVal = new Array(63);
	for (var i = 0; i < (63 - val.length); i++)
		binaryVal[i] = 0;

	// puis on affecte
	var indice = 0;
	for (var i = (63 - val.length); i < 63; i++)
		binaryVal[i] = val[indice++];

	// inversion des bits pour les parcourir correctement différent de java où un mask est appliqué
	// ici le mask ne fonctionne que sur des entiers 32 bits
	// en java on part du bit de poids faible alors qu'ici on part du bit de poids fort
	// pour conserver l'ordre java, on inverse la valeur binaire
	var binaryValInverse = new Array(63);
	indice = 0;
	for (var i = 62; i >= 0; i--)
		binaryValInverse[indice++] = binaryVal[i];

	// matrices liées au checksum
	var matriceA = new Array(new Array(4),new Array(4));
	var matriceB = new Array(new Array(4),new Array(4));
	var matriceC = new Array(new Array(4),new Array(4));
	var matriceD = new Array(new Array(4),new Array(4));
	matriceA[positionsMatriceColonne[i]] = [];
	matriceB[positionsMatriceColonne[i]] = [];
	matriceC[positionsMatriceColonne[i]] = [];
	matriceD[positionsMatriceColonne[i]] = [];
	for (var i = 0; i < 16; i++) {
		matriceA[positionsMatriceColonne[i]] = [];
		matriceA[positionsMatriceColonne[i]][positionsMatriceLigne[i]] = [];
	}
	for (var i = 16; i < 32; i++) {
		matriceB[positionsMatriceColonne[i]] = [];
		matriceB[positionsMatriceColonne[i]][positionsMatriceLigne[i]] = [];
	}
	for (var i = 32; i < 48; i++) {
		matriceC[positionsMatriceColonne[i]] = [];
		matriceC[positionsMatriceColonne[i]][positionsMatriceLigne[i]] = [];
	}
	for (var i = 48; i < 63; i++) {
		matriceD[positionsMatriceColonne[i]] = [];
		matriceD[positionsMatriceColonne[i]][positionsMatriceLigne[i]] = [];
	}
	for (var i = 0; i < 63; i++) {
		var valBit = binaryValInverse[i] != 0 ? true : false;
		if (i < 16)
			matriceA[positionsMatriceColonne[i]][positionsMatriceLigne[i]] = valBit;
		else if (i < 32)
			matriceB[positionsMatriceColonne[i]][positionsMatriceLigne[i]] = valBit;
		else if (i < 48)
			matriceC[positionsMatriceColonne[i]][positionsMatriceLigne[i]] = valBit;
		else
			matriceD[positionsMatriceColonne[i]][positionsMatriceLigne[i]] = valBit;

		tableau[bit[i]] = valBit;
		// gestion des checksum
		if(valBit) {
			var cheese = cheeses[i];
			switch (cheese) {
			case 1:
				nbBitTrueCheese1++;
				break;
			case 2:
				nbBitTrueCheese2++;
				break;
			case 3:
				nbBitTrueCheese3++;
				break;
			case 4:
				nbBitTrueCheese4++;
				break;
			case 5:
				nbBitTrueCheese5++;
				break;
			case 6:
				nbBitTrueCheese6++;
				break;
			}
		}
	}

	/*console.log("nbBitTrueCheese1 ",nbBitTrueCheese1);
	console.log("nbBitTrueCheese2 ",nbBitTrueCheese2);
	console.log("nbBitTrueCheese3 ",nbBitTrueCheese3);
	console.log("nbBitTrueCheese4 ",nbBitTrueCheese4);
	console.log("nbBitTrueCheese5 ",nbBitTrueCheese5);
	console.log("nbBitTrueCheese6 ",nbBitTrueCheese6);*/
	var indice = 0;
	// parcours des lignes et colonnes matriceA
	for (var j = 0; j < matriceA.length; j++) {
		var valBit1 = matriceA[j][0];
		var valBit2 = matriceA[j][1];
		var valBit3 = matriceA[j][2];
		var valBit4 = matriceA[j][3];
		var paire = estPaire(valBit1,valBit2,valBit3,valBit4);
		tableau[bitParite[indice]] = paire;
		if(paire){
			var cheese = cheeseBitParite[indice];
			switch (cheese) {
			case 1:
				nbBitTrueCheese1++;
				break;
			case 2:
				nbBitTrueCheese2++;
				break;
			case 3:
				nbBitTrueCheese3++;
				break;
			case 4:
				nbBitTrueCheese4++;
				break;
			case 5:
				nbBitTrueCheese5++;
				break;
			case 6:
				nbBitTrueCheese6++;
				break;
			}
		}
		indice++;
	}

	for (var j = 0; j < matriceA.length; j++) {
		var valBit1 = matriceA[0][j];
		var valBit2 = matriceA[1][j];
		var valBit3 = matriceA[2][j];
		var valBit4 = matriceA[3][j];
		var paire = estPaire(valBit1,valBit2,valBit3,valBit4);
		tableau[bitParite[indice]] = paire;
		if(paire){
			var cheese = cheeseBitParite[indice];
			switch (cheese) {
			case 1:
				nbBitTrueCheese1++;
				break;
			case 2:
				nbBitTrueCheese2++;
				break;
			case 3:
				nbBitTrueCheese3++;
				break;
			case 4:
				nbBitTrueCheese4++;
				break;
			case 5:
				nbBitTrueCheese5++;
				break;
			case 6:
				nbBitTrueCheese6++;
				break;
			}
		}
		indice++;
	}

	// matriceB
	for (var j = 0; j < matriceB.length; j++) {
		var valBit1 = matriceB[j][0];
		var valBit2 = matriceB[j][1];
		var valBit3 = matriceB[j][2];
		var valBit4 = matriceB[j][3];
		var paire = estPaire(valBit1,valBit2,valBit3,valBit4);
		tableau[bitParite[indice]] = paire;
		if(paire) {
			var cheese = cheeseBitParite[indice];
			switch (cheese) {
			case 1:
				nbBitTrueCheese1++;
				break;
			case 2:
				nbBitTrueCheese2++;
				break;
			case 3:
				nbBitTrueCheese3++;
				break;
			case 4:
				nbBitTrueCheese4++;
				break;
			case 5:
				nbBitTrueCheese5++;
				break;
			case 6:
				nbBitTrueCheese6++;
				break;
			}
		}
		indice++;
	}

	for (var j = 0; j < matriceB.length; j++) {
		var valBit1 = matriceB[0][j];
		var valBit2 = matriceB[1][j];
		var valBit3 = matriceB[2][j];
		var valBit4 = matriceB[3][j];
		var paire = estPaire(valBit1,valBit2,valBit3,valBit4);
		tableau[bitParite[indice]] = paire;
		if(paire){
			var cheese = cheeseBitParite[indice];
			switch (cheese) {
			case 1:
				nbBitTrueCheese1++;
				break;
			case 2:
				nbBitTrueCheese2++;
				break;
			case 3:
				nbBitTrueCheese3++;
				break;
			case 4:
				nbBitTrueCheese4++;
				break;
			case 5:
				nbBitTrueCheese5++;
				break;
			case 6:
				nbBitTrueCheese6++;
				break;
			}
		}
		indice++;
	}

	// matriceC
	for (var j = 0; j < matriceC.length; j++) {
		var valBit1 = matriceC[j][0];
		var valBit2 = matriceC[j][1];
		var valBit3 = matriceC[j][2];
		var valBit4 = matriceC[j][3];
		var paire = estPaire(valBit1,valBit2,valBit3,valBit4);
		tableau[bitParite[indice]] = paire;
		if(paire){
			var cheese = cheeseBitParite[indice];
			switch (cheese) {
			case 1:
				nbBitTrueCheese1++;
				break;
			case 2:
				nbBitTrueCheese2++;
				break;
			case 3:
				nbBitTrueCheese3++;
				break;
			case 4:
				nbBitTrueCheese4++;
				break;
			case 5:
				nbBitTrueCheese5++;
				break;
			case 6:
				nbBitTrueCheese6++;
				break;
			}
		}
		indice++;
	}

	for (var j = 0; j < matriceC.length; j++) {
		var valBit1 = matriceC[0][j];
		var valBit2 = matriceC[1][j];
		var valBit3 = matriceC[2][j];
		var valBit4 = matriceC[3][j];
		var paire = estPaire(valBit1,valBit2,valBit3,valBit4);
		tableau[bitParite[indice]] = paire;
		if(paire){
			var cheese = cheeseBitParite[indice];
			switch (cheese) {
			case 1:
				nbBitTrueCheese1++;
				break;
			case 2:
				nbBitTrueCheese2++;
				break;
			case 3:
				nbBitTrueCheese3++;
				break;
			case 4:
				nbBitTrueCheese4++;
				break;
			case 5:
				nbBitTrueCheese5++;
				break;
			case 6:
				nbBitTrueCheese6++;
				break;
			}
		}
		indice++;
	}

	// matriceD
	for (var j = 0; j < matriceD.length; j++) {
		var valBit1 = matriceD[j][0];
		var valBit2 = matriceD[j][1];
		var valBit3 = matriceD[j][2];
		var valBit4 = matriceD[j][3];
		var paire = estPaire(valBit1,valBit2,valBit3,valBit4);
		tableau[bitParite[indice]] = paire;
		if(paire){
			var cheese = cheeseBitParite[indice];
			switch (cheese) {
			case 1:
				nbBitTrueCheese1++;
				break;
			case 2:
				nbBitTrueCheese2++;
				break;
			case 3:
				nbBitTrueCheese3++;
				break;
			case 4:
				nbBitTrueCheese4++;
				break;
			case 5:
				nbBitTrueCheese5++;
				break;
			case 6:
				nbBitTrueCheese6++;
				break;
			}
		}
		indice++;
	}

	for (var j = 0; j < matriceD.length; j++) {
		var valBit1 = matriceD[0][j];
		var valBit2 = matriceD[1][j];
		var valBit3 = matriceD[2][j];
		var valBit4 = matriceD[3][j];
		var paire = estPaire(valBit1,valBit2,valBit3,valBit4);
		tableau[bitParite[indice]] = paire;
		if(paire){
			var cheese = cheeseBitParite[indice];
			switch (cheese) {
			case 1:
				nbBitTrueCheese1++;
				break;
			case 2:
				nbBitTrueCheese2++;
				break;
			case 3:
				nbBitTrueCheese3++;
				break;
			case 4:
				nbBitTrueCheese4++;
				break;
			case 5:
				nbBitTrueCheese5++;
				break;
			case 6:
				nbBitTrueCheese6++;
				break;
			}
		}
		indice++;
	}

	// répartition des checksum dans le tableau en fonction des bits dans les différentes parts de fromage
	for (var i = 0; i < 6; i++) {
		switch (i) {
		case 0:
			// si on a plus d'absents que de présents
			if(((cheese1Tableau.length + cheese1PariteTableau.length) - nbBitTrueCheese1) > nbBitTrueCheese1){
				// on inverse B&W
				tableau[blackWhiteTableau[i]] = !tableau[blackWhiteTableau[i]];
				nbBitTrueCheese1 = (cheese1Tableau.length  + cheese1PariteTableau.length) - nbBitTrueCheese1;
				// on inverse les bits de la part de fromage
				for (var j = 0; j < cheese1Tableau.length; j++)
					tableau[cheese1Tableau[j]] = !tableau[cheese1Tableau[j]];
				for (var j = 0; j < cheese1PariteTableau.length; j++)
					tableau[cheese1PariteTableau[j]] = !tableau[cheese1PariteTableau[j]];

				nbBitTrueCheese1++; // on ajoute le noir & blanc au nombre de bit de la part 1
			}
			break;
		case 1:
			if(((cheese2Tableau.length + cheese2PariteTableau.length) - nbBitTrueCheese2) > nbBitTrueCheese2){
				tableau[blackWhiteTableau[i]] = !tableau[blackWhiteTableau[i]];
				nbBitTrueCheese2 = (cheese2Tableau.length  + cheese2PariteTableau.length) - nbBitTrueCheese2;
				for (var j = 0; j < cheese2Tableau.length; j++)
					tableau[cheese2Tableau[j]] = !tableau[cheese2Tableau[j]]
				for (var j = 0; j < cheese2PariteTableau.length; j++)
					tableau[cheese2PariteTableau[j]] = !tableau[cheese2PariteTableau[j]];

				nbBitTrueCheese2++;
			}
			break;
		case 2:
			if(((cheese3Tableau.length + cheese3PariteTableau.length) - nbBitTrueCheese3) > nbBitTrueCheese3){
				tableau[blackWhiteTableau[i]] = !tableau[blackWhiteTableau[i]];
				nbBitTrueCheese3 = (cheese3Tableau.length  + cheese3PariteTableau.length) - nbBitTrueCheese3;
				for (var j = 0; j < cheese3Tableau.length; j++)
					tableau[cheese3Tableau[j]] = !tableau[cheese3Tableau[j]];
				for (var j = 0; j < cheese3PariteTableau.length; j++)
					tableau[cheese3PariteTableau[j]] = !tableau[cheese3PariteTableau[j]];

				nbBitTrueCheese3++;
			}
			break;
		case 3:
			if(((cheese4Tableau.length + cheese4PariteTableau.length) - nbBitTrueCheese4) > nbBitTrueCheese4){
				tableau[blackWhiteTableau[i]] = !tableau[blackWhiteTableau[i]];
				nbBitTrueCheese4 = (cheese4Tableau.length  + cheese4PariteTableau.length) - nbBitTrueCheese4;
				for (var j = 0; j < cheese4Tableau.length; j++)
					tableau[cheese4Tableau[j]] = !tableau[cheese4Tableau[j]];
				for (var j = 0; j < cheese4PariteTableau.length; j++)
					tableau[cheese4PariteTableau[j]] = !tableau[cheese4PariteTableau[j]];

				nbBitTrueCheese4++;
			}
			break;
		case 4:
			if(((cheese5Tableau.length + cheese5PariteTableau.length)- nbBitTrueCheese5) > nbBitTrueCheese5){
				tableau[blackWhiteTableau[i]] = !tableau[blackWhiteTableau[i]];
				nbBitTrueCheese5 = (cheese5Tableau.length  + cheese5PariteTableau.length) - nbBitTrueCheese5;
				for (var j = 0; j < cheese5Tableau.length; j++)
					tableau[cheese5Tableau[j]] = !tableau[cheese5Tableau[j]];
				for (var j = 0; j < cheese5PariteTableau.length; j++)
					tableau[cheese5PariteTableau[j]] = !tableau[cheese5PariteTableau[j]];

				nbBitTrueCheese5++;
			}
			break;
		case 5:
			if(((cheese6Tableau.length + cheese6PariteTableau.length) - nbBitTrueCheese6) > nbBitTrueCheese6){
				tableau[blackWhiteTableau[i]] = !tableau[blackWhiteTableau[i]];
				nbBitTrueCheese6 = (cheese6Tableau.length  + cheese6PariteTableau.length) - nbBitTrueCheese6;
				for (var j = 0; j < cheese6Tableau.length; j++)
					tableau[cheese6Tableau[j]] = !tableau[cheese6Tableau[j]];
				for (var j = 0; j < cheese6PariteTableau.length; j++)
					tableau[cheese6PariteTableau[j]] = !tableau[cheese6PariteTableau[j]];

				nbBitTrueCheese6++;
			}
			break;
		}
	}

	// on ajoute les présents
	nbBitTrueCheese1+=nbPresentCheese1;
	nbBitTrueCheese2+=nbPresentCheese2;
	nbBitTrueCheese3+=nbPresentCheese3;
	nbBitTrueCheese4+=nbPresentCheese4;
	nbBitTrueCheese5+=nbPresentCheese5;
	nbBitTrueCheese6+=nbPresentCheese6;

	// on compte le nombre de bit à true
	var totalBitTrue = nbBitTrueCheese1 + nbBitTrueCheese2 + nbBitTrueCheese3 + nbBitTrueCheese4 + nbBitTrueCheese5 +nbBitTrueCheese6;
	var mask = 1;
	for (var i = 0; i < checkSumValueTableau.length - 1; i++, mask <<= 1)
		checkSumValueTableau[i] = (totalBitTrue & mask) != 0 ? true : false;

	// le dernier checksum est l'inverse du premier
	checkSumValueTableau[4] = !checkSumValueTableau[0];

	for (var i = 0; i < checkSumTableau.length; i++)// on ne se serre pas du dernier checksum
		tableau[checkSumTableau[i]] = checkSumValueTableau[i];

	var chaine = "";
	for (var i = 0; i < tableau.length; i++)
		chaine += tableau[i] ? 'X' : '.';

	//console.log(chaine);
	return chaine;
}
export function Capitalize( str ) {
  var pieces = str.toLowerCase().split(" ");
  for ( var i = 0; i < pieces.length; i++ )
  {
      var j = pieces[i].charAt(0).toUpperCase();
      pieces[i] = j + pieces[i].substr(1);
  }
  pieces = pieces.join(" ");

  pieces = pieces.split("-");
  for ( i = 0; i < pieces.length; i++ )
  {
      var j = pieces[i].charAt(0).toUpperCase();
      pieces[i] = j + pieces[i].substr(1);
  }
  pieces = pieces.join("-");

  pieces = pieces.split("'");
  for ( i = 0; i < pieces.length; i++ )
  {
      var j = pieces[i].charAt(0).toUpperCase();
      pieces[i] = j + pieces[i].substr(1);
  }
  return pieces.join("'");
}
export function SortBy(tab,prop) {
  var indexed = _.indexBy( tab, prop );
  var tmp = _.keys(indexed);
  tmp.sort(Intl.Collator().compare);  // sert a trier les string en prenant en compte les accents
  var res = [];
  for(var t=0;t<tmp.length;t++) {
    res.push(indexed[tmp[t]]);
  }
  return res;
}

export function Clone(o) {
  return JSON.parse(JSON.stringify(o));
}

export function IsJsonString(str) {
  try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

/*export function GetJSON(url,callback) {
  var request = new XMLHttpRequest();
  request.open('GET', url, true);
  request.onload = function() {
    if (request.status >= 200 && request.status < 400) {
      var data = JSON.parse(request.responseText);
      callback(data);
    } else {
      callback({error:'unknown'});
    }
  };
  request.onerror = function() {
    callback({error:'network error'});
  };
  request.send();
}*/

export function exportXLSX(arrayOfArrays,titleSheet,titleFile) {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(arrayOfArrays);
  XLSX.utils.book_append_sheet(wb, ws, titleSheet);
  XLSX.writeFile(wb, titleFile);
}

/*export function exportToXML(obj,addRoot) {
  var xml = '';
  if (addRoot == true) {
    xml += '<?xml version="1.0" encoding="UTF-8"?>'
    xml += '<root>';
  }

  for (var prop in obj) {
    xml += obj[prop] instanceof Array ? '' : "<" + prop + ">";
    if (obj[prop] instanceof Array) {
      for (var array in obj[prop]) {
        xml += "<" + prop + ">";
        xml += exportToXML(new Object(obj[prop][array]));
        xml += "</" + prop + ">";
      }
    } else if (typeof obj[prop] == "object") {
      xml += exportToXML(new Object(obj[prop]));
    } else {
      xml += obj[prop];
    }
    xml += obj[prop] instanceof Array ? '' : "</" + prop + ">";
  }
  if (addRoot == true) {
    xml += '</root>';
  }
  var xml = xml.replace(/<\/?[0-9]{1,}>/g, '');
  return xml
}*/

/*export function GetMD(url,callback) {
  var request = new XMLHttpRequest();
  request.open('GET', url, true);
  request.onload = function() {
    if (request.status >= 200 && request.status < 400) {
      callback(request.responseText);
    } else {
      callback({error:'unknown'});
    }
  };
  request.onerror = function() {
    callback({error:'network error'});
  };
  request.send();
}*/

export function StrDef(s) {
  return ( typeof(s) != 'undefined' && s !== null && s !== '' );
}

export function Defined(obj) {
 return typeof(obj) != 'undefined' && obj != null;
}

export function isInt(str) {
  return /^[0-9]*$/.test(str);
}

export function isPhoneNumber(str) {
  return /^0[1-9]{1}(([0-9]{2}){4})|((\s[0-9]{2}){4})|((-[0-9]{2}){4})$/.test(str);

}
/*export function Phone(s) {
  var phoneNumber = s;

  if( StrDef(phoneNumber) ) {
    var formatted = phoneNumber.replace(/(\d{1,2})?(\d{1,2})?(\d{1,2})?(\d{1,2})?(\d{1,2})?/, function(_, p1, p2, p3, p4,p5){
      let output = ""
      if (p1) output = `${p1}`;
      if (p2) output += ` ${p2}`;
      if (p3) output += ` ${p3}`;
      if (p4) output += ` ${p4}`;
      if (p5) output += ` ${p5}`;
      return output;
    });
  }
  return formatted;
}*/

/*export function file_hash(filename) {
  return new Promise(function(resolve, reject){
    let hash = sha3_256.create()
    var reader = new FileReader();
    reader.addEventListener("loadend", function() {
      console.log("reader.result",reader.result);
      hash.update(reader.result)

      let buf = Buffer.from(hash.hex(), 'hex')
      resolve(multihash.toB58String(multihash.encode(buf, 'sha3-256')))
      return;
    });
    reader.readAsArrayBuffer(filename);
    return;
  })
}*/

/*export function removeAccents(str) {
  let accents = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
  let accentsOut = "AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
  str = str.split('');
  str.forEach((letter, index) => {
    let i = accents.indexOf(letter);
    if (i !== -1) {
      str[index] = accentsOut[i];
    }
  })
  return str.join('');
}*/

export function ValidateEmail(email) {
  if( !StrDef(email) ) return false;
  var accents = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
  var str = email.split('');
  for(var i=0;i<str.length;i++) {
    if( accents.indexOf(str[i]) != -1 ) return false;
  }
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

//========================================================= Currency ===========
/*export function price(n) {
  const formatter = new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  })

  return formatter.format(n);
}*/

/*export function getLocale(length) {
  var locale = '';
  if (window.navigator.languages) {
      locale = window.navigator.languages[0];
  } else {
      locale = window.navigator.userLanguage || window.navigator.language;
  }
  var tiret = locale.indexOf('-');
  if( tiret != -1 ) {
    var tmp = locale.split('-');
    locale = tmp[0].toLowerCase()+'-'+tmp[1].toUpperCase();
  }
  else {
    locale = locale.toLowerCase()+'-'+locale.toUpperCase();
  }
  if( length === 2 ) locale = locale.substr(0,2).toUpperCase();
  return locale;
}*/

// ======================================================== PERMISSION =========
export function HasPermission(scope,need) {
  var tmp = localStorage.getItem(scope);
  try {
    if( StrDef(tmp) ) tmp = JSON.parse(tmp);
  }
  catch(e) {
    if( StrDef(tmp) ) tmp = [tmp];
  }
  var res = _.intersection(tmp,need);
  if( res.length > 0 ) return true;
  else return false;
}

// ======================================================== MOMENT =========
/*export function DateDisplay(date) {
  return moment(date).format('DD MMMM YYYY');
}
export function DateDisplayS(date) {
  if( StrDef(date) )
    return moment(date).format('DD/MM/YY');
  else
    return '';
}*/


export class HourRange extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  Change(what,val) {
    var state = {};
    state[what] = val.target.value;
    this.setState(state);
  }

  Blur() {
    var el = (str) => document.querySelector('#'+str);
    var tab = [];
    for(var i=0;i<4;i++) {
      tab.push( el(this.props.id+''+i).value );
      if( el(this.props.id+''+i).value == '' ) el(this.props.id+''+i).value='--:--';
    }

    if( typeof(this.props.onChange) == 'function' ) {
      this.props.onChange( tab );
    }
  }

  Set(data) {
    var el = (str) => document.querySelector('#'+str);
    for(var i=0;i<data.length;i++) {
      el(this.props.id+''+i).value = data[i];
    }
  }

  render() {
    return(
      <Row>
        <Col md="6" xs="12">
          <input id={this.props.id+'0'} style={{width:80}} disabled={this.props.disabled} type="time" min="00:00" max="23:59" defaultValue={this.props.default[0]} onBlur={this.Blur.bind(this)}/>-
          <input id={this.props.id+'1'} style={{width:80}} disabled={this.props.disabled} type="time" min="00:00" max="23:59" defaultValue={this.props.default[1]} onBlur={this.Blur.bind(this)}/>
        </Col>
        <Col md="6" xs="12">
          <input id={this.props.id+'2'} style={{width:80}} disabled={this.props.disabled} type="time" min="00:00" max="23:59" defaultValue={this.props.default[2]} onBlur={this.Blur.bind(this)}/>-
          <input id={this.props.id+'3'} style={{width:80}} disabled={this.props.disabled} type="time" min="00:00" max="23:59" defaultValue={this.props.default[3]} onBlur={this.Blur.bind(this)}/>
        </Col>
      </Row>
    );
  }
};

export function GetAtelierVeFromEntity(e) {
  var activityGender = 0;
  var isAtelier = true;
  var isVE = false;

  if( !StrDef(e.entity_data.activity) ) { // cas des anciens inscrits : par defaut c'est atelier seulement
    isAtelier = true;
    isVE = false;
  }
  else {
    if( StrDef(e.entity_data.activity.done) ) {
      activityGender = e.entity_data.activity.done;
    }
    if( StrDef(e.entity_data.activity.request)
        && e.entity_data.activity.request.gender != e.entity_data.activity.done
        && !StrDef(e.entity_data.activity.request.abort)
      ) {
      activityGender = e.entity_data.activity.request.gender;
    }
    switch (activityGender) {
      case 0 : isAtelier = true;isVE = false; break;
      case 1 : isAtelier = false;isVE = true; break;
      case 2 : isAtelier = true;isVE = true; break;
    }
  }
  return {isAtelier:isAtelier,isVE:isVE};
}

export function GetAtelierVeFromEntityBORozo(e) {     // on ne tient pas compte du cas ou c'est refuse fub, pour qu'ils puissent revenir en arriere
  var activityGender = 0;
  var isAtelier = true;
  var isVE = false;

  if( !StrDef(e.entity_data.activity) ) { // cas des anciens inscrits : par defaut c'est atelier seulement
    isAtelier = true;
    isVE = false;
  }
  else {
    if( StrDef(e.entity_data.activity.done) ) {
      activityGender = e.entity_data.activity.done;
    }
    if( StrDef(e.entity_data.activity.request)
        && e.entity_data.activity.request.gender != e.entity_data.activity.done
      /*&& !StrDef(e.entity_data.activity.request.abort)*/
      ) {
      activityGender = e.entity_data.activity.request.gender;
    }
    switch (activityGender) {
      case 0 : isAtelier = true;isVE = false; break;
      case 1 : isAtelier = false;isVE = true; break;
      case 2 : isAtelier = true;isVE = true; break;
    }
  }
  return {isAtelier:isAtelier,isVE:isVE};
}

export function GetActivityFromEntity(e) {
  var activityGender = 0;
  var isAtelier = true;
  var isVE = false;

  if( !StrDef(e.entity_data.activity) ) { // cas des anciens inscrits : par defaut c'est atelier seulement
    isAtelier = true;
    isVE = false;
  }
  else {
    if( StrDef(e.entity_data.activity.done) ) {
      activityGender = e.entity_data.activity.done;
    }
    switch (activityGender) {
      case 0 : isAtelier = true;isVE = false; break;
      case 1 : isAtelier = false;isVE = true; break;
      case 2 : isAtelier = true;isVE = true; break;
    }
  }
  return {isAtelier:isAtelier,isVE:isVE};
}

export function GetActivityFromSession(what) {
  if( !StrDef(what) ) {
    return {
      isAtelier:localStorage.getItem('isAtelier')=='1',
      isVE:localStorage.getItem('isVE')=='1'
    }
  }
  else {
    return localStorage.getItem(what)=='1';
  }
}


export function GetStatusVEFromEntityData(data) {
  var sentToFUB = '', value='';
  if( StrDef(data.activity) ) {
    if( StrDef(data.activity.request) ) {
      if( data.activity.request.gender == 1 || data.activity.request.gender == 2 ) {
        if( StrDef(data.activity.request.fub) ) {
          sentToFUB = i18next.t("workflow.sent_timestamp", { date: data.activity.request.fub.substring(0,5) });
          value = 've_sent';
          if( StrDef(data.activity.request.accept) ) {  // accept : la fub a repondu oui
            sentToFUB = i18next.t("workflow.validated_timestamp", { date : data.activity.request.accept.substring(0,5) });
            value = 've_done';
          }
          if( StrDef(data.activity.request.abort) ) {  // abort : la fub a repondu oui
            sentToFUB = i18next.t("workflow.refused_timestamp", { date : data.activity.request.abort.substring(0,5) });
            value = 've_reject';
          }
        }
        else {
          sentToFUB = i18next.t("workflow.waiting");
          value = 've_wait';
          if( StrDef(data.activity.request.accept) ) {  // accept : la fub a repondu oui
            sentToFUB = `${i18next.t("workflow.validated_date")} ${data.activity.request.accept.substring(0,5)}`;
            value = 've_done';
          }
          if( StrDef(data.activity.request.abort) ) {  // abort : la fub a repondu oui
            sentToFUB = `${i18next.t("workflow.refused_date")} ${data.activity.request.abort.substring(0,5)}`;
            value = 've_reject';
          }
        }
      }
    }
  }
  return {label:sentToFUB,value:value};
}

export function GetRepairHisto(data) {
  var res = {};
  for(var i=0;i<data.length;i++) {
    res[data[i].repair_data.operation_code] = data[i].repair_data.operation_code;
  }
  var tab = [];
  for(var k in res) {
    tab.push(k);
  }
  return tab;
}

// ope : tableau de code operation , par exemple ['FAC','FACVE']
export function RepairHistoOpe(data,ope) {
  var res = {};
  for(var i=0;i<data.length;i++) {
    if( ope.indexOf(data[i].repair_data.operation_code) != -1 ) {
      return data[i];
    }
  }
  return null;
}

export function RepairHistoNeed(data) {
  var res = {}, ve = false;
  for(var i=0;i<data.length;i++) {
    ve = data[i].gender == 1;
    res[data[i].repair_data.operation_code] = data[i].repair_data.operation_code;
  }
  var tab = [], need = [];
  for(var k in res) {
    tab.push(k);
  }
  if( tab.indexOf('PHO') == -1 && !ve ) need.push('PHO');
  if( tab.indexOf('VAL') == -1 ) need.push('VAL');
  if( tab.indexOf('FAC') == -1 && !ve ) need.push('FAC');
  if( tab.indexOf('FACVE') == -1 && ve ) need.push('FAC');
  return need;
}

export function RepairHistoHasFac(data) {
  var res = {};
  for(var i=0;i<data.length;i++) {
    res[data[i].repair_data.operation_code] = data[i].repair_data.operation_code;
  }
  var tab = [];
  for(var k in res) {
    tab.push(k);
  }
  var r = tab.indexOf('FAC');
  var ve = tab.indexOf('FACVE');
  if( r == -1 && ve == -1 ) {
    return false;
  }
  else {
    return true;
  }
}
