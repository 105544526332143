import React from "react"

const LogoBicycode = ({ className, style }) => {
return (
	<svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" width="300" height="50" viewBox="0 0 300 50">
		<title>Logo BicyCode</title>
		<path fill="currentColor" d="M73.4 27.3c0 5.3-3.3 9.8-10.6 9.8H50.4V17.8h11c4.7 0 6.3-2.4 6.3-5.3s-1.3-5.3-6-5.3H50.3V3.8H62c6.7 0 9.5 3.9 9.5 8.7 0 2.7-1.3 5.1-3.5 6.6 3.4 1.6 5.4 4.6 5.4 8.2zm-3.7 0c0-3.4-2.2-6.4-7.1-6.4h-8.7v12.7h8.8c4.8.1 7-2.8 7-6.3zM77.7 7.9c0-1.1.8-2 1.9-2 1.1 0 1.9.9 1.9 2 0 1-.8 1.9-1.9 1.9-1 0-1.9-.9-1.9-1.9zm.2 6.3h3.5v22.9h-3.5V14.2zM103.1 30.7l3 1.5c-1.9 3.2-5.3 5.3-9.4 5.3-6.6 0-11-5.2-11-11.8 0-6.6 4.5-11.9 11-11.9 4.2 0 7.6 2.1 9.5 5.4l-3 1.4c-1.4-2.1-3.6-3.5-6.5-3.5-4.7 0-7.5 3.8-7.5 8.5s2.9 8.4 7.6 8.4c2.7.1 4.9-1.2 6.3-3.3zM128.6 14.2l-8 22.2c-1.4 3.7-2.5 6.3-3.9 8.1-1.8 2.4-4.4 2.8-7.1 2.1l.6-3.4c3.2.6 4.1-.3 6.4-6.2l-8.4-22.9h3.7l6.5 18.1 6.5-18.1h3.7zM158.1 28.4l3 1.5c-2.9 4.6-7.9 7.6-13.8 7.6-9.4 0-16.4-7.6-16.4-17s7-16.9 16.4-16.9c5.9 0 10.9 3 13.8 7.6l-3 1.5C155.8 9.2 152 7 147.4 7c-7.5 0-12.9 6.1-12.9 13.6s5.4 13.6 12.9 13.6c4.5-.1 8.4-2.4 10.7-5.8zM147.2 18c1.5 0 2.6 1.1 2.6 2.6 0 1.4-1.1 2.6-2.6 2.6-1.4 0-2.6-1.2-2.6-2.6 0-1.5 1.2-2.6 2.6-2.6zM186.4 25.7c0 6.6-4.6 11.8-11.2 11.8-6.5 0-11.1-5.2-11.1-11.8 0-6.6 4.5-11.9 11.1-11.9 6.6.1 11.2 5.3 11.2 11.9zm-3.4 0c0-4.7-3-8.5-7.7-8.5s-7.6 3.8-7.6 8.5 2.9 8.4 7.6 8.4c4.7 0 7.7-3.7 7.7-8.4zM211.3 3.8v33.3h-3.4V34c-1.7 2.1-4.3 3.4-7.6 3.4-6.6 0-10.8-5.2-10.8-11.8 0-6.6 4.2-11.9 10.8-11.9 3.3 0 5.9 1.3 7.6 3.5V3.8h3.4zm-3.6 21.9c0-4.7-2.7-8.5-7.4-8.5S193 21 193 25.7s2.6 8.4 7.3 8.4c4.7 0 7.4-3.7 7.4-8.4zM236.6 25.7c0 .4-.1.8-.1 1.2h-17.6c.5 4.1 3.3 7.2 7.6 7.2 2.9 0 5-1.3 6.3-3.4l3 1.5c-1.9 3.2-5.1 5.3-9.3 5.3-6.5 0-11.2-5.2-11.2-11.8 0-6.6 4.4-11.9 10.9-11.9 6.6.1 10.4 5.3 10.4 11.9zM219 23.5h14.1c-.7-3.6-2.9-6.3-6.9-6.3-3.9 0-6.5 2.7-7.2 6.3z"/>
        <path fill="currentColor" d="M242 11.1V5.4h2.5c.5 0 .9.1 1.1.2.3.1.5.3.6.5.1.2.2.5.2.8 0 .4-.1.7-.4 1-.2.3-.6.5-1.2.5.2.1.4.2.5.3.2.2.4.5.6.8l1 1.5h-.9l-.7-1.2c-.2-.4-.4-.6-.6-.8-.1-.2-.3-.3-.4-.4-.1-.1-.2-.1-.4-.1h-1.3V11l-.6.1zm.7-3.2h1.6c.4 0 .6 0 .8-.1s.3-.2.4-.3c.1-.2.1-.3.1-.5 0-.3-.1-.5-.3-.7-.2-.2-.5-.3-.9-.3h-1.8l.1 1.9z"/>
        <path fill="currentColor" d="M244.2 13.8c-3 0-5.4-2.4-5.4-5.4s2.4-5.4 5.4-5.4 5.4 2.4 5.4 5.4c0 3-2.5 5.4-5.4 5.4zm0-10.2c-2.6 0-4.8 2.1-4.8 4.7s2.1 4.8 4.8 4.8 4.7-2.1 4.7-4.8c0-2.5-2.1-4.7-4.7-4.7z"/>
      </svg>
)}

export default LogoBicycode
