import React from "react"
import { default as ReactDatePicker, registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import fr from 'date-fns/locale/fr'

registerLocale('fr', fr)

const FormDate = ({
    value,
    name,
    formError,
    what,
    filterDate = null,
    error,
    label,
    handleInputChange,
    disabled,
    readonly = false,
    required = false,
    showYearDropdown = true,
    type,
    style,
    className,
    classNameLabel = "",
    classNameInput = "",
    customRules,
    showErrors,
    // Handler
    onChange,
    onValidate
}) => {
    const [selectedDate, setSelectedDate] = React.useState(new Date())
    const [errors] = React.useState([])

    React.useEffect(() => {
        if(value.length > 0) {
            const dateArray = value.split("-")
            const defaultDate = new Date(dateArray[0], (dateArray[1] - 1), dateArray[2])
            setSelectedDate(defaultDate)
        }

    }, []) // eslint-disable-line

    return (
        <>
            <div className={"c-form-group " + (className)} style={style}>
                <label className={"c-label" + classNameLabel} htmlFor="code">{label} {required && "*"}</label>
                <ReactDatePicker
                    selected={selectedDate}
                    locale="fr"
                    dateFormat="dd/MM/y"
                    disabled={disabled}
                    showYearDropdown={showYearDropdown}
                    closeOnScroll={true}
                    filterDate={filterDate}
                    className={"c-datepicker " + (classNameInput ? classNameInput : "")}
                    onChange={(e) => setSelectedDate(e) || handleInputChange(e, type="date")}
                    // Prevent keyboard opening on mobile
                    // https://github.com/Hacker0x01/react-datepicker/issues/1640
                    onFocus={(e) => e.target.readOnly = true}
                />

                {/* Error messages */}
                {showErrors && errors && errors.map((error, index) => {
                    return <p key={index} className="u-fs-xs u-danger">{error.message}</p>
                })}
            </div>
        </>
    )
}

export default FormDate;
