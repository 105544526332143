import React from "react"
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
  } from "reactstrap"
import i18next from "i18next"
import { StrDef } from "assets/lib/utils"
import FormInput from 'components/Forms/FormInput';

const FNUCI_CODE_LENGTH = 16

const defaultFormFields = {
    bikeCode : '',
    password : '',
}

const defaultFormErrors = {
    bikeCode  : false,
    password  : false,
}

const defaultFormEvents = {
    ready: true
}

const TableImportModal = ({ importModaleConfig,  setImportModaleConfig, defaultImportModaleConfig}) => {
    const [formFields, setFormFields] = React.useState(defaultFormFields)
    const [formErrors, setFormErrors] = React.useState(defaultFormErrors)
    const [formEvents, setFormEvents] = React.useState(defaultFormEvents)
    const [loading, setLoading] = React.useState(false)

    const handleInputChangeLogin = (event) => {
        const target = event.target
        const val = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        setFormFields({
            ...formFields,
            [name]: val
        })
        // error management should be automatized
        // see other project
        setFormErrors({
            ...formErrors,
            [name]: false
        })
    }

    const formValidationLogin = () => {
        // error management should be automatized
        // see other project
        let formFieldsTemp = {...formFields}
        let formErrorsTemp = {...formErrors}
        let inputValidation = Object.keys(formFieldsTemp).map(itemName => {
            switch (itemName) {
                case 'bikeCode':
                    const bikeCodeError = !StrDef(formFieldsTemp.bikeCode) || formFieldsTemp.bikeCode.length > FNUCI_CODE_LENGTH
                    if (bikeCodeError) formErrorsTemp.bikeCode = bikeCodeError
                    return !bikeCodeError

                case 'password':
                    const passwordCodeError = !StrDef(formFieldsTemp.password)
                    if (passwordCodeError) formErrorsTemp.password = passwordCodeError
                    return !passwordCodeError

                default:
                    return true
            }
        })
        setFormErrors(formErrorsTemp)
        return inputValidation.reduce((acc, next) => acc && next)
    }

    const handleSubmit = async (event) => {

        event.preventDefault()
        // Validate inputs
        const formReady = formValidationLogin()

        if (formReady) {
            setLoading(true)
            // Inform the state that the component is unavailable
            // to prevent any new user submit
            setFormEvents({
                ...formEvents,
                ready: false
            })

            let data = {}
            data.fleet_code = formFields.bikeCode
            data.fleet_password = formFields.password
            const importDataFromCode = await window.API.add_fleet_towallet(data)
            //const importDataFromCode = true

            if (!importDataFromCode || importDataFromCode?.error) {
                switch (importDataFromCode?.error?.code) {
                    case "TooManyRequestsException":
                        window.notify(i18next.t('recovery.error_max_attempts'),'danger')
                        break
                    case "NotAuthorizedException":
                        window.notify(i18next.t('recovery.error_not_authorized'),'danger')
                        break
                    case "RegisteredFleetException":
                        window.notify(i18next.t('recovery.fleet_already_registered'),'danger')
                        break
                    default:
                        window.notify(i18next.t('recovery.error_not_authorized'),'danger')
                        break;
                }
                setFormEvents({
                    ...formEvents,
                    ready: true
                })
            }
            else {
                setFormEvents({
                    ...formEvents,
                    ready: true
                })
                if (importModaleConfig?.callback) importModaleConfig.callback()
                window.notify(i18next.t('recovery.import_success'),'success')
                setImportModaleConfig(defaultImportModaleConfig)
            }
            setLoading(false)
        }
    }

    return (
        <form>
            <Modal
                isOpen={importModaleConfig?.isOpen}
                fade={false}
                style={ window.isMobile ? { maxWidth: 'calc(100vw)', margin: 10 } : null }
            >
                <ModalHeader className="">
                    {i18next.t('bike.import_bundle_title')}
                </ModalHeader>
                <ModalBody className="">
                    {loading === true &&
                      <div className="u-center">
                        <div className="c-spinner"></div>
                      </div>
                    }
                    {loading === false &&
                      <>
                      <FormInput
                          className={"u-mg-bottom-m"}
                          formData={formFields}
                          formError={formErrors}
                          what={"bikeCode"}
                          error={i18next.t('home.label_bikecode_error')}
                          placeholder={i18next.t('bike.import_bundle_bikecode')}
                          maxLength={FNUCI_CODE_LENGTH}
                          handleInputChange={handleInputChangeLogin}
                          helpText={i18next.t('bike.import_bundle_bikecode_help')}
                      />

                      <FormInput
                          className={"u-mg-bottom-m"}
                          formData={formFields}
                          formError={formErrors}
                          what={"password"}
                          error={i18next.t('home.label_password_error')}
                          placeholder={i18next.t('home.label_password')}
                          handleInputChange={handleInputChangeLogin}
                      />
                    </>
                    }
                </ModalBody>
                <ModalFooter className="justify-content-center">
                    <div className="u-flex u-flex-center-hz u-mg-top-l">
                        <button
                            className="c-btn u-mg-hz-m"
                            onClick={() => setImportModaleConfig(defaultImportModaleConfig)}
                        >
                            {i18next.t('bike.confirm_bundle_cancel')}
                        </button>

                        <button
                            className="c-btn c-btn--primary u-mg-hz-m"
                            onClick={handleSubmit}
                        >
                            {formEvents.ready ? i18next.t('bike.import_bundle_submit') : i18next.t('home.label_waiting')}
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        </form>
    )
}

export default TableImportModal
