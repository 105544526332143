import React from "react"

const LogoOcode = ({ className, style }) => {
return (
	<svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" width="300" height="50" viewBox="0 0 300 50">
		<title>Logo O°Code</title>
		<path fill="currentColor" d="M92.5 47c-5.9 0-11.4-2.3-15.6-6.4-4.2-4.2-6.5-9.7-6.5-15.6 0-12.1 9.9-21.9 22-22h.1c12 0 21.9 9.8 22 21.9.1 5.8-2.2 11.3-6.3 15.5-4.2 4.2-9.8 6.6-15.7 6.6zm0-39.4C82.8 7.6 75 15.4 75 25c0 4.7 1.8 9.1 5.1 12.4 3.3 3.3 7.7 5.1 12.4 5.1h.1c4.7 0 9.1-1.9 12.4-5.2 3.3-3.3 5.1-7.7 5-12.2-.1-9.7-8-17.5-17.5-17.5zM125.6 18.8c-4.4 0-7.9-3.6-7.9-7.9 0-4.4 3.6-7.9 7.9-7.9 4.4 0 7.9 3.6 7.9 7.9 0 4.4-3.6 7.9-7.9 7.9zm0-11.6c-2.1 0-3.7 1.7-3.7 3.7 0 2.1 1.7 3.7 3.7 3.7 2.1 0 3.7-1.7 3.7-3.7s-1.7-3.7-3.7-3.7zM128.4 34.1c0 5.5 3.5 8.5 7.4 8.5 4.5 0 6.2-3.1 6.8-5l4.2 1.8c-1.2 3.5-4.6 7.6-10.9 7.6-7.1 0-12.3-5.5-12.3-12.9 0-7.6 5.3-12.9 12.2-12.9 6.5 0 9.8 4 10.8 7.7l-4.3 1.8c-.6-2.4-2.4-5.1-6.5-5.1-3.9 0-7.4 2.8-7.4 8.5zM197.5 42.6c-1.2 2.3-3.7 4.3-7.5 4.3-7.1 0-11.5-5.6-11.5-12.9 0-6.9 4.6-12.7 11.5-12.7 4.3 0 6.6 2.1 7.4 4.1V9.7h4.7v32c0 2.3.2 4.1.3 4.5h-4.6c-.1-.6-.3-1.8-.3-3.3v-.3zm-7.1.1c4.3 0 7-3.8 7-8.8 0-5-2.6-8.4-7-8.4-4.3 0-7.2 3.5-7.2 8.5.1 5 2.7 8.7 7.2 8.7zM229.4 39.3c-1.4 4.3-5.2 7.7-10.9 7.7-6.5 0-12.2-4.7-12.2-13 0-7.6 5.5-12.8 11.6-12.8 7.5 0 11.6 5.1 11.6 12.7 0 .6 0 1.2-.1 1.5h-18.3c.1 4.3 3.2 7.4 7.3 7.4 3.9 0 5.9-2.2 6.8-4.9l4.2 1.4zm-4.8-7.7c-.1-3.5-2.4-6.2-6.7-6.2-4 0-6.4 3.1-6.6 6.2h13.3zM162.4 47c-3.4 0-6.6-1.3-9-3.7-2.4-2.4-3.8-5.7-3.8-9.2 0-7.1 5.7-12.8 12.7-12.8 7 0 12.7 5.7 12.7 12.8 0 3.4-1.3 6.6-3.7 9.1-2.3 2.4-5.5 3.8-8.9 3.8zm0-21.5c-4.7 0-8.6 3.9-8.6 8.7 0 2.4.9 4.6 2.6 6.2 1.6 1.6 3.7 2.5 6 2.5s4.4-.9 6-2.6c1.6-1.7 2.5-3.8 2.5-6.1 0-4.8-3.8-8.7-8.5-8.7z"/>
	</svg>
)}

export default LogoOcode
