import React from "react"
import {StrDef} from '../assets/lib/utils'
import FormPassword from '../components/Forms/FormPassword'
import Logo from "../components/Logos/Logo"
import Icon from "../components/Icons/Icon.jsx"
import { withTranslation } from 'react-i18next'

class Forgot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading : false,
      formData : {
        email : '',
        tempCode : '',
        password : '',
        password2 : '',
      },
    };
  }

  updateState = (state) => {
    this.setState({
      formData: state.formData
    })
  }

  changePassword = async () => {
    //console.log("changePassword2hggh",this.state.formData);
    this.setState({loading:true})
    let obj = {}
    obj.code_gender       = 'LOGIN'
    obj.code_id           = this.state.formData.email
    obj.newpassword       = this.state.formData.password
    obj.recovery_password = this.state.formData.tempCode
    //console.log("obj",obj);

    let res = await window.API.forgotpassword(obj)
    //console.log("res",res);
    if (!StrDef(res.error)) {
      window.notify(this.props.t('forgot.success_reinit'),'success')
      setTimeout(()=>{
        this.props.history.push('/');
      },1000)
    } else if (StrDef(res.error)) {
      this.setState({loading:false})
      if (res.error.code === 'NotAuthorizedException') {
        window.notify(this.props.t('forgot.error_reinit'),'danger')
      }
    }
  }


  render() {
    const { t } = this.props;
    return (
      <main className={"c-main-content u-pd-hz-xl u-pd-xl@main"} style={{ maxWidth: "400px", margin: "auto" }}>

        {this.state.loading &&
          <div className="u-flex u-flex-center-vt u-flex-center-hz u-mg-top-xl">
            <div className="c-spinner"></div>
          </div>
        }
        {!this.state.loading &&
          <>
            <button className="c-btn-back u-primary" title={t('common.back')} onClick={() => this.props.history.goBack()} >
              <Icon key={'back'} name="back" size={30} />
            </button>

            <div className="u-flex u-flex-center-hz u-mg-bottom-xl">
              <Logo className="u-primary" />
            </div>

            <h1 className="c-h2 u-center u-bold u-mg-bottom-xl">{t('forgot.title')}</h1>

            <FormPassword
              mode = {'forgot'}
              formData={this.state.formData}
              updateState={this.updateState}
              onSubmit = {this.changePassword}
            />
          </>
        }
      </main>
    );
  }
}

export default withTranslation()(Forgot);
