import React from "react"
import {StrDef,ValidateEmail} from '../assets/lib/utils'
import FormInput from '../components/Forms/FormInput'
import Logo from "../components/Logos/Logo"
import Icon from "../components/Icons/Icon.jsx"
import { withTranslation } from 'react-i18next'

class ForgotNoWallet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading : false,
      formData : {
        fnucicode : '',
        mail : '',
      },
      formError : {
        fnucicode : false,
        mail : false,
      },
      formEvent : {
        ready : true
      }
    };
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: name === 'fnucicode' ? value.toUpperCase() : value
      },
      formError: {
        ...this.state.formError,
        [name]: false
      }
    })
  }

  formValidation = () => {
    let formData = this.state.formData;
    let formError = this.state.formError;
    let inputValidation = Object.keys(formData).map(itemName => {
      switch (itemName) {
        case 'fnucicode':
          const fnuciError = !StrDef(formData.fnucicode)
          if (fnuciError) {
            formError.fnucicode = fnuciError
          }
          return !fnuciError
        case 'mail':
          const mailError = !StrDef(formData.mail) || !ValidateEmail(formData.mail)
          if (mailError) {
            formError.mail = mailError
          }
          return !mailError
        default:
            return true
      }
    })
    this.setState({formError:formError})
    return inputValidation.reduce((acc, next) => acc && next)
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    const formReady = this.formValidation()
    if (formReady) {
      this.setState({
        formEvent: {
            ...this.state.formEvent,
            ready: false
        },
        loading : true,
      })

      // call api
      let obj = {}
      obj.bike_id = this.state.formData.fnucicode
      obj.mail    = this.state.formData.mail
      let res = await window.API.forgotpasswordnowallet(obj)
      if (!StrDef(res.error)) {
        window.notify(this.props.t('forgotnowallet.success_reinit'),'success')
        setTimeout(()=>{
          this.props.history.push('/');
        },1000)
      } else {
        window.notify(this.props.t('forgotnowallet.error_reinit'),'danger')
      }
      this.setState({
        formEvent: {
          ...this.state.formEventStolen,
          ready: true
        },
        loading : false,
      })

    }
  }


  render() {
    const { t } = this.props;
    return (
      <main className={"c-main-content u-pd-xl"} style={{ maxWidth: "400px", margin: "auto" }}>

        {this.state.loading &&
          <div className="u-flex u-flex-center-vt u-flex-center-hz u-mg-top-xl">
            <div className="c-spinner"></div>
          </div>
        }
        {!this.state.loading &&
          <>
            <button className="c-btn-back u-primary" title={t('common.back')} onClick={() => this.props.history.goBack()} >
              <Icon key={'back'} name="back" size={30} />
            </button>

            <div className="u-flex u-flex-center-hz u-mg-bottom-xl">
              <Logo className="u-primary" />
            </div>

            <h1 className="c-h2 u-center u-bold u-mg-bottom-xl">{t('forgotnowallet.title')}</h1>

            <FormInput
              formData={this.state.formData}
              formError={this.state.formError}
              what={"fnucicode"}
              error={t('forgotnowallet.label_bikecode_error')}
              placeholder={t('forgotnowallet.label_bikecode')}
              className={"u-mg-bottom-m u-mg-hz-s"}
              handleInputChange={this.handleInputChange}
            />

            <FormInput
              formData={this.state.formData}
              formError={this.state.formError}
              what={"mail"}
              error={t('forgotnowallet.label_mail_error')}
              placeholder={t('forgotnowallet.label_mail')}
              className={"u-mg-bottom-m u-mg-hz-s"}
              handleInputChange={this.handleInputChange}
            />

            <div className="u-flex-1 u-flex u-flex-center-hz">
              <button
                type="button"
                className="c-btn c-btn--primary"
                onClick={this.state.formEvent.ready ? this.handleSubmit.bind(this) : undefined}
              >
                {this.state.formEvent.ready ? t('common.validate') : t('common.wait')}
              </button>
            </div>
          </>
        }


      </main>
    );
  }
}

export default withTranslation()(ForgotNoWallet);
