import React from "react"
import {StrDef} from '../assets/lib/utils'
import Icon from "../components/Icons/Icon.jsx"
import FormInput from '../components/Forms/FormInput'
import { withTranslation } from 'react-i18next'

class AddBike extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData : {
        password : '',
      },
      formError : {
        password : false,
      },
      formEvent : {
        ready : true,
      }
    };
  }

  handleInputChange = (event) => {
    const target = event.target
    const val = target.type === 'checkbox' ? target.checked : target.value
    const what = target.name

    this.setState({
      formData: {
        ...this.state.formData,
        [what]: val
      },
      formError: {
        ...this.state.formError,
        [what]: false
      },
    })
  }

  formValidation = () => {
    let formData = this.state.formData;
    let formError = this.state.formError;
    let inputValidation = Object.keys(formData).map(itemName => {
      switch (itemName) {
        case 'password':
          const passwordError = !StrDef(formData.password)
          if (passwordError) {
            formError.password = passwordError
          }
          return !passwordError
        default:
            return true
      }
    })
    this.setState({formError:formError})
    return inputValidation.reduce((acc, next) => acc && next)
  }

  addToWallet = async (event) => {
    event.preventDefault()
    // Validate inputs
    const formReady = this.formValidation()

    //const formReady = true;
    if (formReady) {
      // Inform the state that the component is unavailable
      // to prevent any new user submit
      this.setState({
        formData: { ...this.state.formData, },
        formError: { ...this.state.formError },
        formEvent: {
          ...this.state.formEvent,
          ready: false
        },
      })
      // call api
      let obj = {}
      obj.password = this.state.formData.password
      let res = await window.API.addtowallet(this.props.match.params.id,obj)
      if (StrDef(res.error)) {
        if (res.error.code === 'NotAuthorizedException') {
          window.notify(this.props.t('addbike.error_not_authorized'),'danger')
        } else {
          window.notify(this.props.t('addbike.error_other'),'danger')
        }
      } else if (!StrDef(res.error)) {
        this.props.history.goBack()
      }
      this.setState({
        formData: { ...this.state.formData, },
        formError: { ...this.state.formError },
        formEvent: {
          ...this.state.formEvent,
          ready: true
        },
      })
    }
  }

  render() {
    const { t } = this.props;
    return (
      <main className={"c-main-content u-flex u-flex-dir-col"}>
      <div className="c-skew-header">
        <div className="c-skew-header__top">
          <div className="l-container-md u-pd-hz-m">
            <h1 className="c-h1 u-medium u-uppercase" style={{ transform: "translateY(20px)"}}>{t('addbike.title')}</h1>
          </div>
        </div>
        <div className="c-skew-header__bottom">
          <div className="c-skew-header__color">{}</div>
          <div className="u-flex u-flex-center-vt u-flex-between">
          </div>
        </div>
      </div>
        <button className="c-btn-back" title={t('common.back')} onClick={() => this.props.history.goBack()} >
          <Icon key={'back'} name="back" size={30} />
        </button>

        <h1 className="c-h2 u-center u-bold u-mg-bottom-xl">{t('addbike.subtitle')}</h1>

        <div className="u-pd-hz-m">
          <form className="l-form-width">
            <p className="u-fs-xs u-blue20 u-center u-mg-bottom-m">{t('addbike.label_explain_p1')}</p>

            <div className="u-flex u-flex-1 u-flex-dir-col">
              <FormInput
                style={{ minWidth: "calc(100% - 20px)" }}
                formData={this.state.formData}
                formError={this.state.formError}
                what={"password"}
                error={t('addbike.label_password_error')}
                placeholder={t('addbike.label_password')}
                handleInputChange={this.handleInputChange}
              />
            </div>

            <div className="u-flex u-flex-center-hz u-mg-vt-l">
              <button
                type="submit"
                className="c-btn c-btn--primary"
                onClick={this.state.formEvent.ready ? this.addToWallet.bind(this) : undefined}
              >
                {this.state.formEvent.ready ? t('addbike.label_valid') : t('addbike.label_waiting')}
              </button>
            </div>
          </form>
        </div>
      </main>
    );
  }
}

export default withTranslation()(AddBike);
