import React from "react"
import {
    Text,
    StyleSheet,
    View,
} from "@react-pdf/renderer"

const dataStyles = StyleSheet.create({
    view: {
        marginBottom: 5,
        fontSize: 10,
    },
    label: {
        color: "#1B1464"
    }
})

// Data specific component
const PDFDataView = ({ label, value, unit, styles = null, valueStyles = null }) => {
    return (
        <View style={[dataStyles.view, styles]}>
            <Text style={[dataStyles.label]}>
                {label && label.length > 0 ? label : " "}
            </Text>
            <Text style={valueStyles}>
                {value && value.toString().length > 0 ? value : "-"}
                {unit && <Text style={{ marginLeft: 10 }}>{unit}</Text>}
            </Text>
        </View>
    )
}

export default PDFDataView