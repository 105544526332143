import React from "react"
import LogoBicycode from "./LogoBicycode"
import LogoMFC from "./LogoMFC"
import LogoDecathlon from "./LogoDecathlon"

const Logo = ({ id, className, style }) => {
	const renderLogo = (id) => {
		const idToTest = id.toString().toUpperCase()
		if (/^MF/i.test(idToTest)) return <LogoMFC className={className} style={style} />
		if (/^DK/i.test(idToTest)) return <LogoDecathlon className={className} style={style} />
		// else
		return <LogoBicycode className={className} style={style} />
    }
	return renderLogo(id)
}

export default Logo
