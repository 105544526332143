import React from "react";
import { StrDef,get_types_bikes,get_brand_bikes } from '../assets/lib/utils';
import FormPerson from '../components/Forms/FormPerson';
import FormInput from '../components/Forms/FormInput';
import { withTranslation } from 'react-i18next';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import FormAutocomplete from "../components/Forms/FormAutocomplete"

const LABEL_DESTROY = 'destroy'
const MAIL_DESTROY  = 'destroy@destroy.fr'
const TEL_DESTROY   = '+33000000000'

class Recovery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading : false,
      role : 1,
      bike: {},
      step: 1,
      status : 1,
      statusInProgress: '',
      formData : {
        lastName      : '',
        firstName     : '',
        phone         : '',
        email         : '',

      },
      formError : {
        lastName      : false,
        firstName     : false,
        phone         : false,
        email         : false,
      },
      formEvent : {
        ready : true,
      },
      formDataBike : {
        gear_type : '',
        gear_type_id : '',
        brand_id : '',
        brand : '',
        model : '',
        color: '',
        is_electric : false
      },
      formErrorBike : {
        gear_type : false,
        brandId : false,
        brand : false,
        model : false,
        color: false
      },
      formEventBike : {
        ready : true,
      },
    }

    this.status = [
      {id:1,label:this.props.t('bikestatus.label_inservice')},
      {id:2,label:this.props.t('bikestatus.label_stolen')},
      {id:3,label:this.props.t('bikestatus.label_destroy')}
    ]
    this.listElect = [
      {id:1, label: this.props.t('onebike.label_electric_yes'), value:true},
      {id:2, label: this.props.t('onebike.label_electric_no'), value:false}
    ]
    this.types = get_types_bikes()
    this.brands = get_brand_bikes()

    this.changeValueStatus = this.changeValueStatus.bind(this)
    this.changeValueElect = this.changeValueElect.bind(this)
    this.changeValueType  = this.changeValueType.bind(this)
    this.changeValueBrand = this.changeValueBrand.bind(this)
  }

  componentDidMount() {
    if (StrDef(this.props.location.state) && StrDef(this.props.location.state.bike)) {
      let bike = this.props.location.state.bike
      let bikeOwner = bike.bike_owner
      let formData = {
        email         : bikeOwner ? bikeOwner.mail : '',
        socialReason  : bikeOwner ? (bikeOwner.social_reason === 'INCONNU' ? '' : bikeOwner.social_reason || '') : '',
        firstName     : bikeOwner ? (bikeOwner.first_name === 'INCONNU' ? '' : bikeOwner.first_name || '') : '',
        lastName      : bikeOwner ? (bikeOwner.last_name === 'INCONNU' ? '' : bikeOwner.last_name || '') : '',
        phone         : bikeOwner ? bikeOwner.phone : '',
      }
      let formDataBike = {
        brand         : bike.bike_description ? (bike.bike_description.brand  === 'INCONNU' ? '' : bike.bike_description.brand || '') : '',
        color         : bike.bike_description ? (bike.bike_description.color  === 'INCONNU' ? '' : bike.bike_description.color || '') : '',
        gear_type     : bike.bike_description ? (bike.bike_description.gear_type  === 'INCONNU' ? '' : bike.bike_description.gear_type || '') : '',
        model         : bike.bike_description ? (bike.bike_description.model  === 'INCONNU' ? '' : bike.bike_description.model || '') : '',
        is_electric   : false,
      }

      // gear_type
      let gear_type_id = 999 // inconnu
      let gear_find = this.types.find(oneType => oneType.label === formDataBike.gear_type)
      if (StrDef(gear_find) ) {
        gear_type_id = gear_find.id
      }
      formDataBike.gear_type_id = gear_type_id

      // brand
      // let brand_id = 999 // inconnu
      let brand_id = null // inconnu
      let brand_find = this.brands.find(oneBrand => oneBrand.label === formDataBike.brand)
      if (StrDef(brand_find) ) {
        brand_id = brand_find.id
      }
      formDataBike.brand_id = brand_id

      // status
      let statusInProgress
      let status_find = this.status.find(oneStatus => oneStatus.id === bike.bike_status)
      if (StrDef(status_find) ) {
        statusInProgress = status_find.label
      } else {
        statusInProgress = this.props.t('bikestatus.label_inservice')
      }

      this.setState({
        bike: bike,
        formData: formData,
        formDataBike: formDataBike,
        loading: false,
        role : bikeOwner ? bikeOwner.gender : 1,
        typeInProgress : formDataBike.gear_type,
        brandInProgress : formDataBike.brand,
        status : bike.bike_status || 1,
        statusInProgress : statusInProgress
      })
    }
  }

  updateStatePerson = (state) => {
    this.setState({
      formData: state.formData,
      role : state.role
    })
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState({
      formDataBike: {
        ...this.state.formDataBike,
        [name]: value
      },
      formErrorBike: {
        ...this.state.formErrorBike,
        [name]: false
      },
    })
  }

  getObjPerson() {
    let objPerson = {}
    objPerson.mail = this.state.formData.email
    objPerson.phone = this.state.formData.phone
    objPerson.gender = this.state.role
    if (this.state.role === 1) {
      objPerson.last_name      = this.state.formData.lastName
      objPerson.first_name     = this.state.formData.firstName
    } else if(this.state.role === 2) {
      objPerson.social_reason  = this.state.formData.socialReason
    }
    return objPerson
  }

  formValidationBike = () => {
    let formData = this.state.formDataBike;
    let formError = this.state.formErrorBike;
    let inputValidation = Object.keys(formData).map(itemName => {
      switch (itemName) {
        case 'brand_id':
          const brandIdError = formData.brand_id === null
          if (brandIdError) {
            formError.brandId = brandIdError
          }
          return !brandIdError
        case 'brand':
          const brandError = !StrDef(formData.brand)
          if (brandError) {
            formError.brand = brandError
          }
          return !brandError
        case 'color':
          const colorError = !StrDef(formData.color)
          if (colorError) {
            formError.color = colorError
          }
          return !colorError
        case 'gear_type':
          const gearError = !StrDef(formData.gear_type)
          if (gearError) {
            formError.gear_type = gearError
          }
          return !gearError
        case 'model':
          const modelError = !StrDef(formData.model)
          if (modelError) {
            formError.model = modelError
          }
          return !modelError
        default:
            return true
      }
    })
    this.setState({formErrorBike:formError})
    return inputValidation.reduce((acc, next) => acc && next)
  }

  updateAccount = async (event) => {
    event.preventDefault()
    const formReady = this.formValidationBike()
    if (formReady) {
      this.setState({
        loading:true,
        formEventBike: {
          ...this.state.formEvent,
          ready: false
        },
      })

      let knownBike = this.state.bike;
      let bikeToPut = {}
      if (knownBike) {
        bikeToPut.bike_id     = knownBike.bike_id
        bikeToPut.bike_status = this.state.status
        bikeToPut.row_version = knownBike.row_version || "0"
      }


      // attention au co_owner
      let bike_owner        = this.getObjPerson()
      bikeToPut.bike_owner  = bike_owner

      if (bikeToPut.bike_status === 3) { // détruit
        bike_owner = {}
        let gender = bikeToPut.bike_owner.gender
        bike_owner.mail         = MAIL_DESTROY
        bike_owner.phone        = TEL_DESTROY
        bike_owner.gender       = gender
        if (gender === 1) {
          bike_owner.first_name     = LABEL_DESTROY
          bike_owner.last_name      = LABEL_DESTROY
        } else {
          bike_owner.social_reason  = LABEL_DESTROY
        }
        bikeToPut.bike_owner = bike_owner
      }

      let bike_description = this.state.formDataBike
      bikeToPut.bike_description = bike_description
      let resPut = await window.API.put_bike_bicycode_2_0(bikeToPut)

      if (!StrDef(resPut.error)) {
        window.notify(this.props.t('recovery.success_register'),'success')
        // Redirect to regular view
        setTimeout(()=>{
          this.props.history.push('/')
        },1000)
      } else if (StrDef(resPut.error)) {
        if (resPut.error.code === 'TooManyRequestsException') {
          window.notify(this.props.t('recovery.error_already_update'),'danger')
        } else if (resPut.error.code === 'BikeVersionMismatchException') {
          window.notify(this.props.t('recovery.error_version'),'danger')
        } else if (resPut.error.code === 'RegisteredBikeException') {
          window.notify(this.props.t('recovery.error_already_registered'),'danger')
          setTimeout(()=>{
            this.props.history.push('/')
          },1000)
        }
        this.setState({
          formEvent: {
            ...this.state.formEvent,
            ready: true
          },
          loading : false,
        })
      }
    }


  }

  nextStep = () => {
    this.setState({step: this.state.step + 1})
  }
  previousStep = () => {
    this.setState({step: this.state.step - 1,role:this.state.role})
  }

  changeValueElect = (e) => {
    let elect = e.currentTarget.id;
    let value = this.listElect.find(oneType => oneType.id === parseInt(elect)).value
    this.setState({
      formDataBike: {
        ...this.state.formDataBike,
        is_electric: value
      },
      formErrorBike: {
        ...this.state.formErrorBike,
        is_electric: false
      },
    })
  }

  async changeValueType(e) {
    let type = this.types.find(oneType=>oneType.id === e.currentTarget.id)
    let typeInProgress = type.label
    this.setState({
      formDataBike: {
        ...this.state.formDataBike,
        gear_type : type.label,
        gear_type_id : type.id
      },
      formErrorBike: {
        ...this.state.formErrorBike,
        gear_type : false,
      },
      typeInProgress:typeInProgress,
    })
  }

  async changeValueBrand(e) {
    let brand = this.brands.find(oneBrand=>oneBrand.id === e.currentTarget.id)
    let brandInProgress = brand.label
    this.setState({
      formDataBike: {
        ...this.state.formDataBike,
        brand : brand.id === 999 ? "" : brand.label,
        brand_id : brand.id
      },
      formErrorBike: {
        ...this.state.formErrorBike,
        brandId : false,
      },
      brandInProgress:brandInProgress,
    })
  }

  async changeValueStatus(e) {
    let status = this.status.find(oneStatus=>oneStatus.id === e.currentTarget.id)
    let statusInProgress = status.label
    this.setState({
      status : status.id,
      statusInProgress:statusInProgress,
    })
  }

  render() {
    const { t } = this.props;
    return(
      <main className={"c-main-content u-flex u-flex-dir-col"}>
        <div className="c-skew-header">
          <div className="c-skew-header__top">
            <div className="l-container-md u-pd-hz-m">
              <h1 className="c-h1 u-medium u-uppercase" style={{ transform: "translateY(20px)"}}>
                {t('recovery.recovery_title')}
              </h1>
            </div>
          </div>
          <div className="c-skew-header__bottom">
            <div className="c-skew-header__color">{}</div>
            <div className="u-flex u-flex-center-vt u-flex-between">
            </div>
          </div>
        </div>
        {this.state.loading &&
          <div className="u-center">
            <div className="c-spinner"></div>
          </div>
        }
        {!this.state.loading &&
          <>
            {/* Step 1 */}
            {this.state.step === 1 &&
              <>
                <div className="l-container-md u-pd-vt-l u-pd-hz-m u-pd-bottom-xl">
                  <h2 className="c-h2 u-center u-mg-bottom-l">{t('recovery.recovery_step1_title')}</h2>
                  <FormPerson
                    mode={'recovery'}
                    role = {this.state.role}
                    formData={this.state.formData}
                    disabled={false}
                    updateState={this.updateStatePerson}
                    onSubmit = {this.nextStep}
                  />
                </div>
              </>
            }

            {/* Step 2 */}
            {this.state.step === 2 &&
              <>
                <div className="l-container-md u-pd-vt-l u-pd-hz-m u-pd-bottom-xl">
                  <h2 className="c-h2 u-center u-mg-bottom-l">{t('recovery.recovery_step2_title')}</h2>

                  {/* type */}
                  <div className={"c-form-group l-form-width u-mg-bottom-m"}>
                    <label className="c-label">{t('recovery.label_type')}</label>
                    <FormAutocomplete
                      options={this.types}
                      defaultValue={this.state.typeInProgress}
                      handleInputChange={this.changeValueType}
                      what={"typeInProgress"}
                    />
                    {this.state.formErrorBike.gear_type &&
                        <p className="u-fs-xs u-danger">{t('recovery.label_error_gear')}</p>
                    }
                  </div>

                  {/* brand */}
                  <div className={"c-form-group l-form-width u-mg-bottom-m"}>
                    <label className="c-label">{t('onebike.label_brand')}</label>
                    <FormAutocomplete
                      options={this.brands}
                      defaultValue={this.state.typeInProgress}
                      handleInputChange={this.changeValueBrand}
                      what={"brandInProgress"}
                    />
                    {this.state.formErrorBike.brandId &&
                        <p className="u-fs-xs u-danger">{t('recovery.label_error_brand')}</p>
                    }
                  </div>

                  {this.state.formDataBike.brand_id === 999 &&
                    <FormInput
                      className={"l-form-width u-mg-bottom-m"}
                      formData={this.state.formDataBike}
                      formError={this.state.formErrorBike}
                      what={"brand"}
                      error={t('recovery.label_error_brand')}
                      placeholder={t('recovery.label_other_brand')}
                      handleInputChange={this.handleInputChange}
                      disabled={false}
                    />
                  }

                  { false &&
                    <FormInput
                      className={"l-form-width u-mg-bottom-m"}
                      formData={this.state.formDataBike}
                      formError={this.state.formErrorBike}
                      what={"gear_type"}
                      error={t('recovery.label_error_gear')}
                      placeholder={t('onebike.label_type')}
                      handleInputChange={this.handleInputChange}
                      disabled={false}
                    />
                  }

                  { false &&
                    <FormInput
                      className={"l-form-width u-mg-bottom-m"}
                      formData={this.state.formDataBike}
                      formError={this.state.formErrorBike}
                      what={"brand"}
                      error={t('recovery.label_error_brand')}
                      placeholder={t('onebike.label_brand')}
                      handleInputChange={this.handleInputChange}
                      disabled={false}
                    />
                  }

                  <FormInput
                    className={"l-form-width u-mg-bottom-m"}
                    formData={this.state.formDataBike}
                    formError={this.state.formErrorBike}
                    what={"model"}
                    error={t('recovery.label_error_model')}
                    placeholder={t('onebike.label_model')}
                    handleInputChange={this.handleInputChange}
                    disabled={false}
                  />

                  <FormInput
                    className={"l-form-width u-mg-bottom-m"}
                    formData={this.state.formDataBike}
                    formError={this.state.formErrorBike}
                    what={"color"}
                    error={t('recovery.label_error_color')}
                    placeholder={t('onebike.label_color')}
                    handleInputChange={this.handleInputChange}
                    disabled={false}
                  />

                  <FormInput
                    className={"l-form-width u-mg-bottom-m"}
                    formData={this.state.formDataBike}
                    formError={this.state.formErrorBike}
                    what={"frame_serial_number"}
                    error={t('recovery.label_error_color')}
                    placeholder={t('recovery.label_serial_number')}
                    handleInputChange={this.handleInputChange}
                    disabled={false}
                    helpText={t('recovery.recovery_serial_help')}
                  />

                  <form className="l-form-width ">
                    <label className="c-label" htmlFor="code">{t('onebike.label_electric')}</label>
                    <div className="u-flex u-flex-dir-col u-flex-dir-row@main u-flex-align-center-vt u-flex-between u-mg-bottom-l">
                      {this.listElect.map((data, i) => {
                      return (

                        <label
                          key ={'listElect'+i}
                          className={"c-radio u-mg-bottom-s " + ((data.value === this.state.formDataBike.is_electric) ? "is-checked" : "")}
                        >
                          <input
                            type="radio"
                            id={data.id}
                            className="u-sr-only"
                            name="roles"
                            value={data.id}
                            checked={data.value === this.state.formDataBike.is_electric}
                            onChange={this.changeValueElect}
                          />
                          <div className="">
                            {data.label}
                          </div>
                        </label>
                      )
                    })}
                    </div>
                    {this.state.formDataBike.is_electric === true &&
                      <>
                        <FormInput
                          className={"l-form-width u-mg-bottom-m"}
                          formData={this.state.formDataBike}
                          formError={this.state.formErrorBike}
                          what={"engine_serial_number"}
                          error={""}
                          placeholder={t('onebike.label_engine_serial')}
                          handleInputChange={this.handleInputChange}
                          disabled={false}
                        />
                        <FormInput
                          className={"l-form-width u-mg-bottom-m"}
                          formData={this.state.formDataBike}
                          formError={this.state.formErrorBike}
                          what={"battery_serial_number"}
                          error={""}
                          placeholder={t('onebike.label_battery_serial')}
                          handleInputChange={this.handleInputChange}
                          disabled={false}
                        />
                      </>
                    }
                  </form>
                  <form className="l-form-width ">
                    <p className="u-fs-xs u-blue20 u-mg-top-l u-mg-bottom-s">{t('recovery.recvery_explain')}</p>
                  </form>
                  {/* Status */}
                  <div className={"c-form-group l-form-width u-mg-top-l u-mg-bottom-m"}>
                    <label className="c-label" htmlFor="code">{t('onebike.label_state_bike')}</label>
                    <UncontrolledDropdown className={"c-dropdown"}>
                      <DropdownToggle className={"c-dropdown__toggle"} caret>
                          {this.state.statusInProgress}
                      </DropdownToggle>
                      <DropdownMenu className={"c-dropdown__menu"} >
                        {this.status.map((data, i) => {
                          return(
                            <DropdownItem key={'stat' + i}>
                              <div id={data.id} onClick={this.changeValueStatus}>
                                {data.label}
                              </div>
                            </DropdownItem>
                          )
                        })}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <div className="u-flex-1 u-flex u-flex-center-hz u-mg-top-l">
                    <button
                      type="button"
                      className="c-btn c-btn--primary u-mg-hz-m"
                      onClick={this.previousStep}
                    >
                      {t('recovery.recovery_label_previous')}
                    </button>
                    <button
                      type="button"
                      className="c-btn c-btn--primary u-mg-hz-m"
                      onClick={this.updateAccount}
                      disabled={false}
                    >
                      {this.state.formEventBike.ready ? t('recovery.recovery_label_submit') : t('onebike.label_waiting')}
                    </button>
                  </div>
                </div>
              </>
            }

          </>
        }
      </main>
    )
  }
}

export default withTranslation()(Recovery);
