/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ConsumerNavbar from "components/Navbars/ConsumerNavbar.jsx";
import ConsumerNoWalletNavbar from "components/Navbars/ConsumerNoWalletNavbar.jsx";
// core components
import HelpFAQ from "components/Shared/HelpFAQ.jsx";
import Footer from "components/Footer/Footer.jsx";
import NotificationAlert from "components/Notification/Notification.js";
import DesktopSideView from "layouts/DesktopSideView"

import routes from "routes.js";

var moment = require('moment');

class Account extends React.Component {
  state = {
    sidebarMini: false,
    backgroundColor: "blue",
    hasAside: true
  };
  notificationAlert = React.createRef();
  mainPanel = React.createRef();
  componentDidMount() {
    this.modalAlert = false;
    window.notify = (msg,type) => this.refs.notificationAlert.notificationAlert({ message: msg, type: type });
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
    var msg = localStorage.getItem('alertMsg');
    if( moment().format('YYYYMMDD') < '20200529' ) {
      if( (msg == null || msg < '202005271500') && !this.modalAlert ) {
        this.modalAlert = true;
        this.setState({modalAlert:true});
      }
    }
  }

  alertCheck() {
    this.setState({modalAlert:false});
    localStorage.setItem('alertMsg',moment().format('YYYYMMDDHHMM'));
  }

  minimizeSidebar = () => {
    var message = "Sidebar mini ";
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
      message += "deactivated...";
    } else {
      this.setState({ sidebarMini: true });
      message += "activated...";
    }
    document.body.classList.toggle("sidebar-mini");
    var options = {};
    options = {
      place: "tr",
      message: message,
      type: "info",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7
    };
    this.notificationAlert.current.notificationAlert(options);
  };

  handleColorClick = color => {
    this.setState({ backgroundColor: color });
  };

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/moncompte") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => (
              <prop.component
                {...props}
                setLayoutAside={this.setHasAside}
              />
            )}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  getActiveRoute = routes => {
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  setHasAside = bool => {
    this.setState({ hasAside: bool })
  }
  

  render() {
    // @TODO clean management of the light-header
    if (this.props.location.pathname === "/moncompte/mes-velos" || this.props.location.pathname === "/moncompte/mon-velo" || this.props.location.pathname === "/moncompte/conditions-générales-utilisation") {
      document.body.classList.add("light-header");
    } else {
      document.body.classList.remove("light-header");
    }
    return (
      <>
        <NotificationAlert ref="notificationAlert"/>

        <div className="l-grid c-main-grid">

          <aside className={"c-main-grid__aside l-col-4 " + (this.state.hasAside ? "u-flex-desktop-only" : "u-hide")}>
            <DesktopSideView />
          </aside>

          <div className={
              "l-col-12 c-main-grid__main u-sticky-view "
              + (this.state.hasAside ? "l-col-8@main " : " ")
              + (this.props.location.pathname !== '/public/login' ?  "u-pd-top-navbar" : "")
            }
            ref="fullPages"
          >

            {window.API.getSubDomain() === 'wallet' &&
              <ConsumerNavbar className={(this.state.hasAside ? "" : "no-aside")} {...this.props}/>
            }
            {(
              this.props.location.pathname !== "/moncompte/conditions-générales-utilisation"
              && this.props.location.pathname !== "/moncompte/reprise-bicycode"
              && window.API.getSubDomain() !== 'wallet')
              &&
                <ConsumerNoWalletNavbar className={""} {...this.props}/>
            }
            
            {/* Empty navbar */}
            {this.props.location.pathname === "/moncompte/reprise-bicycode" &&
              <div className={"c-menu-navbar"}>
                <div className="c-menu-navbar__navbar u-pd-hz-m"></div>
              </div>
            }

            <Switch>
              {this.getRoutes(routes)}
              <Redirect from="/public" to="/" />
            </Switch>

            {(window.API.getSubDomain() === 'wallet' || window.API.getSubDomain() === 'bicycode') && <HelpFAQ />}

            <Footer />
          </div>
        </div>
      </>
    );
  }
}

export default Account;
