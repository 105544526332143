import React from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {StrDef,StringifyOCode,DrawOCode,GetBikeIdFormat, GetSubDomainByOperator, ValidateEmail} from '../assets/lib/utils';
import FormInput from '../components/Forms/FormInput';
import Icon from "../components/Icons/Icon.jsx";
import { withTranslation } from 'react-i18next';
import dateService from "services/date"
import toast, { Toaster } from 'react-hot-toast';
import * as translationContext from 'translation';

// PDF
import { pdf } from "@react-pdf/renderer"
import saveBlob from "utils/saveBlob"
import getBikeIdOperator from "utils/operator"
import SummaryBikePDF from "components/Pdf/SummaryBikePDF"

const WIDHT_CANVAS_OCODE_DISPLAY = 110
const LABEL_DESTROY = 'destroy'
const MAIL_DESTROY  = 'destroy@destroy.fr'
const TEL_DESTROY   = '+33000000000'
const SUFFIXE_ELECTRIQUE = "ELECTRIQUE"

class OneBike extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      locale: translationContext.getLanguage(),
      disabledStolen : false,
      isWallet : false,
      loading : false,
      code: '',
      status : 1, // statut du vélo
      bike : {}, // le vélo et ses infos
      brand : '',
      color : '',

      citySuggestion : [], // pour le vol

      statusInProgress : this.props.t('bikestatus.label_inservice'),
      disableCopro : true,
      disableBikeInfo : true,

      // copro
      formData: {
        lastName : '',
        firstName : '',
        mail : ''
      },
      formError: {
        lastName : false,
        firstName : false,
        mail : false
      },
      formEvent : {
        ready : true,
      },

      // bike info
      formDataInfo: {
        engineSerialNumber : '',
        batterySerialNumber : '',
        frameSerialNumber : ''
      },
      formErrorInfo: {
        engineSerialNumber : false,
        batterySerialNumber : false,
        frameSerialNumber : false
      },
      formEventInfo : {
        ready : true,
      },

      // volé
      formDataStolen: {
        street_number : '',
        adresse : '',
        cp : '',
        city : '',
        date : '',
        time : '',
        insee_code : '',
      },
      formErrorStolen: {
        date : false,
        time : false,
        adresse : false,
        cp : false,
        city : false
      },
      formEventStolen : {
        ready : true,
      },

      // vélo
      formDataBike : {
        gear_type : '',
        brand : '',
        color: '',
        model : '',
        is_electric : false,
        label_electric : ''
      },
      formErrorBike : {
      },
    }

    this.status = [
      {id:1,label:this.props.t('bikestatus.label_inservice')},
      {id:2,label:this.props.t('bikestatus.label_stolen')},
      {id:3,label:this.props.t('bikestatus.label_destroy')},
      {id:5,label:this.props.t('bikestatus.label_insale')},
    ]

    this.changeValue = this.changeValue.bind(this)
    this.load = this.load.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this._handleKeyDown);
    this.load()
  }

  componentDidUpdate() {
    if(this.state.locale !== translationContext.getLanguage()) {
      this.setState({
        locale : translationContext.getLanguage(),
      }, function() { this.load() })
    }
  }

  async load() {
    this.setState({loading:true})
    let isWallet = this.state.isWallet
    let bike = {}
    if (window.API.getSubDomain() === 'wallet') {
      isWallet = true
      bike = await window.API.get_one_bike('byBikeId',this.props.match.params.id)
    } else {
      isWallet = false
      bike = await window.API.get_bike_nowallet('byBikeId',this.props.match.params.id)
    }

    if (!StrDef(bike.error)) {
      let formDataBike  = bike.bike_description
      let ocode         = bike.ocode_id
      let code          = (bike.is_bicycode_2_0 === 1 || bike.is_bicycode_2_0 === 0) ? bike.bike_id : GetBikeIdFormat(bike.bike_id)
      let brand         = bike.bike_description.brand
      let color         = bike.bike_description.color
      let is_electric   = formDataBike?.is_electric
      let label_electric = formDataBike?.is_electric === true ? this.props.t('onebike.label_electric_yes') : this.props.t('onebike.label_electric_no')

      // fbo - le 16/06/21
      if (!formDataBike?.is_electric) {
        let tabGearType = bike.bike_description.gear_type.split(" ")
        if (tabGearType.includes(SUFFIXE_ELECTRIQUE)) {
          is_electric = true;
          label_electric = this.props.t('onebike.label_electric_yes')
        } else {
          is_electric = false;
          label_electric = this.props.t('onebike.label_electric_no')
        }
      }
      // fin fbo

      formDataBike.label_electric = label_electric
      formDataBike.is_electric = is_electric

      let disabledStolen= this.state.disabledStolen
      let formData = {
        lastName : '',
        firstName : '',
        mail : ''
      }
      let formDataInfo = {
        engineSerialNumber : bike.bike_description?.engine_serial_number || "",
        batterySerialNumber : bike.bike_description?.battery_serial_number || "",
        frameSerialNumber : bike.bike_description?.frame_serial_number || "",
      }

      let co_owner = bike.bike_owner.co_owner
      if (StrDef(co_owner)) {
        formData = {
          lastName  : co_owner.last_name,
          firstName : co_owner.first_name,
          mail      : co_owner.mail
        }
      }
      if (bike.bike_status === 2) {
        this.status = [
          {id:1,label:this.props.t('bikestatus.label_find')},
          {id:2,label:this.props.t('bikestatus.label_stolen')},
        ]
      } else if (bike.bike_status === 1) {
        this.status = [
          {id:1,label:this.props.t('bikestatus.label_inservice')},
          {id:2,label:this.props.t('bikestatus.label_stolen')},
          {id:3,label:this.props.t('bikestatus.label_destroy')},
          {id:5,label:this.props.t('bikestatus.label_insale')},
        ]

        // Case : Backstore - Display the option if operator is DK only
        if(bike.operator === "DK") {
          this.status.push({id:6,label:this.props.t('bikestatus.label_backstore')})
        }

      } else {
        this.status = [
          {id:1,label:this.props.t('bikestatus.label_inservice')},
          {id:2,label:this.props.t('bikestatus.label_stolen')},
          {id:3,label:this.props.t('bikestatus.label_destroy')},
          {id:5,label:this.props.t('bikestatus.label_insale')},
        ]
      }
      this.setState({
        bike : bike,
        code : code,
        brand : brand,
        color : color,
        status : bike.bike_status,
        statusInProgress : this.status.find(oneStatus=>oneStatus.id === bike.bike_status).label,
        formDataBike : formDataBike,
        formData : formData,
        formDataInfo : formDataInfo,
        loading : false,
        disableCopro : true,
        disableBikeInfo : true,
        isWallet : isWallet,
        disabledStolen : disabledStolen
      },() => {
        if (window.API.getSubDomain() === 'decathlon' || !StrDef(ocode)) // pas de o°code pour D4
          ocode = '123'

        var canvas = document.getElementById('ocode_bike');
        var ctx = canvas.getContext('2d');
        canvas.width = WIDHT_CANVAS_OCODE_DISPLAY
        canvas.height = WIDHT_CANVAS_OCODE_DISPLAY
        let chaine = StringifyOCode(ocode)
        ctx = DrawOCode(chaine,ctx,30,25,true, "#fff")


      })
    } else if (StrDef(bike.error)) {
      if (bike.error.code === 'RessourceNotFoundException') {
        window.notify(this.props.t('onebike.error_bike_unknwown'),'danger');
        setTimeout(()=>{
          this.props.history.goBack()
        },1000)
      } else {
        window.notify(this.props.t('onebike.error_bike_unknwown'),'danger');
        setTimeout(()=>{
          window.Logout()
        },1000)
      }
    }
  }

  _handleKeyDown = (event) => {
    switch( event.keyCode ) {
      case 13:
          event.preventDefault();
      default:
          break
    }
  }

    // Generate PDF summary
    viewSummary = async (event, bike = null) => {
      event.preventDefault()
      // Manage pdf generation
      //const operatorGender = bike.bike_id.substr(0, 2)
      const operatorGender = bike.operator
      const timeStamp = dateService.getCurrentDate("dd-MM-y-'at'-HH'h'mm")

      const generateBikePDF = bike && operatorGender && await pdf((
              <SummaryBikePDF
                  bike={bike}
                  operatorGender={operatorGender}
              />
          ))
          .toBlob()
          .then((blob) => {
              saveBlob(blob, `preuve-achat-${bike?.bike_id}-${timeStamp}.pdf`)
              return blob
          })
          .catch((e) => null)

      if (!generateBikePDF) {
          toast("error", this.props.t("onebile.errror_folder_generation"))
      }
  }

  // COPROPRIO
  handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value
      },
      formError: {
        ...this.state.formError,
        [name]: false
      }
    })
  }

  // BKE INFO
  handleInputChangeInfo = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState({
      formDataInfo: {
        ...this.state.formDataInfo,
        [name]: value
      },
      formErrorInfo: {
        ...this.state.formErrorInfo,
        [name]: false
      }
    })
  }

  // VOLÉ
  handleInputChangeStolen = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    let city = this.state.formDataStolen.city
    if (name === 'cp') {
      city = ''
    }
    this.setState({
      formDataStolen: {
        ...this.state.formDataStolen,
        [name]: value,
        city : city
      },
      formErrorStolen: {
        ...this.state.formErrorStolen,
        [name]: false
      }
    },() => {
      if( name === 'cp' && value.length === 5 ) {
        var url = 'https://geo.api.gouv.fr/communes?codePostal='+value+'&fields=nom,code,codesPostaux,codeDepartement,population&format=json';
        fetch(url)
        .then( res => res.json() )
        .then( data => {
          if( data.length > 0 ) {
            var city=null, res=[];
            for(var i=0;i<data.length;i++) {
              city = data[i];
              for(var c=0;c<city.codesPostaux.length;c++) {
                res.push({
                  code:city.code,
                  dpt:city.codeDepartement,
                  cp:city.codesPostaux[c],
                  nom:city.nom
                });
              }
            }
            this.setState({
              citySuggestion:res,
              formErrorStolen: {
                ...this.state.formErrorStolen,
                [name]: false
              }
            })
          } else {
            window.notify(this.props.t('onebike.error_cp_unknwown'),'danger');
            this.setState({
              formErrorStolen: {
                ...this.state.formErrorStolen,
                [name]: true
              }
            })
          }

        })
        .catch((err) => {

        });
      }
    })
  }

  processSale = (event) => {
    event.preventDefault()
    let uri;
    if (this.state.isWallet) {
      uri = '/moncompte/mes-velos/'+this.props.match.params.id+'/transferer'
    } else {
      uri = '/moncompte/mon-velo/'+this.props.match.params.id+'/transferer'
    }
    this.props.history.push({
      pathname: uri,
      state: {
        from: this.props.location.pathname,
        bike_id : this.state.bike.bike_id,
        row_version : this.state.bike.row_version,
        bike_description : this.state.bike.bike_description,
        is_bicycode_2_0 : this.state.bike.is_bicycode_2_0,
        operator: this.state.bike.operator
      }
    });
  }

  processBackStore = (event) => {
    event.preventDefault()
    let uri;
    if (this.state.isWallet) {
      uri = '/moncompte/mes-velos/'+this.props.match.params.id+'/retour'
    } else {
      uri = '/moncompte/mon-velo/'+this.props.match.params.id+'/retour'
    }
    this.props.history.push({
      pathname: uri,
      state: {
        from: this.props.location.pathname,
        bike_id : this.state.bike.bike_id,
        row_version : this.state.bike.row_version,
        bike_description : this.state.bike.bike_description,
        bike_last_registered_by : this.state.bike.bike_last_registered_by,
        is_bicycode_2_0 : this.state.bike.is_bicycode_2_0
      }
    });
  }

  updateStatus = async (event) => {
    event.preventDefault()

    const formReady = true
    if (formReady) {
      this.setState({
        formEvent: {
            ...this.state.formEvent,
            ready: false
        },
        loading : true,
      })

      let knownBike = this.state.bike;
      let bikeToPut = {}
      bikeToPut.bike_id     = knownBike.bike_id
      bikeToPut.bike_status = this.state.status
      bikeToPut.row_version = knownBike.row_version
      bikeToPut.bike_status_detail = null;// TODO détail pour un vélo détruit ?
      let bike_owner = {}
      if (bikeToPut.bike_status === 3) { // détruit
        let gender = this.state.bike.bike_owner.gender
        bike_owner.mail         = MAIL_DESTROY
        bike_owner.phone        = TEL_DESTROY
        bike_owner.gender       = gender
        if (gender === 1) {
          bike_owner.first_name     = LABEL_DESTROY
          bike_owner.last_name      = LABEL_DESTROY
        } else {
          bike_owner.social_reason  = LABEL_DESTROY
        }
        bikeToPut.bike_owner = bike_owner
      } else if (this.state.isWallet && bikeToPut.bike_status === 5) {
        let consumer = await window.API.get_consumer('byConsumerId');
        if (!StrDef(consumer.error)) {
          bikeToPut.bike_owner = consumer.consumer_data
        }
      }

      let resPut
      if (this.state.isWallet) {
        resPut = await window.API.put_bike(bikeToPut);
      } else {
        resPut = await window.API.put_bike_no_wallet(bikeToPut);
      }
      
      if (!StrDef(resPut.error)) {
        window.notify(this.props.t('onebike.success_update'),'success')
        // Redirect if bike is destroy
        if (bikeToPut.bike_status === 3) {
          setTimeout(() => {
            this.props.history.push({ pathname: '/moncompte/mes-velos' })
          }, 1000)
        } else {
          this.setState({
            formEvent: {
                ...this.state.formEvent,
                ready: true
            },
            loading : false,
          })
          this.load()
        }
      } else if (StrDef(resPut.error)) {
        this.setState({
          formEvent: {
              ...this.state.formEvent,
              ready: true
          },
          loading : false,
        })
        if (resPut.error.code === 'TooManyRequestsException') {
          window.notify(this.props.t('onebike.error_already_update'),'danger')
        } else if (resPut.error.code === 'BikeVersionMismatchException') {
          window.notify(this.props.t('onebike.error_version'),'danger')
          this.load()
        } else if (resPut.error.code === 'FnuciApiException') {
          window.notify(this.props.t('onebike.error_other'),'danger')
          this.load()
        }
      }
    }
  }

  // VOLÉ / NORMAL
  handleSubmit = async (event) => {
    event.preventDefault()
    const formReady = true
    if (formReady) {
      this.setState({
        formDataStolen: { ...this.state.formDataStolen, },
        formErrorStolen: { ...this.state.formErrorStolen },
        formEventStolen: {
            ...this.state.formEventStolen,
            ready: false
        },
      })

      //cal API
      let knownBike = this.state.bike;
      let bikeToPut = {}
      bikeToPut.bike_id = knownBike.bike_id
      bikeToPut.bike_status = this.state.status
      bikeToPut.row_version = knownBike.row_version
      let bike_status_detail = null

      bikeToPut.bike_status_detail = bike_status_detail;
      let resPut
      if (this.state.isWallet) {
        let consumer = await window.API.get_consumer('byConsumerId');
        if (!StrDef(consumer.error)) {
          bikeToPut.bike_owner = consumer.consumer_data
        }
        resPut = await window.API.put_bike(bikeToPut);
      } else {
        resPut = await window.API.put_bike_no_wallet(bikeToPut);
      }

      if (!StrDef(resPut.error)) {
        window.notify(this.props.t('onebike.success_update'),'success')
        this.setState({
          formEventStolen: {
            ...this.state.formEventStolen,
            ready: true
          },
        },() => {
          this.load()
        })
      } else if (StrDef(resPut.error)) {
        this.setState({
          formEventStolen: {
            ...this.state.formEventStolen,
            ready: true
          },
        })
        if (resPut.error.code === 'TooManyRequestsException') {
          window.notify(this.props.t('onebike.error_already_update'),'danger')
        } else if (resPut.error.code === 'BikeVersionMismatchException') {
          window.notify(this.props.t('onebike.error_version'),'danger')
          this.load()
        }
      }

    }

  }

  // BIKE INFORMATION
  handleSubmitBikeInfo = async (event) => {
    event.preventDefault()

      this.setState({
        formDataInfo: { ...this.state.formDataInfo, },
        formErrorInfo: {
          engineSerialNumber : false,
          frameSerialNumber : false,
          batteryerialNumber : false
        },
        formEventInfo: {
            ...this.state.formEventInfo,
            ready: false
        },
      })

      // cal API
      let bikeToPut = {}
      let info = {}

      if (StrDef(this.state.formDataInfo.engineSerialNumber)) {
        info.engine_serial_number  = this.state.formDataInfo.engineSerialNumber
      }

      if (StrDef(this.state.formDataInfo.batterySerialNumber)) {
        info.battery_serial_number  = this.state.formDataInfo.batterySerialNumber
      }

      if (StrDef(this.state.formDataInfo.frameSerialNumber)) {
        info.frame_serial_number  = this.state.formDataInfo.frameSerialNumber
      }

      info.gear_type = this.state.bike.bike_description.gear_type
      info.brand = this.state.bike.bike_description.brand
      info.model = this.state.bike.bike_description.model
      info.color = this.state.bike.bike_description.color
      info.is_electric = this.state.bike.bike_description.is_electric

      let knownBike = this.state.bike;
      if (StrDef(knownBike.bike_description.brand_id)) {
        info.brand_id = knownBike.bike_description.brand_id
      }
      if (StrDef(knownBike.bike_description.gear_type_id)) {
        info.gear_type_id = knownBike.bike_description.gear_type_id
      }

      bikeToPut.bike_id = this.state.bike.bike_id
      bikeToPut.row_version = this.state.bike.row_version
      bikeToPut.bike_description = info

      let resPut
      if (this.state.isWallet) {
        resPut = await window.API.put_bike(bikeToPut);
      } else {
        resPut = await window.API.put_bike_no_wallet(bikeToPut);
      }

      if (!StrDef(resPut.error)) {
        window.notify(this.props.t('onebike.success_update_coowner'),'success')
        this.load()
      } else if (StrDef(resPut.error)){
        if (resPut.error.code === 'TooManyRequestsException') {
          window.notify(this.props.t('onebike.error_update_coowner'),'danger')
        } else if (resPut.error.code === 'BikeVersionMismatchException') {
          window.notify(this.props.t('onebike.error_version'),'danger')
        } else if (resPut.error.code === 'FnuciApiException') {
          window.notify(this.props.t('onebike.error_other'),'danger')
        } else {
          window.notify(this.props.t('onebike.error_other'),'danger')
        }
      }

      this.setState({
        formEventInfo: {
          ...this.state.formEventInfo,
          ready: true
        },
        disableBikeInfo:true,
      })
  }

  // COPRO
  handleSubmitCopro = async (event) => {
    event.preventDefault()
    const formReady = this.formValidationCopro()
    if (formReady) {
      this.setState({
        formData: { ...this.state.formData, },
        formError: {
          lastName : false,
          firstName : false
        },
        formEvent: {
            ...this.state.formEvent,
            ready: false
        },
      })

      //cal API
      let bikeToPut = {}
      let co_owner  = {}
      let bike_owner = this.state.bike.bike_owner
      if (!StrDef(this.state.formData.firstName) && !StrDef(this.state.formData.lastName) && !StrDef(this.state.formData.mail)) {
        delete bike_owner.co_owner
      } else {
        co_owner.first_name = this.state.formData.firstName
        co_owner.last_name  = this.state.formData.lastName
        if (StrDef(this.state.formData.mail)) {
          co_owner.mail       = this.state.formData.mail
        }
        bike_owner.co_owner = co_owner
      }

      bikeToPut.bike_id     = this.state.bike.bike_id
      bikeToPut.row_version = this.state.bike.row_version
      bikeToPut.bike_owner  = bike_owner
      let resPut
      if (this.state.isWallet) {
        resPut = await window.API.put_bike(bikeToPut);
      } else {
        resPut = await window.API.put_bike_no_wallet(bikeToPut);
      }
      if (!StrDef(resPut.error)) {
        window.notify(this.props.t('onebike.success_update_coowner'),'success')
        this.load()
      } else if (StrDef(resPut.error)){
        if (resPut.error.code === 'TooManyRequestsException') {
          window.notify(this.props.t('onebike.error_update_coowner'),'danger')
        } else if (resPut.error.code === 'BikeVersionMismatchException') {
          window.notify(this.props.t('onebike.error_version'),'danger')
        } else if (resPut.error.code === 'FnuciApiException') {
          window.notify(this.props.t('onebike.error_other'),'danger')
        } else {
          window.notify(this.props.t('onebike.error_other'),'danger')
        }
      }

      this.setState({
        formEvent: {
          ...this.state.formEvent,
          ready: true
        },
        disableCopro:true,
      })
    }
  }

  formValidationCopro = () => {
    let formData = this.state.formData;
    let formError = this.state.formError;
    let inputValidation = Object.keys(formData).map(itemName => {
      switch (itemName) {
        case 'firstName':
          const firstNameError = !StrDef(formData.firstName) && StrDef(formData.lastName)
          if (firstNameError) {
            formError.firstName = firstNameError
          }
          return !firstNameError
        case 'lastName':
          const lastNameError = !StrDef(formData.lastName) && StrDef(formData.firstName)
          if (lastNameError) {
            formError.lastName = lastNameError
          }
          return !lastNameError
        case 'mail':
          const mailError = StrDef(formData.mail) && !ValidateEmail(formData.mail)
          if (mailError) {
            formError.mail = mailError
          }
          return !mailError
        default:
            return true
      }
    })
    this.setState({formError:formError})
    return inputValidation.reduce((acc, next) => acc && next)
  }

  async changeValue(e) {
    let status = this.status.find(oneStatus => oneStatus.id === parseInt(e.currentTarget.id))
    let statusInProgress = status.label
    this.setState({
      status : status.id,
      statusInProgress:statusInProgress,
    })
  }

  render() {
    const { t } = this.props;
    let subdomainTransfer
    if (StrDef(this.state.bike.bike_id)) {
      subdomainTransfer = GetSubDomainByOperator(this.state.bike.operator)
    }
    return(
      <main className={"c-main-content u-flex u-flex-dir-col"}>
        {this.state.loading &&
          <div className="u-center">
            <div className="c-spinner"></div>
          </div>
        }
        {!this.state.loading &&
          <>
            <button className="c-btn-back" title={t('common.back')} onClick={() => this.props.history.goBack()} >
              <Icon key={'back'} name="back" size={30} />
            </button>
            <div className="c-skew-header">
              <div className="c-skew-header__top">
                <div className="l-container-md u-pd-hz-m">
                  <h1 className="c-h1 u-medium u-uppercase">{this.state.brand}</h1>
                </div>
              </div>
              <div className="c-skew-header__bottom">
                <div className="l-container-md u-pd-hz-m">
                  <div className="c-skew-header__color">{this.state.color}</div>
                  <div className="u-flex u-flex-center-vt u-flex-end">
                    <div className="c-skew-header__code u-pd-right-xl u-mg-top-l">
                      {this.state.code}
                    </div>
                    <div className="c-skew-header__canvas">
                      <canvas className="" id='ocode_bike' width={WIDHT_CANVAS_OCODE_DISPLAY} height={WIDHT_CANVAS_OCODE_DISPLAY} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="u-pd-hz-m u-pd-vt-l">

              {/* Status */}
              <div className={"c-form-group l-form-width u-mg-bottom-m"}>
                <label className="c-label" htmlFor="code">{t('onebike.label_state_bike')}</label>
                <UncontrolledDropdown className={"c-dropdown"}>
                  <DropdownToggle className={"c-dropdown__toggle"} caret>
                      {this.state.statusInProgress}
                  </DropdownToggle>
                  <DropdownMenu className={"c-dropdown__menu"} >
                    {this.status.map((data, i) => {
                      return(
                        <DropdownItem key={'stat' + i}>
                          <div id={data.id} onClick={this.changeValue}>
                            {data.label}
                          </div>
                        </DropdownItem>
                      )
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              {this.state.status === 1 && (this.state.bike.bike_status === 2) &&
                <div className="l-form-width">
                  <p className="u-fs-xs u-blue20 u-mg-bottom-s">{t('onebike.label_bike_find')}</p>
                </div>
              }
              {this.state.status === 1 && (this.state.status !== this.state.bike.bike_status) &&
                <div className="u-flex u-flex-center-hz u-mg-top-l">
                  <button
                    type="submit"
                    className="c-btn c-btn--primary"
                    onClick={this.state.formEventStolen.ready ? this.updateStatus.bind(this) : undefined}
                  >
                    {this.state.formEventStolen.ready ? t('onebike.label_save') : t('onebike.label_waiting')}
                  </button>
                </div>
              }

              {/* Perdu / volé */}
              {this.state.status === 2 &&
                <form className="l-form-width">
                  <p className="u-fs-xs u-blue20 u-mg-bottom-l">{t('onebike.label_explain_stolen')}</p>
                
                  {this.state.status === 2 && (this.state.status !== this.state.bike.bike_status) &&
                    <div className="u-flex u-flex-center-vt u-mg-top-l">
                      {false &&
                        <div className="u-flex-1 u-flex u-flex-center-hz">
                          <button
                            type="button"
                            className="c-btn c-btn--primary"
                            onClick={this.load}
                          >
                            {t('onebike.label_cancel')}
                          </button>
                        </div>
                      }

                      <div className="u-flex-1 u-flex u-flex-center-hz">
                        <button
                          type="submit"
                          className="c-btn c-btn--primary"
                          onClick={this.state.formEventStolen.ready ? this.handleSubmit.bind(this) : undefined}
                        >
                          {this.state.formEventStolen.ready ? t('onebike.label_save') : t('onebike.label_waiting')}
                        </button>
                      </div>
                    </div>
                  }


                </form>
              }

              {/* En vente */}
              {this.state.status === 5 && this.state.bike.bike_status === 5 &&
                <div className="l-form-width">
                  <p className="u-fs-xs u-blue20 u-mg-bottom-s">{t('onebike.label_explain_transfer_1')}</p>

                {/* Explanation for a Bicycode bike */}
                {this.state.bike.operator === "BC"
                  ?
                    <p className="u-fs-xs u-blue20 u-mg-bottom-s">{t('onebike.label_explain_bicycode_transfer')} <a href="https://wallet.o-code.co/" className="u-bold">wallet.o-code.co</a></p>
                  :
                    <>
                      <p className="u-fs-xs u-blue20 u-mg-bottom-s">{t('onebike.label_explain_transfer_2')}</p>
                      <ul>
                        <li className="u-fs-xs u-blue20">{t('onebike.label_li_1')}</li>
                        <li className="u-fs-xs u-blue20 u-mg-bottom-s">{t('onebike.label_li_2')} {'https://'+subdomainTransfer+'.o-code.co/pre-inscription'}</li>
                      </ul>
                      <p className="u-fs-xs u-blue20 u-mg-bottom-s">{t('onebike.label_explain_transfer_3')}</p>
                    </>
                }
                  <div className="u-flex-1 u-flex u-flex-center-hz u-mg-top-l">
                    <button type="button" className="c-btn c-btn--primary" onClick={this.processSale.bind(this)}>
                      {t('onebike.label_continue')}
                    </button>
                  </div>
                </div>
              }
              {this.state.status === 5 && (this.state.status !== this.state.bike.bike_status) &&
                <form className="l-form-width">
                  <div className="l-form-width">
                    <p className="u-fs-xs u-blue20 u-mg-bottom-s">{t('onebike.label_explain_sale')}</p>
                    <div className="u-flex-1 u-flex u-flex-center-hz u-mg-top-l">
                      <button
                        type="submit"
                        className="c-btn c-btn--primary"
                        onClick={this.state.formEventStolen.ready ? this.updateStatus.bind(this) : undefined}
                      >
                        {this.state.formEventStolen.ready ? t('onebike.label_save') : t('onebike.label_waiting')}
                      </button>
                    </div>
                  </div>
                </form>
              }

              {/* Hors d'usage / détruit */}
              {this.state.status === 3 && (this.state.status !== this.state.bike.bike_status) &&
                <div className="l-form-width">
                  <p className="u-fs-xs u-blue20 u-mg-bottom-s">
                    {t('onebike.label_explain_destroy')}
                  </p>
                  <p className="u-fs-xs u-red25 u-bold">
                    {t('onebike.label_explain_destroy_warning')}
                  </p>
                  <p className="u-fs-xs u-red25 u-mg-bottom-s">
                    {
                      t(window.API.getSubDomain() === 'wallet' ? 'onebike.label_explain_destroy_wallet' : 'onebike.label_explain_destroy_nowallet')
                    }
                  </p>
                  <div className="u-flex-1 u-flex u-flex-center-hz u-mg-top-l">
                    <button type="button" className="c-btn c-btn--primary" onClick={this.state.formEvent.ready ? this.updateStatus.bind(this) : undefined}>
                      {this.state.formEvent.ready ? t('onebike.label_save') : t('onebike.label_waiting')}
                    </button>
                  </div>
                </div>
              }

              {/* Retour magasin */}
              {this.state.status === 6 &&
                <div className="l-form-width">
                  <p className="u-fs-xs u-blue20 u-mg-bottom-s">{t('onebike.label_explain_backstore')}</p>
                  <div className="u-flex-1 u-flex u-flex-center-hz u-mg-top-l">
                    <button type="button" className="c-btn c-btn--primary" onClick={this.processBackStore.bind(this)}>
                      {this.state.formEvent.ready ? t('onebike.label_continue') : t('onebike.label_waiting')}
                    </button>
                  </div>
                </div>
              }

              {/* Description du vélo */}
              <div className="c-section-title u-pd-left-m u-mg-negative-hz-m u-mg-vt-l">
                <div className="l-container-md u-relative u-pd-right-m">
                  <div className="c-section-title__content">
                    <h2 className="c-h2 u-medium u-uppercase">{t('onebike.label_bike_description')}</h2>
                    <Icon key={''} name="" size={40} />
                  </div>
                </div>
              </div>

              <FormInput
                className={"l-form-width u-mg-bottom-m"}
                formData={this.state.formDataBike}
                formError={this.state.formErrorBike}
                what={"gear_type"}
                error={""}
                placeholder={t('onebike.label_type')}
                handleInputChange={null}
                disabled={true}
              />

              <FormInput
                className={"l-form-width u-mg-bottom-m"}
                formData={this.state.formDataBike}
                formError={this.state.formErrorBike}
                what={"brand"}
                error={""}
                placeholder={t('onebike.label_brand')}
                handleInputChange={null}
                disabled={true}
              />

              <FormInput
                className={"l-form-width u-mg-bottom-m"}
                formData={this.state.formDataBike}
                formError={this.state.formErrorBike}
                what={"color"}
                error={""}
                placeholder={t('onebike.label_color')}
                handleInputChange={null}
                disabled={true}
              />

              <FormInput
                className={"l-form-width u-mg-bottom-m"}
                formData={this.state.formDataBike}
                formError={this.state.formErrorBike}
                what={"model"}
                error={""}
                placeholder={t('onebike.label_model')}
                handleInputChange={null}
                disabled={true}
              />

              <FormInput
                className={"l-form-width u-mg-bottom-m"}
                formData={this.state.formDataBike}
                formError={this.state.formErrorBike}
                what={"label_electric"}
                error={""}
                placeholder={t('onebike.label_electric')}
                handleInputChange={null}
                disabled={true}
              />

            <form className="l-form-width">
              {this.state.formDataBike.is_electric === true &&
                <>
                  <FormInput
                    className={"l-form-width u-mg-bottom-m"}
                    formData={this.state.formDataInfo}
                    formError={this.state.formErrorInfo}
                    what={"engineSerialNumber"}
                    error={t('onebike.label_engine_serial_error')}
                    placeholder={t('onebike.label_engine_serial')}
                    handleInputChange={this.handleInputChangeInfo}
                    disabled={this.state.disableBikeInfo}
                  />
                  <FormInput
                    className={"l-form-width u-mg-bottom-m"}
                    formData={this.state.formDataInfo}
                    formError={this.state.formErrorInfo}
                    what={"batterySerialNumber"}
                    error={t('onebike.label_battery_serial_error')}
                    placeholder={t('onebike.label_battery_serial')}
                    handleInputChange={this.handleInputChangeInfo}
                    disabled={this.state.disableBikeInfo}
                  />
                </>
              }
              <FormInput
                className={"l-form-width u-mg-bottom-m"}
                formData={this.state.formDataInfo}
                formError={this.state.formErrorInfo}
                what={"frameSerialNumber"}
                error={t('onebike.label_serial_number_error')}
                placeholder={t('onebike.label_serial_number')}
                handleInputChange={this.handleInputChangeInfo}
                disabled={this.state.disableBikeInfo}
              />

              <p className="u-fs-xs u-blue20 u-mg-bottom-m">
                {t('onebike.label_explain_modification')}
              </p>

              {this.state.disableBikeInfo &&
                <div className="u-flex-1 u-flex u-flex-center-hz">
                  <button
                    type="button"
                    className="c-btn c-btn--primary"
                    onClick={() => {this.setState({disableBikeInfo: !this.state.disableBikeInfo})}}
                  >
                    {this.state.formEventInfo.ready ? t('onebike.label_modify') : t('onebike.label_waiting')}
                  </button>
                </div>
              }

              {!this.state.disableBikeInfo &&
                <div className="u-flex u-flex-center-vt u-mg-top-l">
                  <div className="u-flex-1 u-flex u-flex-center-hz">
                    <button
                      type="button"
                      className="c-btn c-btn--primary"
                      onClick={this.load}
                    >
                      {t('onebike.label_cancel')}
                    </button>
                  </div>
                  <div className="u-flex-1 u-flex u-flex-center-hz">
                    <button
                      type="button"
                      className="c-btn c-btn--primary"
                      onClick={this.state.formEventInfo.ready ? this.handleSubmitBikeInfo.bind(this) : undefined}
                    >
                      {this.state.formEventInfo.ready ? t('onebike.label_save') : t('onebike.label_waiting')}
                    </button>
                  </div>
                </div>
              }
            </form>

              {/* Co-propriétaire */}
              {StrDef(this.state.bike.bike_owner) && this.state.bike.bike_owner.gender !== 2 && this.state.bike.bike_status !== 3 && // détruit
                <>
                  <div className="c-section-title u-pd-left-m u-mg-negative-hz-m u-mg-vt-l">
                    <div className="l-container-md u-relative u-pd-right-m">
                      <div className="c-section-title__content">
                        <h2 className="c-h2 u-medium u-uppercase">{t('onebike.label_copro')}</h2>
                      </div>
                    </div>
                  </div>

                  <form className="l-form-width">
                  <FormInput
                    className={"u-mg-bottom-m"}
                    formData={this.state.formData}
                    formError={this.state.formError}
                    what={"lastName"}
                    error={t('onebike.label_lastname_error')}
                    placeholder={t('onebike.label_lastname')}
                    handleInputChange={this.handleInputChange}
                    disabled={this.state.disableCopro}
                  />

                  <FormInput
                    className={"u-mg-bottom-m"}
                    formData={this.state.formData}
                    formError={this.state.formError}
                    what={"firstName"}
                    error={t('onebike.label_firstname_error')}
                    placeholder={t('onebike.label_firstname')}
                    handleInputChange={this.handleInputChange}
                    disabled={this.state.disableCopro}
                  />

                  <FormInput
                    className={"u-mg-bottom-m"}
                    formData={this.state.formData}
                    formError={this.state.formError}
                    what={"mail"}
                    error={t('onebike.label_mail_error')}
                    placeholder={t('onebike.label_mail')}
                    handleInputChange={this.handleInputChange}
                    disabled={this.state.disableCopro}
                  />

                  {this.state.disableCopro &&
                      <div className="u-flex-1 u-flex u-flex-center-hz">
                        <button
                          type="button"
                          className="c-btn c-btn--primary"
                          onClick={() => {this.setState({disableCopro: !this.state.disableCopro})}}
                        >
                          {this.state.formEvent.ready ? t('onebike.label_modify') : t('onebike.label_waiting')}
                        </button>
                      </div>
                    }

                    {!this.state.disableCopro &&
                      <div className="u-flex u-flex-center-vt u-mg-top-l">
                        <div className="u-flex-1 u-flex u-flex-center-hz">
                          <button
                            type="button"
                            className="c-btn c-btn--primary"
                            onClick={this.load}
                          >
                            {t('onebike.label_cancel')}
                          </button>
                        </div>
                        <div className="u-flex-1 u-flex u-flex-center-hz">
                          <button
                            type="button"
                            className="c-btn c-btn--primary"
                            onClick={this.state.formEvent.ready ? this.handleSubmitCopro.bind(this) : undefined}
                          >
                            {this.state.formEvent.ready ? t('onebike.label_save') : t('onebike.label_waiting')}
                          </button>
                        </div>
                      </div>
                    }
                  </form>
                </>
              }


              {/*  Télécharger la preuve d'achat */}
              <div className="l-form-width u-mg-top-xl">
                <p className="u-fs-xs u-blue20 u-mg-bottom-s u-center">
                  {t('onebike.label_buy_document')}
                </p>

                <div className="u-flex u-flex-center-hz u-mg-top-s">
                  <button type="button" className="c-btn c-btn--primary" onClick={(e) => this.viewSummary(e, this.state.bike)}>
                    {t('common.download')} {t('onebike.label_pdf')}
                  </button>
                </div>
              </div>
              <Toaster />

            </div>
          </>
        }

      </main>
    )
  }
}

export default withTranslation()(OneBike);