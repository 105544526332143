/**
 * Languages used for translation
 */
export const languages = {
  'en-UK': { language: "English", country: "United Kingdom", dir: "ltr"},
  'fr-FR': { language: "Français", country: "France", dir: "ltr"},
  //'fr-BE': { language: "Français", country: "Belgique", dir: "ltr"},
  //'nl-BE': { language: "Nederlands", country: "België", dir: "ltr"},
  'nl-NL': { language: "Nederlands", country: "Nederland", dir: "ltr"},
};

export const WALLET_TOU_LINK = {
  'fr-FR': "https://legal.o-code.co/bike/Wallet/CGU/fr/CGU_WALLET_20210301.pdf",
  'fr': "https://legal.o-code.co/bike/Wallet/CGU/fr/CGU_WALLET_20210301.pdf",
  'en-UK': "https://legal.o-code.co/bike/Wallet/CGU/en/TOU_WALLET_20220301.pdf",
  'nl-NL': "https://legal.o-code.co/bike/Wallet/CGU/nl/AGE_WALLET_20220301.pdf"
}

export const WALLET_LEGAL_NOTICE_LINK = {
  'fr-FR': "https://legal.o-code.co/bike/Wallet/ML/fr/MentionsLegales_WALLET_20210301.pdf",
  'fr': "https://legal.o-code.co/bike/Wallet/ML/fr/MentionsLegales_WALLET_20210301.pdf",
  'en-UK': "https://legal.o-code.co/bike/Wallet/ML/en/LegalNotice_WALLET_20220301.pdf",
  'nl-NL': "https://legal.o-code.co/bike/Wallet/ML/nl/WettelijkeMededelingen_WALLET_20210301.pdf"
}

export const NO_WALLET_TOU_LINK = "https://legal.o-code.co/bike/MFC/NoWallet/CGU/fr/CGU_MFC_20210301.pdf"

export const NO_WALLET_LEGAL_NOTICE_LINK = "https://legal.o-code.co/bike/MFC/NoWallet/ML/fr/MentionsLegales_MFC_20210301.pdf"
