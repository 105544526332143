import React from "react"
import { useHistory } from "react-router-dom"

import Logo from "components/Logos/Logo";
import { AsideFooter } from "components/Footer/Footer";
import { withTranslation,useTranslation } from 'react-i18next';

const DesktopSideView = () => {
    const { t } = useTranslation()
    const history = useHistory()

    const goToHome = () => {
      var authenticated = localStorage.getItem('isAuth') === '1'

      if (authenticated) {
        history.push({
          pathname: '/moncompte/mes-velos',
        });
      } else {
        history.push({
          pathname: '/',
        });
      }
    }

    return (
      <>
        <div className="c-content u-pd-xxl">
            <button
              type="button"
              onClick={() => goToHome()}
              className="u-flex u-flex-center-hz u-mg-hz-auto u-mg-bottom-xl">
                <Logo vertical={true} className="c-logo c-logo--lg u-primary" />
            </button>


              <>
                <p className="u-center u-fs-xs u-mg-bottom-s text-justify">{t('desktopsideview.label_explain_p1')}</p>
                <p className="u-center u-fs-xs text-justify" >{t('desktopsideview.label_explain_wallet')}</p>
              </>

        </div>
        <AsideFooter />
      </>
    )
}

export default withTranslation()(DesktopSideView);
