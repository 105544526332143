import React from "react"
import {
    Text,
    View,
} from "@react-pdf/renderer"
import { useTranslation } from "react-i18next"
import styles from "./PDFStyles"
import RequestPDF from "./PDFRequestTemplate"

const SummaryBikePDF = ({ bike, operatorGender }) => {
    const { t } = useTranslation()
    const listContent = t("pdf.main_bottom_list", { returnObjects: true })

    return (
        <RequestPDF bike={bike} operatorGender={operatorGender}>
            <View>
                <Text style={[styles.p]}>{t("pdf.main_bottom_content")}</Text>
            </View>

            <View style={[styles.list]}>   
                {
                    listContent.map((item, index) => {
                        return <Text style={styles.li} key={index}> {item} </Text>
                    })
                }
            </View>

            <View style={[styles.bottomPage]}>
                <Text style={[styles.p]}>{t("pdf.greetings")}</Text>
                <Text style={[styles.p]}>{t("pdf.signature")}</Text>
            </View>
        </RequestPDF>
    )
}

export default SummaryBikePDF