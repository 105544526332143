import React from "react";
import {StrDef,GetBikeIdFormat} from '../assets/lib/utils';
import FormInput from '../components/Forms/FormInput';
import Icon from "../components/Icons/Icon.jsx";
import { withTranslation } from 'react-i18next';

class BackStore extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isWallet : false,
      step : 1,//2
      rowVersion : '',
      bikeId : '',
      socialReason : '',
      formDataBackStore : {
        brand : '',
        colorBike : '',
        model : '',
        gearType : '',
        fnuciCode : '',
        code : '', // ocode_id ou short_code_id
      },
      formErrorBackStore : {

      },
      formEventBackStore : {
        ready : true,
      },
    }
  }

  async componentDidMount() {
    let isWallet = this.state.isWallet

    if (window.API.getSubDomain() === 'wallet') {
      isWallet = true
    } else {
      isWallet = false
    }
    this.setState({isWallet:isWallet})
    if (StrDef(this.props.location.state) && StrDef(this.props.location.state.bike_description)) {
      let bike_id           = this.props.location.state.bike_id
      let bike_description  = this.props.location.state.bike_description
      let rowVersion        = this.props.location.state.row_version
      let is_bicycode_2_0   = this.props.location.state.is_bicycode_2_0
      let bike_last_registered_by   = this.props.location.state.bike_last_registered_by

      this.setState({
        bikeId : bike_id,
        rowVersion : rowVersion,
        socialReason : bike_last_registered_by?.social_reason,
        formDataBackStore: {
          ...this.state.formDataBackStore,
          brand : bike_description.brand,
          model : bike_description.model,
          gearType : bike_description.gear_type,
          colorBike : bike_description.color,
          fnuciCode : ( is_bicycode_2_0 === 0 || is_bicycode_2_0=== 1) ? this.props.location.state.bike_id : GetBikeIdFormat(this.props.location.state.bike_id)
        }
      })
    }
  }

  confirmBackStore = async (event) => {
    event.preventDefault()

    const formReady = true
    if (formReady) {
      this.setState({
        formEventBackStore: {
            ...this.state.formEventBackStore,
            ready: false
        },
        loading : true,
      })

      let bikeToPut = {}
      bikeToPut.bike_id     = this.state.bikeId
      bikeToPut.bike_status = 6
      bikeToPut.row_version = this.state.rowVersion
      bikeToPut.bike_status_detail = null;

      let resPut = {}
      if (this.state.isWallet) {
        resPut = await window.API.put_bike(bikeToPut);
      } else {
        resPut = await window.API.put_bike_no_wallet(bikeToPut);
      }
      if (!StrDef(resPut.error)) {
        this.setState({
          formEventBackStore: {
              ...this.state.formEventBackStore,
              ready: true
          },
          loading : false,
        })
        window.notify(this.props.t('backstore.success_backstore'),'success')

        this.setState({step:3})
      } else if (StrDef(resPut.error)) {
        this.setState({
          formEventBackStore: {
              ...this.state.formEventBackStore,
              ready: true
          },
          loading : false,
        })
        if (resPut.error.code === 'TooManyRequestsException') {
          window.notify(this.props.t('onebike.error_already_update'),'danger')
        } else if (resPut.error.code === 'BikeVersionMismatchException') {
          window.notify(this.props.t('onebike.error_version'),'danger')
          this.props.history.goBack()
        } else if (resPut.error.code === 'FnuciApiException') {
          window.notify(this.props.t('onebike.error_other'),'danger')
          this.props.history.goBack()
        }
      }
    }
  }

  goToHome() {
    if (this.state.isWallet) {
      this.props.history.push({
        pathname: '/moncompte/mes-velos',
      });
    } else {
      // plus de vélo
      //window.Logout()
      this.props.history.push({
        pathname: '/moncompte/mon-velo',
      });
    }
  }

  render() {
    const { t } = this.props;
    return(
      <main className={"c-main-content u-flex u-flex-dir-col"}>
      {this.state.loading &&
        <div className="u-center">
          <div className="c-spinner"></div>
        </div>
      }
      {!this.state.loading &&
        <>
          <button className="c-btn-back" title={t('common.back')} onClick={() => this.props.history.goBack()} >
            <Icon key={'back'} name="back" size={30} />
          </button>

          <div className="c-header u-pd-top-l@main">
            <div className="l-container-md u-pd-hz-m">
              <h1 className="c-h1 c-header__title u-medium u-uppercase">{t('backstore.title')}</h1>
            </div>
          </div>

          <div className="l-container-md u-pd-hz-m u-pd-vt-l">
            {/* Créer le lien */}
            {this.state.step === 1 &&
              <div className="l-form-width u-pd-bottom-xl">
                <div className="u-center text-justify">
                  <h2 className="c-h2 u-center u-bold u-mg-bottom-xl">{t('backstore.subtitle_infos_bike')}</h2>
                  <p className="u-fs-xs u-center u-blue20 u-mg-bottom-xl">{t('backstore.label_backstore_p1')} : <br /> {this.state.socialReason}</p>
                </div>

                <FormInput
                  className={"u-mg-bottom-m"}
                  formData={this.state.formDataBackStore}
                  formError={this.state.formErrorBackStore}
                  what={"brand"}
                  error={""}
                  placeholder={t('backstore.label_brand')}
                  handleInputChange={null}
                  disabled={true}
                />

                <FormInput
                  className={"u-mg-bottom-m"}
                  formData={this.state.formDataBackStore}
                  formError={this.state.formErrorBackStore}
                  what={"colorBike"}
                  error={""}
                  placeholder={t('backstore.label_color')}
                  handleInputChange={null}
                  disabled={true}
                />

                <FormInput
                  className={"u-mg-bottom-l"}
                  formData={this.state.formDataBackStore}
                  formError={this.state.formErrorBackStore}
                  what={"model"}
                  error={""}
                  placeholder={t('backstore.label_model')}
                  handleInputChange={null}
                  disabled={true}
                />

                <FormInput
                  className={"u-mg-bottom-l"}
                  formData={this.state.formDataBackStore}
                  formError={this.state.formErrorBackStore}
                  what={"gearType"}
                  error={""}
                  placeholder={t('backstore.label_gear_type')}
                  handleInputChange={null}
                  disabled={true}
                />

                <FormInput
                  className={"u-mg-bottom-l"}
                  formData={this.state.formDataBackStore}
                  formError={this.state.formErrorBackStore}
                  what={"fnuciCode"}
                  error={""}
                  placeholder={t('backstore.label_bikecode')}
                  handleInputChange={null}
                  disabled={true}
                />

                <p className="u-fs-xs u-center u-blue20 u-mg-bottom-m">{t('backstore.label_backstore_p2')}</p>

                <div className="u-flex u-flex-center-hz u-mg-top-l">
                  <button
                    type="button"
                    className="c-btn c-btn--primary u-fs-xs"
                    onClick={()=>{this.setState({step:2})}}
                  >
                    {t('backstore.label_continue')}
                  </button>
                </div>
              </div>
            }

            {/* Verification */}
            {this.state.step === 2 &&
              <div className="l-form-width u-pd-bottom-xl">
                <div className="u-center text-justify">
                  <h2 className="c-h2 u-center u-bold u-mg-bottom-xl">{t('backstore.subtitle_confirm')}</h2>
                  <p className="u-fs-xs u-blue20 u-mg-bottom-s">{t('backstore.label_confirm_p1')}</p>
                  <p className="u-fs-xs u-blue20 u-mg-bottom-xl">{t('backstore.label_confirm_p2')}</p>
                  <p className="u-fs-xs u-blue20 u-mg-bottom-l u-bold">{t('backstore.label_confirm_p3')}</p>
                </div>
                <div className="u-flex u-flex-center-hz u-mg-top-l">
                  <button type="button" className="c-btn c-btn--primary" onClick={this.state.formEventBackStore.ready ?this.confirmBackStore : null}>
                    {this.state.formEventBackStore.ready ? t('backstore.label_confirm') : t('backstore.label_waiting')}
                  </button>
                </div>
            </div>
          }

          {/* Verification */}
          {this.state.step === 3 &&
            <div className="l-form-width u-pd-bottom-xl">
              <div className="u-center text-justify">
                <h2 className="c-h2 u-center u-bold u-mg-bottom-xl">{t('backstore.subtitle_save')}</h2>
                <p className="u-fs-xs u-blue20 u-mg-bottom-s">{t('backstore.label_save_p1')}</p>
                {this.state.isWallet &&
                  <p className="u-fs-xs u-blue20 u-mg-bottom-xl">{t('backstore.label_save_p2_wallet')}</p>
                }
                {!this.state.isWallet &&
                  <p className="u-fs-xs u-blue20 u-mg-bottom-xl">{t('backstore.label_save_p2_nowallet')}</p>
                }
              </div>
              {this.state.isWallet &&
                <div className="u-flex u-flex-center-hz u-mg-top-l">
                  <button
                    type="button"
                    className="c-btn c-btn--primary"
                    onClick={this.goToHome.bind(this)}
                  >
                    {t('backstore.label_home')}
                  </button>
                </div>
              }

          </div>
        }
          </div>
        </>
      }
      </main>
    )
  }
}


export default withTranslation()(BackStore);
