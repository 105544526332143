import React from "react";

import {
  StrDef,
  Clone,
  ValidateEmail,
  isPhoneNumber,
  isMdpValid,
  isInt,
  isBikeCodeValid
} from '../assets/lib/utils';
import Icon from "../components/Icons/Icon.jsx";
import FormInput from '../components/Forms/FormInput';
import FormPassword from '../components/Forms/FormPassword';
import FormPerson from '../components/Forms/FormPerson';
import Logo from "../components/Logos/Logo"
import { withTranslation } from 'react-i18next';

const PASSWORD_LENGTH = 8;

class PreinscriptionRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modePreRegister : true,
      role : 1, // particulier par défaut,
      // subdomain: window.API.getSubDomain(),
      formDataRegisterPerson : {
        socialReason  : '',
        lastName      : '', // nom
        firstName     : '', // prénom
        phone         : '',
        email         : '',
        confirmMail   : '',
      }
    }

    this.switchMode       = this.switchMode.bind(this);
    this.changeValueRole  = this.changeValueRole.bind(this);

  }
  componentDidMount() {
    document.body.classList.add("home-page", "light-header");
  }

  changeValueRole(e) {
    let role = e.currentTarget.id;
    this.setState({
      role:parseInt(role)
    })
  }

  updateStatePerson = (state) => {
    this.setState({
      role : state.role,
      formDataRegisterPerson: state.formData,
    },() => {
      console.log("new state ",this.state);
    })
  }

  preregister = async () => {
    // CALL PI
    let data = {}
    let formPerson = this.state.formDataRegisterPerson

    data.mail   = formPerson.email
    data.phone  = formPerson.phone
    data.gender = this.state.role
    if (this.state.role === 1) {
      data.last_name      = formPerson.lastName
      data.first_name     = formPerson.firstName
    } else if(this.state.role === 2) {
      data.social_reason  = formPerson.socialReason
    }

    let resPust = await window.API.pre_registration(data);
    if (StrDef(resPust.error)) {
      window.notify(this.props.t('common.error.unknow_pb_try_later'),'danger')
    } else {
      this.props.history.push('/pre-inscription/'+resPust.pre_registration_id);//uuid
    }

  }

  switchMode() {
    this.setState({modePreRegister:!this.state.modePreRegister})
  }

  goToWallet() {
    let urlWallet = 'https://wallet.' + document.domain.split('.')[1]
    if (document.domain.split('.')[1] === 'localhost') {
      urlWallet += ':3000'
    } else {
      urlWallet += process.env.REACT_APP_MODE == 'production' ? '.co' : '.fr'
    }
    window.location = urlWallet
  }

  render() {
    const { t } = this.props;
    return (
      <main className={"c-main-content u-pd-hz-xl u-pd-xl@main"}>
        <div className="u-flex-mobile-only u-flex-center-hz u-mg-bottom-xl">
          <Logo className="u-primary" />
        </div>

        <div className="l-form-width-container u-mg-bottom-xl">
          {/* Tabs navigation */}
          <div className="c-tabs">
            <div
              className={"c-tabs__tab " + (this.state.modePreRegister ? "is-active" : "")}
              onClick={!this.state.modePreRegister ? this.switchMode : null}
            >
              {t('preinscription.title_tab_1')}
            </div>
            <div
              className={"c-tabs__tab " + (this.state.modePreRegister ? "" : "is-active")}
              onClick={!this.state.modePreRegister ? null : this.switchMode}
            >
              {t('preinscription.title_tab_2')}
            </div>
          </div>
        </div>

        <div className="l-form-width">

          {/* Register */}
          {this.state.modePreRegister === true &&
            <>
              <p className="u-fs-xs u-blue20 u-mg-bottom-s ">{t('preinscription.nowallet_p1')}</p>

              <FormPerson
                mode={'preinscription'}
                formData={this.state.formDataRegisterPerson}
                updateState={this.updateStatePerson}
                onSubmit = {this.preregister}
              />
            </>
          }

          {/* Goto O°Code Wallet */}
          {this.state.modePreRegister === false &&
            <>
              <p className="u-fs-xs u-blue20 u-mg-bottom-s">{t('preinscription.wallet_p1')}</p>
              <p className="u-fs-xs u-blue20 u-mg-bottom-s">{t('preinscription.wallet_p2')}</p>
              <div className="u-flex u-flex-center-hz u-mg-vt-xl">
                <button
                  type="button"
                  className="c-btn c-btn--primary"
                  onClick={this.goToWallet.bind(this)}
                >
                  {t('preinscription.goto_wallet')}
                </button>
              </div>
            </>
          }

        </div>

      </main>
    )
  }
}

export default withTranslation()(PreinscriptionRegister);
