import React from "react"
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'

const HelpFAQ = ({ className }) => {
    const { t } = useTranslation();

    return (
      <Link to="/faq" className="u-pd-xs">
        <div className={"c-help " + (className || "")}>
          <div className="c-help__content">
              <h3>{t('help.title')}</h3>
              <p>{t('help.content_faq')}</p>
          </div>
        </div>
      </Link>
    )
}

export default HelpFAQ
