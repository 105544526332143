import React from "react"
import {StrDef} from '../assets/lib/utils'

import { WALLET_TOU_LINK, NO_WALLET_TOU_LINK } from "../utils/languages";
import { withTranslation } from 'react-i18next'
import * as translationContext from 'translation';
var moment = require('moment')


class CGUNoWallet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData : {
        cguAccept : false,
      },
      formError : {
        cguAccept : false,
      },
      formEvent : {
        ready : false,
      },
      currentLanguage : translationContext.getLanguage()
    };
  }

  handleInputChange = (event) => {
    const target = event.target
    const val = target.type === 'checkbox' ? target.checked : target.value
    const what = target.name

    this.setState({
      formData: {
        ...this.state.formData,
        [what]: val
      },
      formError: {
        ...this.state.formError,
        [what]: false
      },

    })
  }

  formValidation = () => {
    let formData = this.state.formData;
    let formError = this.state.formError;
    let inputValidation = Object.keys(formData).map(itemName => {
      switch (itemName) {
        case 'cguAccept':
          const cguAcceptError = formData.cguAccept == false
          if (cguAcceptError) {
            formError.cguAccept = cguAcceptError
          }
          return !cguAcceptError
        default:
            return true
      }
    })
    this.setState({formError:formError})
    return inputValidation.reduce((acc, next) => acc && next)
  }

  goToAccount = async (event) => {
    event.preventDefault()
    // Validate inputs
    const formReady = this.formValidation()

    if (formReady) {
      // Inform the state that the component is unavailable
      // to prevent any new user submit
      this.setState({
        formData: { ...this.state.formData, },
        formError: { ...this.state.formError },
        formEvent: {
          ...this.state.formEvent,
          ready: false
        },
      })

      // push home
      let date = moment.utc().format('YYYY-MM-DD HH:mm:ss.SSS')
      let obj = {}
      obj.accept_date = date
      obj.version     = "v1"
      let res = await window.API.accept_cgu(obj)
      if (StrDef(res.error)) {
        window.notify(this.props.t('cgunowallet.error_accept'),'danger')
      } else {
        this.props.history.push('/moncompte/mon-velo');
      }

      this.setState({
        formData: { ...this.state.formData, },
        formError: { ...this.state.formError },
        formEvent: {
          ...this.state.formEvent,
          ready: true
        },
      })
    }
  }

  render() {
    const { t } = this.props;
    let cguTitle = window.API.getSubDomain()
    return (
      <main className={"c-main-content u-pd-hz-xl u-pd-top-m u-pd-xl@main"} style={{ maxWidth: "430px", margin: "auto" }}>

        {window.API.getSubDomain() === 'bicycode' &&
          <h1 className="c-h2 u-center u-bold u-mg-bottom-xl">{t('cgunowallet.title_bicycode')}</h1>
        }
        {window.API.getSubDomain() !== 'bicycode' &&
          <h1 className="c-h2 u-center u-bold u-mg-bottom-xl">{t('cgunowallet.title')}</h1>
        }

        {window.API.getSubDomain() !== 'bicycode' &&
          <>
            <p className="u-fs-xs u-blue20 u-center u-mg-bottom-m">{t('cgunowallet.label_explain_p1')}</p>
            <p className="u-fs-xs u-blue20 u-center u-mg-bottom-m">{t('cgunowallet.label_explain_p2')}</p>
          </>
        }
        {window.API.getSubDomain() === 'bicycode' &&
          <>
            <p className="u-fs-xs u-blue20 u-center u-mg-bottom-m">{t('cgunowallet.label_explain_bicy_p1')}</p>
            <p className="u-fs-xs u-blue20 u-center u-mg-bottom-m">{t('cgunowallet.label_explain_bicy_p2')}</p>
            <p className="u-fs-xs u-blue20 u-center u-mg-bottom-m">{t('cgunowallet.label_explain_bicy_p3')}</p>
            <p className="u-fs-xs u-blue20 u-center u-mg-bottom-m">{t('cgunowallet.label_explain_bicy_p4')}</p>
            <p className="u-fs-xs u-blue20 u-center u-mg-bottom-m">{t('cgunowallet.label_explain_bicy_p5')}</p>
          </>
        }
        <div className="c-form-group u-flex u-flex-start-vt u-pd-vt-s u-mg-top-l u-mg-bottom-s">
          <input
              className="c-checkbox u-mg-right-s"
              id="CGU"
              name="cguAccept"
              type="checkbox"
              checked={this.state.formData.cguAccept}
              onChange={this.handleInputChange}
          />
          {window.API.getSubDomain() === 'mfc' &&
            <label className="u-mg-0 u-blue20 u-fs-xs" htmlFor="CGU">
              {t('cgunowallet.label_accept_p1')} <a href={NO_WALLET_TOU_LINK} target="_blank" rel="noopener noreferrer"><u>{t('cgunowallet.label_accept_p2')} {cguTitle.charAt(0).toUpperCase() + cguTitle.slice(1)}</u></a> {t('cgunowallet.label_accept_p3')}
            </label>
          }

          {window.API.getSubDomain() === 'wallet' &&
            <label className="u-mg-0 u-blue20 u-fs-xs" htmlFor="CGU">
              {t('cgunowallet.label_accept_p1')} <a href={WALLET_TOU_LINK[this.state.currentLanguage]} target="_blank" rel="noopener noreferrer"><u>{t('cgunowallet.label_accept_p2')} {cguTitle.charAt(0).toUpperCase() + cguTitle.slice(1)}</u></a> {t('cgunowallet.label_accept_p3')}
            </label>
          }

          {window.API.getSubDomain() === 'decathlon' &&
            <label className="u-mg-0 u-blue20 u-fs-xs" htmlFor="CGU">
              {t('cgunowallet.label_accept_p1')} <a href={WALLET_TOU_LINK[this.state.currentLanguage]} target="_blank" rel="noopener noreferrer"><u>{t('cgunowallet.label_accept_p2')} {cguTitle.charAt(0).toUpperCase() + cguTitle.slice(1)}</u></a> {t('cgunowallet.label_accept_p3')}
            </label>
          }

          {this.state.formError.cguAccept === true &&
            <p className="u-danger">{t('cgunowallet.error_accept')}</p>
          }
        </div>

        <div className="u-flex u-flex-center-hz u-mg-vt-l">
          <button
            type="submit"
            disabled={!this.state.formData.cguAccept}
            className="c-btn c-btn--primary"
            onClick={this.goToAccount.bind(this)}>
              {t('cgunowallet.label_continue')}
          </button>
        </div>
      </main>
    );
  }
}

export default withTranslation()(CGUNoWallet);
