import React from "react"
import { Typeahead } from "react-bootstrap-typeahead"
import "react-bootstrap-typeahead/css/Typeahead.css"

function filterBy(option, state) {
  if (state?.selected?.length) return true
  return option?.label.toLowerCase().indexOf(state?.text.toLowerCase()) > -1
}

const ToggleButton = ({ isOpen, onClick }) => (
  <button
    className="toggle-button"
    onClick={onClick}
    onMouseDown={e => {
      // Prevent input from losing focus.
      e.preventDefault()
    }}>
    {isOpen
        ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 292.362 292.361" width="15" height="15"><path d="M286.935 197.287L159.028 69.381c-3.613-3.617-7.895-5.424-12.847-5.424s-9.233 1.807-12.85 5.424L5.424 197.287C1.807 200.904 0 205.186 0 210.134s1.807 9.233 5.424 12.847c3.621 3.617 7.902 5.425 12.85 5.425h255.813c4.949 0 9.233-1.808 12.848-5.425 3.613-3.613 5.427-7.898 5.427-12.847s-1.814-9.23-5.427-12.847z"/></svg>
        : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 292.362 292.361" width="15" height="15"><path d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z"/></svg>
    }
  </button>
)

const FormAutocomplete = ({
    className,
    options,
    what,
    placeholder,
    defaultValue,
    handleInputChange,
    disabled

}) => {

    const handleChange = (event) => {
        // let inputValue = {
        //     target: {
        //         name: what,
        //         value: event[0].id,
        //         label: event[0].label
        //     }
        // }
        // for now the front is ony expected this format :
        let inputValue = {
            currentTarget: {
                name: what,
                id: event[0].id,
                label: event[0].label
            }
        }
        handleInputChange(inputValue)
    }

    return (
        <Typeahead
          filterBy={filterBy}
          id="toggle-example"
          options={options}
          placeholder={placeholder}
          className={"c-autocomplete " + (className ? className : "")}
          onChange={handleChange}
          disabled={disabled}
        >
          {({ isMenuShown, toggleMenu }) => (
            <ToggleButton isOpen={isMenuShown} onClick={e => toggleMenu()} />
          )}
        </Typeahead>
      )
}

export default FormAutocomplete
